import React, {
  useEffect, useState, useMemo, useCallback, useContext,
} from 'react';
import {
  Button, Col, Row, Tabs, Tab, Alert, Spinner, ButtonGroup, OverlayTrigger
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { FiAlertCircle } from 'react-icons/fi';
import { FaPen, FaTimes, FaTrash } from 'react-icons/fa';
import Api from '../../services/api';

import './Forms.scss';
import MachineForm from '../database/MachineForm';
// import MaterialForm from '../database/MaterialForm';
import { renderTooltip } from '../common/helpers';
import { SettingsContext } from '../common/settings-context';
import loglevel from '../../services/loglevel';

/**
 * EditableMachine combines saving logic with the form from MachineForm
 * @param {Object} props for this component
 * @returns React component
 */
const EditableMachine = ({
  machine, fromUnitId, onSubmit, onCancel, project, organizationId, readOnly, hideFooter
}) => {
  const { t } = useTranslation();
  const [editMachine, setEditMachine] = useState(machine);

  const handleSaveEditMachine = async () => {
    // Select the correct operation
    let response = null;
    if (editMachine.id === undefined || editMachine.id === null || editMachine.id === 0) {
      response = await Api().machines().post(editMachine);
    } else {
      response = await Api().machines(editMachine.id).update(editMachine);
    }
    onSubmit(response);
  };

  return (
    <>
      <Row>
        <Col>
          <div className="material-form">
            <MachineForm
              organizationId={organizationId}
              machine={machine}
              fromUnitId={fromUnitId}
              onChange={(m) => setEditMachine(m)}
              project={project}
              readOnly={readOnly}
              isInstallation
              editable={false}
              hideTrash={false}
              editMachineDetails={false}
            />
          </div>
        </Col>
      </Row>

      {!hideFooter && <Row className="justify-content-end">
        <Col xs={4} className="text-right">
          <Button variant="default" onClick={() => { onCancel(); }}>{t('common.Cancel')}</Button>
          <Button onClick={() => handleSaveEditMachine()}>{t('common.Save')}</Button>
        </Col>
      </Row>}
    </>
  );
};
EditableMachine.propTypes = {
  machine: PropTypes.node.isRequired,
  fromUnitId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

/**
 * InstallationPhase component contains form for installation editing with phase selection
 * In addition, it allows selecting custom conversions if provided by the machine
 *
 * @param {Object} props for this component
 * @returns React component
 */
const InstallationPhase = ({
  installation, onChange, onRemove, element, project, organizationId, toggleError
}) => {
  const { getUnitById, getUnitByCode} = useContext(SettingsContext);
  const { t } = useTranslation();

  // Visibility
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Installation Phases
  const [installationPhaseData, setInstallationPhaseData] = useState([]);

  // UI elements
  const [installationPhaseId, setInstallationPhaseId] = useState(0);
  const [phaseOptionId, setPhaseOptionId] = useState(installation.installationPhaseOptionId);
  const [phaseMachineId, setPhaseMachineId] = useState(installation.machineId);
  const [machineConversionId, setMachineConversionId] = useState(installation.machineConversionId);

  // installation materials and machine
  const [material, setMaterial] = useState(installation.material);
  const [machine, setMachine] = useState(installation.machine);

  // Listings (based on selection)
  const installationPhase = useMemo(() => installationPhaseData
    .find((ipd) => ipd.id === installationPhaseId), [installationPhaseId, installationPhaseData]);

  // Provide unique phase options
  const phaseOptions = useMemo(() => installationPhase?.options ?? [], [installationPhase]);
  const phaseOptionName = useMemo(() => phaseOptions.find((o) => o.id === phaseOptionId)?.name ?? '', [phaseOptions, phaseOptionId]);
  const phaseOptionNames = useMemo(() => phaseOptions?.map((o) => o.name).reduce((acc, cur) => {
    if (!acc.includes(cur)) {
      return [...acc, cur];
    }
    return acc;
  },
    []) ?? [], [phaseOptions]);

  const allPhaseOptions = useMemo(() => installationPhaseData
    .reduce((acc, cur) => [
      ...acc,
      ...cur.options.map((o) => ({ ...o, installationPhase: cur })),
    ], []), [installationPhaseData]);
  const phaseOption = useMemo(() => allPhaseOptions.find((o) => o.id === phaseOptionId) ?? null,
    [allPhaseOptions, phaseOptionId]);

  const phaseMachines = useMemo(() => (phaseOption !== null
    ? installationPhase?.options
      .filter((o) => o.name === phaseOption.name)
      .map((o) => o.machine)
      .reduce((acc, cur) => ([...acc, cur]), []) ?? []
    : []), [phaseOption, installationPhase]);
  // if machineId changes. update machine
  useEffect(() => {
    setMachine(phaseMachines.find((m) => m.id === phaseMachineId) ?? null);
  }, [phaseMachineId]);

  // Helpers
  const getConversionById = (conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found !== undefined) {
      return found;
    }
    return { id: 0, fromUnitId: 0, toUnitId: 0 };
  };
  const hasMachineCorrectConversion = useMemo(() => {
    if (machine === null || machine.id === 0 || phaseOptionId === 0 || phaseMachineId === 0) {
      return true; // no selection. All ok!
    }

    const availableElementUnits = [element.unitId, ...element.conversions.map((c) => c.toUnitId)];
    const availableConversions = machine.conversions
      .filter((c) => availableElementUnits.includes(c.fromUnitId));

    if (availableConversions.map((c) => c.id).includes(machineConversionId)) {
      return true; // fixed by overriding conversion id
    }

    const availableMachineEmission = machine.emissions.map((e) => e.fromUnitId);
    const machineEmissionwithElementUnit = availableElementUnits.some((r) => availableMachineEmission.includes(r));

    if (machineEmissionwithElementUnit) {
      return true;
    }

    const machineConversions = machine.conversions
      .find((c) => c.id === phaseOption?.machineConversionId);

    if (machineConversions === undefined) {
      return false;
    }

    if (machineConversions.fromUnitId === element.unitId) {
      return true; // Matches
    }
    return false;
  }, [machine, machineConversionId, phaseOption, element]);

  /**
   * Attempt to find appropriate conversion from the machine
   */
  const fixMachineConversionSelection = useCallback(() => {
    if (hasMachineCorrectConversion) {
      return;
    }
    loglevel.info('fixing machine conversions');
    const conversions = machine.conversions.filter((c) => c.fromUnitId === element.unitId);
    if (conversions.length > 0) {
      loglevel.info(conversions);
      setMachineConversionId(conversions[0].id);

      if (typeof onChange === 'function') {
        onChange({
          ...installation,
          machineConversionId: conversions[0].id,
          machineFactor: (phaseOption?.machineFactor === 0 ? 1 : phaseOption?.machineFactor) ?? 1,
        });
      }
    }
  }, [machine, hasMachineCorrectConversion]);

  // Get installation phases
  const retrieveInstallationPhases = () => Api().installationPhases().get();

  // Start by retrieving phases
  useEffect(() => {
    retrieveInstallationPhases().then((phases) => {
      console.log(phases);
      console.log(organizationId);
      //filter by project organization Id
      const filteredPhases = phases.filter(phase => phase.organization_id === organizationId);
      console.log(filteredPhases);
      setInstallationPhaseData(filteredPhases);
      setIsLoading(false);
    });
  }, []);

  // handle change event of the installation phase dropdown
  const handleInstallationPhaseChange = (e) => {
    const id = Number(e.target.value);
    const selected = installationPhaseData.find((item) => item.id === id);
    if (selected !== undefined) {
      setInstallationPhaseId(selected.id);
      setPhaseOptionId(selected.options[0].id);
      setPhaseMachineId(selected.options[0].machineId);
      setMachine(selected.options[0].machine);
      setMachineConversionId(selected.options[0].machineConversionId);
      fixMachineConversionSelection();
      toggleError();
    } else {
      setInstallationPhaseId(0);
      setPhaseOptionId(0);
      setPhaseMachineId(0);
      setMachine(null);
      setMachineConversionId(0);
    }
  };

  useEffect(() => {
    if (typeof onChange === 'function' && machine !== null) {
      onChange({
        ...installation,
        machine,
        installationPhaseOptionId: phaseOptionId,
        machineId: phaseMachineId,
        machineFactor: (phaseOption?.machineFactor === 0 ? 1 : phaseOption?.machineFactor) ?? 1,
        machineConversionId,
        name: machine?.name,
      });
    }
  }, [phaseOptionId]);

  // handle change event of the phase option dropdown
  const handlePhaseOptionChange = (e) => {
    const name = e.target.value;

    // No selection
    if (name === '') {
      setPhaseOptionId(0);
      setPhaseMachineId(0);
      setMachine(null);
      setMachineConversionId(0);
      return;
    }

    const options = phaseOptions.filter((item) => item.name === name);

    if (options.length > 0) {
      if (phaseMachineId > 0) {
        const found = options.find((s) => s.machineId === phaseMachineId);
        if (found !== undefined) {
          setPhaseOptionId(found.id);
          setPhaseMachineId(found.machineId);
          setMachine(found.machine);
          setMachineConversionId(found.machineConversionId);
          return;
        }
      }
      const selected = options[0];
      setPhaseOptionId(selected.id);
      setPhaseMachineId(selected.machineId);
      setMachine(selected.machine);
      setMachineConversionId(selected.machineConversionId);
    }
  };

  const handleMachineChange = (e) => {
    const id = Number(e.target.value);
    if (id === 0) {
      setMachine(null);
      setPhaseMachineId(id);
      setMachineConversionId(0);
    }
    if (id > 0) {
      const allMachines = phaseOptions
        .reduce((acc, cur) => ([...acc, { ...cur.machine, option: cur }]), []) // combine machines
        .filter((m, idx, arr) => arr.map((a) => a.id).indexOf(m.id) === idx); // remove duplicates
      const selectedMachine = allMachines.find((m) => m.id === id);
      if (selectedMachine !== null) {
        setMachine(selectedMachine);
        setPhaseMachineId(id);
        setPhaseOptionId(selectedMachine.option.id);
      }
      setMachineConversionId(selectedMachine.option.machineConversionId);
    }
  };

  const handleMachineConversionChange = (e) => {
    const id = Number(e.target.value);
    if (id > 0) {
      if (typeof onChange === 'function') {
        onChange({
          ...installation,
          machineConversionId: id,
          machineFactor: (phaseOption?.machineFactor === 0 ? 1 : phaseOption?.machineFactor) ?? 1,
        });
      }
    }
  };

  const handleMachineSelect = (m) => {
    setMachine(m);
    setShowDetailsForm(false);

    if (typeof onChange === 'function') {
      onChange({
        ...installation,
        machine: { ...m },
        machineId: m.id,
        name: m.name,
      });
    }
  };

  // const handleMaterialSelect = (m) => {
  //   setMaterial(m);
  //   setShowDetailsForm(false);

  //   if (typeof onChange === 'function') {
  //     onChange({
  //       ...installation,
  //       material: { ...m },
  //       materialId: m.id,
  //     });
  //   }
  // };

  const phaseOptionConversion = useMemo(() => machine?.conversions
    .find((c) => c.id === phaseOption?.machineConversionId),
    [machine, phaseOption]);

  const isCustomConversion = useMemo(() => phaseOption !== null
    && machineConversionId !== phaseOption?.machineConversionId
    && machineConversionId !== 0
    && machineConversionId !== null,
    [machineConversionId, phaseOption]);

  const getRequiredUnitId = useCallback(() => {
    if (installation.materialId > 0) {
      return getConversionById(
        installation.material.conversions,
        installation.materialConversionId,
      ).toUnitId;
    }
    return element.unitId;
  }, [element, installation]);

  useEffect(() => {
    // if installation contains selection. Set that value
    if (installation !== undefined
      && installation !== null
      && allPhaseOptions.length > 0) {
      if (installation.installationPhaseOptionId !== null
        && installation.installationPhaseOptionId >= 0) {
        const option = allPhaseOptions.find((o) => o.id === installation.installationPhaseOptionId);
        if (option !== undefined) {
          const selected = option.installationPhase;
          loglevel.info('selecting option', selected, option);
          setMachine(option.machine);
          setPhaseMachineId(option.machine.id);
          setInstallationPhaseId(selected.id);
          setPhaseOptionId(option.id);
          setMachineConversionId(option.machineConversionId);
        }
      }
    }
  }, [installation, allPhaseOptions]);

  return (
    <>

      <Row className="installation__row">
        <Col xl={4}>
          <div className="label" >
            <span>{t('installation.Select Installation Stage')}</span>
          </div>
          {installation.machine.id !== undefined && (
            <Row>
              <Col className="flex-grow-1">
                <select
                  className="form-control form-control-md"
                  id={`installationPhase-${installation.id}`}
                  onChange={handleInstallationPhaseChange}
                  value={installationPhaseId}
                >
                  <option value={0}>{t('installation.Select option')}</option>
                  {installationPhaseData.filter((p) => p.options.length > 0).map((u) => (
                    <option key={u.id} value={u.id}>{u.name}</option>
                  ))}
                </select>
              </Col>
              {isLoading
                && (
                  <Col className="flex-shrink-1 flex-grow-0">
                    <Spinner variant="primary" animation="border" role="status" size="md">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                )}
              {installationPhaseId > 0 && (
                <Col xs={6}>
                  <select
                    className="form-control form-control-md"
                    id={`installationPhaseOption-${installation.id}`}
                    onChange={handlePhaseOptionChange}
                    value={phaseOptionName}
                  >
                    <option value="">{t('installation.Select option')}</option>
                    {phaseOptionNames.map((u) => (
                      <option key={u} value={u}>{u}</option>
                    ))}
                  </select>
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col xl={3}>
          {phaseMachines.length > 0 && (
            <>
              <div className="label">
                <span>{t('installation.Select machine')}</span>
              </div>
              <select
                className="form-control form-control-md"
                id={`installationPhaseOption-${installation.id}`}
                onChange={handleMachineChange}
                value={phaseMachineId}
              >
                <option value={0}>{t('installation.Select machine')}</option>
                {phaseMachines.map((u) => (
                  <option key={u.id} value={u.id}>{u.name}</option>
                ))}
              </select>
            </>
          )}
        </Col>
        <Col xl={2}>
          {phaseMachines.length > 0 && machine !== null && isCustomConversion && (
            <>
              <div className="label">
                <span>{t('installation.Select Conversion')}</span>
              </div>
              <select
                className="form-control form-control-md"
                id={`machineConversion-${installation.id}`}
                onChange={handleMachineConversionChange}
                value={installation.machineConversionId}
              >
                {machine.conversions.filter((c) => c.fromUnitId === element.unitId).map((c) => (
                  <option key={c.id} value={c.id}>
                    {getUnitById(c.fromUnitId).abbreviation}
                    {' '}
                    &#8594;
                    {' '}
                    {getUnitById(c.fromUnitId).abbreviation}
                    {c.comment !== '' && c.comment !== null && (` (${c.comment})`)}
                  </option>
                ))}
              </select>
            </>
          )}
        </Col>
        <Col xs={4} xl={{ span: 1, offset: 0 }}>
          <h6>
            {t('common.Emission')}
            {' '}
            (
            <span style={{ textTransform: 'none' }}>{getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}</span>
            )
          </h6>
          {`${installation.totalEmission.toFixed(2)}`}
        </Col>
        <Col xs={3} xl={2} className="text-right">
          {(machine !== null || material !== null) && (
            <ButtonGroup>
              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installation.Edit machine and conversions', 1))}>
              <Button variant="outline-info" onClick={() => { setShowDetailsForm((status) => !status); }} disabled={machine === null}><FaPen /></Button>
              </OverlayTrigger>
              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installation.Delete installation', 2))}>
              <Button variant="outline-danger" onClick={() => { onRemove(); }}><FaTrash /></Button>
              </OverlayTrigger>
            </ButtonGroup>)}
        </Col>
      </Row>

      {isCustomConversion && (
        <Row className="mt-2 installation__row">
          <Col>
            <Alert variant="info">
              {t('installation.Custom conversion in use')}
            </Alert>
          </Col>
        </Row>
      )}
      {!hasMachineCorrectConversion && machine !== null && (
        <>
          <Row className="mt-2">
            <Col>
              <Alert className="m-0" variant="danger">
                <FiAlertCircle size="24" />
                {' '}
                {t('installation.The pre selected conversion is ')} {` '${getUnitById(phaseOptionConversion?.fromUnitId).abbreviation}'`}
                &#8594;
                {' '}
                {` '${getUnitById(phaseOptionConversion?.toUnitId).abbreviation}'`}
                {' '}
                {t('installation.Please convert the element quantity to ')}
                {' '}
                {` '${getUnitById(phaseOptionConversion?.fromUnitId).abbreviation}'`}
                {' '}
                {t('installation.using the i button')}
              </Alert>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Alert className="m-0" variant="danger">
                <FiAlertCircle size="24" />
                {' '}
                {t(`installation.If you don't have a conversion in`)}
                {' '}
                {` '${getUnitById(phaseOptionConversion?.fromUnitId).abbreviation}'`}
                {' '}
                {t('installation.please add a conversion in any of the available Machine Emissions units using the i button')}
              </Alert>
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-2">
        <Col xl={12} >
          {showDetailsForm && (
            <EditableMachine
              organizationId={organizationId}
              machine={machine}
              fromUnitId={getRequiredUnitId()}
              onSubmit={(m) => handleMachineSelect(m)}
              onCancel={() => setShowDetailsForm(false)}
              project={project}
              readOnly={machine.name !== ''}
              hideFooter={true}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

InstallationPhase.propTypes = {
  installation: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  element: PropTypes.node.isRequired,
};

export default InstallationPhase;