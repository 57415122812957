import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form, Alert
} from 'react-bootstrap';

export default (props) => {
  const { t } = useTranslation();

  const [project, setProject] = useState({
    ...props,
    name: '',
    description: '',
    id: -1,
    status: '',
    projectType: '',
    locationGeo: '',
    locationName: '',
    locationType: '',
    projectManager: '',
    dates: '',
  });

  useEffect(() => {
    setProject(props.project, [props.project]);
    // setInputList(props.project.elements, [props.project.elements])
  }, [props.project, setProject]);

  const handleChange = (event) => {
    project[event.target.name] = event.target.value;
    // project.elements = inputList
    setProject(project);
    props.onChange(project);
  };

  return (
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>{t('common.Name')}</Form.Label>
        <Form.Control name="name" defaultValue={project.name} type="text" placeholder={t('common.Enter name')} onChange={handleChange} />
      </Form.Group>
      {props.scenarioError && <Alert variant="danger">{props.scenarioError}</Alert>}      
      <Form.Group controlId="formDescription">
        <Form.Label>{t('common.Description')}</Form.Label>
        <Form.Control name="description" defaultValue={project.description} type="text" placeholder={t('common.Enter description')} onChange={handleChange} />
      </Form.Group>
    </Form>
  );
};
