import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import {
  Col,
  Button,
  Table,
  Row,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { FaTrash, FaPaperPlane } from 'react-icons/fa';
import InviteDialog from './InviteDiaglog';
import DeleteDialog from './DeleteDialog';
import Api from '../../services/api';
import { any } from 'prop-types';

const ProjectUsers = ({ projectId }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [user, setUser] = useState([]);  

  // Asynchronous retrievers
  const getUsersAndRoles = useCallback(async () => ({
    users: await Api().projects(projectId).users().get(),
  }), [projectId]);

  const handleUserDeletion = async () => {
    await getUsersAndRoles();
    setShowDeleteDialog(false);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        {t('project.You can invite users to edit or view the calculation')}
        <ul className="pt-1 pl-3">
          <li>{t('project.ProjectUser Permission to edit the calculation')}</li>
          <li>{t('project.ProjectReader Permission to view the calculation')}</li>
        </ul>
      </Popover.Content>
    </Popover>
  );

  const [currentUser, setCurrentUser] = useState(null);

  async function getCurrentUser() {
    const user = await Api().accounts.me.get();
    setCurrentUser(user);
  }

  useEffect(() => {
    getCurrentUser();
  },[])
  const getUsers = async()=> {
    const userList = await Api().projects(projectId).users().get()
    setUsers(userList);
  }

  // Side-effects
  useEffect(() => {
    let isMounted = true;
    if (projectId > 0) {
      getUsersAndRoles().then(({ users, roles }) => isMounted && setUsers(users));
    }
    return () => { isMounted = false; }; // cleanup
  }, [getUsersAndRoles, projectId, showDeleteDialog]);

  return (
    <div className="card-background">
      <div>
      {showInviteDialog && <InviteDialog
          projectId={projectId}
          currentUser={currentUser}
          show={showInviteDialog}
          onClose={() => setShowInviteDialog(false)}
          getUsers={getUsers}
        />}
        <DeleteDialog
          projectId={projectId}
          description={'User'}
          show={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          object={user}
          deletionType={'user'}
          onUserDeleted={handleUserDeletion}
        />
      </div>
      <Row>
        <Col>
          <h2 className="h5">
            {t('project.Users')}
            {' '}
            <OverlayTrigger trigger={("hover")} placement="right" overlay={popover}>
              <FiHelpCircle />
            </OverlayTrigger>
          </h2>
        </Col>
        <Col className="text-right">
          <Button onClick={() => { setShowInviteDialog(true); }} variant="outline-primary">
            <FaPaperPlane />
            {' '}
            {t('project.Invite user')}
          </Button>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>{t('common.Name')}</th>
            <th>{t('project.Email')}</th>
            <th>{t('project.Role')}</th>
            <th>{t('')}</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.id}>
              <td>{u.name}</td>
              <td>{u.email.toLowerCase()}</td>
              <td>{u.roles.map((r) => t(r.name)).join(', ')}</td>
              <td className="text-right">
                <Button variant="outline-danger" onClick={() => {
                  setShowDeleteDialog(true);
                  setUser(u);
                }}>
                  <FaTrash />
                  {' '}
                  {t('common.Remove')}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default ProjectUsers;
