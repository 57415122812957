import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Nav,
  Dropdown,
  Row,
  Col,
  FormControl,
} from 'react-bootstrap';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import Materials from './Materials';
import Machines from './Machines';
import Vehicles from './Vehicles';
import Vessels from './Vessels';
import Templates from './Templates';
import InstallationPhases from './InstallationPhases';

import './Database.scss';
import { useState, useCallback, useEffect } from 'react';
import Api from "../../services/api";

export default () => {
  const { t } = useTranslation();
  const [organizations, setOrganizations] = useState([]);
  const [dropDownValue, setDropDownValue] = useState(null);
  const [dropDownButtonValue, setDropDownButtonValue] = useState('Select Data Source');

  const getOrganizations = useCallback(async () => {
    const getAllOrganizations = await Api().settings.organizations();
    const sortedOrganization = getAllOrganizations.sort((a,b) => a.name.localeCompare(b.name));
    setOrganizations(sortedOrganization);
    handleSelect(localStorage.getItem('org') ?? JSON.stringify(sortedOrganization[0]));    
  }, [organizations]);

  const handleSelect = (event) => {
    setDropDownValue(JSON.parse(event).id);
    const parsedEvent = JSON.parse(event);
    const translatedName = t(`organization.${parsedEvent.name}`);
    setDropDownButtonValue(translatedName);
    localStorage.setItem('org', event);
  }

  useEffect(() => {
    getOrganizations();
    setDropDownButtonValue(t('defaultButtonText'));
  }, [t]);

  return (
    <div>

      <Container className="my-4" fluid>
        <Row>
          <Col md={1}> </Col>

          <Col md={9}>
            <Nav variant="pills" defaultActiveKey="/database/" className="justify-content-center">
              <LinkContainer to="/database/materials">
                <Nav.Link>{t('product.Materials')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/database/vehicles">
                <Nav.Link>{t('database.Vehicles')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/database/vessels">
                <Nav.Link>{t('database.Vessels')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/database/machines">
                <Nav.Link>{t('database.Machines')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/database/installationphases">
              <Nav.Link>{t('database.Installation Phases')}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/database/templates">
                <Nav.Link>{t('element.Templates')}</Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>

          <Col md={2} className='text-right'>          
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {dropDownButtonValue}                
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  {organizations.map((ele) => <Dropdown.Item value={ele.id} eventKey={JSON.stringify(ele)} > {t(`organization.${ele.name}`)} </Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>

      <Container className="my-4" fluid>
        <Switch>
          <Route
            exact
            path="/database"
            render={() => (<Redirect to="/database/materials" />)}
          />
          <Route exact key="materials" path="/database/materials" children={<Materials organizationSelect={dropDownValue} />} />
          <Route exact key="vehicles" path="/database/vehicles" children={<Vehicles organizationSelect={dropDownValue} />} />
          <Route exact key="vessels" path="/database/vessels" children={<Vessels organizationSelect={dropDownValue} />} />
          <Route exact key="machines" path="/database/machines" children={<Machines organizationSelect={dropDownValue} />} />
          <Route exact key="templates" path="/database/templates" children={<Templates organizationSelect={dropDownValue} />} />
          <Route exact key="installationPhases" path="/database/installationPhases" children={<InstallationPhases organizationSelect={dropDownValue} />} />
        </Switch>
      </Container>
    </div>
  );
};
