import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import ProjectUsers from './ProjectUsers';
import Api from '../../services/api';
import { useHistory } from 'react-router-dom';

const DeleteDialog = ({ projectId, description, show, onClose, object, deletionType, onScenarioDeleted, onUserDeleted }) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(!!show);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  async function handleDelete() {
    switch (deletionType) {
      case 'user':
        await Api().projects(projectId).users(object.id).delete();
        onUserDeleted();
        break;
      case 'project':
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
        await Api().projects(projectId).delete({});
        setIsLoading(false);
        history.push('/home');

        break;
      case 'scenario':
        await Api().scenarios(object.id).delete(object.id);
        onScenarioDeleted();
        break;
    }
    setShowDialog(false);
    //window.location.reload()
  };

  return (
    <Modal show={showDialog} onHide={() => { setShowDialog(false); onClose(); }}>
      <Modal.Header>
        <Modal.Title>{t('common.Delete')}{' '} {description}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {isLoading ? (
        <body className='text-center' style={{alignItems: 'center'}}>
          <Spinner variant="danger" animation="border" role="status" size='lg' style={{ marginLeft: '10px' }} />
          <span style={{ marginLeft: '10px', paddingBottom: '10px' }}>
              {t('common.Deletion of')} {' '} {object.name} {' '} {t('common.in progress')}...
          </span>
        </body>
        ):(
          <body className="text-center">
            {t('common.Are you sure you want to delete')} '{object.name}'? {t('common.This action cannot be undone')}!
          </body>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => {
          handleDelete();
        }}>
          <FaTrash />
          {' '}
          {t('common.Remove')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteDialog;
