import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Popover,
  OverlayTrigger,
  Container,
  ModalTitle,
  Alert
} from 'react-bootstrap';

import Api from '../../services/api';
import { defaultHandleChange } from '../common/helpers';
import SortTable from '../common/SortTable';
import { SettingsContext } from '../common/settings-context';
import DeleteDialog from './DeleteDialog';
import ScenarioForm from './scenarios/ScenarioForm';

import './Project.css';

const emptyprojectScenario = {
  name: '',
  description: '',
  id: -1,
  status: '',
  projectType: '',
  locationGeo: '',
  locationName: '',
  locationType: '',
  projectManager: '',
  dates: '',
  elements: [{
    name: '',
    description: '',
    code: '',
    amount: 0,
    unit: {
      id: 0,
      name: 'string',
      symbol: 'string',
      abbreviation: 'string',
    },
    totalEmission: 0,
  }],
};

const ProjectScenarios = (props) => {
  const { t } = useTranslation();
  const { projectId } = props;
  const [scenarios, setScenarios] = useState([]);
  const [showScenarioDialog, setShowScenarioDialog] = useState(false);
  const [showEditScenarioDialog, setShowEditScenarioDialog] = useState(false);
  const { isInRole } = useContext(SettingsContext);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [scenarioDelete, setScenarioDelete] = useState([]);

  const [editedScenario, setEditedScenario] = useState({ ...emptyprojectScenario, projectId });
  const [editScenario, setEditScenario] = useState(false);

  const scenarioHeaders = [{ name: t('common.Name'), key: 'name' }, { name: t('common.Description'), key: 'description' }];
  const scenarioActions = 'projectScenario';
  const [scenarioError, setScenarioError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    Api().projects(projectId).getScenarios({}).then((result) => {
      if (isMounted) {
        setScenarios(result);
      }
    });
    return () => { isMounted = false; }; // cleanup
  }, [projectId]);

  useEffect(() => {
    return ()=>{
      setScenarioError(null)
    }
  },[editedScenario])

  const getScenarios = async () => {
    const scenarios = await Api().projects(projectId).getScenarios({});
    setScenarios(scenarios);
  };

  const handleSaveScenario = async () => {
    editedScenario.projectId = projectId;
    if (editedScenario.name?.toString()?.trim() === '') {
      setScenarioError('Please enter scenario name');
      return;
    }
    if (editedScenario.id <= 0) {
      setEditedScenario(await Api().scenarios().post(editedScenario));
      setShowScenarioDialog(false);
    } else {
      editedScenario.projectId = projectId;
      setEditedScenario(await Api().scenarios(editedScenario).update(editedScenario));
      setShowScenarioDialog(false);
    }
    getScenarios();
  };

  const handleEditScenario = (editedScenarioLocal) => {
    setEditedScenario(editedScenarioLocal);
    setShowEditScenarioDialog(true);
  };
  const handleEditSc = async () => {
    let scenarioName = editedScenario?.name?.toString()?.trim();
    if (scenarioName === '') {
      setScenarioError('Please enter scenario name');
      return;
    }
    await Api().scenarios(editedScenario).update(editedScenario);
    setShowEditScenarioDialog(false);
    getScenarios();
  };

  const onChange = function (action) {
    if (typeof props.onChange === 'function') {
      props.onChange(editedScenario);
    }
  };

  const handleChange = (event) => {
    defaultHandleChange(event, setEditedScenario);
    onChange(editedScenario);
  };

  const handleScenarioDeletion = async () => {
    await getScenarios();
    setShowDeleteDialog(false);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.element-info-description')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="card-background">
      <div>
        <Modal show={showScenarioDialog} onHide={() => { setShowScenarioDialog(false); }}>
          <Modal.Header closeButton>
            <Modal.Title>{editedScenario.id <= 0 ? t('scenario.Create scenario') : t('scenario.Edit scenario')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScenarioForm project={editedScenario} onChange={(editedScenario) => setEditedScenario(editedScenario)} scenarioError={scenarioError} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleSaveScenario()}>{t('common.Save')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Modal show={showEditScenarioDialog} onHide={() => { setShowEditScenarioDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('scenario.Edit scenario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>{t('common.Name')}</Form.Label>
              <Form.Control name="name" defaultValue={editedScenario.name} type="text" placeholder={t('common.Enter name')} onChange={handleChange} />
            </Form.Group>
            {scenarioError && <Alert variant="danger">{scenarioError}</Alert>}
            <Form.Group controlId="formDescription">
              <Form.Label>{t('common.Description')}</Form.Label>
              <Form.Control name="description" defaultValue={editedScenario.description} type="text" placeholder={t('common.Enter description')} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleEditSc()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-2">
        <Row>
          <Col sm={8}>
            <h2 className="h5">
              {t('scenario.Scenarios')}
              {' '}
              <OverlayTrigger trigger={("hover")} placement="right" overlay={popover}>
                <FiHelpCircle />
              </OverlayTrigger>
            </h2>
          </Col>
          {(isInRole('ProjectUser', projectId) || isInRole('Admin')) && (
            <Col className="text-right">
              <Button
                variant="outline-primary"
                onClick={() => {
                  setEditedScenario({ ...emptyprojectScenario, projectId });
                  setShowScenarioDialog(true);
                }}
              >
                <FaPlus />
                {' '}
                {t('scenario.New scenario')}
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <div>
        <DeleteDialog
          projectId={scenarioDelete.projectId}
          description={'Scenario'}
          show={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          object={scenarioDelete}
          deletionType={'scenario'}
          onScenarioDeleted={handleScenarioDeletion}
        />
      </div>
      <SortTable
        hideActions = {!(isInRole('Admin') || isInRole('ProjectUser', projectId))}
        dataRows={scenarios}
        dataHeaders={scenarioHeaders}
        dataActions={scenarioActions}
        handleEditScenario={(item) => {
          handleEditScenario(item);
        }}
        handleRemoveScenario={(item) => {
          setScenarioDelete(item);
          setShowDeleteDialog(true);
        }}
      />
    </div>
  );
};
export default ProjectScenarios;
