import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Form,
  Button,
} from 'react-bootstrap';
import Api from '../../services/api';
import UserForm from './UserForm';

function removeNulls(obj) {
  return Object.keys(obj).reduce((acc, cur) => {
    acc[cur] = obj[cur] === null ? '' : obj[cur];
    return acc;
  }, {});
}

const Me = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentRoles, setCurrentRoles] = useState([]);
  async function checkHealth() {
    await Api().health.available();
  }
  async function getCurrentUser() {
    const user = await Api().accounts.me.get();
    setCurrentUser(user);
  }
  async function getCurrentRoles() {
    const roles = await Api().accounts.me.roles();
    setCurrentRoles(roles);
  }

  const enableAccessToken = async () => {
    await Api().accounts.me.update({ ...currentUser, isAccessTokenEnabled: true });
    getCurrentUser();
  };

  console.log(currentUser);

  const disableAccessToken = async () => {
    await Api().accounts.me.update({ ...currentUser, isAccessTokenEnabled: false });
    getCurrentUser();
  };

  useEffect(() => {
    checkHealth();
    getCurrentUser();
    getCurrentRoles();
  }, []);

  return (
    currentUser && currentRoles && ((
      <Container className="py-4">
        <UserForm user={currentUser} roles={currentRoles}>
          <Form.Label>{t('user.Access token')}</Form.Label>
          <Form.Group controlId="access_token">
            {currentUser.accessToken === null ? (
              <div>
                <Button onClick={() => enableAccessToken()}>{t('user.Enable access token access')}</Button>
              </div>
            ) : (
              <div>
                <Form.Control name="accessToken" value={currentUser.accessToken} type="text" />
                <Button onClick={() => disableAccessToken()}>{t('user.Disable access token access')}</Button>
              </div>
            )}
          </Form.Group>
        </UserForm>
      </Container>) || (<div>{t('Loading...')}</div>))
  );
};

export default Me;
