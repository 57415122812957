import React, {
  useState, useEffect, useCallback, Fragment
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  Modal,
  ButtonGroup,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Badge,
  Pagination,
} from 'react-bootstrap';
import {
  FaFileImport, FaPlus, FaTrash, FaPen, FaEye, FaGlobe, FaSearch, FaUser, FaDatabase
} from 'react-icons/fa';
import Api from '../../services/api';
import MaterialForm from './MaterialForm';
import { withSettingsStore } from '../common/settings-context';
import ImportForm from './ImportForm';
import loglevel from '../../services/loglevel';
import { humanize } from '../common/helpers';
import { sortObjectArray } from '../../utils/utilityFunctions';
import notFound from '../../assets/no-data.png';

import './Database.scss';
import { error } from 'loglevel';

const Materials = withSettingsStore(({
  units, displayError, isInRole, user, organizationSelect, getUnitByCode, getUnitById
}) => {
  const { t } = useTranslation();
  const itemsPerPageOptions = [
    { name: '20', value: 20 },
    { name: '50', value: 50 },
    { name: '100 ', value: 100 },
  ];

  const [materials, setMaterials] = useState([]);
  const [importedMaterials, setImportedMaterials] = useState([]);
  const [loadingElements, setLoadingElements] = useState(false);
  const [materialElements, setMaterialElements] = useState([]);
  const [materialTemplates, setMaterialTemplates] = useState([]);
  const [viewMaterialId, setViewMaterialId] = useState(0);
  const [showMaterialDialog, setShowMaterialDialog] = useState(false);
  const [showMaterialImportDialog, setShowMaterialImportDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [numItems, setNumItems] = useState(50);
  const [sortBy, setSortBy] = useState(null);
  const [loading, setLoading] = useState(false);
  const emptyMaterial = useCallback(() => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    organizationId: 0,
    source: '',
    conversions: [],
  }), []);
  const [newMaterial, setNewMaterial] = useState(emptyMaterial());
  const [searchText, setSearchText] = useState('');
  const [updates, setUpdates] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showDeleteMaterialDialog, setShowDeleteMaterialDialog] = useState(false);
  const [materialToDelete, setMaterialToDelete] = useState(emptyMaterial());
  const [errors, setErrors] = useState([]);
  const [toPublishMaterial, setToPublishMaterial] = useState(null); 
  const [validMaterialImport, setValidMaterialImport] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(0);

  const addDefaultConversions = (material) => {
    try {
      const defaultConversions = [
        { comment: "Autogenerated kg > t", factor: "1000", fromUnitId: getUnitByCode('kg', organizationSelect)?.[0]?.id, inverse: true, materialId: 0, toUnitId: getUnitByCode('t', organizationSelect)?.[0]?.id },
        { comment: "Autogenerated t > kg", factor: "1000", fromUnitId: getUnitByCode('t', organizationSelect)?.[0]?.id, inverse: false, materialId: 0, toUnitId: getUnitByCode('kg', organizationSelect)?.[0]?.id }
      ];

      material.conversions.push(...defaultConversions);
    } catch (error) {
      console.log('Failed to add autogenerated conversions', error);
    };
  };

  const getUnitAbbreviation = useCallback((unitId) => {
    const unit = units.find((u) => u.id === unitId);
    if (unit === undefined) {
      return '';
    }
    return unit.abbreviation;
  }, [units]);

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const retrieveMaterials = useCallback(async () => {
    clearTimeout(currentTimeout);
    setLoading(true);
    setCurrentTimeout(setTimeout(async () => {
      const m = await Api().materials().get({
        search: searchText, limit: numItems, offset: page * numItems, sortBy, filterByOrgId: organizationSelect, filterType: selectedFilter
      });
      const sortedByEmission = sortObjectArray(m.items, 'emission', true)
      setMaterials(sortedByEmission);
      if (numItems > 0) {
        setLastPage(Math.floor(m.count / numItems));
        setPages((current) => {
          // iterate 1 ... lastPage
          current = [];
          for (let i = 0; i <= Math.floor(m.count / numItems); i++) {
            current.push(i + 1);
          }
          return current;
        });
      }
      setLoading(false);
    }, 1000));
  }, [searchText, numItems, page, sortBy, organizationSelect, selectedFilter]);
  console.log(materials);

  useEffect(() => {
    setPage(0);
  }, [searchText, numItems, selectedFilter]);

  const handleSaveMaterial = async () => {
    const errorLog = []
    if (!newMaterial?.name?.trim()) {
      errorLog.push('name')
    } 
    if (!newMaterial?.source?.trim()) {
      errorLog.push('source')
    }
    setErrors(errorLog);
    if (errorLog.length > 0) {
      return;
    }

    setLoading(true);
    if (newMaterial.id > 0) {
      await Api().materials(newMaterial.id).update(newMaterial);
    } else {
      newMaterial.organizationId = organizationSelect;
      addDefaultConversions(newMaterial);
      await Api().materials().post(newMaterial);
    }
    setShowMaterialDialog(false);
    retrieveMaterials();
    // const m = await Api().materials().get({ search: searchText })
    // setMaterials(m.items)
    setLoading(false);
  };

  const removeMaterial = async (material) => {
    if (material.isLinked) {
      displayError(t('database.failed-to-remove-material'));
    } else {
      setMaterialToDelete(material);
      setShowDeleteMaterialDialog(true);
    }
  };

  const handleRemoveMaterial = async (id) => {
    try {
      await Api().materials(id).delete();
    } catch (e) {
      displayError(t('database.failed-to-remove-material'));
    }
    retrieveMaterials();
    // const m = await Api().materials().get({ search: searchText })
    // setMaterials(m.items)
  };
  const handleEditMaterial = (material) => {
    setNewMaterial(material);
    setShowMaterialDialog(true);
  };
  const handleNewMaterial = () => {
    setNewMaterial(emptyMaterial());
    setShowMaterialDialog(true);
  };
  const handleMaterialImport = async () => {
    setLoading(true);
    // loglevel.info('Handling materials', importedMaterials);
    // Loop through the imported materials. Two separate arrays (posts, updates) are created, one for new materials and one for updates. 
    importedMaterials.forEach(x => {
      // Check if the material is a duplicate
      if (x.duplicate === true) {
        x.id.forEach(singleId => {
          let filteredConversions = x.conversions // Filter the conversions based on the material id. Conversions are not updated, previous cnversions are kept. TODO: Be able to import conversions. 
            .flatMap(conversionArray => conversionArray) // Flatten the array of arrays
            .filter(conversion => conversion.materialId === singleId); 
          // Create a new object with the id and filtered conversions.
          let updatedMaterial = { ...x, id: singleId, conversions: filteredConversions };
          updates.push(updatedMaterial);
        });
      }
      // If the material is not a duplicate, create a new material with no conversions. TODO: Be able to import conversions.
      else {
        let newMaterial = { ...x, id: 0 };
        posts.push(newMaterial);
      }
    });

    //post new materials and loop through updates to handle each id seperately
    await Api().materials().post(posts);
    updates.forEach(u => {
      importUpdate(u);
    })
    setImportedMaterials([]);
    setUpdates([]);
    setPosts([]);
    setShowMaterialImportDialog(false);
    setLoading(false);
    retrieveMaterials();
  };
  const importUpdate = async (u) => {
    await Api().materials(u.id).update(u);
  };
  const handleViewMaterial = async (material) => {
    if (viewMaterialId === material.id) {
      setViewMaterialId(0);
    } else {
      setLoadingElements(true);
      setViewMaterialId(material.id);
      setMaterialElements(await Api().materials(material.id).elements());
      setMaterialTemplates(await Api().materials(material.id).templates());
      setLoadingElements(false);
    }
  };

  useEffect(() => {
    if (toPublishMaterial) {
      handleSaveMaterial(toPublishMaterial);
    }
  }, [toPublishMaterial]);

  const handlePublishMaterial = async (material) => {
    const updatedMaterial = { ...material, createdBy: null, updatedBy: null, emissionUpdatedBy: null, updatedAt: null, emissionUpdatedAt: null, publish: true };
    setNewMaterial(updatedMaterial);
    setToPublishMaterial(updatedMaterial);
  };

  const filterBySearch = useCallback((m) => m.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0, [searchText]);

  useEffect(() => {
    if (organizationSelect !== null) {
      retrieveMaterials();
    }
  }, [organizationSelect, retrieveMaterials]);

  const getMaterialStyle = (material) => {
    let background = 'white';
    if (material.isLinked) {
      background = '#d4edda';
    }
    if (material.state === 1) {
      background = 'red';
    }
    return {
      background,
    };
  };

  return (
    <div>
      <Modal show={showDeleteMaterialDialog} onHide={() => { setShowDeleteMaterialDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('database.Delete material')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {materialToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteMaterialDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button variant="outline-danger" onClick={() => { handleRemoveMaterial(materialToDelete.id); setShowDeleteMaterialDialog(false) }}>
            <FaTrash />
            {' '}
            {t('common.Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        dialogClassName="modal--lg"
        show={showMaterialImportDialog}
        onHide={() => { setShowMaterialImportDialog(false); }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('product.Import materials')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportForm 
          onChange={(e) => setImportedMaterials(e)} 
          organizationId={organizationSelect} 
          setValidMaterialImport={setValidMaterialImport}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button 
          onClick={() => handleMaterialImport()}
          disabled={!validMaterialImport}>
            {t('common.Import')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMaterialDialog} onHide={() => { setShowMaterialDialog(false); setErrors([]); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('product.New material')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MaterialForm
            material={newMaterial}
            onChange={(m) => setNewMaterial(m)}
            organizationId={organizationSelect}
            units={units} 
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveMaterial()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>
      <div className="button-toolbar d-flex flex-column">
        <div className="row d-flex align-items-center">
          <div className="col-auto">
            <InputGroup style={{ height: '40px' }}>
              <ButtonGroup className="d-flex align-items-center">
                <Button
                  variant="primary"
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#05326e',
                    borderColor: '#05326e',
                    color: 'white',
                    opacity: 1,
                  }}
                  disabled
                >
                  {t('common.View')}
                </Button>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t('common.All materials', 1))}
                >
                  <Button
                    variant={selectedFilter === 0 ? 'primary' : 'outline-primary'}
                    onClick={() => setSelectedFilter(0)}
                    className="d-flex align-items-center"
                  >
                    <FaUser />
                    <FaDatabase />
                    {/* All materials */}
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t('common.My materials', 2))}
                >
                  <Button
                    variant={selectedFilter === 1 ? 'primary' : 'outline-primary'}
                    onClick={() => setSelectedFilter(1)}
                    className="d-flex align-items-center"
                  >
                    <FaUser />
                    {/* My Materials */}
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(t('common.Published materials', 3))}
                >
                  <Button
                    variant={selectedFilter === 2 ? 'primary' : 'outline-primary'}
                    onClick={() => setSelectedFilter(2)}
                    className="d-flex align-items-center"
                  >
                    <FaDatabase />
                    {/* Published materials */}
                  </Button>
                </OverlayTrigger>
                <div className="col-0">
                  <InputGroup>
                    <FormControl
                      className="database-forms-placeholder"
                      style={{ height: '40px', textAlign: 'center', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                      onChange={(e) => { setSearchText(e.target.value); }}
                      placeholder={
                        selectedFilter === 1
                          ? t('common.Search my materials')
                          : selectedFilter === 2
                            ? t('common.Search published materials')
                            : t('common.Search all materials')
                      }
                    />
                  </InputGroup>
                </div>
              </ButtonGroup>
            </InputGroup>
          </div>
          <div className="col-auto">
            <InputGroup style={{ height: '40px' }}>
              <FormControl
                as="select"
                value={numItems}
                onChange={(e) => setNumItems(Number(e.target.value))}
                style={{ height: '40px' }}
              >
                {itemsPerPageOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.name} {t('database.per page')}
                  </option>
                ))}
              </FormControl>
            </InputGroup>
          </div>

          {(numItems > 0 && materials.length > 0) && (
            <div className="row d-flex justify-content-center" style={{ height: '40px' }}>
              <Pagination className="sticky-pagination d-flex custom-pagination">
                <Pagination.First
                  disabled={page <= 0}
                  onClick={() => setPage(0)}
                />
                <Pagination.Prev
                  disabled={page <= 0}
                  onClick={() => setPage((p) => p - 1)}
                />

                {(() => {
                  const maxPagesToShow = 5;
                  let startPage = Math.max(0, page - Math.floor(maxPagesToShow / 2));
                  let endPage = startPage + maxPagesToShow - 1;

                  if (endPage > lastPage) {
                    endPage = lastPage;
                    startPage = Math.max(0, endPage - maxPagesToShow + 1);
                  }

                  if (startPage < 0) {
                    startPage = 0;
                  }

                  const pagesToShow = [];
                  for (let i = startPage; i <= endPage; i++) {
                    pagesToShow.push(
                      <Pagination.Item
                        key={`page-${i}`}
                        active={i === page}
                        onClick={() => setPage(i)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    );
                  }
                  return pagesToShow;
                })()}

                <Pagination.Next
                  disabled={page >= lastPage}
                  onClick={() => setPage((p) => p + 1)}
                />
                <Pagination.Last
                  disabled={page >= lastPage}
                  onClick={() => setPage(lastPage)}
                />
              </Pagination>
            </div>
          )}

          <div className="col-auto ml-auto">
            <ButtonGroup>
              <Button variant="outline-primary" onClick={() => handleNewMaterial()}>
                <FaPlus /> {t('product.New material')}
              </Button>
              {isInRole('Admin') && (
                <Button variant="outline-primary" onClick={() => setShowMaterialImportDialog(true)}>
                  <FaFileImport /> {t('common.Import')}
                </Button>
              )}
            </ButtonGroup>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : <>
          <div id='material' className='table-container'>
            <Table>
              <thead className="sticky1-header">
                <tr>
                  <th style={{ width: '1vw' }}></th>
                  <th style={{ width: '20vw' }}>{t('common.Name')}</th>
                  <th style={{ width: '20vw' }}>{t('common.Description')}</th>
                  <th style={{ width: '5vw' }}>{t('database.Module')}</th>
                  <th style={{ width: '5vw' }}>
                {t('common.Emission factor')}
                    {/* {' '}
              (
              <span style={{ textTransform: 'none' }}>{getUnitByCode('kgco2eq', organizationSelect)[0].symbol}</span>
              ) */}
                  </th>
                  <th style={{ width: '5vw' }}>{t('common.Unit')}</th>
                  <th style={{ width: '5vw' }}>{t('common.Conversions')}</th>
                  <th style={{ width: '10vw' }}>{t('database.Source')}</th>
                  <th style={{ width: '5vw' }}>{t('database.Expiration Year')}</th>
                  <th style={{ width: '5vw' }}>{t('database.Created')}</th>
                  <th style={{ width: '5vw' }}>{t('database.Created By')}</th>
                  <th style={{ width: '5vw' }}>{t('database.Updated')}</th>
                  {isInRole('Admin') && (<th style={{ width: '5vw' }}>{t('database.Updated By')}</th>)}
                  <th style={{ width: '5vw' }}>{t('database.Updated (Emission Factor)')}</th>
                  {isInRole('Admin') && (<th style={{ width: '5vw' }}>{t('database.Updated By (Emission Factor)')}</th>)}
                  {!isInRole('Admin') && (<th style={{ width: '5vw' }}>{t('database.Updated By')}</th>)}
                  <th style={{ width: '2vw' }} />
                </tr>
              </thead>
              <tbody>
                {materials.length > 0 ? (materials.filter(filterBySearch).map((m) => (
                  <Fragment key={m.id}>
                    <tr style={getMaterialStyle(m)}>
                      <td></td>
                      <td>{m.name?.trim() ? m.name : '-'}</td>
                      <td>{m.description?.trim() ? m.description : '-'}</td>
                      <td>{m.module?.trim() ? m.module : '-'}</td>
                      <td>{m.emission}</td>
                      <td>
                        <Badge key={m.unitId} className="m-1" variant="secondary">
                          {getUnitByCode('kgco2eq', organizationSelect)[0].symbol}
                          {' '}
                          /
                          {' '}
                          {getUnitAbbreviation(m.unitId)}
                        </Badge>
                      </td>
                      <td>
                        {m.conversions.length === 0 ? t('-') : (
                          m.conversions.map((c) => (
                            <Badge key={c.id} variant="secondary">
                              {getUnitById(c.fromUnitId).symbol}
                              {' '}
                              &#8594;
                              {' '}
                              {getUnitById(c.toUnitId).symbol}
                            </Badge>
                          ))
                        )}
                      </td>
                      <td>{m.source?.trim() ? m.source : '-'}</td>
                      <td>{m.expirationYear === 0 ? '-' : m.expirationYear}</td>
                      <td>{m.createdAt ? humanize.date(m.createdAt, 'date') : '-'}</td>
                      {isInRole('Admin') && (<td>
                        {m.createdByName === null ? (
                          <strong>{t('common.Published')}</strong>
                        ) : (
                          m.createdBy === user.id ? (
                            <strong>{m.createdByName}</strong>
                          ) : 
                            m.createdByName
                        )}
                      </td>)}
                      {!isInRole('Admin') && (<td>
                        {m.createdByName === null ? (
                          <strong>{t('common.Published')}</strong>
                        ) : (
                          m.createdBy === user.id ? (
                            <strong>{t('database.You')}</strong>
                          ) : 
                            t('database.Application Admin')
                        )}
                      </td>)}
                      <td>{m.updatedAt ? humanize.date(m.updatedAt, 'date') : '-'}</td>
                      {isInRole('Admin') && (<td>{m.updatedByName === null ? '-' : (
                          m.updatedBy === user.id ? (
                            <strong>{m.updatedByName}</strong>
                          ) : 
                            m.updatedByName
                        )}
                      </td>)}
                      <td>{m.emissionUpdatedAt ? humanize.date(m.emissionUpdatedAt, 'date') : '-'}</td>
                      {isInRole('Admin') && (<td>{m.emissionUpdatedByName === null ? '-' : (
                          m.emissionUpdatedBy === user.id ? (
                            <strong>{m.emissionUpdatedByName}</strong>
                          ) : 
                            m.emissionUpdatedByName
                        )}
                      </td>)}
                      {!isInRole('Admin') && (
                        <td>{m.updatedByName === null ? '-' : m.updatedBy === user.id ? (
                            <strong>{t('database.You')}</strong>
                          ) : (
                            t('database.Application Admin'))}
                        </td>)}
                      <td>
                        {user !== undefined && (
                          <ButtonGroup>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.Edit', 1))}
                            >
                              <Button
                                disabled={!(isInRole('Admin') || m.createdBy === user.id || m.createdBy === null)}
                                variant="outline-info"
                                onClick={() => { handleEditMaterial(m); }}
                              >
                                <FaPen />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.View', 1))}
                            >
                              <Button
                                disabled={!m.isLinked}
                                variant="outline-info"
                                onClick={() => { handleViewMaterial(m); }}
                              >
                                <FaEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.Publish', 1))}
                            >
                              <Button
                                disabled={!(isInRole('Admin'))}
                                variant="outline-info"
                                onClick={() => { handlePublishMaterial(m); }}
                              >
                                <FaGlobe />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.Delete', 2))}
                            >
                              <Button
                                disabled={!(isInRole('Admin') || m.createdBy === user.id)}
                                variant="outline-danger"
                                onClick={() => { removeMaterial(m); }}
                              >
                                <FaTrash />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        )}
                      </td>
                    </tr>
                    {viewMaterialId === m.id && (
                      <tr>
                        <td colSpan={isInRole('Admin') ? 17: 15}>
                          {loadingElements ? (
                            <div className="text-center">
                              <Spinner variant="primary" animation="border" role="status">
                                <span className="sr-only">{t('Loading...')}</span>
                              </Spinner>
                            </div>
                          )
                            : (
                              <div>
                                {materialElements.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="6">{t("element.Linked elements")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Element name")}</th>
                                      <th>{t("project.Project")}</th>
                                      <th>{t("scenario.Scenario")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {materialElements.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name?.trim() ? e.name : '-'}</td>
                                        <td>{e.projectName?.trim() ? e.projectName : '-'}</td>
                                        <td>{e.scenarioName?.trim() ? e.scenarioName : '-'}</td>
                                        <td>{e.code?.trim() ? e.code : '-'}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description?.trim() ? e.description : '-'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                                {materialTemplates.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="4">{t("element.Linked templates")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Template name")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {materialTemplates.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name?.trim() ? e.name : '-'}</td>
                                        <td>{e.code?.trim() ? e.code : '-'}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description?.trim() ? e.description : '-'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                              </div>
                            )}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))) :
                  <tr style={{ height: '60vh' }}>
                    <td colSpan={isInRole('Admin') ? 17: 15} className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                          <img src={notFound} className="img-responsive" style={{ height: '70px' }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                          <h1 style={{ fontSize: "20px" }}>{t('database.Data Not Available in the Database')}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>}
              </tbody>
            </Table>
        </div>
      </>
      }
    </div>
  );
});

const renderTooltip = (name, id) => (
  <Tooltip id={id}>
    {name}
  </Tooltip>
);

export default Materials;