import React, { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  ButtonGroup,
  Button,
  Modal,
  Badge,
  Spinner,
  OverlayTrigger,
} from 'react-bootstrap';
import { FaPlus, FaTrash, FaPen, FaEye } from 'react-icons/fa';
import Api from '../../services/api';
import VesselsForm from './VesselsForm';
import loglevel from '../../services/loglevel';
import { sortObjectArray } from '../../utils/utilityFunctions';
import notFound from '../../assets/no-data.png';
import { humanize, renderTooltip } from '../common/helpers';
import { SettingsContext } from '../common/settings-context';
import './Database.scss';

export default function Vessels({ organizationSelect }) {

  const { t } = useTranslation();
  const [vessels, setVessels] = useState([]);
  const [newVessel, setNewVessel] = useState({ id: -1 });
  const [showVesselDialog, setShowVesselDialog] = useState(false);
  const [units, setUnits] = useState([]);
  const [error, setError] = useState({
    title: t('common.Error'),
    text: '',
  });
  const [formError, setFormError] = useState([]);

  const [showError, setShowError] = useState(false);
  const emptyVessel = () => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    organizationId: 0,
    source: '',
  });
  const [showDeleteVesselDialog, setShowDeleteVesselDialog] = useState(false);
  const [vesselToDelete, setVesselToDelete] = useState(emptyVessel());
  const [loading, setLoading] = useState(false);
  const [viewVesselId, setViewVesselId] = useState(0);
  const [loadingElements, setLoadingElements] = useState(false);
  const [vesselElements, setVesselElements] = useState([]);
  const [vesselTemplates, setVesselTemplates] = useState([]);

  const { getUnitByCode, getUnitById, isInRole, user } = useContext(SettingsContext);

  const retrieveVessels = useCallback(async () => {
    const v = await Api().transports().waterborne().get();
    const sortedByEmission = sortObjectArray(v, 'emission', true)
    setVessels(sortedByEmission);
  }, [setVessels]);

  const getVesselsByOrgId = useCallback(async (organizationSelect) => {
    setLoading(true);
    const res = await Api().transports().waterborne().getVesselsByOrgId(organizationSelect);
    const sortedByEmission = sortObjectArray(res, 'emission', true)
    setVessels(sortedByEmission);
    setLoading(false);
  }, [setVessels])


  const handleSaveVessel = async () => {
    const errorLog = []
    if (!newVessel?.name?.trim()) {
      errorLog.push('name');
    }
    if (!newVessel?.source?.trim()) {
      errorLog.push('source');
    }
    setFormError(errorLog);
    if (errorLog.length > 0) {
      return;
    }

    setLoading(true);
    loglevel.info('save', newVessel);
    if (newVessel.id <= 0) {
      newVessel.organizationId = organizationSelect;
      await Api().transports().waterborne().post(newVessel);
    } else {
      await Api().transports().waterborne(newVessel.id).update(newVessel);
    }
    setLoading(false);
    setShowVesselDialog(false);
    // retrieveVessels();
    getVesselsByOrgId(organizationSelect);
  };

  const removeVessel = async (vessel) => {
    if (vessel.isLinked) {
      handleRemoveVessel(vessel.id);
    } else {
      setVesselToDelete(vessel);
      setShowDeleteVesselDialog(true);
    }
  };

  const handleRemoveVessel = async (id) => {
    loglevel.info(`remove ${id}`);
    try {
      if (id > 0) {
        await Api().transports().waterborne(id).delete();
        // retrieveVessels();
        getVesselsByOrgId(organizationSelect);
      }
    } catch (e) {
      displayError(t('database.failed-to-remove-transport'));
    }

  };

  const retrieveUnitsByOrgId = useCallback(async (orgId) => {
    try {
      let units = await Api().settings.units();
      setUnits(units);
    } catch (error) {
      console.error('Error retrieving units by organization ID:', error);
    }
  }, []);

  // const getUnitAbbreviation = useCallback((unitId) => {
  //   const unit = units.find((u) => u.id === unitId);
  //   if (unit === undefined) {
  //     return '';
  //   }
  //   return unit.abbreviation;
  // }, [units]);

  const displayError = useCallback((text, title) => {
    setError({ title: title === undefined ? t('common.Error') : title, text });
    setShowError(true);
  }, [t]);

  const handleNewVessel = () => {
    setNewVessel(emptyVessel());
    setShowVesselDialog(true);
  };

  useEffect(() => {
    // retrieveVessels();
    getVesselsByOrgId(organizationSelect);
    retrieveUnitsByOrgId(organizationSelect);
  }, [organizationSelect]);

  const handleViewWaterborneTransport = async (vessel) => {
    if (viewVesselId === vessel.id) {
      setViewVesselId(0);
    } else {
      setLoadingElements(true);
      setViewVesselId(vessel.id);
      setVesselElements(await Api().transports().waterborne(vessel.id).elements(vessel.id));
      setVesselTemplates(await Api().transports().waterborne(vessel.id).templates(vessel.id));
      setLoadingElements(false);
    }
  };

  return (
    <div>
      <Modal show={showDeleteVesselDialog} onHide={() => setShowDeleteVesselDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("database.Delete vessel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("common.Are you sure you want to delete")} {vesselToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteVesselDialog(false)}>
            {t("common.Cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              handleRemoveVessel(vesselToDelete.id);
              setShowDeleteVesselDialog(false);
            }}
          >
            <FaTrash /> {t("common.Delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showError} onHide={() => setShowError(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error.text}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowError(false)}>{t("common.Close")}</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVesselDialog}
        onHide={() => {
          setShowVesselDialog(false);
          setFormError([]);
        }}
        dialogClassName="form-large"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("database.New vessel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VesselsForm vessel={newVessel} errors={formError} organizationId={organizationSelect} onChange={(v) => setNewVessel(v)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveVessel()}>{t("common.Save")}</Button>
        </Modal.Footer>
      </Modal>
      <div className="button-toolbar">
        <div className="divider" />
        <ButtonGroup>
          <Button variant="outline-primary" onClick={() => handleNewVessel()} disabled={!isInRole("Admin")}>
            <FaPlus /> {t("database.New vessel")}
          </Button>
        </ButtonGroup>
      </div>
      {loading ? (
        <div className="position-fixed text-center" style={{ left: "50%", top: "50%" }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div id="vessel" className="table-container">
            <Table>
              <thead className="sticky1-header">
                <tr>
                  <th style={{ width: "1vw" }}></th>
                  <th style={{ width: "20vw" }}>{t("common.Name")}</th>
                  <th style={{ width: "20vw" }}>{t("common.Description")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Module")}</th>
                  <th style={{ width: "5vw" }}>
                    {t("common.Emission factor")}
                  </th>
                  <th style={{ width: "5vw" }}>{t("common.Unit")}</th>
                  <th style={{ width: "10vw" }}>{t("database.Source")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Expiration Year")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Created")}</th>
                  {isInRole('Admin') && (<th style={{ width: "10vw" }}>{t("database.Created By")}</th>)}
                  <th style={{ width: "5vw" }}>{t("database.Updated")}</th>
                  {isInRole('Admin') && (<th style={{ width: "10vw" }}>{t("database.Updated By")}</th>)}
                  <th style={{ width: "2vw" }} />
                </tr>
              </thead>
              <tbody>
                {vessels?.length > 0 ? (
                  vessels.map((v) => (
                    <Fragment key={v.id}>
                      <tr key={v.id} style={{ background: v.isLinked ? "#d4edda" : "white" }}>
                        <th />
                        <td>{v.name}</td>
                        <td>{v.description?.trim() ? v.description : '-'}</td>
                        <td>{v.module?.trim() ? v.module : '-'}</td>
                        <td>{v.emission.toFixed(3)}</td>
                        <td>
                          <Badge key={v.emissionUnitId} className="m-1" variant="secondary">
                            {getUnitById(v.emissionUnitId).symbol} / {getUnitByCode("tkm", organizationSelect)?.[0]?.symbol}
                          </Badge>
                        </td>
                        <td>{v.source?.trim() ? v.source : '-'}</td>
                        <td>{v.expirationYear === 0 ? '-' : v.expirationYear}</td>
                        <td>{v.createdAt ? humanize.date(v.createdAt, "date") : '-'}</td>
                        {isInRole('Admin') && (<td>{v.createdByName === null ? '-' : (
                          v.createdBy === user.id ? (
                            <strong>{v.createdByName}</strong>
                          ) : 
                          v.createdByName
                        )}
                        </td>)}
                        <td>{v.updatedAt ? humanize.date(v.updatedAt, "date") : '-'}</td>
                        {isInRole('Admin') && (<td>{v.updatedByName === null ? '-' : (
                          v.updatedBy === user.id ? (
                            <strong>{v.updatedByName}</strong>
                          ) : 
                          v.updatedByName
                        )}
                        </td>)}
                        <td align="right">
                          <ButtonGroup>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Edit", 1))}>
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  setNewVessel(v);
                                  setShowVesselDialog(true);
                                }}
                                disabled={!isInRole("Admin")}
                              >
                                <FaPen />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.View", 1))}>
                              <Button
                                disabled={!v.isLinked}
                                variant="outline-info"
                                onClick={() => {
                                  handleViewWaterborneTransport(v);
                                }}
                              >
                                <FaEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Delete", 2))}>
                              <Button variant="outline-danger" onClick={() => removeVessel(v)} disabled={!isInRole("Admin")}>
                                <FaTrash />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                      {viewVesselId === v.id && (
                        <tr>
                          <td colSpan={isInRole('Admin') ? 13: 11}>
                            {loadingElements ? (
                              <div className="text-center">
                                <Spinner variant="primary" animation="border" role="status">
                                  <span className="sr-only">{t("Loading...")}</span>
                                </Spinner>
                              </div>
                            ) : (
                              <div>
                                {vesselElements.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="6">{t("element.Linked elements")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Element name")}</th>
                                      <th>{t("project.Project")}</th>
                                      <th>{t("scenario.Scenario")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {vesselElements.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name?.trim() ? e.name : '-'}</td>
                                        <td>{e.projectName?.trim() ? e.projectName : '-'}</td>
                                        <td>{e.scenarioName?.trim() ? e.scenarioName : '-'}</td>
                                        <td>{e.code?.trim() ? e.code : '-'}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description?.trim() ? e.description : '-'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                                {vesselTemplates.length > 0 && (
                                <Table className="table-sm">
                                  <thead>
                                    <tr style={{ fontStyle: 'italic' }}>
                                      <th colSpan="4">{t("element.Linked templates")}</th>
                                    </tr>
                                    <tr className="view-header">
                                      <th>{t("common.Template name")}</th>
                                      <th>{t("common.Code")}</th>
                                      <th>{t("common.Unit")}</th>
                                      <th>{t("common.Description")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className='view-body'>
                                    {vesselTemplates.map((e) => (
                                      <tr key={e.id}>
                                        <td>{e.name?.trim() ? e.name : '-'}</td>
                                        <td>{e.code?.trim() ? e.code : '-'}</td>
                                        <td>{getUnitById(e.unitId).symbol}</td>
                                        <td>{e.description?.trim() ? e.description : '-'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <tr style={{ height: "60vh" }}>
                    <td colSpan={isInRole('Admin') ? 13: 11} className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                          <img src={notFound} className="img-responsive" style={{ height: "70px" }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                          <h1 style={{ fontSize: "20px" }}>{t("database.Data Not Available in the Database")}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
}