import React, { useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Table,
  Button,
  Form,
  Popover,
  ButtonGroup,
  Overlay,
} from 'react-bootstrap';

import { FaPen, FaTrash } from 'react-icons/fa';

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    if (items.length > 0) {
      const sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig
            && sortConfig.key === key
            && sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default function SortTable({
  dataRows, dataHeaders, dataActions, handleEditScenario, handleRemoveScenario, hideActions,
}) {
  const { t } = useTranslation();
  const data = dataRows || {};
  const [show, setShow] = useState(false);
  const [actions] = useState(dataActions || null);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const { items, requestSort } = useSortableData(data);
  const [headers] = useState(
    dataHeaders || {},
  );
  const [hidden, setHidden] = useState([]);

  const setVisibleColumns = (header) => {
    if (hidden.some((item) => item.hasOwnProperty(header))) {
      setHidden(hidden.filter((item, index) => index === header));
    } else {
      setHidden([...hidden, { [header]: true }]);
    }
  };
  const checkVisibility = (key) => {
    if (hidden.some((item) => item.hasOwnProperty(key))) {
      return true;
    }
    return false;
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const checkActions = (item) => {
    if (actions === 'projectScenario') {
      return (
        <td key="actions" className="text-right">
          <ButtonGroup>
            <Button variant="outline-info" onClick={() => handleEditScenario(item)}>
              <FaPen />
              {' '}
              {t('common.Edit')}
            </Button>
            <Button variant="outline-danger" onClick={() => handleRemoveScenario(item)}>
              <FaTrash />
              {' '}
              {t('common.Remove')}
            </Button>
          </ButtonGroup>
        </td>
      );
    }
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>
              {t('common.Name')}
            </th>
            <th>
              {t('common.Description')}
            </th>                       
          </tr>
        </thead>
        <tbody>
          {items && items.map((item, index) => (
            <tr key={index}>
              {headers && headers.map((h) => h.key).map((key) => (
                <td hidden={checkVisibility(key)} key={key + index}>{item[key]}</td>
              ))}
              {!hideActions && checkActions(item)}
            </tr>
          ))}
          {!items && <tr><td colSpan={headers.length + 2}><Alert variant="danger">{t('scenario.No items')}</Alert></td></tr>}
        </tbody>
      </Table>
    </>
  );
}
