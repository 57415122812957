import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
} from 'react-bootstrap';

const AccountDialog = (props) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({
    email: '',
    password: '',
    password2: '',
  });

  const handleChange = (event) => {
    user[event.target.name] = event.target.value;
    setUser(user);

    props.onChange(user);
  };
  return (
    <Form>
      <Form.Group controlId="formEmail">
        <Form.Label>{t('user.Email address')}</Form.Label>
        <Form.Control name="email" value={props.email} type="email" placeholder={t('project.Enter email')} onChange={handleChange} />
        <Form.Text className="text-muted">
          {t("We'll never share your email with anyone else.")}
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="formPassword">
        <Form.Label>{t('Password')}</Form.Label>
        <Form.Control name="password" value={props.password} type="password" placeholder={t('Enter password')} onChange={handleChange} />
        <br />
        <Form.Control name="password2" value={props.password2} type="password" placeholder={t('Enter password again')} onChange={handleChange} />
        <Form.Text className="text-muted">
          {t('Password requires at least one uppercase, one number and one special character.')}
        </Form.Text>
      </Form.Group>
    </Form>
  );
};

export default AccountDialog;
