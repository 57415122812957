import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
} from 'react-bootstrap';
import { withSettingsStore } from '../common/settings-context';
import Error from '../common/Error';
import './Database.scss'

export default withSettingsStore(({ vessel, onChange, units, organizationId, getUnitByCode, errors }) => {
  const { t } = useTranslation();
  const [newVessel, setNewVessel] = useState({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    ...vessel === undefined ? {} : vessel,
  });

  useEffect(() => {
    onChange(newVessel);
  }, [newVessel, onChange]);

  useEffect(() => {
    if (newVessel.id !== vessel.id) {
      setNewVessel(vessel);
    }
  }, [vessel, newVessel.id]);

  const handleChange = (event, element) => {
    const { name, value } = event.currentTarget;
      element[name] = value;
      onChange(element);
  };

  const TextField = ({ element, field, placeholder, checkNumber, formValidation }) => (<Form.Control type={`${checkNumber ? "number" : "text"}`} defaultValue={element[field] === 0 && field === "expirationYear" ? " " : element[field]} name={field} onChange={(event) => handleChange(event, element)} placeholder={placeholder} className={`database-forms-placeholder ${formValidation}`}/>);

  return (
    <Form>
      <div>
        <h4>
          {t('database.Vessel')}
          {' '}
        </h4>
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  * {t('common.Name')}
                  {errors?.includes('name') && <span className="error-text" > {t('project.is required')}</span>}
                </Form.Label>
                <TextField
                  element={newVessel}
                  field="name"
                  placeholder={t('vesselForm.eg Name')}
                  formValidation={errors?.includes('name') ? 'error-glow' : ''}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Description')}
                </Form.Label>
                <TextField element={newVessel} field="description" placeholder={t('vesselForm.eg Description')} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('database.Module')}
                </Form.Label>
                <TextField element={newVessel} field="module" placeholder={t('vesselForm.eg Module')}/>
              </Form.Group>
            </Col>
          </Row>

          {/* Conditional Error Display for Name field */}
          {/* {errors.includes('name') && (
            <Row>
              <Col xs="4"> <Error text={`${t("common.Name")} ${t("project.is required")}`} /> </Col>
            </Row>
          )} */}

          <Row className="align-items-end">
          <Col>
              <Form.Group>
                <Form.Label>
                  * {t('database.Source')}
                  {errors?.includes('source') && <span className="error-text"> {t('project.is required')}</span>}
                </Form.Label>
                <TextField
                  element={newVessel}
                  field="source"
                  placeholder={t('vesselForm.eg Source')}
                  formValidation={errors?.includes('name') ? 'error-glow' : ''}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('database.Expiration Year')}
                </Form.Label>
                <TextField element={newVessel} field="expirationYear" placeholder={t('vesselForm.eg Expiration Year')} checkNumber={true} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Emission factor')}{' '}
                  <small style={{ textTransform: 'none' }}>
                    ({getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/{getUnitByCode('tkm', organizationId)?.[0]?.symbol})
                  </small>
                </Form.Label>
                <TextField element={newVessel} field="emission" checkNumber={true} />
              </Form.Group>
            </Col>
          </Row>

          {/* Conditional Error Display for Source field */}
          {/* {errors.includes('source') && (
            <Row>
              <Col xs="4"> <Error text={`${t("common.Enter source")}`} /> </Col>
            </Row>
          )} */}
        </>
      </div>
    </Form>
  );
});