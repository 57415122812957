import React, {
  useState, useEffect, Fragment, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaExclamationCircle,
} from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import {
  Card,
  Button,
  Alert,
  Dropdown,
  ButtonGroup,
  Row, Col
} from 'react-bootstrap';

import { withSettingsStore } from '../common/settings-context';

import CalculationHeader from './CalculationHeader';
import InstallationPhase from './InstallationPhase';
import InstallationLegacy from './InstallationLegacy';
import ElementConversionsModal from './ElementConversionModal';

import generators from './generators';
import { useCallback } from 'react';
import Api from '../../services/api';
import './Forms.scss';

const InstallationForm = withSettingsStore(({
  element: localElement,
  getUnitById,
  onChange,
  isValid,
  saveCalculations,
  organizationId,
  error,
  toggleError,
  setDropDownError,
  ...props
}) => {
  const { t } = useTranslation();
  const [element, setElement] = useState(localElement);
  const createInstallation = (i) => {
    i = i === undefined ? {} : i;
    return {
      id: i.id ? Number(i.id) : 0,
      materialId: i.materialId ? i.materialId : null,
      machineId: i.machineId ? Number(i.machineId) : 0,
      materialConversionId: i.materialConversionId
        ? i.materialConversionId
        : null,
      machineConversionId: i.machineConversionId
        ? i.machineConversionId
        : null,
      installationPhaseOptionId: i.installationPhaseOptionId !== undefined
        ? i.installationPhaseOptionId
        : 0,
      materialFactor: i.materialFactor ? Number(i.materialFactor) : 1,
      machineFactor: i.machineFactor ? Number(i.machineFactor) : 1,
      name: i.name,
      comment: i.comment,
      totalEmission: i.totalEmission ? Number(i.totalEmission) : 0,
      material: (i.materialId !== null && i.materialId > 0)
        ? i.material
        : generators.createMaterial(),
      machine: (i.machineId !== null && i.machineId > 0)
        ? i.machine
        : generators.createMachine(),
    };
  };

  const hasInstallations = (e) => e.installations !== undefined && e.installations.length > 0;

  // By default validate the existing installations
  const [installations, setInstallations] = useState(hasInstallations(element) ? element.installations.map((i) => createInstallation(i)) : []);
  const [databaseInstalations, setDatabaseInstalations] = useState([])

  useEffect(() => {
    setElement(localElement);
    setInstallations(hasInstallations(localElement) ? localElement.installations.map((i) => createInstallation(i)) : []);
  }, [localElement]);

  const [showElementConversionForm, setShowElementConversionForm] = useState(false);

  // Event handlers
  const handleInstallationChange = (installation, i) => {
    const targetObject = installations[i];
    Object.keys(targetObject).forEach((key) => {
      targetObject[key] = installation[key];
    });
    setInstallations([...installations]);

    element.installations = installations;
    setElement({ ...element });

    onChange !== undefined && onChange(element);
  };

  const retrieveInstallationPhases = useCallback(async () => {
    const m = await Api().installationPhases().get({});
    setDatabaseInstalations(m)
  }, [setDatabaseInstalations]);

  const deleteInstallationPhase = async (id) => {
    await Api().installationPhases(id).delete();
  };

  const handleInstallationRemove = (installation, i) => {
    let emptyMachines = [];
    retrieveInstallationPhases();
    const databaseCopy = databaseInstalations.find((dbInstallation) => dbInstallation.id === installation.id);
    databaseCopy && deleteInstallationPhase(databaseCopy.id);
    const insts = [...installations.filter((ins) => ins !== installation)];
    setInstallations([...insts]);
    element.installations = insts;
    setElement({ ...element });
    emptyMachines = insts.filter((item) => item.machine.id == 0);
    if(emptyMachines.length == 0){
      toggleError();
    }
    onChange !== undefined && onChange(element);
  };

  const isValidInstallationPhaseConversion = useMemo(() => {
    const elementsWithInstallation = element.installations.filter((i) => i.installationPhaseOptionId > 0);
    if (elementsWithInstallation.length === 0) {
      return true;
    }

    const fromUnitIds = [element.unitId, ...element.conversions.map((c) => c.toUnitId)];
    const targetUnitIds = elementsWithInstallation.map((i) => i.machine.conversions.find((c) => c.id === i.machineConversionId)?.fromUnitId);
    const matching = targetUnitIds.filter((uid) => fromUnitIds.includes(uid));

    console.log(elementsWithInstallation);
    console.log(fromUnitIds, targetUnitIds, matching);

    return matching.length === elementsWithInstallation.length;
  }, [element]);

  return (
    <div className="installation">
      <CalculationHeader
        element={element}
        totalEmissionType="totalInstallationEmission"
        isValid={isValid}
        saveCalculations={saveCalculations}
        organizationId={organizationId}
      >
        {element.conversions.map((c) => (
          <span className="value d-block">
            {`${Math.round(c.factor * element.quantity)} ${getUnitById(c.toUnitId).abbreviation}`}
          </span>
        ))}
        <span className="value d-block">
          {!isValidInstallationPhaseConversion && (
            <Button variant="outline-danger" size="sm" onClick={() => setShowElementConversionForm(true)}><FaExclamationCircle /></Button>
          )}
        </span>
      </CalculationHeader>

      <ElementConversionsModal
        element={element}
        show={showElementConversionForm}
        onClose={() => { setShowElementConversionForm(false); }}
        onSubmit={(e) => {
          setElement({ ...element, conversions: e.conversions });
          onChange({ ...element, conversions: e.conversions });
          setShowElementConversionForm(false);
        }}
        scenarioId={element.scenarioId}
        elementId={element.id}
        organizationId={organizationId}
      />
      {installations.map((installation, i) => (
        <Fragment key={`${installation.id}-${i}`}>
          {installation.installationPhaseOptionId !== null && installation.installationPhaseOptionId >= 0
            ? (
              <Card className="installation__item">
                <Card.Body className="installation__content">
                  <InstallationPhase
                    organizationId={organizationId}
                    element={element}
                    installation={installation}
                    index={i}
                    onChange={(inst) => { handleInstallationChange(inst, i); }}
                    onRemove={() => { handleInstallationRemove(installation, i); }}
                    project={props.project}
                    error={error}
                    toggleError={toggleError}
                    isInstallation
                  />
                </Card.Body>
              </Card>
            )
            : (
              <Card className="installation__item">
                <Card.Body className="installation__content">
                  <InstallationLegacy
                    organizationId={organizationId}
                    element={element}
                    installation={installation}
                    onChange={(inst) => { handleInstallationChange(inst, i); }}
                    onRemove={() => { handleInstallationRemove(installation, i); }}
                    project={props.project}
                    isInstallation
                    setDropDownError={setDropDownError}
                  />
                </Card.Body>
              </Card>
            )}

        </Fragment>
      ))}
      {error && <Row className="mt-2 installation__row">
        <Col>
          <Alert variant="danger">
            {t('installation.Please select a Machine or Intallation Phase')}
          </Alert>
        </Col>
      </Row>}
      {installations.length === 0 ? (
        <Alert variant="primary" className="text-center p-5">
          <h2 className="h5">{t('installation.No installations')}</h2>
          <Dropdown className="mt-1" as={ButtonGroup}>
            <Button variant="primary" onClick={() => { setInstallations([...installations, createInstallation()]); }}>
              <FiPlus />
              {` ${t('installation.Add installation')}`}
            </Button>
            <Dropdown.Toggle split variant="primary" />
            <Dropdown.Menu alignRight>
              <Dropdown.Item onClick={() => { setInstallations([...installations, createInstallation()]); }}>{t('installation.Use new form')}</Dropdown.Item>
              <Dropdown.Item onClick={() => { setInstallations([...installations, createInstallation({ installationPhaseOptionId: null })]); }}>{t('installation.Use legacy form')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Alert>
      ) : (
      <Dropdown className="mt-1" as={ButtonGroup}>
        <Button variant="outline-primary" onClick={() => { setInstallations([...installations, createInstallation()]); }}>
          <FiPlus />
          {` ${t('installation.Add installation')}`}
        </Button>
        <Dropdown.Toggle split variant="outline-primary" />
        <Dropdown.Menu alignRight>
          <Dropdown.Item onClick={() => { setInstallations([...installations, createInstallation()]); }}>{t('installation.Use new form')}</Dropdown.Item>
          <Dropdown.Item onClick={() => { setInstallations([...installations, createInstallation({ installationPhaseOptionId: null })]); }}>{t('installation.Use legacy form')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      )}
    </div>
  );
});

export default InstallationForm;
