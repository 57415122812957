const generators = {
  createMachine: () => ({
    id: 0,
    name: '',
    description: '',
    emissions: [],
    conversions: [],
  }),
  createMaterial: () => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
  }),
};
export default generators;
