import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, OverlayTrigger, Popover, Row,
} from 'react-bootstrap';
import { FiInfo } from 'react-icons/fi';
import { humanize } from '../../common/helpers';

import car from '../../../assets/icons/car.svg';
import tree from '../../../assets/icons/tree.svg';
import airplane from '../../../assets/icons/plane.svg';
import people from '../../../assets/icons/people.svg';
import factory from '../../../assets/icons/factor.svg';

const EmissionVisualizator = (props) => {
  const { selectedEmissions } = props;
  const { t } = useTranslation();

  const visuals = [
    {
      converter: 0.1515, unit: 'km', label: 'car_kilometers', icon: car, desc: 'car_kilometers_desc', link: ['https://tieto.traficom.fi/fi/tilastot/liikennekaytossa-olevat-henkiloautot-kayttovoimat-paastot-ja-keski-ika'],
    },
    {
      converter: 4700, unit: 'ha', label: 'binding_amount_of_growing_forest', icon: tree, desc: 'binding_amount_of_growing_forest_desc', link: ['https://forest.fi/fi/faq/miten-puuston-sitoma-hiilidioksidimaara-voidaan-todentaa-luotettavalla-tavalla/#fe7ed5c3','https://www.luke.fi/fi/uutiset/metsien-kasvuvauhti-hidastui-mutta-puuston-tilavuus-suureni'],
    },
    {
      converter: 1250, unit: '', label: 'trip_to_thailand', icon: airplane, desc: 'trip_to_thailand_desc', link: ['https://helda.helsinki.fi/server/api/core/bitstreams/2cb36b36-c20c-4adf-b9e9-35a3ad46335e/content'],
    },
    {
      converter: 10300, unit: '', label: 'annual_emissions_of_person', icon: people, desc: 'annual_emissions_of_person_desc', link: ['https://www.sitra.fi/artikkelit/keskivertosuomalaisen-hiilijalanjalki/'],
    },
    {
      converter: 54.180 / 1000, unit: '€', label: 'eu_allowance_price', icon: factory, inverse: false, desc: 'eu_allowance_price_desc', link: ['https://energiavirasto.fi/-/paastokaupan-huutokauppatulot-511-miljoonaa-euroa'],
    },
  ];

  const calculateValue = (visual) => {
    const value = visual.inverse === false
      ? selectedEmissions * visual.converter
      : selectedEmissions / visual.converter;
    return humanize.amount_long(value, 0);
  };

  return (
    <>
      <Row className="p-4">
        {visuals.map((item) => (
          <Col xl="4" className="mb-4 text-center">
            <div className="h3 text-primary mb-0">
              <div><img src={item.icon} alt="" className="icon--xl" aria-hidden="true" /></div>
              {calculateValue(item)}
              <small>
                {' '}
                {item.unit}
              </small>
            </div>
            <span>
              {calculateValue(item) == 0
                ? t('results.Emissions below measurable equivalent')
                : (
                  <>
                    {t(`results.${item.label}`)} {calculateValue(item)} {Number((calculateValue(item)).replace(/\s/g, '')) > 1 ? t(`results2.${item.label}`) : t(`results3.${item.label}`)}
                  </>
                )
              }
            </span>
            <div>
              <OverlayTrigger
                trigger={['click']}
                placement="top"
                rootClose
                overlay={(
                  <Popover id="popover-basic" rootClose>
                    <Popover.Title>
                      {t(`results.${item.label}`)}
                    </Popover.Title>
                    <Popover.Content>
                      {t(`results.${item.desc}`)}
                      {' '}
                      <ul style={{'padding-inline-start' : '20px'}}>
                        {item.link.map((l) => <li><a style={{ display: 'block' }} href={`${l}`} target="_blank" rel="noopener noreferrer">{l}</a></li>)}
                      </ul>
                    </Popover.Content>
                  </Popover>
                )}
              >
                <Button variant="link" size="sm">
                  <FiInfo />
                </Button>
              </OverlayTrigger>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

EmissionVisualizator.propTypes = {
  selectedEmissions: PropTypes.number.isRequired,
};

export default EmissionVisualizator;
