import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Alert,
  Table,
  Nav,
  Badge,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Bar } from 'react-chartjs-2';
import Api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';
import { DropDown, PageHeader } from '../../common/components';
import ScenarioCharts, {
  StackedBarChartWithGroupsOptions,
  getPreConstructionEmission,
  getRoadTransportEmission,
  getBEmission,
  getCEmission,
  getWaterborneTransportEmission,
  baseColors,
  ChartToolbar,
} from './ScenarioCharts';
import { humanize } from '../../common/helpers';

export default withSettingsStore(({ projectId, carbonStockLabels }) => {
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [project, setProject] = useState({});
  const [scenarios, setScenarios] = useState([]);
  const [comparedScenarios, setComparedScenarios] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Dropdown, setDropDownError] = useState(null);

  const getReport = useCallback(async () => {
    setIsLoading(true);
    const report = await Api().reports().get({ projectId });
    setProject(report.project);
    setScenarios(report.scenarios);
    setComparedScenarios([report.scenarios[0], report.scenarios[1]]);
    console.log(report.scenarios[0], report.scenarios[1]);
    setIsLoading(false);
  }, [projectId]);

  const calculateTotalEmissionForElements = useCallback((elements) => {
    const groupLabels = [];
    const preconstructionEmission = [];
    const materialEmission = [];
    const installationEmission = [];
    const transportEmission = [];
    const roadTransportEmission = [];
    const waterborneTransportEmission = [];
    const bEmission  = [];
    const cEmission = [];

    if (project.groups !== undefined) {
      [...project.groups, { code: null, name: t('No group'), id: null }].forEach((p) => {
        const numElements = elements.filter((e) => e.groupId === p.id).length;
        if (numElements > 0) {
          groupLabels.push(p.name);
          if (Array.isArray(elements)) {
            elements.filter((i) => i.groupId === p.id).forEach((item) => {
              preconstructionEmission.push(getPreConstructionEmission(item));
              materialEmission.push(item.totalProductEmission);
              installationEmission.push(item.totalInstallationEmission);
              transportEmission.push(item.totalTransportEmission);
              bEmission.push(getBEmission(item));
              cEmission.push(getCEmission(item));
              roadTransportEmission.push(getRoadTransportEmission(item));
              waterborneTransportEmission.push(getWaterborneTransportEmission(item));
            });
          }
        }
      });
    }
    return {
      groupLabels, preconstructionEmission, materialEmission, installationEmission, transportEmission, roadTransportEmission, waterborneTransportEmission, bEmission, cEmission
    };
  }, [project.groups, t]);

  const getDifference = (type) => {
    const sum = (acc, cur) => acc + cur;
    const groups = [...project.groups.filter((g) => g.parentId === Number(selectedGroup) || !hasHierarcy).map((g) => g.id), null];

    const totals = comparedScenarios.map((s) => {
      const { preconstructionEmission, materialEmission, installationEmission, transportEmission, bEmission, cEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [preconstructionEmission.reduce(sum, 0), materialEmission.reduce(sum, 0), installationEmission.reduce(sum, 0), transportEmission.reduce(sum, 0), bEmission.reduce(sum, 0), cEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const pre = comparedScenarios.map((s) => {
      const { preconstructionEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [preconstructionEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const mat = comparedScenarios.map((s) => {
      const { materialEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [materialEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const inst = comparedScenarios.map((s) => {
      const { installationEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [installationEmission.reduce(sum, 0)].reduce(sum, 0);
    });
    const trans = comparedScenarios.map((s) => {
      const { transportEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [transportEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const bEm = comparedScenarios.map((s) => {
      const { bEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [bEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const cEm = comparedScenarios.map((s) => {
      const { cEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [cEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    const baselines = [
      Math.max(...mat),
      Math.max(...trans),
      Math.max(...inst),
      Math.max(...bEm),
      Math.max(...cEm),
      Math.max(...totals),
      Math.max(...pre),
    ];

    const calcPercentage = (total, cur) => {
      const prefix = Math.floor(cur) <= Math.floor(total) ? '-' : '';
      const value = (cur / total) * 100;
      if (Number.isNaN(cur / total)) {
        return '-';
      }
      if (value === 0 || value === Infinity || value === -Infinity) {
        return '-';
      }
      return `${prefix + value.toFixed(0)} %`;
    };

    return (
      <Table striped>
        <thead>
          <tr>
            {comparedScenarios.map((s) => <th key={s.name} className="text-center">{s.name}</th>)}
          </tr>
          <tr>
            {totals.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[3] && 'green', fontWeight: e !== baselines[0] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[5], baselines[5] - e)}
                )
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td colSpan="2" className="text-center">
              {t('product.Pre-construction emissions')}
              {' '}
              A0 (kgCO₂e)
            </td>
          </tr>
          <tr>
            {pre.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[6] && 'green', fontWeight: e !== baselines[6] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[6], baselines[6] - e)}
                )
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="2" className="text-center">
              {t('product.Material emissions')}
              {' '}
              A1-A3 (kgCO₂e)
            </td>
          </tr>
          <tr>
            {mat.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[0] && 'green', fontWeight: e !== baselines[0] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[0], baselines[0] - e)}
                )
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="2" className="text-center">
              {t('transportation.Transportation emissions')}
              {' '}
              A4 (kgCO₂e)
            </td>
          </tr>
          <tr>
            {trans.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[1] && 'green', fontWeight: e !== baselines[1] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[1], baselines[1] - e)}
                )
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="2" className="text-center">
              {t('installation.Installation emissions')}
              {' '}
              A5 (kgCO₂e)
            </td>
          </tr>
          <tr>
            {inst.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[2] && 'green', fontWeight: e !== baselines[2] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[2], baselines[2] - e)}
                )
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan="2" className="text-center">
              {t('generic.BoundB') + ' ' + t('common.Emissions')}
              {' '}
              (kgCO₂e)
            </td>
          </tr>
          <tr>
            {bEm.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[2] && 'green', fontWeight: e !== baselines[2] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[3], baselines[3] - e)}
                )
              </td>
            ))}
          </tr>

          <tr>
            <td colSpan="2" className="text-center">
              {t('generic.BoundC') + ' ' + t('common.Emissions')}
              {' '}
              (kgCO₂e)
            </td>
          </tr>
          <tr>
            {cEm.map((e) => (
              <td className="text-center" style={{ color: e !== baselines[2] && 'green', fontWeight: e !== baselines[2] && 'bold' }}>
                {humanize.amount_long(e, 0)}
                {' '}
                (
                {calcPercentage(baselines[4], baselines[4] - e)}
                )
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };

  // Stacked Bar Chart with Groups
  const getStackedGroupBarChart = useCallback(() => {
    const sum = (acc, cur) => acc + cur;
    const groups = [...project.groups.filter((g) => g.parentId === Number(selectedGroup) || !hasHierarcy).map((g) => g.id), null];
    const totals = comparedScenarios.map((s) => {
      const {preconstructionEmission, materialEmission, installationEmission, transportEmission, bEmission, cEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
      return [preconstructionEmission.reduce(sum, 0), materialEmission.reduce(sum, 0), installationEmission.reduce(sum, 0), transportEmission.reduce(sum, 0), bEmission.reduce(sum, 0), cEmission.reduce(sum, 0)].reduce(sum, 0);
    });

    return {
      labels: comparedScenarios.map((s) => s.name),
      datasets: [
        {
          label: `${t('product.Pre-construction')} (A0)`,
          data: comparedScenarios.map((s) => {
            const { preconstructionEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return preconstructionEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[0],
          stack: t('product.Pre-construction'),
        },
        {
          label: `${t('product.Materials')} (A1-A3)`,
          data: comparedScenarios.map((s) => {
            const { materialEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return materialEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[1],
          stack: t('product.Material'),
        },
        {
          label: `${t('transportation.Transportation')}, ${t('Waterborne transport')} (A4)`,
          data: comparedScenarios.map((s) => {
            const { waterborneTransportEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return waterborneTransportEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[2],
          stack: t('transportation.Transportation'),
        },
        {
          label: `${t('transportation.Transportation')}, ${t('Road transport')} (A4)`,
          data: comparedScenarios.map((s) => {
            const { roadTransportEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return roadTransportEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[3],
          stack: t('transportation.Transportation'),
        },
        {
          label: `${t('installation.Installation')} (A5)`,
          data: comparedScenarios.map((s) => {
            const { installationEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return installationEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[4],
          stack: t('installation.Installation'),
        },
        {
          label: `${t('generic.BoundB')}`,
          data: comparedScenarios.map((s) => {
            const { bEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return bEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[5],
          stack: t('generic.BoundB'),
        },
        {
          label: `${t('generic.BoundC')}`,
          data: comparedScenarios.map((s) => {
            const { cEmission } = calculateTotalEmissionForElements(s.elements.filter((e) => groups.includes(e.groupId)));
            return cEmission.reduce(sum, 0);
          }),
          backgroundColor: baseColors[6],
          stack: t('generic.BoundC'),
        },
        {
          label: t('common.Total'),
          data: comparedScenarios.map((s, idx) => totals[idx]),
          backgroundColor: baseColors[7],
          stack: t('common.Total'),
        },
      ],
    };
  }, [
    baseColors,
    calculateTotalEmissionForElements,
    comparedScenarios,
    project.groups,
    selectedGroup,
  ]);

  const scenarioOptions = useMemo(() => scenarios.map((v) => {
    let longName = v.name;
    if (v.description !== '') {
      longName = `${longName} (${v.description})`;
    }
    return { ...v, longName };
  }), [scenarios]);

  const handleScenarioSelect = (event, idx) => {
    const { value } = event.currentTarget;
    const scenario = scenarios.find((s) => Number(s.id) === Number(value));
    console.log(value);
    setComparedScenarios([...comparedScenarios.map((s, i) => {
      if (i === idx) {
        return scenario;
      }
      return s;
    })]);
  };

  console.log(comparedScenarios);

  const childGroups = useMemo(() => {
    if (project === undefined
      || project.groups === undefined) {
      return [];
    }
    return project.groups.filter((g) => g.parentId !== null);
  }, [project]);

  const parentGroups = useMemo(() => {
    if (project === undefined
      || project.groups === undefined
      || project.groups.filter((g) => g.parentId !== null).length === 0) {
      return [];
    }
    return project.groups.filter((g) => g.parentId === null);
  }, [project]);

  const hasHierarcy = useMemo(() => (parentGroups.length > 0),
    [childGroups.length, parentGroups.length]);

  useEffect(() => {
    if (!initialized) {
      getReport();
      setInitialized(true);
    }
  }, [initialized, getReport]);

  useEffect(() => {
    if (parentGroups.length > 0) {
      setSelectedGroup(parentGroups[0].id.toString());
    }
  }, [parentGroups]);

  useEffect(() => {

  }, [comparedScenarios]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          {scenarios.length >= 2 ? (
            <>
              <Container fluid className="my-2" style={{ maxWidth: '2200px' }}>
                <PageHeader title={t('comparison.Compare scenarios')}>
                  <Row>
                    {comparedScenarios.length > 0 && comparedScenarios.map((scenario, idx) => (
                      <Col key={scenario.id}>
                        <strong style={{ whiteSpace: 'nowrap' }}>
                          {`${t('comparison.Select scenario')} ${idx + 1}`}
                        </strong>
                        {Array.isArray(scenarios) && (
                          <DropDown
                            object={scenario}
                            prop="id"
                            options={idx === 0 ? scenarioOptions.filter(option => option.id !== comparedScenarios[1].id) : scenarioOptions.filter(option => option.id !== comparedScenarios[0].id)}
                            optiontext="{longName}"
                            optionvalue="id"
                            onChange={() => (e) => handleScenarioSelect(e, idx)}
                            selectionRequired
                            setDropDownError={setDropDownError}
                          />
                        )}
                      </Col>
                    ))}
                  </Row>
                </PageHeader>

                {parentGroups.length > 0 && (
                  <Row className="mt-2 mb-4">
                    <Col xs="12">
                      <Nav variant="tabs" className="justify-content-center" defaultActiveKey={selectedGroup} onSelect={(e) => setSelectedGroup(e)}>
                        {parentGroups.map((g) => (
                          <Nav.Item key={g.id}>
                            <Nav.Link eventKey={g.id}>
                              <Badge pill variant="primary" className="mr-2">
                                {g.code}
                              </Badge>
                              {g.name}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-md-center mt-2">
                  <Col lg="6">
                    <Card className="mb-4">
                      <LinkContainer to="/project/">
                        <Button variant="info" className="no-border-radius" disabled>
                          {t('comparison.Grand totals for selected scenarios')}
                        </Button>
                      </LinkContainer>
                      <Card.Img variant="top" />
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center my-4">
                          <h3 className="h4 mb-0">{t('comparison.Grand totals for selected scenarios')}</h3>
                          <ChartToolbar
                            graphId="stacked-group-bar-chart"
                            graphName={`${project.name}_${t('comparison.Grand totals for selected scenarios')}`}
                          />
                        </div>
                        <Bar
                          id="stacked-group-bar-chart"
                          data={getStackedGroupBarChart()}
                          options={StackedBarChartWithGroupsOptions}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card className="mb-4">
                      <LinkContainer to="/project/">
                        <Button variant="info" className="no-border-radius" disabled>
                          {t('comparison.Comparison')}
                        </Button>
                      </LinkContainer>
                      <Card.Img variant="top" />
                      <Card.Body>
                        {getDifference()}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="justify-content-md-center mt-2 mb-5">
                  {comparedScenarios.length > 0 && comparedScenarios.map((scenario) => (
                    <Col key={scenario.id} lg="6">
                      <Card>
                        <LinkContainer to="/project/">
                          <Button variant="info" className="no-border-radius" disabled>
                            {scenario.name}
                          </Button>
                        </LinkContainer>
                        <Card.Img variant="top" />
                        <Card.Body>
                          <ScenarioCharts
                            project={project}
                            groups={project.groups.filter((g) => g.parentId === Number(selectedGroup) || !hasHierarcy)}
                            scenario={scenario}
                            carbonStockLabels={carbonStockLabels}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </>
          ) : (
            <Alert variant="primary" className="text-center p-5 m-3">
              <h2 className="text-center h4 m-0">{t('comparison.At least 2 scenarios required')}</h2>
            </Alert>
          )}
        </>
      )}
    </>
  );

});
