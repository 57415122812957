import React, {
  useState, useEffect, useCallback, Fragment, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut, Bar } from 'react-chartjs-2';

import { FaClipboard, FaDownload } from 'react-icons/fa';
import { Button, ButtonGroup, Row, Col, OverlayTrigger } from 'react-bootstrap';
import { renderTooltip, humanize, saveAs, blob } from '../../common/helpers';
import EmissionVisualizator from './EmissionVisualizator';
import { SettingsContext } from '../../common/settings-context';

// Tooltip label
const tooltipLabel = (tooltipItem, data) => {
  const indice = tooltipItem.index;
  const datasetIndice = tooltipItem.datasetIndex;
  let dataValue = data.datasets[datasetIndice].data[indice];
  dataValue = Math.round(Number(dataValue));
  return `${data.labels[indice]}: ${humanize.amount_long(dataValue, 0)}`;
};

// Doughnut Options
const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: true,
  rotation: -0.001,
  plugins: {
    tooltips: {
      callbacks: {
        label: tooltipLabel,
        afterLabel: (tooltipItem, data) => {
          const dataset = data.datasets[0];
          let total = 0;
          for (let i = 0; i < dataset.data.length; i++) {
            total += parseFloat(dataset.data[i]);
          }
          const percent = Math.round(
            (dataset.data[tooltipItem.index] / total) * 100,
          );
          return `(${percent} %)`;
        },
      },
    },
    legend: {
      display: true,
      responsive: true,
      position: "top",
      labels: {
        boxWidth: 20,
        padding: 5,
        font: {
          size: 14
        },
      },
      align: "center",
    },
    
  },
  
};

// Stacked Chart Options
const stackedBarChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const { index } = tooltipItem;
        const type = data.datasets[tooltipItem.datasetIndex].label;
        const currentValue = Math.round(
          Number(data.datasets[tooltipItem.datasetIndex].data[index]),
        );
        let total = 0;
        data.datasets.forEach((e) => {
          total += e.data[index];
        });
        const percent = Math.round((currentValue / total) * 100);
        return `${type}: ${humanize.amount_long(currentValue, 0)} (${percent} %)`;
      },
    },
  },

  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          autoSkip: false,
        },
        offset: true,
      },
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          autoSkip: false,
          callback(label) {
            if (/\s/.test(label)) {
              return label.split(' ');
            }
            return label;
          },
        },
      },
    ],
  },
};

// Stacked Bar Chart with Groups
const StackedBarChartWithGroupsOptions = {
  ...stackedBarChartOptions,
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const { index } = tooltipItem;
        const type = data.datasets[tooltipItem.datasetIndex].label;
        const currentValue = Math.round(
          Number(data.datasets[tooltipItem.datasetIndex].data[index]),
        );
        let total = 0;
        data.datasets.forEach((e, idx, arr) => {
          idx !== arr.length - 1 ? total += e.data[index] : '';
        });
        const percent = Math.round((currentValue / total) * 100);
        return `${type}: ${currentValue} (${percent} %)`;
      },
    },
  },
};

// Stacked Chart Options
const stackedBarChartOptions2 = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const { index } = tooltipItem;
        const type = data.datasets[tooltipItem.datasetIndex].label;
        const currentValue = Math.round(Number(data.datasets[tooltipItem.datasetIndex].data[index]));
        let total = 0;
        data.datasets.forEach((e) => {
          total += e.data[index];
        });
        const percent = Math.round((currentValue / total) * 100);
        return `${type}: ${humanize.amount_long(currentValue, 0)} (${percent} %)`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          autoSkip: false,
        },
        offset: true,
      },
    ],
    xAxes: [
      {
        stacked: true,
        ticks: {
          autoSkip: false,
          callback(label) {
            if (/\s/.test(label)) {
              return label.split(' ');
            }
            return label;
          },
        },
      },
    ],
  },
};

const getPreConstructionEmission =  (item) => {
  const a0EmissionList = item.genericItems.filter((e) => e.type.includes("A0"));
  return a0EmissionList.map((i) => i.totalEmission).reduce((acc, cur) => acc + cur, 0);
};

// Get road transport emission
const getRoadTransportEmission = (item) => {
  const transportRoadList = item.transports.filter((e) => e.transportRoadId !== null);
  return transportRoadList.map((i) => i.totalEmission).reduce((acc, cur) => acc + cur, 0);
};

const getBEmission = (item) => {
  const bEmissionList = item.genericItems.filter((e) => e.type.includes("B"));
  return bEmissionList.map((i) => i.totalEmission).reduce((acc, cur) => acc + cur, 0);
};

const getCEmission = (item) => {
  const cEmissionList = item.genericItems.filter((e) => e.type.includes("C"));
  return cEmissionList.map((i) => i.totalEmission).reduce((acc, cur) => acc + cur, 0);
};

// Get waterborne transport emission
const getWaterborneTransportEmission = (item) => {
  const transportWaterborneList = item.transports.filter((e) => e.transportWaterborneId !== null);
  return transportWaterborneList.map((i) => i.totalEmission).reduce((acc, cur) => acc + cur, 0);
};

// Chart colors
const baseColors = [
  '#ADDDFF',
  '#3AA551',
  '#A8D100',
  '#f09c00',
  '#C63418',
  '#C40079',
  '#CFCBC4',
  '#5D3FD3',
];
const carbonColors = [
  '#A8D100',
  '#ADDDFF',
];
const neutralColors = [
  'hsla(214, 90%, 23%, 1)',
  'hsla(214, 90%, 23%, .75)',
  'hsla(214, 90%, 23%, .50)',
  'hsla(214, 90%, 23%, .25)',
  'hsla(201, 100%, 47%, 1)',
  'hsla(201, 100%, 47%, .75)',
  'hsla(201, 100%, 47%, .50)',
  'hsla(201, 100%, 47%, .25)',
  'hsla(324, 41%, 27%, 1)',
  'hsla(324, 41%, 27%, .75)',
  'hsla(324, 41%, 27%, .50)',
  'hsla(324, 41%, 27%, .25)',
  'hsla(201, 26%, 21%, 1)',
  'hsla(201, 26%, 21%, .75)',
  'hsla(201, 26%, 21%, .50)',
  'hsla(201, 26%, 21%, .25)',
];

const clipboardCopyAvailable = typeof ClipboardItem !== 'undefined';

const ChartToolbar = ({ graphId, graphName }) => {
  const { t } = useTranslation();
  const copyGraphToClipboard = async (id) => {
    const elem = document.getElementById(id);
    const b = blob.dataURItoBlob(elem.toDataURL());
    const item = new ClipboardItem({
      [b.type]: b,
    });
    await navigator.clipboard.write([item]);
  };

  const downloadGraph = (id, name) => {
    const elem = document.getElementById(id);
    saveAs(blob.dataURItoBlob(elem.toDataURL()), `${name}.png`);
  };

  return (
    <ButtonGroup size="sm" style={{ height: "30px" }}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('common.Download'), 1)}
      >
        <Button variant="outline-primary" onClick={() => downloadGraph(graphId, graphName)}>
          <FaDownload />
        </Button>
      </OverlayTrigger>
      {clipboardCopyAvailable && (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t('common.Copy'), 1)}
        >
          <Button variant="outline-primary" onClick={() => copyGraphToClipboard(graphId)}>
            <FaClipboard />
          </Button>
        </OverlayTrigger>
      )}
    </ButtonGroup>
  );
};

const ScenarioCharts = ({
  project, scenario, groups, graphs, carbonStockLabels
}) => {
  const { t } = useTranslation();
  const {getUnitByCode} = useContext(SettingsContext);
  const [showGraphs, setShowGraphs] = useState([
    'stacked_by_group',
    'doughnut_by_phase',
    'doughnut_by_group',
    'doughnut_by_product',
    'doughnut_by_carbon',
    'stacked_by_carbon',
    'emission_visualization',
  ]);

  const stackedGraphsAndVisualization = showGraphs.filter(graphName => ['stacked_by_group', 'stacked_by_carbon', 'emission_visualization'].includes(graphName));
  const doughnutGraphs = showGraphs.filter(graphName =>     ['doughnut_by_phase', 'doughnut_by_group', 'doughnut_by_product', 'doughnut_by_carbon'].includes(graphName));

  const getEmissions = (elements) => {
    let allElementEmissions = 0;
    elements.length > 0 && elements.filter((e) => [...groups.map((g) => g.id), null].includes(e.groupId)).forEach((item) => (
      // allElementEmissions += item.totalEmission
      allElementEmissions += (getPreConstructionEmission(item) + item.totalProductEmission + item.totalTransportEmission + item.totalInstallationEmission + getBEmission(item) + getCEmission(item))
    ));
    return allElementEmissions;
  };

  // Total emissions by module/phase.
  const getElementEmissions = useCallback((elements) => {
    let preconstructionData = 0;
    let materialData = 0;
    let installationData = 0;
    let waterborneTransportData = 0;
    let installedProducts = 0;
    let maintenance = 0;
    let roadTransportData = 0;
    let bData = 0;
    let cData = 0;
  
    if (Array.isArray(elements)) {
      elements.filter((e) => [...groups.map((g) => g.id), null].includes(e.groupId)).forEach((item) => {
        // console.log(item);
        preconstructionData += getPreConstructionEmission(item);
        materialData += item.totalProductEmission;
        installationData += item.totalInstallationEmission;
        // bData += item.totalInstalledProductsEmissions + item.totalMaintenanceEmissions + item.totalRepairEmissions + item.totalReplacementEmissions + item.totalRefurbishmentEmissions + item.totalOperationalEnergyEmissions + item.totalOperationalWaterEmissions + item.totalOtherOperationsEmissions + item.totalUserUtilizationsEmissions;
        bData += getBEmission(item);
        // cData += item.totalDisposalEmissions + item.totalWasteProcessingEmissions + item.totalTransportCEmissions + item.totalDeconstructionEmissions;
        cData += getCEmission(item);
        waterborneTransportData += getWaterborneTransportEmission(item);
        roadTransportData += getRoadTransportEmission(item);
        // console.log(preconstructionData);
      });
    }
  
  //Creates an array of objects with the initial data - labels, values, and colors
  const initialData = [
    { label: `${t('product.Pre-construction')} (A0)`, value: preconstructionData, color: baseColors[0] },
    { label: `${t('product.Materials')} (A1-A3)`, value: materialData, color: baseColors[1] },
    { label: `${t('transportation.Transportation')}, ${t('Waterborne transport')} (A4)`, value: waterborneTransportData, color: baseColors[2] },
    { label: `${t('transportation.Transportation')}, ${t('Road transport')} (A4)`, value: roadTransportData, color: baseColors[3] },
    { label: `${t('installation.Installation')} (A5)`, value: installationData, color: baseColors[4] },
    { label: `${t('generic.BoundB')}`, value: bData, color: baseColors[5] },
    { label: `${t('generic.BoundC')}`, value: cData, color: baseColors[6] }
  ];

  // Filters out data with value equal to zero. Not represented in the chart
  const filteredData = initialData.filter(item => item.value > 0);

  // Maps the filtered data to the corresponding labels, values, and colors
  const filteredLabels = filteredData.map(item => item.label);
  const filteredValues = filteredData.map(item => item.value);
  const filteredColors = filteredData.map(item => item.color);

  return {
    labels: filteredLabels,
    datasets: [{
      data: filteredValues,
      backgroundColor: filteredColors, 
      hoverBackgroundColor: filteredColors,  
    }],
  };
}, [baseColors, t]);

  // Total emissions in groups
  const getTotalElementEmissionsByGroups = useCallback((elements) => {
    const chartData = [];
    const dataLabels = [];

if (groups !== undefined) {
  [...groups, { name: t('common.No group'), id: null }].forEach((p) => {
    const groupName = p.name === '' ? t('common.Unnamed group') : p.name; // Use unnamed group if name is empty
    const totalEmission = elements
      .filter((i) => i.groupId === p.id)
      .map((i) => Number(getPreConstructionEmission(i) + i.totalProductEmission + i.totalTransportEmission + i.totalInstallationEmission + getBEmission(i) + getCEmission(i)))
      .reduce((acc, cur) => acc + cur, 0);

    if (totalEmission > 0) {
      dataLabels.push(groupName);
      chartData.push(totalEmission);
    }
    console.log(chartData);
  });
}

    return {
      labels: dataLabels,
      datasets: [{
        data: chartData,
        backgroundColor: neutralColors,
        hoverBackgroundColor: neutralColors,
      }],
    };
  }, [baseColors, groups, t]);

// Total emissions by product
const getTotalElementEmissionsByProducts = useCallback((elements) => {
  const allProducts = elements.reduce((acc, cur) => [...acc, ...cur.products], []);
  const productNames = [...new Set(allProducts.map((p) => p.name))];
  // Calculate the total emissions for each product name
  const chartData = productNames.map((name) => 
    allProducts.filter((p) => p.name === name)
               .map((p) => p.totalEmission)
               .reduce((acc, cur) => acc + cur, 0)
  );
  // Filter out zero emission products. Not shown in chart.
  const filteredData = productNames
    .map((name, index) => ({ label: name, value: chartData[index] }))
    .filter(item => item.value > 0); 
  // Map the filtered data to the corresponding labels and values
  const filteredLabels = filteredData.map(item => item.label);
  const filteredValues = filteredData.map(item => item.value);

  return {
    labels: filteredLabels,
    datasets: [{
      data: filteredValues, 
      backgroundColor: neutralColors, 
      hoverBackgroundColor: neutralColors,
    }],
  };
}, [baseColors, groups, t]);

  getTotalElementEmissionsByProducts(scenario.elements);
  const calculateTotalEmissionForElements = useCallback((elements) => {
    const groupLabels = [];
    const preconstructionEmission = [];
    const materialEmission = [];
    const installationEmission = [];
    const transportEmission = [];
    const roadTransportEmission = [];
    const waterborneTransportEmission = [];
    const bEmissions = [];
    const cEmissions = [];
    const emissionGroup = [];
    

    if (groups !== undefined) {
      [...groups, { code: null, name: t('common.No group'), id: null }].forEach((p) => {
        const numElements = elements.filter((e) => e.groupId === p.id).length;
        if (numElements > 0) {
          groupLabels.push(p.name);
          if (Array.isArray(elements)) {
            elements.filter((i) => i.groupId === p.id).forEach((item) => {
              preconstructionEmission.push(getPreConstructionEmission(item));
              materialEmission.push(item.totalProductEmission);
              installationEmission.push(item.totalInstallationEmission);
              transportEmission.push(item.totalTransportEmission);
              bEmissions.push(getBEmission(item));
              cEmissions.push(getCEmission(item));
              roadTransportEmission.push(getRoadTransportEmission(item));
              waterborneTransportEmission.push(getWaterborneTransportEmission(item));
              emissionGroup.push(p);
            });
          }
        }
      });
    }

    return {
      groupLabels,
      emissionGroup,
      preconstructionEmission,
      materialEmission,
      installationEmission,
      transportEmission,
      roadTransportEmission,
      bEmissions,
      cEmissions,
      waterborneTransportEmission,
    };
  }, [groups, t]);

  // Total emissions in groups
  const getEmissionsByGroupAndPhase = useCallback((elements) => {
    const {
      groupLabels,
      emissionGroup,
      preconstructionEmission,
      materialEmission,
      installationEmission,
      roadTransportEmission,
      bEmissions,
      cEmissions,
      waterborneTransportEmission,
    } = calculateTotalEmissionForElements(elements);
    const sum = (acc, cur) => acc + cur;

    console.log(emissionGroup);

    const totalPreconstructionByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? preconstructionEmission[i] : 0)).reduce(sum, 0));
    const totalMaterialByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? materialEmission[i] : 0)).reduce(sum, 0));
    const totalInstallationByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? installationEmission[i] : 0)).reduce(sum, 0));
    const totalBByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? bEmissions[i] : 0)).reduce(sum, 0));
    const totalCByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? cEmissions[i] : 0)).reduce(sum, 0));
    const totalRoadTransportByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? roadTransportEmission[i] : 0)).reduce(sum, 0));
    const totalWaterborneTransportByGroup = groupLabels.map((l) => emissionGroup.map((g, i) => (g.name === l ? waterborneTransportEmission[i] : 0)).reduce(sum, 0));

    const emptyGroup = (l, i) => 
    totalPreconstructionByGroup[i] + 
    totalRoadTransportByGroup[i] + 
    totalWaterborneTransportByGroup[i] + 
    totalMaterialByGroup[i] + 
    totalInstallationByGroup[i] + 
    totalBByGroup[i] + 
    totalCByGroup[i] !== 0;    

    const isNonZeroData = (data) => data.some(value => value !== 0);

    const datasets = [
      {
        label: `${t('product.Pre-construction')} (A0)`,
        data: totalPreconstructionByGroup.filter(emptyGroup),
        backgroundColor: baseColors[0],
        stack: emissionGroup,
      },
      {
        label: `${t('product.Materials')} (A1-A3)`,
        data: totalMaterialByGroup.filter(emptyGroup),
        backgroundColor: baseColors[1],
        stack: emissionGroup,

      },
      {
        label: `${t('transportation.Transportation')}, ${t('Waterborne transport')} (A4)`,
        data: totalWaterborneTransportByGroup.filter(emptyGroup),
        backgroundColor: baseColors[2],
        stack: emissionGroup,

      },
      {
        label: `${t('transportation.Transportation')}, ${t('Road transport')} (A4)`,
        data: totalRoadTransportByGroup.filter(emptyGroup),
        backgroundColor: baseColors[3],
        stack: emissionGroup,

      },
      {
        label: `${t('installation.Installation')} (A5)`,
        data: totalInstallationByGroup.filter(emptyGroup),
        backgroundColor: baseColors[4],
        stack: emissionGroup,

      },
      {
        label: `${t('generic.BoundB')}`,
        data: totalBByGroup.filter(emptyGroup),
        backgroundColor: baseColors[5],
        stack: emissionGroup,

      },
      {
        label: `${t('generic.BoundC')}`,
        data: totalCByGroup.filter(emptyGroup),
        backgroundColor: baseColors[6],
        stack: emissionGroup,
      },
    ].filter(dataset => isNonZeroData(dataset.data)); // Filter out empty groups

    const labels = groupLabels.filter(emptyGroup).map(label => label === '' ? t('common.Unnamed group') : label); // Filter out empty groups. If group name is empty, use 'Unnamed group'

    return {
      labels,
      datasets
    };
  }, [calculateTotalEmissionForElements, t]);

  // getCarbonStocks
  const getCarbonStocks = useCallback((carbonStocks) => {
    let vegetationData = 0;
    let soilData = 0;
    if (Array.isArray(carbonStocks)) {
      carbonStocks.forEach((item) => {
        vegetationData += item.carbonFluxVegetation;
        soilData += item.carbonFluxSoil;
      });
    }
    return {
      labels: [`${t('carbonstocks.Vegetation carbon stock')}`, `${t('carbonstocks.Soil carbon stock')}`],
      datasets: [{
        data: [[vegetationData], [soilData]],
        backgroundColor: carbonColors,
        hoverBackgroundColor: carbonColors,
      }],
    };
  }, [carbonColors, t]);

  const getCarbonFluxByLabel = useCallback((carbonStocks) => {
    // Merge
    const allCarbonFlux = carbonStocks.reduce((acc, cur) => [...acc, ...cur.carbonFlux], []);
    const groupBy = 'labelLevel2';
    // Create mapping from label id to group name
    // (which is the label name given in groupBy variable)
    const labelIdToLabelName = carbonStockLabels
      .reduce((acc, cur) => ({ ...acc, [cur.id]: cur[groupBy] }), {});

    // Generate label list
    const labels = allCarbonFlux
      .map((cf) => cf.labelId) // get label
      .map((labelId) => carbonStockLabels.find((l) => l.id === labelId)) // map to real label
      .map((label) => label[groupBy]) // Get label name
      .filter((val, idx, cfs) => cfs.indexOf(val) === idx); // get unique

    // Get total carbon by label
    const totalCarbonFluxByLabel = allCarbonFlux.reduce((acc, cur) => {
      const label = labelIdToLabelName[cur.labelId];
      if (acc[label] === undefined) {
        acc[label] = { ...cur };
      }
      acc[label].carbonFluxSoil += cur.carbonFluxSoil;
      acc[label].carbonFluxVegetation += cur.carbonFluxVegetation;
      acc[label].carbonFluxTotal += cur.carbonFluxTotal;
      return acc;
    }, {});

    // Sort labels by total flux
    const sortedLabels = labels
      .map((l) => totalCarbonFluxByLabel[l]) // Get total carbonflux object
      .sort((a, b) => b.carbonFluxTotal - a.carbonFluxTotal) // Sort by totals
      .map((cf) => labelIdToLabelName[cf.labelId]); // Get label name

    // Create an array of totals with the correct sorting order
    const sortedTotalCarbonFlux = sortedLabels.map((l) => totalCarbonFluxByLabel[l]);

    // Limit to 5 labels
    const limit = 5;

    // Convert existing carbon flux data into label-data object for easy handling
    const chartData = [
      ...sortedTotalCarbonFlux
        .slice(0, limit)
        .map((data) => ({ label: labelIdToLabelName[data.labelId], data })),
    ];

    // Others label need to be calculated separately
    const others = sortedTotalCarbonFlux.slice(limit, sortedTotalCarbonFlux.length);
    if (others.length > 0) {
      // All extra labels are summed together
      const carbonFluxVegetation = others.reduce((acc, cur) => (acc + cur.carbonFluxVegetation), 0);
      const carbonFluxSoil = others.reduce((acc, cur) => (acc + cur.carbonFluxSoil), 0);
      chartData.push({ label: t('results.Others'), data: { carbonFluxVegetation, carbonFluxSoil } });
    }

    console.log(chartData);

    return {
      labels: chartData.map((e) => t(`carbonstocks.${e.label}`)),
      datasets: [
        {
          label: t('carbonstocks.Vegetation carbon stock'),
          data: chartData.map((e) => e.data.carbonFluxVegetation),
          backgroundColor: carbonColors[0],
          stack: chartData
        }, {
          label: t('carbonstocks.Soil carbon stock'),
          data: chartData.map((e) => e.data.carbonFluxSoil),
          backgroundColor: carbonColors[1],
          stack: chartData

        },
      ],
    };
  });
  useEffect(() => {
    if (Array.isArray(graphs)) {
      setShowGraphs(graphs);
    }
  }, [graphs]);

  return (
    <div >
      <h3 className="m-3 text-center">
        {t('common.Total emissions -')}
        {' '}
        <strong>{humanize.amount_long(getEmissions(scenario.elements), 0)}</strong>
        {' '}
        {getUnitByCode('kgco2eq', project.organizationId)?.[0]?.symbol}
      </h3>
      <>      
      <Row className="p-4 ">
      {doughnutGraphs.map((graphName, idx) => (
        <Col xl="6" className="mb-4 text-center">
          {graphName === 'doughnut_by_phase' && (
          <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className="d-flex justify-content-between my-4" style={{height: "70px"}}>
              <h3 className="h4 mb-0" style={{marginRight: '20px'}}>{t('comparison.Total emissions by module/phase')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions by module/phase')}`} />
            </div> 
            <div style={{height: "300px"}}>
            <Doughnut
              id={graphName + idx}
              data={getElementEmissions(scenario.elements)}
              options={doughnutOptions}      
            />
            </div>  
            {/* <hr className="my-5" /> */}
          </div>
          )}
          {graphName === 'doughnut_by_group' && (
          <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className="d-flex justify-content-between my-4" style={{height: "70px"}}>
              <h3 className="h4 mb-0" style={{marginRight: '20px'}}>{t('comparison.Total emissions by group')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions by group')}`} />
            </div>
            <div style={{height: "300px"}}>
            <Doughnut
              id={graphName + idx}
              data={getTotalElementEmissionsByGroups(scenario.elements)}
              options={doughnutOptions}
            />
            </div>
            {/* <hr className="my-5" /> */}
          </div>
          )}         
          {graphName === 'doughnut_by_product' && (
          <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className="d-flex justify-content-between my-4" style={{height: "70px"}}>
              <h3 className="h4 mb-0" style={{marginRight: '20px'}}>{t('comparison.Total emissions by product')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions by product')}`} />
            </div>
            <div style={{height: "300px"}}>
              <Doughnut
                id={graphName + idx}
                data={getTotalElementEmissionsByProducts(scenario.elements)}
                options={doughnutOptions}
              />     
            </div> 
            {/* <hr className="my-5" /> */}
          </div>
          )}
          {scenario.carbonStocks.length > 0 && graphName === 'doughnut_by_carbon' && (
          <div style={{display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div className="d-flex justify-content-between my-4" style={{height: "70px"}}>
              <h3 className="h4 mb-0" style={{marginRight: '20px'}}>{`${t('carbonstocks.Changes in carbon stocks')} tCO₂/ha`}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('carbonstocks.Changes in carbon stocks')}`} />
            </div> 
            <div style={{height: "300px"}}>
              <Doughnut
                id={graphName + idx}
                data={getCarbonStocks(scenario.carbonStocks)}
                options={doughnutOptions}
              />
            </div>
            {/* <hr className="my-5" /> */}
          </div>
          )}
        </Col>       
        
      ))}
      </Row>
      <hr className="my-3" />
      <Row className="p-4 g-0">
      {stackedGraphsAndVisualization.map((graphName, idx) => (
        <Col xl="12" className="mb-4 text-center">
          {graphName === 'stacked_by_group' && (
            <div >
              <div className="d-flex justify-content-between align-items-center my-4">
                <h3 className="h4 mb-0 mx-auto text-center">{t('comparison.Total emissions in group by module/phase')}</h3>
                <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions in group by module/phase')}_stacked`} />
              </div>
              <Bar
              id={graphName + idx}
              data={getEmissionsByGroupAndPhase(scenario.elements)}
              options={stackedBarChartOptions}
            />
            <hr className="my-3" />
          </div>
          )}
          {scenario.carbonStocks.length > 0 && graphName === 'stacked_by_carbon' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{`${t('carbonstocks.Changes in carbon stocks by label')} tCO₂/ha`}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('carbonstocks.Changes in carbon stocks by label')}`} />
            </div>
            <Bar
              id={graphName + idx}
              data={getCarbonFluxByLabel(scenario.carbonStocks)}
              options={stackedBarChartOptions}
            />
            <hr className="my-5" />
          </div>
          )}
          {graphName === 'emission_visualization' && (
          <div>
            <h3 className="h4 mb-0 text-center">{t('results.emission_visualization')}</h3>
            <EmissionVisualizator selectedEmissions={getEmissions(scenario.elements)} />
          </div>
          )}
        </Col>
      ))}
      </Row>
      </>
      {/* {showGraphs.map((graphName, idx) => (
        <Fragment key={graphName} style={{display: 'flex', width: '50%'}}>
          {graphName === 'doughnut_by_phase' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{t('comparison.Total emissions in elements in groups')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions in elements in groups')}`} />
            </div>
            <Doughnut
              id={graphName + idx}
              data={getElementEmissions(scenario.elements)}
              options={doughnutOptions}
            />
            <hr className="my-5" />
          </div>
          )}
          {graphName === 'doughnut_by_group' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{t('comparison.Total emissions in groups')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions in groups')}`} />
            </div>
            <Doughnut
              id={graphName + idx}
              data={getTotalElementEmissionsByGroups(scenario.elements)}
              options={doughnutOptions}
            />
            <hr className="my-5" />
          </div>
          )}         
          {graphName === 'doughnut_by_product' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{t('comparison.Total emissions (products)')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions (products)')}`} />
            </div>
              <Doughnut
                id={graphName + idx}
                data={getTotalElementEmissionsByProducts(scenario.elements)}
                options={doughnutOptions}
              />      
            <hr className="my-5" />
          </div>
          )}
          {scenario.carbonStocks.length > 0 && graphName === 'doughnut_by_carbon' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{`${t('carbonstocks.Changes in carbon stocks')} tCO₂/ha`}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('carbonstocks.Changes in carbon stocks')}`} />
            </div>
            <Doughnut
              id={graphName + idx}
              data={getCarbonStocks(scenario.carbonStocks)}
              options={doughnutOptions}
            />
            <hr className="my-5" />
          </div>
          )}
          {graphName === 'stacked_by_group' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{t('comparison.Total emissions in groups')}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('comparison.Total emissions in groups')}_stacked`} />
            </div>
            <Bar
              id={graphName + idx}
              data={getEmissionsByGroupAndPhase(scenario.elements)}
              options={stackedBarChartOptions}
            />
            <hr className="my-5" />
          </div>
          )}
          {scenario.carbonStocks.length > 0 && graphName === 'stacked_by_carbon' && (
          <div>
            <div className="d-flex justify-content-between my-4">
              <h3 className="h4 mb-0">{`${t('carbonstocks.Changes in carbon stocks by label')} tCO₂/ha`}</h3>
              <ChartToolbar graphId={graphName + idx} graphName={`${project.name}_${scenario.name}_${t('carbonstocks.Changes in carbon stocks by label')}`} />
            </div>
            <Bar
              id={graphName + idx}
              data={getCarbonFluxByLabel(scenario.carbonStocks)}
              options={stackedBarChartOptions}
            />
            <hr className="my-5" />
          </div>
          )}
          {graphName === 'emission_visualization' && (
          <div>
            <h3 className="h4 mb-0 text-center">{t('results.emission_visualization')}</h3>
            <EmissionVisualizator selectedEmissions={getEmissions(scenario.elements)} />
          </div>
          )}
        </Fragment>
      ))} */}
    </div>
  );
};

export {
  getPreConstructionEmission,
  getRoadTransportEmission,
  getWaterborneTransportEmission,
  StackedBarChartWithGroupsOptions,
  getBEmission,
  getCEmission,
  baseColors,
  ChartToolbar,
};

export default ScenarioCharts;
