import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash, FaPen } from 'react-icons/fa';
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  ButtonGroup,
  InputGroup,
  Modal,
  FormControl,
  Spinner,
} from 'react-bootstrap';
import { defaultHandleChange, loadSheetData } from '../common/helpers';
import { withSettingsStore } from '../common/settings-context';
import loglevel from '../../services/loglevel';

import { TextField, DropDown } from '../common/components';
import api from '../../services/api';
import fi_Material_SampleData from '../../assets/fi_Material_SampleData.xlsx';
import dk_Material_SampleData from '../../assets/dk_Material_SampleData.xlsx';
import Material_SampleData from '../../assets/Material_SampleData.xlsx';

export default withSettingsStore(({ onChange, units, organizationId, getUnitByCode, setValidMaterialImport }) => {
  const { t } = useTranslation();
  const [materials, setMaterials] = useState([]);
  const [existing, setExisting] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [mapping, setMapping] = useState({
    name: '__EMPTY',
    description: '',
    unitId: '',
    emission: 0,
    source: ''
  });
  // const mappingText = {
  //   name: 'Name',
  //   description: 'Description',
  //   unitId: 'Unit',
  //   emission: 'Emission',
  //   source:'Source'
  // };
  const [fileName, setFileName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Dropdown, setDropDownError] = useState(null);

  // Unit helpers
  const createAltUnits = (abbr) => [abbr.replace('²', '2'), abbr.replace('³', '3')];
  const findUnit = useCallback((str) => units.filter((f) => f.organizationId == organizationId).find((u) => {
    const abbr = u.abbreviation;
    const tests = [abbr, ...createAltUnits(abbr)];

    return tests.includes(str);
  }), [units]);

  const availableUnits = units.filter(unit => unit.organizationId === organizationId);

  // Handler for the selected file
  const handleFile = async (event) => {
    const fileList = event.target.files;
    setFileName(fileList[0].name);
    const data = await loadSheetData(fileList[0]);

    // Iterate each row and get keys
    const rawHeaders = [...data.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        acc.add(key);
      });
      return acc;
    }, new Set())].sort();


    setHeaders(rawHeaders.map((h) => ({
      key: h,
      index: Number(h.replace(/[^0-9]*/gi, '')),
      example: data.map((r) => r[h]).filter((r) => r !== undefined).filter((r, i) => i < 5).join(', '),
    })).sort((a, b) => a.index > b.index));

    setMapping((prev) => ({
      ...prev,
      name: '__EMPTY',
      description: '__EMPTY',
      unitId: '__EMPTY_1',
      emission: '__EMPTY_2',
      source:'__EMPTY_3'
    }));

    const temp = data.filter(x => x.Name !== 'Name'); //Remove Header Row
    const temp2 = temp.filter(y => y.Unit !== ""); //Remove blank values
    setRawData(temp2);
  };

  // Update the data based on the mapping selections
  const materialsFromDataByHeader = useCallback(() => {
    const processedMaterials = [];

    for (let i = 0; i < rawData.length; i++) {
        const m = rawData[i];
        const unit = findUnit(m.Unit) || findUnit(m.Yksikkö) || findUnit(m.Enhed);
        let duplicate = false;
        let matId = [];
        // let matId = 0;
        let index = i;
        let createdBy = null;
        let conversions = [];

      // TODO: Be able to import conversions. 
      //Add conversion property array based from input unit to kg value in excel
      // if (m.InputUnit === "kg") {
      //   var conversions = []; //Ignore kg > kg
      // }
      // else {
      //   var factor = m.Conversions;
      //   var conversions = [{
      //     comment: "Imported from Excel " + m?.InputUnit + " > kg",
      //     factor: String(factor),
      //     fromUnitId: String(unit?.id),
      //     inverse: false,
      //     materialId: 0,
      //     toUnitId: getUnitByCode('kg', organizationId)?.[0]?.id}];
      // }
      
        if (m === null || m === undefined || m === "") {
            console.log('Error, material "' + (i + 1) + '" undefined');
          } 
            else {
            // Check name, source and unit for duplicates
            existing.forEach(x => {
              if (x.createdBy === null) { // Only existing published materials are checked
                if (((x.name === m.Name && x.source === m.Source) || (x.name === m.Nimi && x.source === m.Lähde)  || (x.name === m.Navn && x.source === m.Kilde)) && (x.unitId === unit.id)) {
                    duplicate = true;
                    setDuplicateMessage(true);
                    // matId = x.id;
                    matId.push(x.id);
                    index = i;
                    conversions.push(x.conversions);
                    // createdBy = x.createdBy;
                }
              }
            });

            // Material(m object) properties taken from Excel
              processedMaterials[i] = ({
                name: m.Name || m.Nimi || m.Navn,
                description: m.Description || m.Kuvaus || m.Beskrivelse,
                unitId: unit !== undefined ? unit.id : 0,
                emission: 
                m["Emission / Unit"] !== null && m["Emission / Unit"] !== undefined 
                ? Number(m["Emission / Unit"]) 
                : m["Päästöt / Yksikkö"] !== null && m["Päästöt / Yksikkö"] !== undefined 
                ? Number(m["Päästöt / Yksikkö"]) 
                : m["Udledning / Enhed"] !== null && m["Udledning / Enhed"] !== undefined 
                ? Number(m["Udledning / Enhed"])  
                : 0,
                conversions: conversions,
                source: m.Source || m.Lähde || m.Kilde,
                duplicate: duplicate,
                index: i,
                id: matId,
                // createdBy: createdBy,
                organizationId: organizationId,
            });
        }
    }
    return processedMaterials;
}, [rawData, mapping, findUnit, existing, organizationId, materials]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(materials);
    }
  }, [materials, onChange]);

  // Row background is red if data (name, emission, unit) is invalid. Import disabled if any row is invalid.
  const checkMaterial = useCallback((e) => {
    // console.log("Checking material:", e);
    const nulls = Object.keys(e).filter((key) => {
      const value = e[key];
      console.log(`Key: ${key}, Value: ${value}, Type: ${typeof value}`);
      return (
        (key === 'name' && value === '') ||
        (key === 'emission' && (isNaN(value)) || value === '') ||
        (key === 'unitId' && (value === 0 || value === 'Not selected'))
      );
    });
    // console.log("Filtered nulls:", nulls);
    return nulls.length > 0;
  }, [materials]);

  // Form validation
  useEffect(() => {
    const errors = materials.some((material) => checkMaterial(material));
    if (errors || materials.length === 0) {
      setValidMaterialImport(false);
    } else {
      setValidMaterialImport(true);
    }
  }, [materials, mapping]);

  // Row text is red if imported material already exists in the database. Name, source and unit criteria are checked. 
  const checkDuplicate = useCallback((e) => {
    const nulls = ([]);
    if (e.duplicate === true) { nulls.length = 1 };
    return nulls.length > 0;
  }, [materials]);

  const removeMaterial = (material) => {
    // loglevel.info('Remove material');
    setMaterials(materials.filter((e) => e !== material));
  };

  const isEqual = (a, b) => {
    return (a === b) || (a == null && b === '') || (a === '' && b == null);
  };

  const handleChange = (material) => (event) => {
    const { name, value } = event.currentTarget;
    const convertedValue = name === '' ? null : value;
    // loglevel.info(name, value, material, event);
    setMaterials((prev) => {
      const originalIdx = prev.findIndex((elem) => elem.index === material.index);
      if (originalIdx !== -1) {
        const updatedMaterials = [...prev];

        updatedMaterials[originalIdx] = {
          ...updatedMaterials[originalIdx],
          [name]: convertedValue, 
          duplicate: existing.filter(obj => obj.createdBy === null).some(obj => {
            if (name === 'name') {
              return isEqual(obj.name, value) &&
                obj.unitId == updatedMaterials[originalIdx].unitId &&
                isEqual(obj.source, updatedMaterials[originalIdx].source);
            }
            else if (name === 'unitId') {
              return obj.unitId == value &&
                obj.name === updatedMaterials[originalIdx].name &&
                isEqual(obj.source, updatedMaterials[originalIdx].source);
            }
            else if (name === 'source') {
              return isEqual(obj.source, value) &&
                isEqual(obj.name, updatedMaterials[originalIdx].name) &&
                obj.unitId == updatedMaterials[originalIdx].unitId;
            }
            else {
              return isEqual(obj.name, updatedMaterials[originalIdx].name) &&
                obj.unitId == updatedMaterials[originalIdx].unitId &&
                isEqual(obj.source, updatedMaterials[originalIdx].source);
            }
          }),
        };
        return updatedMaterials;
      }
      return prev;
    });
  };

  const getUnit = (unitId) => units.find((u) => u.id === Number(unitId));

  useEffect(() => {
    checkDatabase();
    if (existing.length < 1) {
      setLoading(true);
    }
    if (headers.length > 0 && rawData !== undefined) {
      const mats = materialsFromDataByHeader();
      // console.log('Materials:', mats);
      setMaterials(mats);
    };
    if (duplicateMessage === true) {
      setShowDialog(true);
    };
  }, [headers, rawData, mapping, duplicateMessage]);

  //Retrive materials from database
  const checkDatabase = useCallback(async () => {
    const temp = await api().materials().get({ filterByOrgId: organizationId });
    if (temp !== null) {
      setLoading(false);
    };
    const mat = temp.items;
    setExisting(mat);
  });

const filterBySearch = useCallback((m) => {
    const name = m.name || ''; 
    return name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
}, [searchText]);

const organizationIdToLink = {
  1: fi_Material_SampleData,
  2: dk_Material_SampleData,
  3: Material_SampleData
};

const selectedLink = organizationIdToLink[organizationId];

const hyperlink = (
  <a href={selectedLink || Material_SampleData} target="_blank" download rel="noreferrer">
    {t('import.download-sample')}
  </a>
);

return (
    <Form>
      <Modal show={loading} className="mt-5">
        <Modal.Header>
          <Modal.Title>Loading Database</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <body className="text-center">Loading Materials from Database, Please Wait</body>
          <body className="text-center"> -   - </body>
          <body className="text-center">
            <Spinner variant="primary" animation="border" role="status" size='lg'>
              <span className="sr-only">{t('Loading...')}</span>
            </Spinner>
          </body>
        </Modal.Body>
      </Modal>
      <Modal show={showDialog} onHide={() => { setShowDialog(false); onClose(); }} className="mt-5">
        <Modal.Header>
          <Modal.Title>Duplicates</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <body className="text-center">Materials shown in red text already exist on the database and will be updated. Remove entries you do not want to be updated before importing.</body>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setShowDialog(false);
          }}>
            {' '}
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid>
        <Row>
          <Col style={{  marginTop:'7px'}}>
            <Form.File
              id="custom-file"
              label={fileName === '' ? t('import.Source file') : fileName}
              custom
              data-browse={t('import.Browse')}
              onChange={handleFile}
            />
          </Col>
          <Col>
              {/* <a
                href={Material_SampleData}
                target="_blank"
                download
                rel="noreferrer"
                style={{fontSize:'15px'}}
              >
                {t('import.download-sample')}
              </a> */}
                {/* <div className="text-center mb-3"> */}
                <div>
                <small>
                  {hyperlink}
                </small>
              </div>
          <div  style={{fontSize:'15px'}}>
              {t('import.Number of rows')}
              :
              {' '}
              {rawData.length}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="button-toolbar ml-3 mb-2">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('common.Search')}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl onChange={(e) => { setSearchText(e.target.value); }} />
            </InputGroup>
          </div>
        </Row>
        <Row>
          <Col>
            <div id='MaterialsImport'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: '27%' }}>{t('common.Name')}</th>
                    <th style={{ width: '27%' }}>{t('common.Description')}</th>
                    <th style={{ width: '8%' }}>{t('common.Unit')}</th>
                    <th style={{ width: '8%' }}>
                      {t('common.Emission factor')}{' '}
                      <small style={{ textTransform: 'none' }}>
                        ({getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>)
                      </small>
                    </th>
                    <th style={{ width: '25%' }}>{t('database.Source')}</th>
                  </tr>
                </thead>
                <tbody>
                  {materials.filter(filterBySearch).map((m, i) => (
                    <tr key={i} onClick={() => { setEditRow(m); }} style={{ background: checkMaterial(m) && '#c6341844', color: checkDuplicate(m) && '#F10C0C' }}>
                      {editRow === m ? <td><TextField onChange={handleChange} object={m} prop="name" /></td> : <td>{m.name}</td>}
                      {editRow === m ? <td><TextField onChange={handleChange} object={m} prop="description" /></td> : <td>{m.description}</td>}
                      {editRow === m
                        ? (
                          <td>
                            <DropDown onChange={handleChange} object={m} prop="unitId" options={availableUnits} optionvalue="id" optiontext="abbreviation" setDropDownError={setDropDownError} />
                          </td>
                        ) : <td>{getUnit(m.unitId) !== undefined && getUnit(m.unitId).abbreviation}</td>}
                      {editRow === m ? <td><TextField onChange={handleChange} object={m} prop="emission" /></td> : <td>{m.emission}</td>}
                      {editRow === m ? <td><TextField onChange={handleChange} object={m} prop="source" /></td> : <td>{m.source}</td>}
                      <td style={{ textAlign: 'center' }}>
                        <ButtonGroup>
                          <Button variant="primary-outline" onClick={() => { setEditRow(m); }} style={{ marginRight: '10px' }}><FaPen /></Button>
                          <Button variant="danger" onClick={() => { removeMaterial(m); }}><FaTrash /></Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>

  );
});
