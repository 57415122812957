import { list } from 'postcss';
import http from './http-common';

function ToNumber(num, nullable) {
  if (num === null && nullable) {
    return null;
  }
  if (typeof num === 'number') {
    return num;
  }
  if (typeof num === 'string') {
    num = num.replace(',', '.');
  }

  num = Number(num);

  if (Number.isNaN(num)) {
    if (nullable) {
      return null;
    }
    return 0;
  }
  return num;
}

export default () => ({
  get url() {
    return {
      sso: `${http.client.defaults.baseURL}account/login/sso`,
    };
  },
  get health() {
    const available = async () => {
      try {
        const healthyOrNot = await http.get('health');
        return healthyOrNot === 'Healthy';
      } catch (e) {
        return false;
      }
    };
    return { available };
  },
  get news() {
    return {
      get: () => http.get('news'),
    };
  },
  get accounts() {
    const logout = async () => http.post('account/logout');
    const login = async ({
      email, password, rememberMe, refreshToken, service, code, accessToken,
    }) => {
      if (refreshToken) {
        return await http.post(`account/login/refresh/${refreshToken}`);
        alert('Refresh token')
      }

      if (accessToken) {

        alert('access token')

        email = '';
        password = '';
      }

      return await http.post('account/login', {
        email, password, rememberMe, code, accessToken,
      }, { withCredentials: true, params: { service } });
    };
    const refresh = async () => await http.post('account/login/refresh/', null, { withCredentials: true });

    const get = async ({ projectId }) => await http.get('account', { params: { projectId } });

    const me = {
      get: async () => await http.get('account/me'),
      update: async ({
        username, password, name, email, telephone, company, address, currentPassword, eulaAcceptedVersion, isAccessTokenEnabled,
      }) => await http.put('account/me', {
        username, password, name, email, telephone, company, address, currentPassword, eulaAcceptedVersion, isAccessTokenEnabled,
      }),
      roles: async () => await http.get('account/me/roles'),
    };

    const update = async ({
      id, username, password, name, email, telephone, company, address, isAdmin,
    }) => await http.put(`account/${id}`, {
      username, password, name, email, telephone, company, address, isAdmin,
    });

    const register = async ({
      username, password, name, email, telephone, company, address, isAdmin,
    }) => await http.post('account/register', {
      username, password, name, email, telephone, company, address, isAdmin,
    });
    const deleteUser = async ({ userId }) => await http.delete(`account/${userId}`);

    return {
      login, get, register, me, update, delete: deleteUser, refresh, logout,
    };
  },

  installationPhases(installationPhaseId) {
    const get = async () => http.get('installationphase');
    const deleteInstallationPhase = async () => http.delete(`installationphase/${installationPhaseId}`);
    const elements = async () => await http.get(`installationphase/${installationPhaseId}/elements`);
    const templates = async () => await http.get(`installationphase/${installationPhaseId}/templates`);

    const post = async ({
      name, description, options, organization_id
    }) => await http.post('installationphase/', {
      name, description, organization_id,
      options: options.map(({
        name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine, organizationId
      }) => ({
        name,
        description,
        machineId: ToNumber(machineId),
        machineConversionId: ToNumber(machineConversionId),
        installationPhaseId: ToNumber(installationPhaseId),
        machineFactor: ToNumber(machineFactor),
        machineConversion,
        machine,
        organizationId
      })),
    });

    const update = async ({
      name, description, options
    }) => await http.put(`installationphase/${installationPhaseId}`, {
      name, description,
      options: options.map(({
        id, name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine,
      }) => ({
        id,
        name,
        description,
        machineId: ToNumber(machineId),
        machineConversionId: ToNumber(machineConversionId),
        installationPhaseId: ToNumber(installationPhaseId),
        machineFactor: ToNumber(machineFactor),
        machine,
        machineConversion,
      })),
    });

    return {
      get, post, update, delete: deleteInstallationPhase, elements, templates
    };
  },

  installationOptions() {
    const get = async () => http.get('installationphase');

    const post = async ({
      name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine,
    }) => await http.post('installationphase/option', {
      name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine,
    });
    const update = async ({
      name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine,
    }) => await http.put(`installationphase/${installationOptionId}`, {
      name, description, machineId, machineConversionId, installationPhaseId, machineFactor, machineConversion, machine,
    });

    return {
      get, post, update,
    };
  },

  machines(machineId) {
    const get = async ({ search, fromUnitId, toUnitId }) => {
      if (machineId !== undefined && machineId !== null) {
        return await http.get(`machine/${machineId}`);
      }
      return await http.get('machine/', { params: { search, fromUnitId, toUnitId } });
    };
    const deleteMachine = async () => await http.delete(`machine/${machineId}`);
    const getMachinesByOrgId = async ({ id, search, includeLinked, offset, limit, sortBy }) => {
      return await http.get(`machine/getMachineByOrgId/`, { params: { id, search, includeLinked, offset, limit, sortBy } });
    }
    const createConversion = (conversion) => ({
      id: conversion.id,
      comment: conversion.comment,
      factor: ToNumber(conversion.factor),
      fromUnitId: ToNumber(conversion.fromUnitId),
      toUnitId: ToNumber(conversion.toUnitId),
      machineId,
      inverse: !!conversion.inverse,
    });
    const createEmission = (emission) => ({
      id: emission.id,
      comment: emission.comment,
      source: emission.source,
      fromUnitId: ToNumber(emission.fromUnitId),
      emission: ToNumber(emission.emission),
      machineId,
    });

    const post = async ({
      name, description, conversions, emissions, organizationId, expirationYear, module
    }) => await http.post('machine/', {
      name, description, conversions: conversions.map((c) => createConversion(c)), emissions: emissions.map((e) => createEmission(e)), organizationId, expirationYear: ToNumber(expirationYear),
      module
    });
    const update = async ({
      name, description, conversions, emissions, expirationYear, module
    }) => await http.put(`machine/${machineId}`, {
      name, description, conversions: conversions.map((c) => createConversion(c)), emissions: emissions.map((e) => createEmission(e)), expirationYear: ToNumber(expirationYear), module
    });
    const elements = async () => await http.get(`machine/${machineId}/elements`);
    const templates = async () => await http.get(`machine/${machineId}/templates`);
    const conversionUsedInIP = async (machineConversionId) => await http.delete(`machine/isMachineConversionUsedinIP/${machineConversionId}`);

    return {
      get, delete: deleteMachine, post, update, elements, templates, getMachinesByOrgId, conversionUsedInIP
    };
  },

  materials(materialId) {
    const get = async ({
      search, fromUnitId, toUnitId, limit, offset, sortBy, filterByOrgId, filterType
    }) => {
      if (materialId !== undefined && materialId !== null) {
        return await http.get(`material/${materialId}`);
      }
      console.log("API Call");
      console.log({
        search, fromUnitId, toUnitId, limit, offset, sortBy, filterByOrgId, filterType
      });
      return await http.get('material/', {
        params: {
          search, fromUnitId, toUnitId, limit, offset, sortBy, filterByOrgId, filterType
        },
      });
    };

    const post = async ({
      name, description, emission, unitId, conversions, source, organizationId, expirationYear, module
    }) => await http.post('material/', {
      name,
      description,
      emission: ToNumber(emission),
      unitId: ToNumber(unitId),
      source,
      organizationId,
      expirationYear: ToNumber(expirationYear),
      module,
      conversions: conversions.map(({
        fromUnitId, toUnitId, factor, materialId, inverse, comment,
      }) => ({
        materialId: ToNumber(materialId),
        fromUnitId: ToNumber(fromUnitId),
        toUnitId: ToNumber(toUnitId),
        factor: ToNumber(factor),
        comment,
        inverse: !!inverse,
      })),
    });
    const materialPostCheck = async (materials) => {
      if (Array.isArray(materials)) {
        materials = materials.map(({
          name, description, emission, unitId, conversions, source, organizationId, expirationYear, module
        }) => ({
          name,
          description,
          emission: ToNumber(emission),
          unitId: ToNumber(unitId),
          source,
          organizationId,
          expirationYear: ToNumber(expirationYear),
          module,
          conversions: conversions.map(({
            fromUnitId, toUnitId, factor, materialId, inverse, comment,
          }) => ({
            materialId: ToNumber(materialId),
            fromUnitId: ToNumber(fromUnitId),
            toUnitId: ToNumber(toUnitId),
            factor: ToNumber(factor),
            comment,
            inverse: !!inverse,
          })),
        }));

        return await http.post('material/batch', materials);
      }
      return post(materials);
    };
    const update = async ({
      id, name, description, emission, unitId, conversions, source, expirationYear, module, organizationId, createdBy, updatedBy, updatedAt, emissionUpdatedBy, emissionUpdatedAt, publish = false,
    }) => await http.put(`material/${materialId}`, {
      id: ToNumber(materialId),
      name,
      description,
      emission: ToNumber(emission),
      unitId: ToNumber(unitId),
      source,
      expirationYear: ToNumber(expirationYear),
      module,
      createdBy,
      updatedBy,
      updatedAt,
      emissionUpdatedBy,
      emissionUpdatedAt,
      publish,
      conversions: conversions.map(({
        id, fromUnitId, toUnitId, factor, materialId, inverse, comment,
      }) => ({
        id,
        materialId: ToNumber(materialId),
        fromUnitId: ToNumber(fromUnitId),
        toUnitId: ToNumber(toUnitId),
        factor: ToNumber(factor),
        comment,
        inverse: !!inverse,
      })),
      organizationId
    });
    const deleteMaterial = async () => await http.delete(`material/${materialId}`);
    const elements = async () => await http.get(`material/${materialId}/elements`);
    const templates = async () => await http.get(`material/${materialId}/templates`);

    return {
      get, post: materialPostCheck, delete: deleteMaterial, update, elements, templates,
    };
  },
  projects(projectId) {
    const projectDelete = async () => await http.delete(`project/${projectId}`);
    const get = async () => {
      if (projectId !== undefined && projectId !== null) {
        return await http.get(`project/${projectId}`);
      }
      return await http.get('project');
    };
    const deleteP = async () => await http.delete(`project/${projectId}`);
    const post = async ({
      name, description, projectToNumber, type, tags, status, managerName, started, ended, groups, isPublic, locationLongitude, locationLatitude, phase, locationName, projectNumber, organizationId,
    }) => {
      started = Number.isNaN(Date.parse(started)) ? undefined : new Date(Date.parse(started));
      ended = Number.isNaN(Date.parse(ended)) ? undefined : new Date(Date.parse(ended));

      locationLatitude = ToNumber(locationLatitude, true);
      locationLongitude = ToNumber(locationLongitude, true);
      groups = groups.map((g) => ({
        ...g,
        parentId: ToNumber(g.parentId, true),
        code: g.code !== null && g.code !== undefined ? g.code.toString() : null,
      }));

      return await http.post('project', {
        name, description, projectToNumber, type, tags, status, managerName, started, ended, groups, isPublic: !!isPublic, locationLatitude, locationLongitude, phase: ToNumber(phase), locationName, projectNumber, organizationId,
      });
    };
    const update = async ({
      id, name, description, projectToNumber, type, tags, status, managerName, started, ended, groups, isPublic, locationLongitude, locationLatitude, phase, locationName, projectNumber,
    }) => {
      locationLatitude = ToNumber(locationLatitude, true);
      locationLongitude = ToNumber(locationLongitude, true);
      groups = groups.map((g) => ({
        ...g,
        parentId: ToNumber(g.parentId, true),
        code: g.code !== null && g.code !== undefined ? g.code.toString() : null,
        children: undefined,
      }));
      return await http.put(`project/${projectId}`, {
        id, name, description, projectToNumber, type: ToNumber(type), tags, status: ToNumber(status), managerName, started, ended, groups, isPublic: !!isPublic, locationLongitude, locationLatitude, phase: ToNumber(phase), locationName, projectNumber,
      });
    };
    const addUser = async ({ userId, roleId }) => await http.put(`project/${projectId}/user/${userId}`, undefined, { params: { roleId } });
    const removeUser = async ({ userId }) => await http.delete(`project/${projectId}/user/${userId}`);
    const inviteUser = async ({ email, roleId }) => await http.post(`project/${projectId}/invite/`, { projectId: ToNumber(projectId), email, roleId: ToNumber(roleId) });
    const getScenarios = async () => await http.get('scenario', { params: { projectId } });

    const roles = () => {
      const get = async () => await http.get(`project/${projectId}/role`);
      return { get };
    };
    const users = (userId) => {
      const get = async () => await http.get(`project/${projectId}/user`);
      const put = async () => await http.put(`project/${projectId}/user/${userId}`);
      const userDelete = async () => await http.delete(`project/${projectId}/user/${userId}`);
      return { get, put, delete: userDelete };
    };

    return {
      deleteP, get, post, update, delete: projectDelete, addUser, removeUser, inviteUser, getScenarios, roles, users,
    };
  },
  scenarios(scenarioId) {
    const scenarioDelete = async () => await http.delete(`scenario/${scenarioId}`);
    const get = async ({ projectId }) => {
      if (scenarioId === undefined) {
        return await http.get('scenario/', { params: { projectId } });
      }
      return await http.get(`scenario/${scenarioId}`);
    };
    const post = async ({
      projectId, name, description, elements,
    }) => http.post('scenario', {
      projectId: ToNumber(projectId),
      name: name.toString(),
      description,
      elements,
    });
    const update = async ({
      id, name, description, elements, projectId,
    }) => http.put(`scenario/${id}`, {
      name: name.toString(),
      description,
      elements,
      projectId,
    });

    const elements = (elementId) => {
      const explain = async () => await http.get(`scenario/${scenarioId}/element/${elementId}/explain`);
      const getElement = async () => {
        if (elementId !== undefined) {
          return await http.get(`scenario/${scenarioId}/element/${elementId}`);
        }
        return await http.get(`scenario/${scenarioId}/element`);
      };
      const deleteElement = async (elementIds) => {
        if (Array.isArray(elementIds)) {
          return await http.delete(`scenario/${scenarioId}/elements/`, { data: elementIds });
        }
        return await http.delete(`scenario/${scenarioId}/element/${elementId}`);
      };
      const elementPost = async ({
        name,
        description,
        code,
        unitId,
        quantity,
        totalEmission,
        groupId,
        transports,
        products,
        installations,
        genericItems,
        templateId,
        productStatus,
        transportStatus,
        installationStatus,
      }) => http.post(`scenario/${scenarioId}/element`, {
        name,
        description,
        code,
        unitId: ToNumber(unitId),
        quantity: ToNumber(quantity),
        totalEmission: ToNumber(totalEmission),
        scenarioId,
        groupId,
        transports,
        products,
        installations, genericItems,
        templateId: ToNumber(templateId, true),
        productStatus,
        transportStatus,
        installationStatus,
      });

      const elementPostCheck = async (elements) => {
        if (Array.isArray(elements)) {
          elements = elements.map(({
            id,
            name,
            description,
            code,
            unitId,
            quantity,
            totalEmission,
            transports,
            groupId,
            preConstructionStatus,
            products,
            installations,
            unitPrice,
            totalPrice,
            productStatus,
            transportStatus,
            installationStatus,
            templateId,
            installedProductsStatus,
            maintenanceStatus,
            repairStatus,
            replacementStatus,
            refurbishmentStatus,
            operationalEnergyStatus,
            operationalWaterStatus,
            otherOperationsStats,
            userUtilizationsStatus,
            deconstructionStatus,
            transportCStatus,
            wasteProcessingStatus,
            disposalStatus,
            conversions,
            genericItems
          }) => {
            // Validate transport
            if (Array.isArray(transports)) {
              transports = transports.map(({
                transportRoadId,
                transportWaterborneId,
                name,
                description,
                capacity,
                roadDistance,
                totalDistance,
                numberOfTrips,
                totalEmission,
                totalQuantity,
                transportMaterials,
              }) => {
                if (Array.isArray(transportMaterials)) {
                  transportMaterials = transportMaterials.map(({
                    materialId,
                    unitId,
                    name,
                    comment,
                    conversionFactor,
                    factor,
                    quantity,
                  }) => ({
                    materialId,
                    unitId: ToNumber(unitId),
                    name,
                    comment,
                    conversionFactor: ToNumber(conversionFactor),
                    factor: ToNumber(factor),
                    quantity: ToNumber(quantity),
                  }));
                }
                return {
                  transportRoadId: ToNumber(transportRoadId, true),
                  transportWaterborneId: ToNumber(transportWaterborneId, true),
                  name,
                  description,
                  capacity: ToNumber(capacity),
                  roadDistance: ToNumber(roadDistance),
                  totalDistance: ToNumber(totalDistance),
                  numberOfTrips: ToNumber(numberOfTrips),
                  totalEmission: ToNumber(totalEmission),
                  totalQuantity: ToNumber(totalQuantity),
                  transportMaterials,
                };
              });
            }
            // Validate products
            if (Array.isArray(products)) {
              products = products.map(({
                id,
                name,
                materialId,
                description,
                comment,
                factor,
                conversionFactor,
                totalEmission,
              }) => ({
                id: ToNumber(id),
                materialId: ToNumber(materialId),
                description,
                comment,
                name,
                factor: ToNumber(factor),
                conversionFactor: ToNumber(conversionFactor),
                totalEmission: ToNumber(totalEmission),
              }));
            }
            if (Array.isArray(conversions)) {
              conversions = conversions.map(({
                id,
                factor,
                fromUnitId,
                toUnitId,
                inverse,
                comment,
              }) => ({
                id: ToNumber(id),
                fromUnitId: ToNumber(fromUnitId),
                factor: ToNumber(factor),
                description,
                comment,
                inverse,
                toUnitId: ToNumber(toUnitId),
              }));
            }

            // Validate installations
            if (Array.isArray(installations)) {
              installations = installations.map(({
                id,
                name,
                materialId,
                machineId,
                materialConversionId,
                installationPhaseOptionId,
                machineConversionId,
                comment,
                description,
                totalEmission,
                materialFactor,
                machineFactor,
              }) => ({
                id: ToNumber(id),
                materialId: ToNumber(materialId),
                machineId: ToNumber(machineId),
                machineConversionId: ToNumber(machineConversionId) === 0 ? null : ToNumber(machineConversionId),
                installationPhaseOptionId: ToNumber(installationPhaseOptionId) === 0 ? null : ToNumber(installationPhaseOptionId),
                machineFactor: ToNumber(machineFactor),
                materialConversionId: ToNumber(materialConversionId) === 0 ? null : ToNumber(materialConversionId),
                materialFactor: ToNumber(materialFactor),
                description,
                comment,
                name,
                totalEmission: ToNumber(totalEmission),
              }));
            }
            // Validate Generic items
            if (Array.isArray(genericItems)) {
              genericItems = genericItems.map(({
                id, type, name, comment, description, totalEmission,
              }) => ({
                id: ToNumber(id),
                type,
                description,
                comment,
                name,
                totalEmission: ToNumber(totalEmission),
              }));
            }

            return {
              id: ToNumber(id),
              scenarioId,
              name,
              description,
              code: `${code}`,
              unitId: ToNumber(unitId),
              quantity: ToNumber(quantity),
              groupId: ToNumber(groupId, true),
              totalEmission: ToNumber(totalEmission),
              transports,
              products,
              installations,
              unitPrice: ToNumber(unitPrice),
              totalPrice: ToNumber(totalPrice),
              preConstructionStatus: ToNumber(preConstructionStatus),
              productStatus: ToNumber(productStatus),
              transportStatus: ToNumber(transportStatus),
              installationStatus: ToNumber(installationStatus),
              installedProductsStatus: ToNumber(installedProductsStatus),
              maintenanceStatus: ToNumber(maintenanceStatus),
              repairStatus: ToNumber(repairStatus),
              replacementStatus: ToNumber(replacementStatus),
              refurbishmentStatus: ToNumber(refurbishmentStatus),
              operationalEnergyStatus: ToNumber(operationalEnergyStatus),
              operationalWaterStatus: ToNumber(operationalWaterStatus),
              otherOperationsStats: ToNumber(otherOperationsStats),
              userUtilizationsStatus: ToNumber(userUtilizationsStatus),
              deconstructionStatus: ToNumber(deconstructionStatus),
              transportCStatus: ToNumber(transportCStatus),
              wasteProcessingStatus: ToNumber(wasteProcessingStatus),
              disposalStatus: ToNumber(disposalStatus),
              templateId: ToNumber(templateId, true),
              conversions,
              genericItems
            };
          });
          return http.post(`scenario/${scenarioId}/elements`, elements);
        }
        return elementPost(elements);
      };
      const elementUpdate = async ({
        id,
        name,
        description,
        code,
        quantity,
        unitId,
        totalEmission,
        groupId,
        preConstructionStatus,
        transports,
        products,
        installations, genericItems,
        unitPrice,
        totalPrice,
        productStatus,
        transportStatus,
        installationStatus,
        installedProductsStatus, maintenanceStatus, repairStatus, replacementStatus, refurbishmentStatus, operationalEnergyStatus, operationalWaterStatus, otherOperationsStats, userUtilizationsStatus, deconstructionStatus, transportCStatus, wasteProcessingStatus, disposalStatus, conversions,
        preview,
        formType
      }) => {
        // Validate transport
        if (Array.isArray(transports)) {
          transports = transports.map(({
            id,
            transportRoadId,
            transportWaterborneId,
            name,
            description,
            capacity,
            roadDistance,
            roadDistancePercentage,
            totalDistance,
            numberOfTrips,
            totalEmission,
            totalQuantity,
            transportMaterials,
          }) => {
            if (Array.isArray(transportMaterials)) {
              transportMaterials = transportMaterials.map(({
                materialId,
                unitId,
                name,
                comment,
                conversionFactor,
                factor,
                quantity,
              }) => ({
                materialId,
                unitId: ToNumber(unitId),
                name,
                comment,
                conversionFactor: ToNumber(conversionFactor),
                factor: ToNumber(factor),
                quantity: ToNumber(quantity),
              }));
            }
            return {
              id: ToNumber(id),
              transportRoadId: ToNumber(transportRoadId, true),
              transportWaterborneId: ToNumber(transportWaterborneId, true),
              name,
              description,
              capacity: ToNumber(capacity),
              roadDistance: ToNumber(roadDistance),
              roadDistancePercentage: ToNumber(roadDistancePercentage),
              totalDistance: ToNumber(totalDistance),
              numberOfTrips: ToNumber(numberOfTrips),
              totalEmission: ToNumber(totalEmission),
              totalQuantity: ToNumber(totalQuantity),
              transportMaterials,
            };
          });
        }
        // Validate products
        if (Array.isArray(products)) {
          products = products.map(({
            id, name, materialId, description, comment, factor, conversionFactor, totalEmission,
          }) => ({
            id: ToNumber(id),
            materialId: ToNumber(materialId),
            description,
            comment,
            name,
            factor: ToNumber(factor),
            conversionFactor: ToNumber(conversionFactor),
            totalEmission: ToNumber(totalEmission),
          }));
        }

        // Validate installations
        if (Array.isArray(installations)) {
          installations = installations.map(({
            id, name, materialId, machineId, materialConversionId, machineConversionId, installationPhaseOptionId, comment, description, totalEmission, materialFactor, machineFactor,
          }) => ({
            id: ToNumber(id),
            materialId: ToNumber(materialId),
            machineId: ToNumber(machineId),
            machineConversionId: ToNumber(machineConversionId) === 0 ? null : ToNumber(machineConversionId),
            installationPhaseOptionId: ToNumber(installationPhaseOptionId) === 0 ? null : ToNumber(installationPhaseOptionId),
            machineFactor: ToNumber(machineFactor),
            materialConversionId: ToNumber(materialConversionId) === 0 ? null : ToNumber(materialConversionId),
            materialFactor: ToNumber(materialFactor),
            description,
            comment,
            name,
            totalEmission: ToNumber(totalEmission),
          }));
        }

        // Validate genericItems
        if (Array.isArray(genericItems)) {
          genericItems = genericItems.map(({
            id, type, name, comment, description, totalEmission,
          }) => ({
            id: ToNumber(id),
            type,
            description,
            comment,
            name,
            totalEmission: ToNumber(totalEmission),
          }));
        }


        // Create query
        return http.put(`scenario/${scenarioId}/element/${elementId}`, {
          id: ToNumber(id),
          name: `${name}`,
          description,
          code: `${code}`,
          quantity: ToNumber(quantity),
          unitId: ToNumber(unitId),
          totalEmission: ToNumber(totalEmission),
          scenarioId: ToNumber(scenarioId),
          groupId: ToNumber(groupId, true),
          transports,
          products,
          installations,
          genericItems,
          conversions,
          unitPrice: ToNumber(unitPrice),
          totalPrice: ToNumber(totalPrice),
          preConstructionStatus: ToNumber(preConstructionStatus),
          productStatus: ToNumber(productStatus),
          installationStatus: ToNumber(installationStatus),
          transportStatus: ToNumber(transportStatus),
          installedProductsStatus: ToNumber(installedProductsStatus),
          maintenanceStatus: ToNumber(maintenanceStatus),
          repairStatus: ToNumber(repairStatus),
          replacementStatus: ToNumber(replacementStatus),
          refurbishmentStatus: ToNumber(refurbishmentStatus),
          operationalEnergyStatus: ToNumber(operationalEnergyStatus),
          operationalWaterStatus: ToNumber(operationalWaterStatus),
          otherOperationsStats: ToNumber(otherOperationsStats),
          userUtilizationsStatus: ToNumber(userUtilizationsStatus),
          deconstructionStatus: ToNumber(deconstructionStatus),
          transportCStatus: ToNumber(transportCStatus),
          wasteProcessingStatus: ToNumber(wasteProcessingStatus),
          disposalStatus: ToNumber(disposalStatus),
          formType,
        },
          { params: { preview } });
      };

      return {
        get: getElement,
        delete: deleteElement,
        update: elementUpdate,
        post: elementPostCheck,
        explain,
      };
    };

    return {
      get, post, update, delete: scenarioDelete, elements,
    };
  },
  get settings() {
    const units = async () => http.get('settings/units/');
    const codes = async () => http.get('settings/codes/');
    const project = async () => http.get('settings/project/');
    const organization = async () => http.get('settings/organization/');
    const templates = async () => http.get('settings/templates');
    const installTemplates = async () => http.post('settings/templates');
    const carbonStock = async () => http.get('settings/carbonstock');
    const organizations = async () => http.get('settings/organizations/')
    return {
      units, codes, project, organization, installTemplates, templates, carbonStock, organizations,
    };
  },
  transports() {
    return {
      road: (transportId) => {
        const get = async () => {
          if (transportId !== undefined && transportId !== null) {
            return http.get(`transport/road/${transportId}`);
          }
          return http.get('transport/road/');
        };
        const getVehiclesBasedOnOrgId = async (orgId) => {
          if (orgId !== undefined) {
            return http.get(`transport/road/getVehicleByOrgId/${orgId}`);
          }
        }
        const post = async ({
          name, description, classification, organizationId, totalMass, deadWeight, vehicleMass,
          emissionRoad, emissionRoadFull, emissionStreet, emissionStreetFull, emissionAverage,
          source, emissionUnitId, unitId, expirationYear, module
        }) =>
          http.post('transport/road/', {
            name,
            description,
            source,
            classification,
            organizationId,
            totalMass: ToNumber(totalMass),
            deadWeight: ToNumber(deadWeight),
            vehicleMass: ToNumber(vehicleMass),
            emissionRoad: ToNumber(emissionRoad),
            emissionRoadFull: ToNumber(emissionRoadFull),
            emissionStreet: ToNumber(emissionStreet),
            emissionStreetFull: ToNumber(emissionStreetFull),
            emissionAverage: ToNumber(emissionAverage),
            emissionUnitId: ToNumber(emissionUnitId),
            unitId: ToNumber(unitId),
            expirationYear: ToNumber(expirationYear),
            module
          });

        //changed
        const update = async ({
          name, description, classification, totalMass, deadWeight, vehicleMass, emissionRoad, emissionRoadFull, emissionStreet, emissionStreetFull, source, emissionUnitId, emissionAverage, unitId, expirationYear, module
        }) => http.put(`transport/road/${transportId}`, {
          name,
          description,
          source,
          classification,
          totalMass: ToNumber(totalMass),
          deadWeight: ToNumber(deadWeight),
          vehicleMass: ToNumber(vehicleMass),
          emissionRoad: ToNumber(emissionRoad),
          emissionRoadFull: ToNumber(emissionRoadFull),
          emissionStreet: ToNumber(emissionStreet),
          emissionStreetFull: ToNumber(emissionStreetFull),
          emissionUnitId: ToNumber(emissionUnitId),
          emissionAverage: ToNumber(emissionAverage),
          unitId: ToNumber(unitId),
          expirationYear: ToNumber(expirationYear),
          module
        });
        const deleteTransport = async () => http.delete(`transport/road/${transportId}`);
        const elements = async () => await http.get(`transport/road/${transportId}/elements`);
        const templates = async () => await http.get(`transport/road/${transportId}/templates`);

        return {
          get, post, delete: deleteTransport, update, getVehiclesBasedOnOrgId, elements, templates
        };
      },
      waterborne: (transportId) => {
        const get = async () => {
          if (transportId !== undefined && transportId !== null) {
            return http.get(`transport/waterborne/${transportId}`);
          }
          return http.get('transport/waterborne/');
        };
        const getVesselsByOrgId = (orgId) => {
          if (orgId !== null) {
            return http.get(`transport/waterborne/getVesselsByOrgId/${orgId}`)
          }
        }
        const post = async ({
          name, description, source, emission, emissionUnitId, organizationId, expirationYear, module
        }) => http.post('transport/waterborne/', {
          name,
          description,
          source,
          emission: ToNumber(emission),
          emissionUnitId: ToNumber(emissionUnitId),
          organizationId,
          expirationYear: ToNumber(expirationYear),
          module
        });
        const update = async ({
          name, description, source, emission, emissionUnitId, expirationYear, module
        }) => http.put(`transport/waterborne/${transportId}`, {
          name,
          description,
          source,
          emission: ToNumber(emission),
          emissionUnitId: ToNumber(emissionUnitId),
          expirationYear: ToNumber(expirationYear),
          module
        });
        const deleteTransport = async () => http.delete(`transport/waterborne/${transportId}`);
        const elements = async () => await http.get(`transport/waterborne/${transportId}/elements`);
        const templates = async () => await http.get(`transport/waterborne/${transportId}/templates`);
        return {
          get, post, delete: deleteTransport, update, getVesselsByOrgId, elements, templates
        };
      },
    };
  },
  templates(templateId) {
    const get = async ({ unitId, code, search }) => {
      if (templateId !== undefined && templateId !== null) {
        return await http.get(`template/${templateId}`);
      }
      return await http.get('template/', { params: { unitId: Number.isNaN(ToNumber(unitId)) ? undefined : ToNumber(unitId), code, search } });
    };
    const getTemplateByOrgId = async ({ unitId, code, search, orgId }) => {
      return await http.get(`template/getAllTemplateByOrgId`, { params: { unitId: Number.isNaN(ToNumber(unitId)) ? undefined : ToNumber(unitId), code, search, orgId } });
    }
    const deleteTemplate = async () => await http.delete(`template/${templateId}`);
    const templatePost = async ({
      name,
      description,
      code,
      unitId,
      quantity,
      totalEmission,
      groupId,
      transports,
      products,
      installations,
      projectType,
      organizationId
    }) => http.post('template/', {
      name,
      description,
      code,
      unitId: ToNumber(unitId),
      quantity: ToNumber(quantity),
      totalEmission: ToNumber(totalEmission),
      groupId,
      transports,
      products,
      installations,
      projectType: ToNumber(projectType),
      organizationId
    });
    const templateUpdate = async ({
      id,
      name,
      description,
      code,
      quantity,
      unitId,
      totalEmission,
      groupId,
      transports,
      products,
      installations,
      unitPrice,
      totalPrice,
      conversions,
      projectType,
      organizationId
    }) => {
      // Validate transport
      if (Array.isArray(transports)) {
        transports = transports.map(({
          transportRoadId,
          transportWaterborneId,
          name,
          description,
          capacity,
          roadDistance,
          roadDistancePercentage,
          totalDistance,
          numberOfTrips,
          totalEmission,
          totalQuantity,
          transportMaterials,
        }) => {
          if (Array.isArray(transportMaterials)) {
            transportMaterials = transportMaterials.map(({
              materialId,
              unitId,
              name,
              comment,
              conversionFactor,
              factor,
              quantity,
            }) => ({
              materialId,
              unitId: ToNumber(unitId),
              name,
              comment,
              conversionFactor: ToNumber(conversionFactor),
              factor: ToNumber(factor),
              quantity: ToNumber(quantity),
            }));
          }
          return {
            transportRoadId: ToNumber(transportRoadId, true),
            transportWaterborneId: ToNumber(transportWaterborneId, true),
            name,
            description,
            capacity: ToNumber(capacity),
            roadDistance: ToNumber(roadDistance),
            roadDistancePercentage: ToNumber(roadDistancePercentage),
            totalDistance: ToNumber(totalDistance),
            numberOfTrips: ToNumber(numberOfTrips),
            totalEmission: ToNumber(totalEmission),
            totalQuantity: ToNumber(totalQuantity),
            transportMaterials,
          };
        });
      }
      // Validate products
      if (Array.isArray(products)) {
        products = products.map(({
          id, name, materialId, description, comment, factor, conversionFactor, totalEmission,
        }) => ({
          id: ToNumber(id),
          materialId: ToNumber(materialId),
          description,
          comment,
          name,
          factor: ToNumber(factor),
          conversionFactor: ToNumber(conversionFactor),
          totalEmission: ToNumber(totalEmission),
        }));
      }

      // Validate installations
      if (Array.isArray(installations)) {
        installations = installations.map(({
          id, name, materialId, machineId, installationPhaseOptionId, materialConversionId, machineConversionId, comment, description, totalEmission, materialFactor, machineFactor,
        }) => ({
          id: ToNumber(id),
          materialId: ToNumber(materialId),
          machineId: ToNumber(machineId),
          machineConversionId: ToNumber(machineConversionId) === 0 ? null : ToNumber(machineConversionId),
          installationPhaseOptionId: ToNumber(installationPhaseOptionId) === 0 ? null : ToNumber(installationPhaseOptionId),
          machineFactor: ToNumber(machineFactor),
          materialConversionId: ToNumber(materialConversionId) === 0 ? null : ToNumber(materialConversionId),
          materialFactor: ToNumber(materialFactor),
          description,
          comment,
          name,
          totalEmission: ToNumber(totalEmission),
        }));
      }
      // Create query
      return await http.put(`template/${templateId}`, {
        id: ToNumber(id),
        name: `${name}`,
        description,
        code: `${code}`,
        unitId: ToNumber(unitId),
        totalEmission: ToNumber(totalEmission),
        groupId: ToNumber(groupId, true),
        transports,
        products,
        installations,
        unitPrice: ToNumber(unitPrice),
        totalPrice: ToNumber(totalPrice),
        conversions: Array.isArray(conversions) ? conversions : [],
        projectType: ToNumber(projectType),
        organizationId
      });
    };

    const search = async (queries, orgId) => {
      queries = queries.map(({
        id, name, code, unitId,
      }) => ({
        id: ToNumber(id),
        // name,
        code,
        unitId: ToNumber(unitId),
      }));

      return await http.post(`template/search/${orgId}`, { queries });
    };
    return {
      get, delete: deleteTemplate, post: templatePost, search, update: templateUpdate, getTemplateByOrgId,
    };
  },
  reports() {
    const get = async ({ projectId }) => http.get('report/', { params: { projectId: ToNumber(projectId) } });

    return { get };
  },
  carbonStocks(scenarioId) {
    const get = async () => http.get(`scenario/${scenarioId}/carbonstock`);
    const calculate = async ({ id, debug }) => http.post(`scenario/${scenarioId}/carbonstock/${id}/calculate`, {}, { params: { debug } });
    const post = async ({
      name, description, groupId, geometry,
    }) => http.post(`scenario/${scenarioId}/carbonstock`, {
      name, description, groupId, geometry, scenarioId,
    });
    const deleteCarbonStock = async (id) => http.delete(`scenario/${scenarioId}/carbonstock/${id}`);
    const updateCarbonStock = async ({
      id,
      name,
      description,
      groupId,
      geometry,
      area,
      calculationCoverage,
      carbonFluxSoil,
      carbonFluxTotal,
      carbonFluxVegetation,
    }) => http.put(`scenario/${scenarioId}/carbonstock/${id}`, {
      id,
      name,
      description,
      groupId,
      geometry,
      scenarioId,
      area,
      calculationCoverage,
      carbonFluxSoil,
      carbonFluxTotal,
      carbonFluxVegetation,
    });
    const importCarbonStock = async (formData) => http.post(`scenario/${scenarioId}/carbonstock/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      get,
      post,
      delete: deleteCarbonStock,
      update: updateCarbonStock,
      calculate,
      import: importCarbonStock,
    };
  },
});