import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  Button,
  Table,
  ButtonGroup,
  Alert,
} from 'react-bootstrap';
import { withSettingsStore } from '../common/settings-context';
import {
  FaPlus, FaTrash,
} from 'react-icons/fa';
import Api from '../../services/api';
import Select from 'react-select';
import { Typeahead } from '../common/components';
import clone from '../common/helpers/clone';
import './Database.scss'

const OptionsRow = withSettingsStore(({ option, onChange, machines, units, projectOrg, organizationId }) => {
  const { t } = useTranslation();
  const [refreshedMachines, setRefreshedMachines] = useState([])

  const getMachinesByOrg = async () => {
    if (organizationId !== null) {
      let { items } = await Api().machines().getMachinesByOrgId({ id: organizationId, includeLinked: false });
      setRefreshedMachines(items)
    }
  }

  useEffect(() => {
    getMachinesByOrg();
  }, [])

  const getUnitAbbreviation = useCallback((unitId) => {
    const unit = units.find((u) => u.id === unitId);
    if (unit === undefined) {
      return '';
    }
    return unit.abbreviation;
  }, [units]);

  const [currOption, setCurrOption] = useState(option)
  const [chosenMachine, setChosenMachine] = useState(option.machine)

  const machineConversionText = (conversion) => {
    try {
      return (`${getUnitAbbreviation(conversion["fromUnitId"])} \u2192 ${getUnitAbbreviation(conversion["toUnitId"])}`);
    } catch {
      return ("Select Conversion")
    }
  }

  const getConversionById = (givenId, conversions) => {
    return conversions.find((conversion) => conversion.id == givenId)
  }

  // const machineOptions = [...refreshedMachines.filter((item) => item.organizationId === organizationId).map(({ name, id, createdBy }) => { return { label: name, value: id } })  ]  
  // console.log(machineOptions);

  let machineConversionOptions = chosenMachine.conversions.map((conversion) => {
    return { label: machineConversionText(conversion), value: conversion["id"], comment: conversion["comment"] }
  }
  )
  
  const [chosenConversion, setChosenConversion] = useState(option.machineConversion)

  const handleMachineOptionChanges = (selectedOption) => {
    let equivalentMachine = refreshedMachines.find(machine => machine?.id === selectedOption.id);
    setChosenMachine(equivalentMachine);
    setCurrOption({ ...currOption, machineId: equivalentMachine?.id, machine: equivalentMachine, machineConversion: equivalentMachine?.conversions[0], machineConversionId: equivalentMachine?.conversions[0]?.id })
    onChange(currOption)
  }

  useEffect(() => {
    onChange(currOption)
  }, [chosenMachine, currOption, chosenConversion])

  const searchMachines = async ({ search }) => {
    const { items } = await Api().machines().getMachinesByOrgId({ id: organizationId, search, includeLinked: false });
    console.log("Machines:", items);
    return items;
  }

  const formatOptionLabel = ({label, comment}) => (
    <>
      <div>{label}</div>
      <div><small>{comment}</small></div>
    </>
  )

  return (
    <tr>
      <td style={{ width: "1000px" }}><Form.Control value={option.name} onChange={(event) => setCurrOption({ ...currOption, name: event.target.value })} placeholder={t('iPhaseForm.eg Option Name')} className="database-forms-placeholder" /></td>
      <td style={{ width: "1000px" }}><Form.Control value={option.description} onChange={(event) => setCurrOption({ ...currOption, description: event.target.value })} placeholder={t('iPhaseForm.eg Option Description')} className="database-forms-placeholder" /></td>
      <td style={{ width: "1000px" }}>
        <Typeahead
          value={chosenMachine.name}
          onSelect={(event) => {
            handleMachineOptionChanges(event); console.log("Event Value:", event);
          }}
          source={searchMachines}
          resultText="{name} - {description}"
          resultIcon={(res) => (res.createdBy > 0 ? 'user' : 'database')}
        />
      </td>
      <td style={{ width: "900px" }}>
        <Select
          value={{ label: machineConversionText(option.machineConversion), value: option.machineConversionId }}
          onChange={(event) => {
            let selectedConversion = getConversionById(event.value, chosenMachine.conversions);
            setCurrOption({ ...currOption, machineConversionId: event.value, machineConversion: selectedConversion });
            setChosenConversion({ value: selectedConversion.id, label: machineConversionText(selectedConversion), comment: selectedConversion.comment })
          }}
          options={machineConversionOptions}
          isSearchable={true}
          formatOptionLabel={formatOptionLabel}
        />
      </td>
      <td style={{ width: "10px" }} className="text-right">
        <ButtonGroup>
          <Button variant="outline-danger" onClick={() => { onChange('remove') }}><FaTrash /></Button>
        </ButtonGroup>
      </td>
      <td>
      </td>
    </tr>
  );
});

export default withSettingsStore(({
  installationPhase, onChange, organizationId, phaseError, backendError, sqlError, optionError, ...props
}) => {
  const { t } = useTranslation();

  const [newPhase, setNewPhase] = useState({
    id: 0,
    name: '',
    description: '',
    organization: '',
    organization_id: '',
    options: [],
    ...installationPhase === undefined ? {} : clone(installationPhase),
  });
  const [toggle, setToggle] = useState(true)

  useEffect(() => {
    onChange(newPhase);
  }, [newPhase, onChange]);

  useEffect(() => {
    if (newPhase.id !== installationPhase.id) {
      setNewPhase(phase);
    }
  }, [installationPhase, newPhase, toggle]);

  const handleChange = (element) => (event) => {
    const { name, value, organizationId } = event.currentTarget;
    newPhase[name] = value;
    newPhase[organizationId] = organizationId;
    setNewPhase(newPhase);
    onChange(element);
  };

  const handleRemoveOption = (idx) => {
    newPhase.options.splice(idx, 1);
    setToggle(!toggle)
  };
  const TextField = ({ element, field, placeholder }) => (<Form.Control type="text" defaultValue={element[field]} name={field} onBlur={handleChange(element)} onChange={handleChange(element)} placeholder={placeholder} className="database-forms-placeholder" />);

  const handleAddOption = () => {
    newPhase.options.push({
      name: null,
      description: null,
      machineId: null,
      machineConversionId: null,
      installationPhaseId: null,
      machineFactor: 0,
      machineConversion: {
        id: 0,
        machineId: 0,
        fromUnitId: 0,
        toUnitId: 0,
        comment: '',
        factor: 0,
        inverse: 0
      },
      machine: {
        id: 0,
        name: '',
        description: '',
        emission: 0,
        unitId: 1,
        conversions: [],
        emissions: [],
        createdBy: null
      },
    });
    setNewPhase({ ...newPhase });
  };


  return (
    <Form>
      <div>
        <h4>
          {t('installation.Phase details')}
          {' '}
        </h4>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('common.Name')}
              </Form.Label>
              <TextField element={newPhase} field="name" placeholder={t('iPhaseForm.eg Name')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('common.Description')}
              </Form.Label>
              <TextField element={newPhase} field="description" placeholder={t('iPhaseForm.eg Description')} />
            </Form.Group>
          </Col>
        </Row>
        <h4>{t('installation.Phase options')}</h4>
        <Table>
          {(
            <thead>
              <tr>
                <th style={{ width: "300px" }}>{t('common.Name')}</th>
                <th style={{ width: "460px" }}>{t('common.Description')}</th>
                <th style={{ width: "300px" }}>{t('database.Machine')}</th>
                <th style={{ width: "400px" }}>{t('common.Conversion')}</th>
                {/* <th>{t('installation.Machine factor')}</th> */}
                <th style={{ width: "400px" }} />
              </tr>
            </thead>
          )}
          <tbody>
            {newPhase.options.map((option, i) => (
              <OptionsRow
                option={option}
                key={i}
                onChange={(c) => {
                  if (c === 'remove') {
                    handleRemoveOption(i);
                  } else {
                    newPhase.options[i] = c;
                    setNewPhase({ ...newPhase });
                  }
                }}
                projectOrg={props.projectOrg}
                organizationId={organizationId}
              />
            ))}
          </tbody>
          {phaseError && (
            <tr>
              <td colSpan="11">
                <Alert variant="danger">
                  {t('installation.Phase Name and atleast one option are mandatory fields for an Installation Phase. Please provide the necessary fields')}
                </Alert>
              </td>
            </tr>
          )
          }
          {sqlError &&
            (
              <tr>
                <td colSpan="11">
                  <Alert variant='danger'>{t('installation.Unable to save the updated installation phase as the deleted option was used in a project')}</Alert>
                </td>
              </tr>
            )
          }
          {optionError &&
            (
              <tr>
                <td colSpan="11">
                  <Alert variant='danger'>{t('installation.Option Name, Machine and Conversions are mandatory fields for an Option. Please provide the necessary fields')}</Alert>
                </td>
              </tr>
            )
          }
          <tfoot>
            <tr>
              <td colSpan="6">
                <Button onClick={() => { handleAddOption(); }}>
                  <FaPlus />
                  {' '}
                  {t('installation.Add option')}
                </Button>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Form>
  );
});
