import React, {
  useState, useEffect,
  useCallback,
} from 'react';
import {
  MapContainer, TileLayer, FeatureGroup,
  Polygon,
  WMSTileLayer,
  Popup,
  Rectangle,
  ZoomControl,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { EditControl } from 'react-leaflet-draw';
import { TbPolygon } from 'react-icons/tb';
import { GiFallingRocks } from 'react-icons/gi';
import { BsTreeFill } from 'react-icons/bs';
import loglevel from '../../services/loglevel';
import { humanize } from './helpers';
import './CarbonStockMap.scss';

function MapFeature({ feature }) {
  const { t } = useTranslation();
  if (feature.type === 'polygon') {
    return (
      <Polygon id={feature.id} positions={feature.coordinates.map((c) => [c.y, c.x])}>
        <Popup>
          <div><strong>{feature.name}</strong></div>
          {feature.isLoading ? (
            <Spinner animation="border" variant="primary" role="status" className="mt-2">
              <span className="sr-only">{`${t('common.Loading')}...`}</span>
            </Spinner>
          ) : (
            <>
              <div className="mb-2">{feature.group}</div>
              <div>
                <TbPolygon className="text-primary" />
                {feature.area < 1 ? ` ${humanize.amount_long(feature.area, 2)} ha` : ` ${humanize.amount_long(feature.area, 0)} ha`}
              </div>
              <div>
                <GiFallingRocks className="text-secondary" />
                {` ${humanize.amount_long(feature.carbonFluxSoil, 0)} tCO₂/ha`}
              </div>
              <div>
                <BsTreeFill className="text-success" />
                {` ${humanize.amount_long(feature.carbonFluxVegetation, 0)} tCO₂/ha`}
              </div>
            </>
          )}
        </Popup>
      </Polygon>
    );
  }

  if (feature.type === 'rectangle') {
    return (
      <Rectangle
        color={feature.color}
        id={feature.id}
        bounds={[
          [feature.coordinates[0].y, feature.coordinates[0].x],
          [feature.coordinates[1].y, feature.coordinates[1].x]]}
      >
        <Popup>
          {feature.name}
        </Popup>
      </Rectangle>
    );
  }
}

const CarbonStockMap = ({
  position, editable, noControls = false, features, onChange, debugFeatures, legendMode,
}) => {
  const { t } = useTranslation();
  const [map, setMap] = useState(null);
  const [localPosition, setLocalPosition] = useState(position);

  const handleDraw = useCallback((e) => {
    loglevel.info(e);

    const getCoordinates = (latlngs) => latlngs.map((ll) => ({
      x: ll.lng,
      y: ll.lat,
    }));

    const { type } = e;
    loglevel.info(`type: ${type}`);
    if (type === 'draw:created') {
      const { layer } = e;
      loglevel.info(layer);
      const layerId = layer._leaflet_id;
      const { id } = layer.options;
      const coordinates = getCoordinates(layer._latlngs[0]);

      // Remove layer by default
      map.removeLayer(layer);
      if (typeof onChange === 'function') {
        onChange({
          action: 'add',
          id,
          coordinates,
        });
      }
      loglevel.info(layerId, coordinates);
    } else if (type === 'draw:edited') {
      const { layers } = e;
      layers.getLayers().forEach((layer) => {
        loglevel.info(layer);
        const { id } = layer.options;
        const coordinates = getCoordinates(layer._latlngs[0]);
        // Remove layer by default
        map.removeLayer(layer);
        onChange({
          action: 'edit',
          id,
          coordinates,
        });
      });
    } else if (type === 'draw:deleted') {
      const { layers } = e;
      layers.getLayers().forEach((layer) => {
        loglevel.info(layer);
        const { id } = layer.options;

        onChange({
          action: 'remove',
          id,
        });
      });
    } else {
      // NOTHING
    }
  }, [map]);
  useEffect(() => {
    if (position !== undefined) {
      if ((position.lat === 0 && position.lng === 0)
        || (position.lat === null && position.lng === null)
      ) {
        setLocalPosition({
          lng: 24.945831,
          lat: 60.192059,
        });
      } else {
        setLocalPosition(position);
        if (map !== undefined && map !== null) {
          map.flyTo(position);
        }
      }
    }
  }, [position]);

  useEffect(() => {
    if (map !== null) {
      map.invalidateSize();
    }
  }, [features]);

  // Translations for Leaflet Draw
  L.drawLocal = {
    draw: {
      toolbar: {
        actions: {
          title: t('leaflet.Cancel drawing'),
          text: t('leaflet.Cancel'),
        },
        finish: {
          title: t('leaflet.Finish drawing'),
          text: t('leaflet.Finish'),
        },
        undo: {
          title: t('leaflet.Delete last point drawn'),
          text: t('leaflet.Delete last point'),
        },
        buttons: {
          polyline: t('leaflet.Draw a polyline'),
          polygon: t('leaflet.Draw a polygon'),
          rectangle: t('leaflet.Draw a rectangle'),
          circle: t('leaflet.Draw a circle'),
          marker: t('leaflet.Draw a marker'),
          circlemarker: t('leaflet.Draw a circlemarker'),
        },
      },
      handlers: {
        circle: {
          tooltip: {
            start: t('leaflet.Click and drag to draw circle'),
          },
          radius: t('leaflet.Radius'),
        },
        circlemarker: {
          tooltip: {
            start: t('leaflet.Click map to place circle marker'),
          },
        },
        marker: {
          tooltip: {
            start: t('leaflet.Click map to place marker'),
          },
        },
        polygon: {
          tooltip: {
            start: t('leaflet.Click to start drawing shape'),
            cont: t('leaflet.Click to continue drawing shape'),
            end: t('leaflet.Click first point to close this shape'),
          },
        },
        polyline: {
          error: `<strong>${t('leaflet.Error')}:</strong> ${t('leaflet.shape edges cannot cross!')}`,
          tooltip: {
            start: t('leaflet.Click to start drawing line'),
            cont: t('leaflet.Click to continue drawing line'),
            end: t('leaflet.Click last point to finish line'),
          },
        },
        rectangle: {
          tooltip: {
            start: t('leaflet.Click and drag to draw rectangle'),
          },
        },
        simpleshape: {
          tooltip: {
            end: t('leaflet.Release mouse to finish drawing'),
          },
        },
      },
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: t('leaflet.Save changes'),
            text: t('leaflet.Save'),
          },
          cancel: {
            title: t('leaflet.Cancel editing discards all changes'),
            text: t('leaflet.Cancel'),
          },
          clearAll: {
            title: t('leaflet.Clear all layers'),
            text: t('leaflet.Clear All'),
          },
        },
        buttons: {
          edit: t('leaflet.Edit layers'),
          editDisabled: t('leaflet.No layers to edit'),
          remove: t('leaflet.Delete layers'),
          removeDisabled: t('leaflet.No layers to delete'),
        },
      },
      handlers: {
        edit: {
          tooltip: {
            text: t('leaflet.Drag handles or markers to edit features'),
            subtext: t('leaflet.Click cancel to undo changes'),
          },
        },
        remove: {
          tooltip: {
            text: t('leaflet.Click on a feature to remove'),
          },
        },
      },
    },
  };

  return (
    <>
      {localPosition !== undefined
        && (
        <MapContainer
          zoomControl={false}
          dragging={!noControls}
          center={localPosition}
          zoom={13}
          maxZoom={18}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%' }}
          ref={setMap}
        >
          <ZoomControl position="topright" />
          {map !== null && (
            <>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                className="grayscale-map"
              />
              <WMSTileLayer
                url="https://paikkatieto.ymparisto.fi/arcgis/services/INSPIRE/SYKE_Maanpeite/MapServer/WMSServer"
                layers="LC.LandCoverRaster.2018"
                opacity={legendMode ? 0.6 : 0.2}
                maxNativeZoom={16}
                maxZoom={18}
              />

              <FeatureGroup>
                {editable && (
                  <EditControl
                    position="topright"
                    onEdited={handleDraw}
                    onCreated={handleDraw}
                    onDeleted={handleDraw}
                    draw={{
                      polyline: false,
                      rectangle: false,
                      circle: false,
                      marker: false,
                      circlemarker: false,
                    }}
                  />
                )}
                {features.map((f) => <MapFeature key={f.timestamp} feature={f} />)}
              </FeatureGroup>
              {debugFeatures.map((f) => <MapFeature key={f.timestamp} feature={f} />)}
            </>
          )}
        </MapContainer>
        )}
    </>
  );
};
export default CarbonStockMap;
