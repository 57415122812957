import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './App.scss';
import {
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown
} from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';
import { HiOutlineUserCircle, HiOutlineGlobeAlt } from 'react-icons/hi';
import { FiHelpCircle } from 'react-icons/fi';
import Home from './views/home/Home';
import Project from './views/project/Project';
import Database from './views/database/Database';
import Me from './views/home/Me';
import { SettingsStore, withSettingsStore } from './views/common/settings-context';
import { FaUserCircle, FaHome } from 'react-icons/fa';
import { BsInfoCircle } from "react-icons/bs";

const currentYear = new Date().getFullYear();

const AppBody = withSettingsStore(({
  isInRole, isLoggedIn, logout, user: currentUser, currentOrganization,
}) => {
  const { t, i18n } = useTranslation();
  const userGuideURL = process.env.REACT_APP_USER_GUIDE_URL;
  const appAdminURL = process.env.REACT_APP_ADMIN_URL;
  const feedbackForm = process.env.REACT_APP_FEEDBACK_FORM;
  const [selectedKey, setSelectedKey] = useState(null);
  const handleItemClick = (key)=> {
    setSelectedKey(key);
  }
  const handleDropdownToggle = (isOpen) =>{
    if(!isOpen){
      setSelectedKey(null);
    }
  }
  return (
    <div id="app-container">
      {isLoggedIn ?
        (
          <>
            <Navbar bg="primary" variant="dark">
              <Navbar.Brand>
                <NavLink to="/" className="nav-link--brand" exact>
                  ZEROinfra
                  <span>by Ramboll</span>
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  {currentUser.name !== 'Public Access User' && (
                  <LinkContainer to="/home">
                    <Nav.Link>{t('project.Projects')}</Nav.Link>
                  </LinkContainer>
                  )}
                  {currentUser.name !== 'Public Access User' && (
                    <LinkContainer to="/database">
                      <Nav.Link>{t('database.Database')}</Nav.Link>
                    </LinkContainer>
                  )}
                </Nav>

                {/* {currentOrganization !== undefined && currentOrganization.defaultLocale.includes('fi') && (
                <Nav>
                  <Nav.Link href="/ohjeistus.pdf" target="_blank">
                    <FiHelpCircle size={24} />
                  </Nav.Link>
                </Nav>
              )} */}
              
                <Nav>
                  {currentUser.name !== 'Public Access User' && (
                    <NavDropdown title={<BsInfoCircle size={21}/>} id="nav-dropdown" onToggle={handleDropdownToggle} className="navbar-nav open-left">
                      <NavDropdown.Item active={selectedKey==='user_guide'} onClick={()=>handleItemClick('user_guide')} href={userGuideURL} target="_blank">{t('info.User Guide')}</NavDropdown.Item>
                      <NavDropdown.Item active={selectedKey==='feedback'} onClick={()=>handleItemClick('feedback')} href={feedbackForm} target="_blank">{t('info.Feedback')}</NavDropdown.Item>
                      <NavDropdown.Item active={selectedKey==='app_admin'} onClick={()=>handleItemClick('app_admin')} href={appAdminURL} target="_blank">{t('info.Application Admins')}</NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
                <Nav>
                  {currentUser.name !== 'Public Access User' && (
                    <LinkContainer to="/home">
                      <Nav.Link><FaHome size={24} /></Nav.Link>
                    </LinkContainer>
                  )}
                </Nav>
                <Nav>
                  <NavDropdown className="navbar-nav open-left" title={<strong>{i18n.language}</strong>}>
                    <NavDropdown.Item onSelect={() => {
                      window.localStorage.setItem('language', 'fi');
                      i18n.changeLanguage('fi');
                      }}>
                      {t('language.Finnish')}
                    </NavDropdown.Item>
                    <NavDropdown.Item onSelect={() => {
                      window.localStorage.setItem('language', 'en');
                      i18n.changeLanguage('en');
                      }}>
                      {t('language.English')}
                    </NavDropdown.Item>
                    <NavDropdown.Item onSelect={() => {
                      window.localStorage.setItem('language', 'da');
                      i18n.changeLanguage('da');
                      }}>
                      {t('language.Danish')}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Nav>
                  {currentUser !== undefined && (
                    <NavDropdown
                      className="navbar-nav open-left"
                      title={(
                        <>
                          <HiOutlineUserCircle size={26} className="mr-1" />
                          {`${currentUser.name}`}
                        </>
                      )}
                    >
                      {currentUser.name !== 'Public Access User' && (
                        <LinkContainer to="/me">
                          <NavDropdown.Item>
                            {t('user.Account information')}
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}
                      <NavDropdown.Item onSelect={() => logout()}>
                        {t('user.Logout')}
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <section id="content" className="content">
              <Switch>
                <Route exact path={['/', '/home']} component={Home} props={currentUser} />
                <Route path="/me" component={Me} />
                <Route path="/project/:id" component={Project} />
                <Route path="/database/" component={Database} />
                <Route path="/about">
                  <h1>About</h1>
                </Route>
              </Switch>
            </section>
            <footer id="footer" className="page-footer font-small bg-primary text-white">
              <div className="footer-copyright text-center py-3">
                &copy;
                {currentYear}
                {' '}
                <a href="https://ramboll.fi/" target='_blank' className="text-white">Ramboll Ltd</a>
              </div>
            </footer>
          </>
        ) : (
          <div className="fullscreen-centered">
            {/* <Spinner animation="border" variant="primary" /> */}
            <img src="/loading_center.gif" alt='loading-gif' />
          </div>
        )}
    </div >
  )
});

const App = () => (
  <SettingsStore>
    <AppBody />
  </SettingsStore>
);

export default App;
