import loglevel from './loglevel';

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class TokenManager {
  constructor(api, setToken) {
    this.api = api;
    this.setToken = setToken;
    this.status = null;
  }

  storeToken({ token, expiration }) {
    //loglevel.info('Storing token', token, expiration);

    // Save the token to local storage
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('tokenExpires', expiration);

    // Save the token
    if (typeof this.setToken === 'function') {
      this.setToken(token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async removeToken() {
    loglevel.info('Removing token');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('tokenExpires');
    try {
      await this.api.accounts.logout();
    } catch {
      // continue. the user was already logged-out
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getToken() {
    const token = window.localStorage.getItem('token');
    const tokenExpires = window.localStorage.getItem('tokenExpires');

    return { token, tokenExpires };
  }

  start(onError) {
    loglevel.debug('Started token refresh loop');
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await this.refreshApiTokenLoop(resolve);
      } catch (e) {
        onError(e);
        reject(e);
      }
    });
  }

  async refreshApiTokenLoop(resolve) {
    // Refresh token
    try {
      loglevel.info('Refreshing api token...');
      await this.refreshApiToken();
      // Prepare the next update
      const currentDate = new Date();
      const { tokenExpires } = this.getToken();
      const expireDate = new Date(tokenExpires);
      let expiresIn = expireDate.getTime() - currentDate.getTime();
      if (expiresIn <= 10000 || Number.isNaN(expiresIn)) {
        expiresIn = 20000;
      }
      loglevel.info(`Refreshing in ${expiresIn - 10000} milliseconds`);
      await delay(expiresIn - 10000);
      // Go to next iteration
      await this.refreshApiTokenLoop(resolve);
    } catch (error) {
      if (error) {
        throw new Error('Token does not exist');
      } else {
        // this.removeToken()
      }
    }
  }

  async refreshApiToken() {
    const { tokenExpires } = this.getToken();

    // We had a failure and the token is no longer valid. Time to refresh
    if (tokenExpires === null || new Date(tokenExpires) <= new Date(new Date().getTime() + 10000)) {
      // Remove the interceptor to prevent a loop in case token refresh also causes the 401
      // Make sure refresh tokens exist and there are no existing queries trying to ret
      loglevel.info('refreshing');
      if (this.status === null) {
        // We are now attempting to refresh the token
        this.status = 'running';
        try {
          loglevel.info('login');
          const response = await this.api.accounts.refresh();
          if (response !== undefined && response !== null) {
            // Request is done and is successful
            this.storeToken(response);
          }
          this.status = null;
          return response;
        } catch (error) {
          this.status = null;
          throw error;
        }
      }
    }
    return null;
  }
}
export default TokenManager;
