import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { FiHelpCircle } from 'react-icons/fi';
import { withSettingsStore } from '../common/settings-context';
import Error from '../common/Error';
import './Database.scss'

export default withSettingsStore(({ vehicle, onChange, emissionUnits, handleEmissionUnit, selectedEmissionUnit, getUnitByCode, organizationId, errors }) => {
  const { t } = useTranslation();
  const [dropDownValue, setDropDownValue] = useState('Id_tkm');
  //const [dropDownValue, setDropDownValue] = useState(`select`edEmissionUnit ? `Id_${selectedEmissionUnit}` : 'Id_tkm');
  const [dropDownButtonValue, setDropDownButtonValue] = useState(
    selectedEmissionUnit
      ? `${t('common.kgco2eq')} / ${t(selectedEmissionUnit)}`
      : `${t('common.kgco2eq')} / tkm`
  );

  useEffect(() => {
    handleEmissionUnit();
  }, []);

  useEffect(() => {
    handleSelect(dropDownValue);
  }, []);

  const getDynamicUnitId = (selectedId) => {
    const selectedUnit = emissionUnits.find((unit) => `Id_${unit.symbol}` === selectedId);
    if (selectedUnit) {
      const id = selectedUnit.id;
      console.log('id:', id);
      return id;
    }
    return null;
  };

  const getDynamicEmissionId = () => {
    const selectedUnit = emissionUnits.find((unit) => unit.symbol.startsWith('kgCO'));
    if (selectedUnit) {
      const id = selectedUnit.id;
      console.log('EmissionId:', id);
      return id;
    }
    return null;
  };

  useEffect(() => {
    const initialDropDownValue = selectedEmissionUnit ? `Id_${selectedEmissionUnit}` : 'Id_tkm';
    setDropDownValue(initialDropDownValue);

    const initialDropDownButtonValue = 'Id_tkm'

    setDropDownButtonValue(initialDropDownButtonValue);
  }, [selectedEmissionUnit, t]);


  useEffect(() => {
    const dynamicEmissionId = getDynamicEmissionId();
    const dynamicUnitId = getDynamicUnitId(dropDownValue);
    setNewVehicle((prevVehicle) => ({
      ...prevVehicle,
      emissionUnitId: dynamicEmissionId || 0,
      unitId: dynamicUnitId || 0,
    }));
  }, [emissionUnits, dropDownValue]);


  const [newVehicle, setNewVehicle] = useState({
    id: 0,
    name: '',
    description: '',
    classification: '',
    emissionRoad: 0,
    emissionRoadFull: 0,
    emissionStreet: 0,
    emissionStreetFull: 0,
    load: 0, // not used
    totalMass: 0,
    vehicleMass: 0,
    deadWeight: 0,
    emissionAverage: 0,
    emissionUnitId: 0,
    unitId: 0,
    ...vehicle === undefined ? {} : vehicle,
  });

  useEffect(() => {
    onChange(newVehicle);
  }, [newVehicle, onChange]);

  useEffect(() => {
    if (newVehicle.id !== vehicle.id) {
      setNewVehicle(vehicle);
    }
  }, [vehicle, newVehicle.id]);

  /* const handleChange = (element) => (event) => {
     const { name, value } = event.currentTarget;
     newVehicle[name] = value;
     setNewVehicle(newVehicle);
   };*/
  const handleChange = (event, element) => {
    const { name, value } = event.currentTarget;
      element[name] = value;
      onChange(element);
  };

  const TextField = ({ element, field, placeholder, checkNumber, formValidation }) => {
    return (
      <Form.Control
        type={`${checkNumber ? "number" : "text"}`}
        defaultValue={element[field] === 0 && field === "expirationYear" ? " " : element[field]}
        name={field} onChange={(event) => handleChange(event, element)}
        placeholder={placeholder}
        className={`database-forms-placeholder ${formValidation}`}
      />
    );
  };

  const handleSelect = (selectedId) => {
    setDropDownValue(selectedId);
    const selectedUnit = emissionUnits.find((unit) => `Id_${unit.symbol}` === selectedId);

    if (selectedUnit && (selectedUnit.symbol === 'tkm' || selectedUnit.symbol === 'km')) {
      const existingEmissionAverage = newVehicle.emissionAverage || 0;

      setNewVehicle((prevVehicle) => ({
        ...prevVehicle,
        emissionUnitId: getDynamicEmissionId(),
        unitId: getDynamicUnitId(selectedId),
        emissionAverage: existingEmissionAverage,
      }));

      setDropDownButtonValue(`${t('common.kgco2eq')} / ${t(selectedUnit.symbol)}`);
    } else {
      setDropDownButtonValue(selectedUnit ? `${t('common.kgco2eq')} / ${t(selectedUnit.symbol)}` : '');
    }
  };

  const tooltipDeadWeight = (
    <Tooltip id="tooltip-public">
      {t('database.Deadweight tooltip'
      )}
    </Tooltip>
  );

  return (
    <Form>
      <div>
        <h4>
          {t('database.Vehicle')}
          {' '}
        </h4>


        {dropDownValue === 'Id_km' && (
          <>
            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    * {t('common.Name')}
                    {errors?.includes('name') && <span className="error-text" > {t('project.is required')}</span>}
                  </Form.Label>
                  <TextField element={newVehicle} 
                  ield="name" 
                  placeholder={t('vehicleForm.eg Name')} 
                  formValidation={errors?.includes('name') ? 'error-glow' : ''}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('common.Description')}
                  </Form.Label>
                  <TextField element={newVehicle} field="description" placeholder={t('vehicleForm.eg Description')}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Classification')}
                  </Form.Label>
                  <TextField element={newVehicle} field="classification" placeholder={t('vehicleForm.eg Classification')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Module')}
                  </Form.Label>
                  <TextField element={newVehicle} field="module" placeholder={t('vehicleForm.eg Module')} />
                </Form.Group>
              </Col>
            </Row>

            {/* Conditional Error Display for Name field */}
            {/* {errors.includes('name') && (
              <Row>
                  <Col xs="3"> <Error text={`${t("common.Name")} ${t("project.is required")}`} /> </Col>
              </Row>
            )} */}

            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Total mass')}
                  </Form.Label>
                  <TextField element={newVehicle} field="totalMass" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Vehicle mass')}
                  </Form.Label>
                  <TextField element={newVehicle} field="vehicleMass" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Deadweight')}
                    <OverlayTrigger
                      trigger={("hover")}
                      placement="right"
                      overlay={tooltipDeadWeight}>
                      <FiHelpCircle style={{ marginLeft: '5px' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <TextField element={newVehicle} field="deadWeight" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Expiration Year')}
                  </Form.Label>
                  <TextField element={newVehicle} field="expirationYear" placeholder={t('vehicleForm.eg Expiration Year')} checkNumber={true}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    * {t('database.Source')}
                    {errors?.includes('source') && <span className="error-text"> {t('project.is required')}</span>}
                  </Form.Label>
                  <TextField 
                  element={newVehicle} 
                  field="source" 
                  placeholder={t('vehicleForm.eg Source')}
                  formValidation={errors?.includes('source') ? 'error-glow' : ''} 
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    {/* {t('database.Emissions (road)')}, */}
                    {t('database.Emission - Roads empty')}
                    {' '}
                    (
                    <small style={{ textTransform: 'none' }}>
                      {/* {getUnitByCode('kgco2eq', organizationId)[0].symbol}/{getUnitByCode('kilometer', organizationId)[0].symbol} */}
                      {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>
                    </small>
                    )
                  </Form.Label>
                  <TextField element={newVehicle} field="emissionRoad" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {/* {t('database.Emissions (road) full')}, */}
                    {t('database.Emission - Roads full')}
                    {' '}
                    (
                    <small style={{ textTransform: 'none' }}>
                      {/* {getUnitByCode('kgco2eq', organizationId)[0].symbol}/{getUnitByCode('kilometer', organizationId)[0].symbol} */}
                      {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>
                    </small>
                    )
                  </Form.Label>
                  <TextField element={newVehicle} field="emissionRoadFull" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {/* {t('database.Emissions (street)')}, */}
                    {t('database.Emission - Streets empty')}
                    {' '}
                    (
                    <small style={{ textTransform: 'none' }}>
                      {/* {getUnitByCode('kgco2eq', organizationId)[0].symbol}/{getUnitByCode('kilometer', organizationId)[0].symbol} */}
                      {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>
                    </small>
                    )
                  </Form.Label>
                  <TextField element={newVehicle} field="emissionStreet" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {/* {t('database.Emissions (street) full')}, */}
                    {t('database.Emission - Streets full')}
                    {' '}
                    (
                    <small style={{ textTransform: 'none' }}>
                      {/* {getUnitByCode('kgco2eq', organizationId)[0].symbol}/{getUnitByCode('kilometer', organizationId)[0].symbol} */}
                      {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>
                    </small>
                    )
                  </Form.Label>
                  <TextField element={newVehicle} field="emissionStreetFull" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('common.Unit')}
                  </Form.Label>
                  <Form.Control as="select" value={dropDownValue} onChange={(e) => handleSelect(e.target.value)}>
                    {emissionUnits
                      .filter(unit => unit.symbol === 'km' || unit.symbol === 'tkm')
                      .map(unit => (
                        <option key={`Id_${unit.symbol}`} value={`Id_${unit.symbol}`}>
                          {t(unit.symbol)}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}

        {dropDownValue === 'Id_tkm' && (
          <>
            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    * {t('common.Name')}
                    {errors?.includes('name') && <span className="error-text" > {t('project.is required')}</span>}
                  </Form.Label>
                  <TextField 
                  element={newVehicle} 
                  field="name" 
                  placeholder={t('vehicleForm.eg Name')} 
                  formValidation={errors?.includes('name') ? 'error-glow' : ''}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('common.Description')}
                  </Form.Label>
                  <TextField element={newVehicle} field="description" placeholder={t('vehicleForm.eg Description')}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Classification')}
                  </Form.Label>
                  <TextField element={newVehicle} field="classification" placeholder={t('vehicleForm.eg Classification')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Module')}
                  </Form.Label>
                  <TextField element={newVehicle} field="module" placeholder={t('vehicleForm.eg Module')}/>
                </Form.Group>
              </Col>
            </Row>

            {/* Conditional Error Display for Name field */}
            {/* {errors.includes('name') && (
              <Row>
                  <Col xs="3"> <Error text={`${t("common.Name")} ${t("project.is required")}`} /> </Col>
              </Row>
            )} */}

            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Total mass')}
                  </Form.Label>
                  <TextField element={newVehicle} field="totalMass" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Vehicle mass')}
                  </Form.Label>
                  <TextField element={newVehicle} field="vehicleMass" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Deadweight')}
                    <OverlayTrigger
                      trigger={("hover")}
                      placement="right"
                      overlay={tooltipDeadWeight}>
                      <FiHelpCircle style={{ marginLeft: '5px' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <TextField element={newVehicle} field="deadWeight" checkNumber={true} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('database.Expiration Year')}
                  </Form.Label>
                  <TextField element={newVehicle} field="expirationYear" placeholder={t('vehicleForm.eg Expiration Year')} checkNumber={true}/>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    * {t('database.Source')}
                    {errors?.includes('source') && <span className="error-text"> {t('project.is required')}</span>}
                  </Form.Label>
                  <TextField element={newVehicle} 
                  field="source" 
                  placeholder={t('vehicleForm.eg Source')}
                  formValidation={errors?.includes('source') ? 'error-glow' : ''} 
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('common.Emission factor')}{' '}
                    <small style={{ textTransform: 'none' }}>
                      ({getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>)
                    </small>
                  </Form.Label>
                  <TextField element={newVehicle} field="emissionAverage" checkNumber={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {t('common.Unit')}
                  </Form.Label>
                  <Form.Control as="select" value={dropDownValue} onChange={(e) => handleSelect(e.target.value)}>
                    {emissionUnits
                      .filter(unit => unit.symbol === 'km' || unit.symbol === 'tkm')
                      .map(unit => (
                        <option key={`Id_${unit.symbol}`} value={`Id_${unit.symbol}`}>
                          {t(unit.symbol)}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {/* Conditional Error Display for Source field */}
            {/* {errors.includes('source') && (
              <Row>
                <Col xs="3"></Col>
                <Col xs="3"> <Error text={`${t("common.Enter source")}`} /> </Col>
              </Row>
            )} */}
          </>
        )}
      </div>
    </Form >
  );
});
