import React, {
  useState, Fragment, useMemo, useEffect, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Button,
  Modal,
  ButtonGroup,
  FormControl,
  OverlayTrigger,
  Form,
  Alert,
  Container,
  Row,
  Popover,
} from 'react-bootstrap';
import {
  FaTrash,
  FaInfoCircle,
  FaPlus,
  FaCalculator,
  FaChevronRight,
  FaChevronDown,
  FaLevelDownAlt,
  FaCopy,
  FaFile,
} from 'react-icons/fa';
import Api from '../../../services/api';
import ElementForm from './ElementForm';
import ElementRow from './ElementRow';

import './Scenarios.scss';
import { withSettingsStore } from '../../common/settings-context';
import { SettingsContext } from '../../common/settings-context';
import ProductForm from '../../CalculationsForms/ProductForm';
import TransportationForm from '../../CalculationsForms/TransportationForm';
import InstallationForm from '../../CalculationsForms/InstallationForm';
import { defaultHandleChange, clone } from '../../common/helpers';
import { emptyElement } from '../../common/initializers';
import { DropDown, StatusButton } from '../../common/components';

import ExplainModal from './ExplainModal';
import GenericForm from '../../CalculationsForms/GenericForm';
import useStore from '../../../state/store';

const ElementTable = withSettingsStore((props) => {
  const { displayError, scenarioId, project, organizationId } = props;
  const { getUnitByCode } = useContext(SettingsContext);
  // Translation helper
  const { t } = useTranslation();

  // List of available emissions and factors
  const emissionUnits = [
    { id: 0, name: getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol, factor: 1 },
    { id: 1, name: getUnitByCode('tco2eq', organizationId)?.[0]?.symbol, factor: 0.001 },
  ];

  // scenarioId is used to fetch certain scenario
  // scenario is the whole scenario object
  // elements are elements attached to this scenario
  const [elements, setElements] = useState([
    ...(props.elements === undefined ? [] : props.elements),
  ]);
  console.log(props.elements);
  const [editedElement, setEditedElement] = useState(
    emptyElement(),
  );

  // const [checkedGroups, setCheckedGroups] = useState({})

  const [showNewElementDialog, setShowNewElementDialog] = useState(false);
  const [showProductCalcDialog, setShowProductCalcDialog] = useState(false);
  const [showInstallationCalcDialog, setShowInstallationCalcDialog] = useState(false);
  const [showGenericCalcDialog, setShowGenericCalcDialog] = useState(false);
  const [showTransportationCalcDialog, setShowTransportationCalcDialog] = useState(false);
  const [showExplainDialog, setShowExplainDialog] = useState(false);
  const [explain, setExplain] = useState([]);
  const [groupId, setGroupId] = useState(0);
  const [newElement, setNewElement] = useState({});
  const [existingTemplates, setExistingTemplates] = useState([]);
  const [editRowGroupId, setEditRowGroupId] = useState(0);
  const [selectedEmissionUnit, setSelectedEmissionUnit] = useState(emissionUnits[0]);
  const [showDeleteElementDialog, setShowDeleteElementDialog] = useState(false);
  const [typeToggle, setTypeToggle] = useState('All')
  const [showDeleteGroupDialog, setShowDeleteGroupDialog] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState([]);
  const [error, setError] = useState(null);
  const [productError, setProductError] = useState(false);
  const [dropDownError, setDropDownError] = useState(null);

  const formType = useStore((state) => state.genericFormType);

  const filterEditedElements = (object, type) => {
    const filteredArray = object.genericItems.filter(function (element) {
      return element.type == type;
    });

    object.genericItems = filteredArray

    return object;
  }
  // console.log(props.project);
  // console.log(project)
  const projectGroups = useMemo(() => {
    // update project groups
    if (Array.isArray(props.project.groups)) {
      return props.project.groups.filter((g) => g.parentId === null).map((g) => {
        g.children = props.project.groups.filter((pg) => pg.parentId === g.id).map((pg) => {
          // Set parent name for listings
          pg.parentName = g.name;
          return pg;
        });
        return g;
      });
    }
    return [];
  }, [props.project]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.currentTarget.blur();
      setEditRowGroupId(0);
    }
  };

  console.log(elements);
  // check and handle changes
  const onChange = function (action) {
    if (typeof props.onChange === 'function') {
      props.onChange(elements);
    }
  };

  const handleShowElementDialog = async (groupId, element) => {
    if (element !== undefined) {
      setNewElement({ ...clone(element), groupId });
    } else {
      setNewElement({ ...emptyElement(), groupId });
    }
    setShowNewElementDialog(true);
    onChange();
  };

  // Handlers for clicks
  const handleRemoveElement = async (elementId) => {
    await Api().scenarios(scenarioId).elements(elementId).delete(elementId);
    setShowDeleteElementDialog(false);
    getElements();
  };

  const saveNewElement = async () => {
    try {
      if (newElement.id > 0) {
        await Api().scenarios(scenarioId).elements(newElement.id).update(newElement);
      } else {
        await Api().scenarios(scenarioId).elements().post(newElement);
      }
    } catch {
      displayError(t('element.Unable to save element'));
    }
  };

  const handleAddElement = async () => {
    await saveNewElement();
    setShowNewElementDialog(false);
    getElements();
  };
  const handleSetAsElementTemplate = async (confirmed) => {
    await saveNewElement();
    if (confirmed === true) {
      await Api().templates().post(newElement);
      setExistingTemplates([]);
      setShowNewElementDialog(false);
    } else {
      const existing = await Api().templates().get({ code: newElement.code, unitId: newElement.unitId });

      const filteredTemplates = existing.filter(template =>
        template.name === newElement.name && template.description === newElement.description
      );
      setExistingTemplates(filteredTemplates);

      if (filteredTemplates.length === 0) {
        handleSetAsElementTemplate(true);
      }
    }
  };

  const handleClose = async () => {
    setExistingTemplates([]);
    setShowTransportationCalcDialog(false);
    setShowProductCalcDialog(false);
    setShowInstallationCalcDialog(false);
    setShowGenericCalcDialog(false);
    setShowNewElementDialog(false);
    const existing = await Api().scenarios(scenarioId).elements(editedElement.id).get({});
    setEditedElement({ ...JSON.parse(JSON.stringify(existing)) });
    toggleError();
  };

  const getGenStatus = (st) => {
    if (st === "A0") {
      return "preConstructionStatus";
    }
    if (st === "B1") {
      return "installedProductsStatus";
    }
    if (st === "B2") {
      return "maintenanceStatus";
    }
    if (st === "B3") {
      return "repairStatus";
    }
    if (st === "B4") {
      return "replacementStatus";
    }
    if (st === "B5") {
      return "refurbishmentStatus";
    }
    if (st === "B6") {
      return "operationalEnergyStatus";
    }
    if (st === "B7") {
      return "operationalWaterStatus";
    }
    if (st === "B8") {
      return "otherOperationsStats";
    }
    if (st === "B9") {
      return "userUtilizationsStatus";
    }
    if (st === "C1") {
      return "deconstructionStatus";
    }
    if (st === "C2") {
      return "transportCStatus";
    }
    if (st === "C3") {
      return "wasteProcessingStatus";
    }
    if (st === "C4") {
      return "disposalStatus";
    }
  }

  const saveElement = async (element) => {
    try {
      return await Api().scenarios(scenarioId).elements(element.id).update(element);
    } catch (error) {
      let responseData = error.response.data;
      if (responseData.includes("machine")) setError(true);
      if(responseData.includes("Material")) setProductError(true);
      if(responseData.includes("Vehicle")) setDropDownError(true);
    }
  }

  let saveCalculations = async ({
    closeDialog, preConstructionStatus, productStatus, transportStatus,
    installationStatus, genericStatus, installedProductsStatus, maintenanceStatus,
    repairStatus, replacementStatus, refurbishmentStatus, operationalEnergyStatus,
    operationalWaterStatus, otherOperationsStats, userUtilizationsStatus, deconstructionStatus,
    transportCStatus, wasteProcessingStatus, disposalStatus, st, s, formType
  }) => {
    closeDialog = !!closeDialog;
    productStatus = productStatus === undefined ? editedElement.productStatus : productStatus;
    transportStatus = transportStatus === undefined ? editedElement.transportStatus : transportStatus;
    installationStatus = installationStatus === undefined ? editedElement.installationStatus : installationStatus;

    if (st === "preConstructionStatus") {
      preConstructionStatus = s
    }
    if (st === "installedProductsStatus") {
      installedProductsStatus = s
    }
    if (st === "maintenanceStatus") {
      maintenanceStatus = s
    }
    if (st === "repairStatus") {
      repairStatus = s
    }
    if (st === "replacementStatus") {
      replacementStatus = s
    }
    if (st === "refurbishmentStatus") {
      refurbishmentStatus = s
    }
    if (st === "operationalEnergyStatus") {
      operationalEnergyStatus = s
    }
    if (st === "operationalWaterStatus") {
      operationalWaterStatus = s
    }
    if (st === "otherOperationsStats") {
      otherOperationsStats = s
    }
    if (st === "userUtilizationsStatus") {
      userUtilizationsStatus = s
    }
    if (st === "deconstructionStatus") {
      deconstructionStatus = s
    }
    if (st === "transportCStatus") {
      transportCStatus = s
    }
    if (st === "wasteProcessingStatus") {
      wasteProcessingStatus = s
    }
    if (st === "disposalStatus") {
      disposalStatus = s
    }

    preConstructionStatus = preConstructionStatus === undefined ? editedElement.preConstructionStatus : preConstructionStatus;
    installedProductsStatus = installedProductsStatus === undefined ? editedElement.installedProductsStatus : installedProductsStatus;
    maintenanceStatus = maintenanceStatus === undefined ? editedElement.maintenanceStatus : maintenanceStatus;
    repairStatus = repairStatus === undefined ? editedElement.repairStatus : repairStatus;
    replacementStatus = replacementStatus === undefined ? editedElement.replacementStatus : replacementStatus;
    refurbishmentStatus = refurbishmentStatus === undefined ? editedElement.refurbishmentStatus : refurbishmentStatus;
    operationalEnergyStatus = operationalEnergyStatus === undefined ? editedElement.operationalEnergyStatus : operationalEnergyStatus;
    operationalWaterStatus = operationalWaterStatus === undefined ? editedElement.operationalWaterStatus : operationalWaterStatus;
    otherOperationsStats = otherOperationsStats === undefined ? editedElement.otherOperationsStats : otherOperationsStats;
    userUtilizationsStatus = userUtilizationsStatus === undefined ? editedElement.userUtilizationsStatus : userUtilizationsStatus;
    deconstructionStatus = deconstructionStatus === undefined ? editedElement.deconstructionStatus : deconstructionStatus;
    transportCStatus = transportCStatus === undefined ? editedElement.transportCStatus : transportCStatus;
    wasteProcessingStatus = wasteProcessingStatus === undefined ? editedElement.wasteProcessingStatus : wasteProcessingStatus;
    disposalStatus = disposalStatus === undefined ? editedElement.disposalStatus : disposalStatus;

    genericStatus = genericStatus === undefined ? editedElement.genericStatus : genericStatus;
    genericStatus > 0 ? editedElement.genericStatus = genericStatus : editedElement.genericStatus = 0;
    try {
      const updated = await saveElement({
        ...editedElement, preConstructionStatus, productStatus, transportStatus,
        installationStatus, genericStatus, installedProductsStatus, maintenanceStatus,
        repairStatus, replacementStatus, refurbishmentStatus, operationalEnergyStatus,
        operationalWaterStatus, otherOperationsStats, userUtilizationsStatus, deconstructionStatus,
        transportCStatus, wasteProcessingStatus, disposalStatus, formType
      });

      setEditedElement(JSON.parse(JSON.stringify(updated)));
      if (closeDialog === true) {
        setShowTransportationCalcDialog(false);
        setShowProductCalcDialog(false);
        setShowInstallationCalcDialog(false);
        setShowGenericCalcDialog(false);
        getElements();
        setError(null);
      }
    }catch(error){
      console.log(error);
    }
  };

  function toggleError() {
    if (error) {
      setError(!error);
    }
  }

  const getElements = async () => {
    let newElements = await Api().scenarios(scenarioId).elements().get();
    newElements = newElements.map((e) => {
      e.transports.map((transport) => (
        {
          ...transport,
          transportMaterials: transport.transportMaterials.map((m) => (
            {
              ...m,
              conversionId: getMatchingConversionId(e, m),
            })),
        }));

      e.products.map((p) => ({ ...p, conversionId: getMatchingConversionId(e, p) }));

      return e;
    });
    // setElements(newElements);
    props.onChange(newElements);
  };
  const handleSaveProject = async () => {
    // console.log(props.project)
    const updatedProject = await Api().projects(props.project.id).update(props.project);
    props.setProject(updatedProject);
  };

  const handleRemoveGroup = async (group) => {
    let groupElements = elements.filter((element) => element.groupId === group.id);

    if (groupElements.length > 0) {
      let groupElementIds = groupElements.map(({ id }) => id);
      for (let id of groupElementIds) {
        await handleRemoveElement(id);
      }
    }
    // Update the project groups
    let filteredArray = props.project.groups.filter((g) => Number(g.id) !== Number(group.id));
    props.project.groups = filteredArray;

    setElements(prevElements => prevElements.filter(element => element.groupId !== group.id));
    handleSaveProject();
  };


  const handleCopySelectedElementsToGroup = async (group) => {
    const selectedIds = Object.keys(props.checkedItems).filter((k) => props.checkedItems[k] === true).map((k) => Number(k));
    const updateElements = elements
      .filter((e) => selectedIds.includes(e.id))
      .map((e) => {
        return {
          ...e,
          id: 0,
          transports: e.transports.map((r) => ({ ...r, id: 0 })),
          products: e.products.map((r) => ({ ...r, id: 0 })),
          installations: e.installations.map((r) => ({ ...r, id: 0 })),
          groupId: group.id,
          conversions: e.conversions.map((r) => ({ ...r, id: 0 })),
          totalEmission: e.totalEmission
        };
      });
    const added = await Api().scenarios(scenarioId).elements().post(updateElements);
    setElements((prev) => [...prev, ...added]);
    getElements();
  };
  const handleMoveSelectedElementsToGroup = async (group) => {
    const selectedIds = Object.keys(props.checkedItems).filter((k) => props.checkedItems[k] === true).map((k) => Number(k));
    const updateElements = elements.filter((e) => selectedIds.includes(e.id)).map((e) => ({
      ...e,
      groupId: group.id,
    }));

    const updated = await Api().scenarios(scenarioId).elements().post(updateElements);
    setElements((cur) => {
      updated.forEach((ue) => {
        const idx = cur.findIndex((e) => e.id === ue.id);
        if (idx >= 0) {
          cur.splice(idx, 1, ue);
        }
      });
      return [...cur];
    });
    // getElements()
  };

  /* const resetEditRows = () => {
        setEditRowElementId(0);
        setEditRowGroupId(0)
    } */

  const toggleEditRowGroup = (group) => {
    // do not allow editin on "no group" group
    if (group.id === 0) {
      return;
    }
    if (editRowGroupId === group.id) {
      // setEditRowGroup(null)
    } else {
      // setEditRowElementId(0)
      setEditRowGroupId(group.id);
    }
  };

  const setProjectGroup = (groupId) => (group) => {
    const existing = props.project.groups.find((g) => g.id === groupId);
    if (existing !== undefined) {
      const obj = group();
      Object.keys(obj).forEach((k) => {
        existing[k] = obj[k];
      });
      props.setProject(props.project);
    }
  };
  const getMatchingConversionId = (element, product) => {
    if (element === undefined || element === null || product === undefined || product === null || product.material === undefined) {
      return 0;
    }
    const fromUnitId = element.unitId;
    const toUnitId = getUnitByCode('t', organizationId)?.[0]?.id; // tonnes // product.material.unitId

    const conversion = product.material.conversions.find((c) => Number(c.fromUnitId) === fromUnitId && Number(c.toUnitId) === toUnitId);
    if (conversion !== undefined) {
      return conversion.id;
    }
    return 0;
  };
  let [isValid, setIsValid] = useState(false);

  /* const checkConversions = (products) => {
        return products.map(p => {
            if (p.conversionId === undefined) {
                return true
            }
            if (p.conversionId > 0) {
                return true
            }
            return false
        })
    } */
  const [groupChecked, setGroupChecked] = useState({});

  const onGroupCheckChange = (groupId, checked) => {
    setGroupChecked(prevState => {
      const newState = { ...prevState, [groupId]: checked };
      return newState;
    });
  };

  const checkGroup = (group, state) => {
    const groupElements = elements.filter(e => e.groupId === group.id);

    state = !!state;

    const firstElement = groupElements[0];

    if (firstElement !== undefined) {
      state = !props.checkedItems[firstElement.id];
    }

    // Update the group state
    onGroupCheckChange(group.id, state);

    groupElements.forEach(e => {
      props.onCheckChange({ currentTarget: { checked: state } }, e);
    });
  };

  const [editRowElementId, setEditRowElementId] = useState(0);
  const handleSaveGroup = (group) => {
    setEditRowGroupId(group.id);
    handleSaveProject();
  };
  const validateElement = () => {
    // check edited element
    setIsValid(true);
    /* if (editedElement.name === null || editedElement.name === '') {
            setIsValid(false)
        }

        if (Array.isArray(editedElement.products) && editedElement.products.length > 0) {
            let errors = checkConversions(editedElement.products)
            errors.forEach(e => {
                if (!e) setIsValid(false)
            })
        }

        if (Array.isArray(editedElement.transports) && editedElement.transports.length > 0) {
            const allProducts = editedElement.transports.map(t => t.transportMaterials).reduce((acc, cur) => ([...acc, ...cur]), [])
            const errors = [...checkConversions(allProducts), ...editedElement.transports.map(t => t.name !== '')]
            errors.forEach(e => {
                if (!e) setIsValid(false)
            })
        } */
  };

  /* useEffect(() => {
        console.log(props.checkedItems)
    }, [props.checkedItems]) */

  useEffect(() => {
    setElements(props.elements);
    toggleError();
    return () => {
      setProductError(false);
    }
  }, [props.elements]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.Make sure you have installations set up. This can be done from Databases -> Installation Phases')}
          </Row>
          <Row>
            {t('installation.Select the material to be worked, type of soil to be excavated (optional)')}
          </Row>
          <Row>
            {t('installation.Select the working machine(s)')}
          </Row>
          <Row>
            {t('installation.The unit of the material must be same as the input unit of the machine')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );
  const selectEmissionUnitById = (emissionUnitId) => {
    const emissionUnit = emissionUnits.find((u) => u.id === Number(emissionUnitId));
    if (emissionUnit !== undefined) {
      setSelectedEmissionUnit(emissionUnit);
    }
  };
  const elementSort = (a, b) => {
    if (a.code < b.code) {
      return -1;
    }
    if (a.code > b.code) {
      return 1;
    }
    return 0;
  };

  const GroupRow = ({ p }) => (
    <tr onClick={(e) => { p.disableEdit === false && toggleEditRowGroup(p); e.stopPropagation(); }}>

      {p.parentId === null && p.children.length > 0 && (
        <th colSpan={1}>
          <Button size="sm" variant="outline-primary" onClick={(e) => { setGroupId((show) => ((show === 0 && p.id !== show) ? p.id : 0)); e.stopPropagation(); }}>
            {groupId !== p.id ? <FaChevronRight /> : <FaChevronDown />}
          </Button>
        </th>
      )}

      <th colSpan={2} style={{ minWidth: "70px", maxWidth: "70px" }}></th>
      <th className="pull-left" onClick={(e) => e.stopPropagation()} colSpan={1}>
        <Form.Check
          type="checkbox"
          checked={groupChecked[p.id]}
          onChange={(e) => checkGroup(p, e.currentTarget.checked)}
        />

      </th>
      <th colSpan={1}>
      </th>
      <th colSpan={1} className="">
        {editRowGroupId === p.id && <FormControl onKeyDown={handleKeyDown} className="long-text" defaultValue={p.name} name="name" onChange={(e) => { GroupNameChange(e, setProjectGroup(p.id)); }} onBlur={() => handleSaveGroup(p)} />}
        {editRowGroupId !== p.id && p.name}
      </th>
      <th colSpan="100%" className="text-right" />
    </tr>
  );

  const showExplain = (stage) => {
    const fetchData = async () => {
      const explain = await Api().scenarios(scenarioId).elements(editedElement.id).explain();
      // console.log(explain)   ;
      setExplain(explain.filter((e) => e.stage === stage));
      setShowExplainDialog(true);
    };
    fetchData();
  };

  const GroupNameChange = (event, setter, target) => {
    let { value } = event.target;
    const { name } = event.target;

    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    if (!isNaN(value) && value !== '') {
      value = Math.abs(Number(value)).toString();
    }
    setter((prev) => {
      if (Array.isArray(prev)) {
        prev.find((v) => v === target)[name] = value;
        return [...prev];
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div>
      {showNewElementDialog && (
        <Modal size="lg" show={showNewElementDialog} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>{newElement.amount === 0 ? t('element.Add new element') : t('element.Edit element')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ElementForm elements={newElement} onChange={(e) => { setNewElement(e); }} project={props.project} organizationId={organizationId} />
          </Modal.Body>
          <Modal.Footer>
            {existingTemplates.length > 0 && (
              <div>
                <Alert variant="warning">{t('element.This template already exists')}</Alert>
                <Table>
                  <thead>
                    <tr>
                      <th>{t('common.Code')}</th>
                      <th>{t('common.Name')}</th>
                      <th>{t('common.Description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {existingTemplates.map((tpl) => (
                      <tr key={tpl.id}>
                        <td>{tpl.code}</td>
                        <td>{tpl.name}</td>
                        <td>{tpl.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Alert variant="warning">{t('element.Do you want to add this item to database as a duplicate?')}</Alert>
              </div>
            )}
            <ButtonGroup>
              {existingTemplates.length > 0 && newElement.id > 0 && <Button variant="outline-danger" onClick={() => handleClose()}>{t('common.No')}</Button>}
              {existingTemplates.length > 0 && newElement.id > 0 && <Button variant="outline-primary" onClick={() => handleSetAsElementTemplate(true)}>{t('common.Yes')}</Button>}
              {existingTemplates.length === 0 && newElement.id > 0 && (
                <Button variant="outline-primary" onClick={() => handleSetAsElementTemplate()}>
                  <FaFile />
                  {' '}
                  {t('element.Add to database')}
                </Button>
              )}
            </ButtonGroup>
            {existingTemplates.length === 0 && <Button onClick={() => handleAddElement()}>{newElement.amount === 0 ? t('element.Add element') : t('common.Save')}</Button>}
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showDeleteGroupDialog} onHide={() => { setShowDeleteGroupDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('scenario.Delete group')}
            {' '}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {groupToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteGroupDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button onClick={() => { handleRemoveGroup(groupToDelete); setShowDeleteGroupDialog(false) }}>{t('common.Delete')}</Button>
        </Modal.Footer>
      </Modal>

      {showProductCalcDialog && (
        <Modal show={showProductCalcDialog} onHide={() => { setProductError(false); handleClose(); }} dialogClassName="form-large" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t('product.Product calculations')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductForm
              element={editedElement}
              onChange={(e) => { setEditedElement(e); validateElement(); }}
              isValid={isValid}
              saveCalculations={saveCalculations}
              project={props.project}
              organizationId={organizationId}
              productError={productError}
              setProductError={setProductError}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowProductCalcDialog(false)}>
              {' '}
              {t('common.Cancel')}
            </Button>
            <Button variant="outline-primary" onClick={() => showExplain(1)}>
              {' '}
              {t('common.Explain calculations')}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <StatusButton onSelectStatus={(s) => saveCalculations({ closeDialog: true, productStatus: s })} />
            <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: false })}>
              <FaCalculator />
              {' '}
              {t('element.Update calculations')}
            </Button>
            {/* <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: true })}>{t('common.Save and close')}</Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {showTransportationCalcDialog && (
        <Modal show={showTransportationCalcDialog} onHide={() => { handleClose(); setDropDownError(false); }} dialogClassName="form-large" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t('transportation.Transportation emissions')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TransportationForm
              element={editedElement}
              onChange={(e) => { setEditedElement(e); validateElement(); }}
              isValid={isValid}
              saveCalculations={saveCalculations}
              project={props.project}
              organizationId={organizationId}
              dropDownError={dropDownError}
              setDropDownError={setDropDownError}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowTransportationCalcDialog(false)}>
              {' '}
              {t('common.Cancel')}
            </Button>
            <Button variant="outline-primary" onClick={() => showExplain(3)}>
              {' '}
              {t('common.Explain calculations')}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <StatusButton onSelectStatus={(s) => saveCalculations({ closeDialog: true, transportStatus: s })} />
            <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: false })}>
              <FaCalculator />
              {' '}
              {t('element.Update calculations')}
            </Button>
            {/* <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: true })}>{t('common.Save and close')}</Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {showInstallationCalcDialog && (
        <Modal show={showInstallationCalcDialog} onHide={() => { handleClose(); }} dialogClassName="form-large" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>
              {t('installation.Installation emissions')}
              {' '}
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <FaInfoCircle />
              </OverlayTrigger>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InstallationForm
              element={editedElement}
              onChange={(e) => { setEditedElement(e); validateElement(); }}
              isValid={isValid}
              saveCalculations={saveCalculations}
              project={props.project}
              organizationId={organizationId}
              error={error}
              toggleError={toggleError}
              setDropDownError={setDropDownError}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowInstallationCalcDialog(false)}>
              {' '}
              {t('common.Cancel')}
            </Button>
            <Button variant="outline-primary" onClick={() => showExplain(4)}>
              {' '}
              {t('common.Explain calculations')}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <StatusButton
              onSelectStatus={(s) => saveCalculations({ closeDialog: true, installationStatus: s })}
            />
            <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: false })}>
              <FaCalculator />
              {' '}
              {t('element.Update calculations')}
            </Button>
            {/* <Button disabled={!isValid} onClick={() => saveCalculations({ closeDialog: true })}>{t('common.Save and close')}</Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {showGenericCalcDialog && (
        <Modal show={showGenericCalcDialog} onHide={() => { handleClose(); }} dialogClassName="form-large" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t('generic.Generic Calculations')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GenericForm element={filterEditedElements(editedElement, formType)} organizationId={organizationId} onChange={(e) => { setEditedElement(e); validateElement(); }} itemType={formType} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowGenericCalcDialog(false)}>
              {' '}
              {t('common.Cancel')}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <StatusButton onSelectStatus={(s) => { saveCalculations({ closeDialog: true, st: getGenStatus(formType), s: s, formType }) }} />
            {/* <Button disabled={!isValid} onClick={() => { saveCalculations({ closeDialog: true }) }}>{t('common.Save and close')}</Button> */}
          </Modal.Footer>
        </Modal>
      )}

      {showDeleteElementDialog && (
        <Modal show={showDeleteElementDialog} onHide={() => { setShowDeleteElementDialog(false); }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t('element.Delete element')}
              {' '}
              {editedElement.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('common.Are you sure you want to delete')}
              {' '}
              {editedElement.name}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowDeleteElementDialog(false)}>
              {' '}
              {t('common.Cancel')}
            </Button>
            <Button onClick={() => handleRemoveElement(editedElement.id)}>{t('common.Delete')}</Button>
          </Modal.Footer>
        </Modal>
      )}

      <ExplainModal show={showExplainDialog} onHide={() => setShowExplainDialog(false)} explain={explain} />
      <div className="table-container" style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
        <Table className="table-sm table-elements">
          <thead className="thead-light thead-main sticky-top">
            <tr className="no-borders text-middle justify-content-center">
              <th colSpan={50} className="mx-6">
                <Button tooltip="Show All Stages" className="mx-2" onClick={() => { setTypeToggle('All') }}>{t('scenario.Show all stages')}</Button>
                <Button tooltip="Show only A (Before use) stage" className="mx-2" onClick={() => { setTypeToggle('A') }}>{t('scenario.Show only A stage')}</Button>
                <Button tooltip="Show Only B (In use) stage" className="mx-2" onClick={() => { setTypeToggle('B') }}>{t('scenario.Show only B stage')}</Button>
                <Button tooltip="Show Only C (End of life) stage" className="mx-2" onClick={() => { setTypeToggle('C') }}>{t('scenario.Show only C stage')}</Button>
              </th>
              {/* <th colSpan={30}><DropDown object={selectedEmissionUnit} prop="id" options={emissionUnits} onChange={(o) => (e) => { selectEmissionUnitById(e.currentTarget.value); }} optiontext="name" optionvalue="id" selectionRequired size="sm" /></th> */}
              <th colSpan={30}>
                <div className="radio-toggle" style={{ display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-end", width: "100%" }}>
                  {emissionUnits.map((unit) => (
                    <label key={unit.id} style={{ margin: 0, display: "flex", alignItems: "center", marginRight: "10px", textTransform: "none" }}>
                      <input
                        type="radio"
                        value={unit.id}
                        checked={selectedEmissionUnit.id === unit.id}
                        onChange={() => selectEmissionUnitById(unit.id)}
                        style={{ height: "22.67px", marginRight: "5px" }}
                      />
                      {unit.name}
                    </label>
                  ))}
                </div>
              </th>
            </tr>
            <tr className="no-borders text-middle " style={{ height: "85px" }}>
              {/* <th></th> */}
              <th colSpan={3} style={{ minWidth: "90px", maxWidth: "90px" }}></th>
              <th style={{ maxWidth: "100px", minWidth: "100px" }}>{t('common.Code')}</th>
              <th style={{ maxWidth: "200px", minWidth: "200px" }}>{t('common.Name')}</th>
              <th style={{ maxWidth: "100px", minWidth: "100px" }}>{t('common.Description')}</th>
              <th style={{ maxWidth: "100px", minWidth: "100px" }}>{t('transportation.Distance')}</th>
              <th style={{ maxWidth: "100px", minWidth: "100px" }} colSpan={2}>  {t('common.Quantity')}</th>              
              {(typeToggle === 'A' || typeToggle === 'All') &&
                <>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>A0</div>{` ${t('generic.Pre-Construction')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>A1-A3</div>{` ${t('product.Products')}`} <div><FaCalculator style={{ fontSize: "1.2em" }} /></div></th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>A4</div>{` ${t('transportation.Transports')}`}<div><FaCalculator style={{ fontSize: "1.2em" }} /></div></th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>A5</div>{` ${t('installation.Installations')}`}<div><FaCalculator style={{ fontSize: "1.2em" }} /></div></th>
                </>
              }
              {(typeToggle === 'B' || typeToggle === 'All') &&
                <>
                  {/* <th className={getTableHeaderClass()}>{`B1 ${t('generic.InstalledProducts')}`}</th> */}
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B1</div>{` ${t('generic.InstalledProducts')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B2</div>{` ${t('generic.Maintenance')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B3</div>{` ${t('generic.Repair')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B4</div>{` ${t('generic.Replacement')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B5</div>{` ${t('generic.Refurbishment')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B6</div>{` ${t('generic.Operationalenergy')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B7</div>{` ${t('generic.Operationalwater')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B8</div>{` ${t('generic.Otheroperations')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>B9</div>{` ${t('generic.Userutil')}`}</th>
                </>
              }
              {(typeToggle === 'C' || typeToggle === 'All') &&
                <>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>C1</div>{` \n${t('generic.Deconstruction')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>C2</div>{` \n${t('generic.Transport')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>C3</div>{` \n${t('generic.WasteProcessing')}`}</th>
                  <th colSpan={3} className="text-center emissions" style={{ minWidth: "110px", maxWidth: "110px" }}><div>C4</div>{` \n${t('generic.Disposal')}`}</th>
                </>
              }
              {(typeToggle === 'A' || typeToggle === 'All') && <th colSpan={3} className="text-center emissions">{t('scenario.Total A Stage Emissions')}</th>}
              {(typeToggle === 'B' || typeToggle === 'All') && <th colSpan={3} className="text-center emissions">{t('scenario.Total B Stage Emissions')}</th>}
              {(typeToggle === 'C' || typeToggle === 'All') && <th colSpan={3} className="text-center emissions">{t('scenario.Total C Stage Emissions')}</th>}
              <th colSpan={3} className="text-center emissions">{t('scenario.Total emissions')}</th>
              <th colSpan={3} className="text-center emissions">{t('scenario.Total emissions %')} </th>
            </tr>
          </thead>
          {[...projectGroups.reduce((a, c) => {
            a.push(c);
            if (c.id === groupId) {
              a = [...a, ...c.children];
            }
            return a;
          }, []).map((g) => ({ ...g, disableEdit: false, checked: false })), {
            // disableEdit: true, code: null, name: t('common.No group'), id: null,
            disableEdit: true, code: null, name: t('common.No group'), id: null,
          }].map((p) => (
            <Fragment key={p.id + p.code + p.name}>
              {/* new Date().toISOString() */}
              <thead className="thead-light">
                <GroupRow p={p} />
              </thead>
              <tbody>
                <tr>
                  <td colSpan="20">
                    {Object.keys(props.checkedItems)
                      .filter((v) => props.checkedItems[v] !== false).length > 0 && (
                        <ButtonGroup size="sm" className="mr-2">
                          <Button variant="primary" onClick={(e) => { handleMoveSelectedElementsToGroup(p); e.stopPropagation(); }}>
                            <FaLevelDownAlt className="mr-1" />
                            {t('scenario.Move selected here')}
                          </Button>
                          <Button variant="primary" onClick={(e) => { handleCopySelectedElementsToGroup(p); e.stopPropagation(); }}>
                            <FaCopy className="mr-1" />
                            {t('scenario.Copy selected here')}
                          </Button>
                        </ButtonGroup>
                      )}
                    <ButtonGroup size="sm">
                      {/* {console.log("Group ID: ", groupId, "; Group: ", p,)} */}
                      <Button variant="outline-primary" onClick={(e) => { handleShowElementDialog(p.id); e.stopPropagation(); }}>
                        <FaPlus />
                        {' '}
                        {p.id !== null ? t('element.Add element to group') : t('element.Add element')}
                      </Button>
                      {/* {p.id !== null && elements.filter((i) => i.groupId === p.id).length === 0 && ( */}
                      {p.id > 0 && (
                        <Button variant="outline-danger" onClick={() => { setGroupToDelete(p); setShowDeleteGroupDialog(true); }} >
                          <FaTrash />
                          {' '}
                          {t('scenario.Delete group')}
                        </Button>
                      )
                      }
                      {/* )} */}
                    </ButtonGroup>
                  </td>
                  <td colSpan="6" className="emissions" />
                  <td colSpan="2" />
                </tr>
                {elements.sort(elementSort).map((item) => item.groupId === p.id && (
                  <ElementRow
                    organizationId={organizationId}
                    key={item.id + item.totalEmission}
                    item={item}
                    emissionUnit={selectedEmissionUnit}
                    onRemove={(elem) => {
                      setEditedElement(elem);
                      setShowDeleteElementDialog(true);
                    }}
                    onUpdate={async (elem) => {
                      const row = await saveElement(elem);
                      setElements((cur) => {
                        const idx = cur.findIndex((e) => e.id === elem.id);
                        if (idx >= 0) {
                          cur.splice(idx, 1, row);
                        }

                        return [...cur];
                      });
                    }}
                    onOpenEdit={(type) => {
                      const clonedItem = clone(item);
                      clonedItem['organizationId'] = organizationId
                      setEditedElement(clonedItem);
                      setNewElement(clonedItem);
                      switch (type) {
                        case 'A0':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'A1-A3':
                          setShowProductCalcDialog(true);
                          break;
                        case 'A4':
                          setShowTransportationCalcDialog(true);
                          break;
                        case 'A5':
                          setShowInstallationCalcDialog(true);
                          break;
                        case 'B1':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B2':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B3':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B4':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B5':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B6':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B7':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B8':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'B9':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'C1':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'C2':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'C3':
                          setShowGenericCalcDialog(true);
                          break;
                        case 'C4':
                          setShowGenericCalcDialog(true);
                          break;
                        default:
                          setShowNewElementDialog(true);
                          break;
                      }
                    }}
                    onSelect={(checked) => {
                      props.onCheckChange({ currentTarget: { checked } }, item);
                    }}
                    selected={props.checkedItems[item.id]}
                    editRowElementId={editRowElementId}
                    setEditRowElementId={setEditRowElementId}
                    emissionType={typeToggle}
                  />
                ))}
              </tbody>
            </Fragment>
          ))}
        </Table>
      </div>
    </div>
  );
});

export default ElementTable;
