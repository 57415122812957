import {create} from "zustand";


const useStore = create((set) => ({

genericFormType: "",

stateToggle: true,

changeGenericFormType: (formType) => set((state) => ({ genericFormType: formType})),

toggleState: (toggle) => set((state) => ({stateToggle: toggle})),
}));
export default useStore;
