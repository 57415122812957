import React, { useEffect, useState, useCallback } from 'react';
import {
  Navbar,
  Alert,
  Button,
  Modal,
} from 'react-bootstrap';
import './Login.scss';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Api from '../../services/api';
import AccountDialog from './AccountDialog';

import loglevel from '../../services/loglevel';
import TokenManager from '../../services/token-manager';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const currentYear = new Date().getFullYear();
  const history = useHistory();
  const [showCreateAccountError, setShowCreateAccountError] = useState(false);
  const [createAccountMessage, setCreateAccountMessage] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
    password2: '',
  });

  const { t } = useTranslation();

  const handleCreateAccount = async () => {
    Api().accounts.register(user).then(() => {
      loglevel.info('login automatically');
    }).catch((error) => {
      let message = t('Something went wrong');
      if (error.response.data && error.response.data.errors) {
        const errors = Object.keys(error.response.data.errors);
        message = errors.map((field) => error.response.data.errors[field]).join(', ');
      } else if (error.response.data) {
        message = error.response.data.map((d) => t(d.code)).join(', ');
      }

      setCreateAccountMessage(message);
      setShowCreateAccountError(true);

      setLoading(false);
    });
  };

  const ssoLoginUrl = () => Api().url.sso;

  // get code from url if needed
  const getSsoLoginCode = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('code');
  };

  const getLoginAccessToken = () => {
    const paramsString = window.location.search;
    const params = new URLSearchParams(paramsString);
    return params.get('accessToken');
  };

  const login = useCallback(async ({
    ssoCode, password, email, accessToken,
  }) => {
    // Create token manager and clear existing tokens
    const manager = new TokenManager();
    manager.removeToken();

    try {
      await Api().accounts.login({
        code: ssoCode, email, username: email, password, accessToken,
      });

      history.push('/home');
    } catch (e) {
     alert(e.message)
     console.log(e)
      setLoading(false);
    }
  }, [history]);

  useEffect(() => {
    const accessToken = getLoginAccessToken();
    if (accessToken !== undefined && accessToken !== null && accessToken !== '') {
      setLoading(true);
      login({ accessToken });
    }

    if (code === null) {
      const newCode = getSsoLoginCode();
      if (newCode !== undefined && newCode !== null) {
        setLoading(true);
        setCode(newCode);
        login({ ssoCode: newCode });
      }
    }
  }, [code, login]);

  return (
    <div>
      <div id="app" style={{ minHeight: '100vh' }}>
        <Modal show={showCreateAccount} onHide={() => { setShowCreateAccount(false); }}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Create account')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert show={showCreateAccountError} variant="danger" onClose={() => setShowCreateAccountError(false)} dismissible>{createAccountMessage}</Alert>
            <AccountDialog user={user} onChange={(u) => { setUser(u); }} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCreateAccount}>{t('Create account')}</Button>
          </Modal.Footer>
        </Modal>
        <Navbar bg="primary" variant="dark" id="nav">
          <Navbar.Brand>
            ZEROinfra
          </Navbar.Brand>
        </Navbar>

        <div className="hero">
          <h1>{t('Welcome to ZEROinfra by Ramboll')}</h1>
          {!loading && (<Button variant="outline-light" href={ssoLoginUrl()} onClick={() => setLoading(true)}>{t('Login using Ramboll credentials')}</Button>)}
          {loading && (
          <Button variant="outline-light">
            <span className="spinner-border spinner-border-sm mr-2" />
            {t('Authenticating')}
            ...
          </Button>
          )}
        </div>

        <footer className="fixed-bottom p-4 bg-light">
          <div className="container">
            <div className="row justify-content-center">              
              <span className="text-muted">Copyright © {currentYear} · Ramboll ZEROinfra · All Rights Reserved</span>
            </div>
            <div className="row justify-content-center">             
              <a href="/termsofservice" target='_blank'>Service Description</a>
              <span className="mx-1">·</span>              
              <a href="https://fi.ramboll.com/ramboll_finland_oy/tietosuoja" target='_blank'>Data protection</a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Login;
