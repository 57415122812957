import React, {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  Modal,
  ButtonGroup,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Badge,
  Spinner,
  Pagination,
} from 'react-bootstrap';
import {
  FaPlus, FaTrash, FaPen, FaEye,
} from 'react-icons/fa';
import Api from '../../services/api';
import MachineForm from './MachineForm';
import { withSettingsStore } from '../common/settings-context';
import notFound from '../../assets/no-data.png';
import { sortObjectArray } from '../../utils/utilityFunctions';
import { humanize } from '../common/helpers';

const Machines = withSettingsStore(({
  displayError, units, isInRole, organizationSelect, getUnitById, getUnitByCode, user
}) => {
  const { t } = useTranslation();
  const itemsPerPageOptions = [
    { name: '20', value: 20 },
    { name: '50', value: 50 },
    { name: '100 ', value: 100 },
  ];
  const [machines, setMachines] = useState([]);
  const [showMachineDialog, setShowMachineDialog] = useState(false);
  const emptyMachine = () => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
    organizationId: 0,
  });
  const [newMachine, setNewMachine] = useState(emptyMachine());
  const [searchText, setSearchText] = useState('');

  const [machineElements, setMachineElements] = useState([]);
  const [machineTemplates, setMachineTemplates] = useState([]);
  const [viewMachineId, setViewMachineId] = useState(0);
  const [loadingElements, setLoadingElements] = useState(false);
  const [showDeleteMachineDialog, setShowDeleteMachineDialog] = useState(false);
  const [machineToDelete, setMachineToDelete] = useState(emptyMachine());
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [numItems, setNumItems] = useState(50);
  const [sortBy, setSortBy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  // const getUnitAbbreviation = useCallback((unitId) => {
  //   const unit = units.find((u) => u.id === unitId);
  //   if (unit === undefined) {
  //     return '';
  //   }
  //   return unit.abbreviation;
  // }, [units]);

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const retrieveMachines = useCallback(async () => {
    clearTimeout(currentTimeout);
    setLoading(true);
    setCurrentTimeout(setTimeout(async () => {
      const m = await Api().machines().getMachinesByOrgId({
        limit: numItems, offset: page * numItems, sortBy, id: organizationSelect, includeLinked: true,
        search: searchText, limit: numItems, offset: page * numItems, sortBy, id: organizationSelect, includeLinked: true
      });
      const sortedByEmission = sortObjectArray(m.items, 'emission', true);

      setMachines(sortedByEmission);
      if (numItems > 0) {
        setLastPage(Math.floor(m.count / numItems));
        setPages((current) => {
          // iterate 1 ... lastPage
          current = [];
          for (let i = 0; i <= Math.floor(m.count / numItems); i++) {
            current.push(i + 1);
          }
          return current;
        });
      }

      setLoading(false);
    }, 1000));
  }, [searchText, numItems, page, sortBy, organizationSelect]);  

  useEffect(() => {
    setPage(0);
  }, [searchText, numItems]);

  const handleSaveMachine = async () => {
    const errorLog = []
    if (newMachine.name == '') {
      errorLog.push('name');
    }
    setFormErrors(errorLog);
    if (errorLog.length > 0) {
      return;
    }

    setLoading(true);
    // In the C# API logic, it will check if editable, and if so it will reassign previous org id.
    newMachine.organizationId = organizationSelect;
    if (newMachine.id > 0) {
      await Api().machines(newMachine.id).update(newMachine);
    } else {
      await Api().machines().post(newMachine);
    }
    setLoading(false);
    setShowMachineDialog(false);
    retrieveMachines();
  };

  const removeMachine = async (machine) => {
    if (machine.isLinked) {
      handleRemoveMachine(machine.id);
    } else {
      setMachineToDelete(machine);
      setShowDeleteMachineDialog(true);
    }
  };

  const handleRemoveMachine = async (id) => {
    try {
      await Api().machines(id).delete();
    } catch (e) {
      displayError(t('database.failed-to-remove-machine'));
    }
    retrieveMachines();
  };

  const handleEditMachine = (machine) => {
    setNewMachine(machine);
    setShowMachineDialog(true);
  };

  const handleNewMachine = () => {
    setNewMachine(emptyMachine());
    setShowMachineDialog(true);
  };

  const handleViewMachine = async (machine) => {
    if (viewMachineId === machine.id) {
      setViewMachineId(0);
    }
    else {
    setLoadingElements(true);
    setViewMachineId(machine.id);
    setMachineElements(await Api().machines(machine.id).elements());
    setMachineTemplates(await Api().machines(machine.id).templates());
    setLoadingElements(false);
    }
  };

  // const filterBySearch = useCallback((m) => m.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0, [searchText]);

  useEffect(() => {
    if (organizationSelect !== null) {
      retrieveMachines();
    }
  }, [organizationSelect, retrieveMachines]);

  return (
    <div>
      <Modal
        show={showDeleteMachineDialog}
        onHide={() => {
          setShowDeleteMachineDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("database.Delete machine")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("common.Are you sure you want to delete")} {machineToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteMachineDialog(false)}>
            {" "}
            {t("common.Cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => {
              handleRemoveMachine(machineToDelete.id);
              setShowDeleteMachineDialog(false);
            }}
          >
            <FaTrash /> {t("common.Delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showMachineDialog}
        onHide={() => {
          setShowMachineDialog(false);
        }}
        dialogClassName="form-large"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("database.New machine")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MachineForm machine={newMachine} organizationId={organizationSelect} onChange={(m) => setNewMachine(m)} editable={true} hideAddEmission={false} hideTrash={isInRole("Admin")} editMachineDetails={isInRole("Admin")} editEmissionDetails={isInRole("Admin")} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveMachine()}>{t("common.Save")}</Button>
        </Modal.Footer>
      </Modal>
      <div className="button-toolbar d-flex flex-column">
        <ButtonGroup>
          <InputGroup className="mr-4">
            <InputGroup.Prepend>
              <InputGroup.Text>{t("common.Search")}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup>
            <FormControl as="select" value={numItems} onChange={(e) => setNumItems(Number(e.target.value))}>
              {itemsPerPageOptions.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.name} {t("database.per page")}
                </option>
              ))}
              {/* <option key={0} value={0}>{t('database.All')}</option> */}
            </FormControl>
          </InputGroup>

          {numItems > 0 && machines.length > 0 && (
            <div className="mr-1" style={{ height: "40px" }}>
              <Pagination className="sticky-pagination d-flex custom-pagination">
                <Pagination.First disabled={page <= 0} onClick={() => setPage(0)} />
                <Pagination.Prev disabled={page <= 0} onClick={() => setPage((p) => p - 1)} />

                {(() => {
                  const maxPagesToShow = 5;
                  let startPage = Math.max(0, page - Math.floor(maxPagesToShow / 2));
                  let endPage = startPage + maxPagesToShow - 1;

                  if (endPage > lastPage) {
                    endPage = lastPage;
                    startPage = Math.max(0, endPage - maxPagesToShow + 1);
                  }

                  if (startPage < 0) {
                    startPage = 0;
                  }

                  const pagesToShow = [];
                  for (let i = startPage; i <= endPage; i++) {
                    pagesToShow.push(
                      <Pagination.Item key={`page-${i}`} active={i === page} onClick={() => setPage(i)}>
                        {i + 1}
                      </Pagination.Item>
                    );
                  }
                  return pagesToShow;
                })()}

                <Pagination.Next disabled={page >= lastPage} onClick={() => setPage((p) => p + 1)} />
                <Pagination.Last disabled={page >= lastPage} onClick={() => setPage(lastPage)} />
              </Pagination>
            </div>
          )}
          <div className="ml-auto">
            <Button variant="outline-primary" onClick={() => handleNewMachine()} disabled={!isInRole("Admin")}>
              <FaPlus /> {t("database.New machine")}
            </Button>
          </div>
        </ButtonGroup>
      </div>
      {loading ? (
        <div className="position-fixed text-center" style={{ left: "50%", top: "50%" }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div id="machine" className="table-container">
            <Table className="table-sm">
              <thead className="sticky1-header" style={{ height: "50px" }}>
                <tr>
                  <th style={{ width: "1vw" }} />
                  <th style={{ width: "10vw" }}>{t("common.Name")}</th>
                  <th style={{ width: "10vw" }}>{t("common.Description")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Module")}</th>
                  <th style={{ width: "5vw" }}>{t("common.Emission factor")}</th>
                  <th style={{ width: "5vw" }}>{t("common.Unit")}</th>
                  <th style={{ width: "5vw" }}>{t("common.Conversions")}</th>
                  <th style={{ width: "10vw" }}>{t("database.Source")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Expiration Year")}</th>
                  <th style={{ width: "5vw" }}>{t("database.Created")}</th>
                  {isInRole('Admin') && (<th style={{ width: "5vw" }}>{t("database.Created By")}</th>)}
                  <th style={{ width: "5vw" }}>{t("database.Updated")}</th>
                  {isInRole('Admin') && (<th style={{ width: "5vw" }}>{t("database.Updated By")}</th>)}
                  <th style={{ width: "2vw" }} />
                </tr>
              </thead>
              <tbody>
                {machines.length > 0 ? (
                  machines.map((m) => (
                    <Fragment key={m.id}>
                      <tr style={{ background: m.isLinked ? "#d4edda" : "white" }}>
                        <td />
                        <td>{m.name}</td>
                        <td>{m.description?.trim() ? m.description : '-'}</td>
                        <td>{m.module?.trim() ? m.module : '-'}</td>
                        <td>
                          {m.emissions.length === 0 ? (
                            <span>-</span>
                          ) : (
                            m.emissions.map((c) => (
                              <Fragment key={c.fromUnitId}>
                                <div>{c.emission}</div>
                              </Fragment>
                            ))
                          )}
                        </td>
                        <td>
                          {m.emissions.length === 0 ? (
                            <span>-</span>
                          ) : (
                            m.emissions.map((c) => (
                              <Fragment key={c.fromUnitId}>
                                <Badge className="m-1" variant="secondary">
                                  {getUnitByCode("kgco2eq", organizationSelect)?.[0]?.symbol} / {getUnitById(c.fromUnitId).symbol}
                                </Badge>
                              </Fragment>
                            ))
                          )}
                        </td>
                        <td>
                          {m.conversions.length === 0 ? (
                            <span>-</span>
                          ) : (
                            m.conversions.map((c) => (
                              <Badge key={c.id} className="m-1" variant="secondary">
                                {getUnitById(c.fromUnitId).symbol} &#8594; {getUnitById(c.toUnitId).symbol}
                              </Badge>
                            ))
                          )}
                        </td>
                        <td>
                          {m.emissions.map((e) => (
                            <div>{e.source?.trim() ? e.source : '-'}</div>
                          ))}
                        </td>
                        <td>{m.expirationYear === 0 ? '-' : m.expirationYear}</td>
                        <td>{m.createdAt ? humanize.date(m.createdAt, 'date') : '-'}</td>
                        {isInRole('Admin') && (<td>{m.createdByName === null ? '-' : (
                          m.createdBy === user.id ? (
                            <strong>{m.createdByName}</strong>
                          ) : 
                          m.createdByName
                        )}
                        </td>)}
                        <td>{m.updatedAt ? humanize.date(m.updatedAt, 'date') : '-'}</td>
                        {isInRole('Admin') && (<td>{m.updatedByName === null ? '-' : (
                          m.updatedBy === user.id ? (
                            <strong>{m.updatedByName}</strong>
                          ) : 
                          m.updatedByName
                        )}
                        </td>)}
                        <td className="text-right">
                          <ButtonGroup>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Edit", 1))}>
                              <Button
                                variant="outline-info"
                                onClick={() => {
                                  handleEditMachine(m);
                                }}
                              >
                                <FaPen />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.View", 2))}>
                              <Button
                                disabled={!m.isLinked}
                                variant="outline-info"
                                onClick={() => {
                                  handleViewMachine(m);
                                }}
                              >
                                <FaEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t("database.Delete", 3))}>
                              <Button
                                variant="outline-danger"
                                onClick={() => {
                                  removeMachine(m);
                                }}
                                disabled={!isInRole("Admin")}
                              >
                                <FaTrash />
                              </Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>
                      {viewMachineId === m.id && (
                        <tr>
                          <td colSpan={isInRole('Admin') ? 14: 12}>
                            {loadingElements ? (
                              <div className="text-center">
                                <Spinner variant="primary" animation="border" role="status">
                                  <span className="sr-only">{t("Loading...")}</span>
                                </Spinner>
                              </div>
                            ) : (
                              <div>
                                {machineElements.length > 0 && (
                                  <Table className="table-sm">
                                    <thead>
                                      <tr style={{ fontStyle: "italic" }}>
                                        <th colSpan="6">{t("element.Linked elements")}</th>
                                      </tr>
                                      <tr className="view-header">
                                        <th>{t("common.Element name")}</th>
                                        <th>{t("project.Project")}</th>
                                        <th>{t("scenario.Scenario")}</th>
                                        <th>{t("common.Code")}</th>
                                        <th>{t("common.Unit")}</th>
                                        <th>{t("common.Description")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="view-body">
                                      {machineElements.map((e) => (
                                        <tr key={e.id}>
                                          <td>{e.name?.trim() ? e.name : '-'}</td>
                                          <td>{e.projectName?.trim() ? e.projectName : '-'}</td>
                                          <td>{e.scenarioName?.trim() ? e.scenarioName : '-'}</td>
                                          <td>{e.code?.trim() ? e.code : '-'}</td>
                                          <td>{getUnitById(e.unitId).symbol}</td>
                                          <td>{e.description?.trim() ? e.description : '-'}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                )}
                                {machineTemplates.length > 0 && (
                                  <Table className="table-sm">
                                    <thead>
                                      <tr style={{ fontStyle: "italic" }}>
                                        <th colSpan="4">{t("element.Linked templates")}</th>
                                      </tr>
                                      <tr className="view-header">
                                        <th>{t("common.Template name")}</th>
                                        <th>{t("common.Code")}</th>
                                        <th>{t("common.Unit")}</th>
                                        <th>{t("common.Description")}</th>
                                      </tr>
                                    </thead>
                                    <tbody className="view-body">
                                      {machineTemplates.map((e) => (
                                        <tr key={e.id}>
                                          <td>{e.name?.trim() ? e.name : '-'}</td>
                                          <td>{e.code?.trim() ? e.code : '-'}</td>
                                          <td>{getUnitById(e.unitId).symbol}</td>
                                          <td>{e.description?.trim() ? e.description : '-'}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <tr style={{ height: "60vh" }}>
                    <td colSpan={isInRole('Admin') ? 14: 12} className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                          <img src={notFound} className="img-responsive" style={{ height: "70px" }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                          <h1 style={{ fontSize: "20px" }}>{t("database.Data Not Available in the Database")}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
});

const renderTooltip = (name, id) => (
  <Tooltip id={id}>
    {name}
  </Tooltip>
);

export default Machines;