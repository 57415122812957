import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import {
  Form,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

// Base services
import Api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';
import loglevel from '../../../services/loglevel';

// Common components
import { Typeahead } from '../../common/components';

const ElementForm = withSettingsStore((props) => {

  const { t } = useTranslation();
  const { organizationId } = props;

  const [element, setElement] = useState(

    props.elements === undefined ? {
      id: undefined, name: '', description: '', code: '', quantity: 0, unitId: 0, totalEmission: 0,
    } : props.elements,
  );
  const [template, setTemplate] = useState({
    id: 0,
    name: '',
  });
  const searchTemplates = async ({ search }) => {
    const allTemplates = await Api().templates().get({ search });
    console.log(allTemplates);
    const filteredTemplates = allTemplates.filter((item) => item.organizationId === organizationId);
    console.log(filteredTemplates);
    return filteredTemplates;
  };
  const searchCodes = async ({ search }) => {
    const res = props.codes
    .filter((c) => (c.name.toLowerCase().includes(search.toLowerCase())
      || c.description.toLowerCase().includes(search.toLowerCase()))).filter((item) => item.organizationId === organizationId)
      console.log(res)
      return res;
  }
  const handleCodeSelect = (code) => {
    element.code = code.name;
    setElement({ ...element });
  };
  const handleTemplateSelect = (tpl) => {
    loglevel.info('template selected:', tpl);
    element.code = tpl.code;
    element.name = tpl.name;
    element.description = tpl.description;
    element.unitId = tpl.unitId;
    element.products = tpl.products;
    element.transports = tpl.transports;
    element.installations = tpl.installations;
    element.productStatus = 1; // draft
    element.installationStatus = 1; // draft
    element.transportStatus = 1; // draft
    setElement({ ...element });
    props.onChange(element);
    setTemplate(tpl);
  };

  // Filter the new vehicles and order them
  const vehicleOptions = useCallback(() => props.roadTransports
    .filter((t) => t.organizationId == organizationId && t.legacyVehicle === false),
  [props.roadTransports]);

  const emptyTransport = {
    name: '',
    description: '',
    code: '',
    capacity: 0,
    roadDistance: 0,
    totalDistance: 0,
    totalQuantity: 0,
    transportRoad: 
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]
        : null,
    transportRoadId: 
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]?.id
        : null,
    transportWaterborneId: null,
    totalEmission: 0,
    transportMaterials: [],
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (element.transports.length < 1) {
      element.transports.push(emptyTransport);
    }
    if (name === 'quantity' && element[name] === undefined) {
      element[name] = " ";
    }
    if (name === 'totalDistance' && value > -1) {
      element.transports.forEach((t)=> t.totalDistance = Number(value));
    }
    else if (name==='quantity' && value < 0) {
      ;
    } else{
      element[name] = value;
    }
    setElement({ ...element });
    props.onChange(element);
  };

  const removeTemplate = () => {
    element.code = '';
    element.name = '';
    element.description = '';
    element.products = [];
    element.transports = [];
    element.installations = [];
    setElement({ ...element });
    setTemplate({ id: 0, name: '' });
  };

  const searchAndSetTemplate = async () => {
    loglevel.info('Searching...', element);
    const results = await Api().templates().get({
      code: element.code,
      search: element.name,
      unitId: element.unitId,
    });
    if (results.length > 0) {
      const tpl = results[0];
      element.products = tpl.products;
      element.transports = tpl.transports;
      element.installations = tpl.installations;
      setElement({ ...element });
      setTemplate(tpl);
    } else {
      removeTemplate();
    }
  };

  const tooltip = (
    <Tooltip id="tooltip-public">
      {t('element.Prepared templates include emissions from material transportation and installations')}
    </Tooltip>
  );

  const filteredUnits = props.units.filter(unit => unit.organizationId === organizationId);
  let defaultUnitId = element.unitId
  if (defaultUnitId === 0) {
    defaultUnitId = filteredUnits[0]['id'];
    element["unitId"] = defaultUnitId;
  }
  const elementQuantityForm = (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>
            {t('common.Distance')}
            <small> (km)</small>
          </Form.Label>
          <Form.Control type="number" name="totalDistance" value={element.transports[0]?.totalDistance} placeholder={t('element.Enter element distance')} onChange={(e) => handleInputChange(e)} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>
            {t('common.Quantity')}
          </Form.Label>
          <Form.Control type="number" name="quantity" value={element.quantity} placeholder={t('element.Enter element quantity')} onChange={(e) => handleInputChange(e)} />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>
            {t('common.Unit')}
          </Form.Label>
          <Form.Control as="select" name="unitId" value={defaultUnitId} defaultValue={defaultUnitId} onChange={(e) => handleInputChange(e)} >
            {
              props.units.filter((units) => units.organizationId === organizationId).map((filteredUnit) => (
                <option key={filteredUnit.id} value={filteredUnit.id}>
                 {t(`unitsName.${filteredUnit.symbol}`)} ({filteredUnit.symbol})
                </option>
              ))
            }
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );

  const editElementForm = (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {t('common.Code')}
            </Form.Label>
            <Typeahead name="code" value={element.code} onSelect={(code) => { handleCodeSelect(code); }} source={searchCodes} resultText="{name} - {description}" onBlur={(e) => { handleInputChange(e); }} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {t('common.Name')}
            </Form.Label>
            <Form.Control name="name" value={element.name} defaultValue={element.name} placeholder={t('element.Enter element name')} onChange={(e) => handleInputChange(e)} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              {t('common.Description')}
            </Form.Label>
            <Form.Control name="description" value={element.description} defaultValue={element.description} placeholder={t('element.Enter element description')} onChange={(e) => handleInputChange(e)} />
          </Form.Group>
        </Col>
      </Row>
      {elementQuantityForm}
    </>
  );

  const useTemplateForm = (
    <Row>
      <Col className="box">
        <Form.Group>
          <Form.Label>
            {t('element.Search prepared template')}
            {' '}
            <OverlayTrigger trigger="hover" placement="right" overlay={tooltip}>
              <FiHelpCircle />
            </OverlayTrigger>
          </Form.Label>
          <Typeahead value={template.name} onSelect={(tpl) => { handleTemplateSelect(tpl); }} source={searchTemplates} resultText="{code} - {name} - {description}" placeholder={t('element.Search by code or name')} />
          {template.id > 0 && (
            <Container className="p-0">
              <div className="d-flex align-items-center justify-content-between">
                <div className="mt-3 mb-3">
                  <strong>{t('element.Selected template')}</strong>
                  <div style={{ color: "black", fontWeight: "bold", fontSize: "13px" }}>{`${template.code} — ${template.name}`}</div>
                  <div style={{ color: "darkgray", fontSize: "12px" }}>{`${template.description || ''}`}</div>
                </div>
                <ButtonGroup style={{marginLeft: "10px"}}>
                  <Button variant="secondary" className="text-nowrap" onClick={() => removeTemplate()}>
                    <FaTrash/>
                    {t('element.Do not apply template')}
                  </Button>
                </ButtonGroup>
              </div>
              {elementQuantityForm}
            </Container>
          )}
        </Form.Group>
      </Col>
    </Row>
  );

  return (
    <>
      {element.id === undefined && (
        <Form>
          <Tabs defaultActiveKey="template" id="add-element-tabs" className="mb-3">
            <Tab eventKey="template" title={t('element.Use template')}>
              {useTemplateForm}
            </Tab>
            <Tab eventKey="custom" title={t('element.Custom element')}>
              {editElementForm}
            </Tab>
          </Tabs>
        </Form>
      )}
      {element.id !== undefined && (
        <Form>
          {editElementForm}
        </Form>
      )}
    </>
  );
});

export default ElementForm;
