import React, {
  useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import {
  Col,
  Form,
  Button,
  Row,
  ButtonGroup,
  FormControl,
  Modal,
} from 'react-bootstrap';
import Api from '../../services/api';
import { SettingsContext } from '../common/settings-context';

import './Forms.scss';

const ElementConversionsModal = ({
  scenarioId, elementId, show, onClose, toUnitId, onSubmit, element: passedElement, organizationId
}) => {
  const [element, setElement] = useState(null);

  const { units } = useContext(SettingsContext);
  const {displayError} = useContext(SettingsContext)

  const filteredUnits = units.filter(unit => unit.organizationId === organizationId);

  const { t } = useTranslation();

  const handleQuantityChange = (conversion) => (e) => {
    const { value } = e.target;
    const target = element.conversions.find((c) => c === conversion);
    target.quantity = Number(value);
    target.factor = target.quantity / (element.quantity === 0 ? 1 : element.quantity);
    setElement({ ...element });
  };
  const handleUnitChange = (conversion) => (e) => {
    const { value } = e.target;
    const target = element.conversions.find((c) => c === conversion);
    target.toUnitId = Number(value);
    setElement({ ...element });
  };
  const handleAddConversion = () => {
    setElement((e) => ({
      ...e,
      conversions: [...e.conversions, {
        toUnitId: typeof toUnitId === 'number' && toUnitId > 0 ? toUnitId : (filteredUnits.length ? filteredUnits[0]?.id : 1),
        factor: 1,
        quantity: e.quantity,
      }],
    }));
  };
  const handleRemoveConversion = (conversion) => () => {
    setElement((e) => ({
      ...e,
      conversions: e.conversions.filter((c) => c !== conversion),
    }));
  };
  const handleSave = () => {
    // Api().scenarios(scenarioId).elements(elementId).update(element)
    //  .then((e) => { setElement(e); onSubmit(e); });    
    let id = element.conversions.map((e)=>e.toUnitId)
    let usedUnitId = [element.unitId, ...id];    

    const hasDuplicate=(usedUnitId)=>{
      return usedUnitId.some((item, index)=>
      usedUnitId.indexOf(item) !== index);
    }
    if(hasDuplicate(usedUnitId)){
      // alert('Please provide unique conversions')
      displayError(t('Please provide unique conversions'));
    }else{
      onSubmit({ ...element });
    }    
  };

  useEffect(() => {
    if (passedElement !== null) {
      const e = passedElement;
      setElement({
        ...e,
        conversions: e.conversions.map((c) => ({ ...c, quantity: Number((e.quantity * c.factor).toFixed(2)) })),
      });
      if (typeof toUnitId === 'number' && toUnitId > 0) {
        handleAddConversion();
      }
    }
  }, [show, passedElement]);

  return (
    <Modal show={show} size="md" onClose={() => onClose()} onHide={() => onClose()}>
      <Modal.Header>
        <Modal.Title>{t('element.Edit Conversions')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {element !== null
          && (
            <Form>
              <Row>
                <Col>
                  <div className="label">
                    <span>{t('common.Quantity')}</span>
                  </div>
                  <FormControl
                    className="form-control form-control-md"
                    type="text"
                    value={element.quantity}
                    disabled
                  />
                </Col>
                <Col>
                  <div className="label">
                    <span>{t('common.Unit')}</span>
                  </div>
                  <select
                    className="form-control form-control-md"
                    value={element.unitId}
                    disabled
                  >
                    {filteredUnits.map((u) => (
                      <option key={u.id} value={u.id}>{u.abbreviation}</option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <strong className="label">{t('element.Equals')}</strong>
                </Col>
              </Row>
              {element.conversions.map((conversion) => (
                <Row className="my-1">
                  <Col>
                    <FormControl
                      className="form-control form-control-md"
                      type="text"
                      defaultValue={conversion.quantity}
                      onChange={handleQuantityChange(conversion)}
                      placeholder={t('common.Quantity')}
                    />
                  </Col>
                  <Col>
                    <ButtonGroup className="d-flex">
                      <select
                        className="form-control form-control-md"
                        onChange={handleUnitChange(conversion)}
                        defaultValue={conversion.toUnitId || filteredUnits[0].id}
                      >
                        {filteredUnits.map((u) => (
                          <option key={u.id} value={u.id}>{u.abbreviation}</option>
                        ))}
                      </select>
                      <Button variant="link" onClick={handleRemoveConversion(conversion)}><FaTrash /></Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              ))}
              <Row className="mt-2">
                <Col>
                  <Button onClick={handleAddConversion}>{t('common.Add')}</Button>
                </Col>
              </Row>
            </Form>
          )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>{t('common.Cancel')}</Button>
        <Button onClick={handleSave}>{t('common.Save')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ElementConversionsModal;
