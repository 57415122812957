import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import {
  FaPen, FaTrash, FaTruck, FaShip,
} from 'react-icons/fa';

import './Scenarios.scss';
import { withSettingsStore } from '../../common/settings-context';
import { defaultHandleChange, clone, humanize } from '../../common/helpers';
import { emptyElement, emptyElementTransport } from '../../common/initializers';
import useStore from '../../../state/store';

/**
 * Component for showing single row in scenario listing
 *
 * @component
 * @example
 * <ElementRow>
 *
 * </ElementRow>
 */
const ElementRow = withSettingsStore(({
  item,
  roadTransports,
  emissionUnit,
  getUnitById,
  onRemove,
  onUpdate,
  onOpenEdit,
  onSelect,
  selected,
  editRowElementId,
  setEditRowElementId,
  emissionType,
  latestElementId,
  organizationId
}) => {
  // Translation helper
  const { t } = useTranslation();

  // State variables
  // copy of the item passed to this component
  const [editedElement, setEditedElement] = useState(emptyElement());
  const [totalEmissionsA0, setTotalEmissionsA0] = useState(0)
  const [totalEmissionsB1, setTotalEmissionsB1] = useState(0)
  const [totalEmissionsB2, setTotalEmissionsB2] = useState(0)
  const [totalEmissionsB3, setTotalEmissionsB3] = useState(0)
  const [totalEmissionsB4, setTotalEmissionsB4] = useState(0)
  const [totalEmissionsB5, setTotalEmissionsB5] = useState(0)
  const [totalEmissionsB6, setTotalEmissionsB6] = useState(0)
  const [totalEmissionsB7, setTotalEmissionsB7] = useState(0)
  const [totalEmissionsB8, setTotalEmissionsB8] = useState(0)
  const [totalEmissionsB9, setTotalEmissionsB9] = useState(0)
  const [totalEmissionsC1, setTotalEmissionsC1] = useState(0)
  const [totalEmissionsC2, setTotalEmissionsC2] = useState(0)
  const [totalEmissionsC3, setTotalEmissionsC3] = useState(0)
  const [totalEmissionsC4, setTotalEmissionsC4] = useState(0)
  const [totalEmissions, setTotalEmissions] = useState(0)
  const [totalEmissionsA, setTotalEmissionsA] = useState(0)
  const [totalEmissionsB, setTotalEmissionsB] = useState(0)
  const [totalEmissionsC, setTotalEmissionsC] = useState(0)


  const changeFormType = useStore((state) => state.changeGenericFormType);


  const calculateEmissions = (genericItemsArray) => {
    const emissionsSum = genericItemsArray.map(element => parseInt(element.totalEmission)).reduce((previous, current) => previous + current, 0);
    return emissionsSum
  }
  const vehicleOptions = useCallback(() => roadTransports.filter((u) => u.organizationId === organizationId), [roadTransports]);

  const emptyElementTransport = {
    name: '',
    description: '',
    code: '',
    capacity: 0,
    roadDistance: 0,
    totalDistance: 0,
    totalQuantity: 0,
    transportRoad: 
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]
        : null,
    transportRoadId: 
      Array.isArray(vehicleOptions()) && vehicleOptions().length > 0
        ? vehicleOptions()?.[0]?.id
        : null,
    transportWaterborneId: null,
    totalEmission: 0,
    transportMaterials: [],
  };

  // Updated editedElement with distance from the first transport
  const setEditedElementWithTotalDistance = (element) => {
    const transports = element.transports.length > 0
      ? element.transports
      : [emptyElementTransport];
    setEditedElement({
      ...clone(element),
      totalDistance: transports[0].totalDistance,
    });
  };

  // Toggle current edited row
  const toggleEditElementGroup = (element) => {
    if (editRowElementId !== element.id) {
      // setEditRowGroupId(0)
      setEditRowElementId(element.id);
      // setEditedElementWithTotalDistance(element);
    }
  };

  // Retrieves Road Transport total distance as comma-separated list
  const getTransportRoadTotalDistance = (element) => {
    if (element.transports.length > 0) {
      const roadList = element.transports.filter((i) => i.transportRoadId !== null);
      return roadList.map((transport) => Math.round(transport.totalDistance)).join(', ');
    }

    return null;
  };

  // Retrieves Waterborne Transport total distance as comma-separated list
  const getTransportWaterborneTotalDistance = (element) => {
    if (element.transports.length > 0) {
      const waterborneList = element.transports.filter((i) => i.transportWaterborneId !== null);
      return waterborneList.map((transport) => transport.totalDistance).join(', ');
    }

    return null;
  };

  // Shows transport total distances with transport type icon
  const getTransportTotalDistances = (element) => {
    const roadTotalDistance = getTransportRoadTotalDistance(element);
    const waterborneTotalDistance = getTransportWaterborneTotalDistance(element);

    return (
      <>
        {roadTotalDistance && (
          <span className="mr-2">
            <FaTruck />
            {` ${roadTotalDistance} km `}
          </span>
        )}
        {waterborneTotalDistance && (
          <>
            <FaShip />
            {` ${waterborneTotalDistance} km `}
          </>
        )}
      </>
    );
  };

  // Is the current row selected
  const isSelected = useMemo(() => !!selected, [selected]);

  // Provide emissions with factor from the unit
  const getFactoredEmission = (emission) => {
    emission = Number(emission);
    emission *= emissionUnit.factor;
    return emission;
  };

  // Handlers

  // User clicks Edit button
  const handleEditRow = (type) => (e) => {
    // resetEditRows();
    onOpenEdit(type);
    e.stopPropagation();
    changeFormType(type)
  };
  // User clicks Delete button
  const handleDeleteRow = (e) => {
    // resetEditRows();
    onRemove(editedElement);
    e.stopPropagation();
  };
  // // User triggers change on the element
  const handleUpdateRow = (e) => {
    // Set totalDistance to 0 if the input is empty or just whitespace
    const { name, value } = e.currentTarget;

    if (
      name === 'totalDistance' &&
      (value === null || value.trim() === '' || value === undefined)
    ) {
      editedElement.totalDistance = 0;
    } else if (name === 'totalDistance') {
      editedElement.totalDistance = Number(value); // Ensuring value is treated as a no
    }
    
    if (editedElement.transports.length === 0) {
      editedElement.transports.push({
        ...emptyElementTransport,
        totalDistance: editedElement.totalDistance,
        transportRoadId: vehicleOptions()?.[0]?.id,
      });
    } else {
      editedElement.transports[0].totalDistance = editedElement.totalDistance;
    }
    setEditedElement({ ...editedElement });  
    onUpdate({ ...editedElement });  
  };

  // User presses key
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      e.currentTarget.blur();
      setEditRowElementId(0);
    }
  };

  const setInitialEmissions = (itemType) => {
    const itemsofType = item.genericItems.filter((item) => item.type === itemType)
    const totalEmission = calculateEmissions(itemsofType)

    switch (itemType) {
      case 'A0':
        setTotalEmissionsA0(totalEmission)
        break;
      case 'B1':
        setTotalEmissionsB1(totalEmission)
        break;
      case 'B2':
        setTotalEmissionsB2(totalEmission)
        break;
      case 'B3':
        setTotalEmissionsB3(totalEmission)
        break;
      case 'B4':
        setTotalEmissionsB4(totalEmission)
        break;
      case 'B5':
        setTotalEmissionsB5(totalEmission)
        break;
      case 'B6':
        setTotalEmissionsB6(totalEmission)
        break;
      case 'B7':
        setTotalEmissionsB7(totalEmission)
        break;
      case 'B8':
        setTotalEmissionsB8(totalEmission)
        break;
      case 'B9':
        setTotalEmissionsB9(totalEmission)
        break;
      case 'C1':
        setTotalEmissionsC1(totalEmission)
        break;
      case 'C2':
        setTotalEmissionsC2(totalEmission)
        break;
      case 'C3':
        setTotalEmissionsC3(totalEmission)
        break;
      case 'C4':
        setTotalEmissionsC4(totalEmission)
        break;
    }
    return itemsofType
  }

useEffect(() => {
  setTotalEmissionsA0(0);
  setTotalEmissionsB1(0);
  setTotalEmissionsB2(0);
  setTotalEmissionsB3(0);
  setTotalEmissionsB4(0);
  setTotalEmissionsB5(0);
  setTotalEmissionsB6(0);
  setTotalEmissionsB7(0);
  setTotalEmissionsB8(0);
  setTotalEmissionsB9(0);
  setTotalEmissionsC1(0);
  setTotalEmissionsC2(0);
  setTotalEmissionsC3(0);
  setTotalEmissionsC4(0);
  setTotalEmissions(0);
  setTotalEmissionsA(0);
  setTotalEmissionsB(0);
  setTotalEmissionsB(0);
}, 
[
  item, 
  emissionType, 
  item.totalEmission, 
  totalEmissions, totalEmissionsB1, totalEmissionsB2, totalEmissionsB3, totalEmissionsB4, totalEmissionsB5, totalEmissionsB6, totalEmissionsB7, totalEmissionsB8, totalEmissionsB9, totalEmissionsC1, totalEmissionsC2, totalEmissionsC3, totalEmissionsC4,
]);

useEffect(() => {
  const itemTypes = [...new Set(item.genericItems.map(({type}) => type))]
  
itemTypes.forEach((type) => {
setInitialEmissions(type)
}
)

    setEditedElementWithTotalDistance(item);

    setTotalEmissions(
      totalEmissionsA0 +
      totalEmissionsB1 +
      totalEmissionsB2 +
      totalEmissionsB3 +
      totalEmissionsB4 +
      totalEmissionsB5 +
      totalEmissionsB6 +
      totalEmissionsB7 +
      totalEmissionsB8 +
      totalEmissionsB9 +
      totalEmissionsC1 +
      totalEmissionsC2 +
      totalEmissionsC3 +
      totalEmissionsC4 + item.totalProductEmission + item.totalTransportEmission + item.totalInstallationEmission
    );

    setTotalEmissionsA(item.totalPreConstructionEmissions + item.totalProductEmission + item.totalTransportEmission + item.totalInstallationEmission);

    setTotalEmissionsB(
      totalEmissionsB1 +
      totalEmissionsB2 +
      totalEmissionsB3 +
      totalEmissionsB4 +
      totalEmissionsB5 +
      totalEmissionsB6 +
      totalEmissionsB7 +
      totalEmissionsB8 +
      totalEmissionsB9
    );

    setTotalEmissionsC(
      totalEmissionsC1 +
      totalEmissionsC2 +
      totalEmissionsC3 +
      totalEmissionsC4
    );

  }, 
  [
    item, 
    emissionType, 
    item.totalEmission, 
    totalEmissions, totalEmissionsB1, totalEmissionsB2, totalEmissionsB3, totalEmissionsB4, totalEmissionsB5, totalEmissionsB6, totalEmissionsB7, totalEmissionsB8, totalEmissionsB9, totalEmissionsC1, totalEmissionsC2, totalEmissionsC3, totalEmissionsC4,
  ]);

  // Tooltip template
  const renderTooltip = (name, id) => (
    <Tooltip id={id}>
      {name}
    </Tooltip>
  );

  const getVariant = (phase) => {
    let status = 0;
    
    if (phase === 'pre-construction') {
      status = item.preConstructionStatus;
    }
    if (phase === 'product') {
      status = item.productStatus;
    }
    if (phase === 'transport') {
      status = item.transportStatus;
    }
    if (phase === 'installation') {
      status = item.installationStatus;
    }
    if (phase === 'installedProducts') {
      status = item.installedProductsStatus;
    }
    if (phase === 'maintenance') {
      status = item.maintenanceStatus;
    }
    if (phase === 'repair') {
      status = item.repairStatus;
    }
    if (phase === 'replacement') {
      status = item.replacementStatus;
    }
    if (phase === 'refurbishment') {
      status = item.refurbishmentStatus;
    }
    if (phase === 'openergy') {
      status = item.operationalEnergyStatus;
    }
    if (phase === 'opwater') {
      status = item.operationalWaterStatus;
    }
    if (phase === 'otherop') {
      status = item.otherOperationsStats;
    }
    if (phase === 'userutil') {
      status = item.userUtilizationsStatus;
    }
    if (phase === 'deconstruction') {
      status = item.deconstructionStatus;
    }
    if (phase === 'transportc') {
      status = item.transportCStatus;
    }
    if (phase === 'wasteprocessing') {
      status = item.wasteProcessingStatus;
    }
    if (phase === 'disposal') {
      status = item.disposalStatus;
    }
    if (status === 0) {
      return 'outline-primary';
    }
    if (status === 1) {
      return 'outline-warning';
    }
    if (status === 2) {
      return 'success';
    }
  };

  return (
    <tr
      onClick={(e) => { toggleEditElementGroup(item); e.stopPropagation(); }}
      className={`
        ${latestElementId === item.id ? 'highlight-row' : ''}
        ${isSelected ? 'bg-primary-10' : ''}
      `}
    >
      <td onClick={(e) => e.stopPropagation()} className="text-right" style={{ position: 'sticky', width: '30px'}} >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t('common.Edit', 1))}
        >
          <Button variant="outline-info" onClick={handleEditRow()} size="sm"><FaPen /></Button>
        </OverlayTrigger>
      </td>
      <td onClick={(e) => e.stopPropagation()} className="text-right" style={{ position: 'sticky', width: '30px'}}>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(t('common.Delete', 2))}
        >
          <Button variant="outline-danger" onClick={handleDeleteRow} size="sm">
            <FaTrash />
            {' '}
            {t('')}
          </Button>
        </OverlayTrigger>
      </td>
      <td onClick={(e) => e.stopPropagation()} style={{width: "30px"}}>
          <Form.Check
            type="checkbox"
            id={item.id}
            item={item}
            label=""
            name={item.name}
            checked={isSelected}
            onChange={(e) => onSelect(e.currentTarget.checked)}
          />
      </td>
      {item.code !== null && item.code !== "null" ?
      <td style={{ minWidth: "100px" }}>
        {editRowElementId === item.id && <FormControl style={{ maxWidth: "100px", minWidth: "100px", width: '100px' }} onKeyPress={handleKeyPress} size="sm" className="long-text" defaultValue={editedElement.code} name="code" onChange={(e) => defaultHandleChange(e, setEditedElement)} onBlur={handleUpdateRow} />}
        {editRowElementId !== item.id && (
          <div style={{ maxWidth: "100px", minWidth: "100px" , width: '100px'}}>
            {item.code}
          </div>
        )}
      </td>
      :
      <td style={{ maxWidth: "100px", minWidth: "100px", width: '100px' }}>
        {editRowElementId === item.id && <FormControl as="textarea" rows={3}  onKeyPress={handleKeyPress} style={{ maxWidth: "100px", minWidth: "100px", width: '100px' }} size="sm" className="long-text" defaultValue={editedElement.code} name="code" onChange={(e) => defaultHandleChange(e, setEditedElement)} onBlur={handleUpdateRow} />}
        {editRowElementId !== item.id && (
          <div className='text-muted' style={{ maxWidth: "100px", minWidth: "100px" , width: '100px'}}>
            {/* <Form.Control type="text" placeholder="" style={{width: "100px"}} /> */}
          </div>
        )}
      </td>
}
      <td style={{width: item.name.length > 50 ? "500px" : "200px"}}>
        {editRowElementId === item.id && <FormControl as="textarea" rows={2} onKeyPress={handleKeyPress} size="sm" className="long-text" defaultValue={editedElement.name} name="name" onChange={(e) => defaultHandleChange(e, setEditedElement)} onBlur={handleUpdateRow} />}
        {editRowElementId !== item.id && item.name}         
      </td>
      <td style={{width: item?.description?.length > 50 ? "500px" : "200px"}}>
      {editRowElementId === item.id && <FormControl as="textarea" rows={2} onKeyPress={handleKeyPress} size="sm" className="long-text" defaultValue={editedElement.description} name="description" onChange={(e) => defaultHandleChange(e, setEditedElement)} onBlur={handleUpdateRow} />}
      {editRowElementId !== item.id && <small className="d-block">{item.description}</small>}      
      </td>
      <td className="text-nowrap" style={{width: "100px"}} >
        {editRowElementId === item.id &&
        <FormControl onKeyPress={handleKeyPress} size="sm"
        className="long-text text-right"
        defaultValue={editedElement.transports[0]?.totalDistance}
        name="totalDistance"
        onChange={(e) => defaultHandleChange(e, setEditedElement)}
        onBlur={handleUpdateRow}
        style={{width: "100px"}}
        type='number'
        /> }
        {editRowElementId !== item.id && getTransportTotalDistances(item)}
      </td>
      <td className="text-right text-nowrap" style={{width: "50px"}} >
        {editRowElementId === item.id && <FormControl onKeyPress={handleKeyPress} size="sm" className="long-text text-right" defaultValue={editedElement.quantity} name="quantity" onChange={(e) => defaultHandleChange(e, setEditedElement)} onBlur={handleUpdateRow} style={{width: "100px"}} type='number'/>}
        {/* {editRowElementId !== item.id && humanize.amount_long(item.quantity, 0)} */}
        {editRowElementId !== item.id && Math.round(item.quantity)}
      </td>
      <td className="info-color text-left" style={{width: "50px"}}>{getUnitById(item.unitId).abbreviation}</td>

      {(emissionType === 'A' || emissionType === 'All') &&
        <>
        <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("pre-construction")} className="btn-sm btn-emissions" block onClick={handleEditRow('A0')}>
              {totalEmissionsA0 && humanize.amount_long(getFactoredEmission(totalEmissionsA0), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant('product')} className="btn-sm btn-emissions" block onClick={handleEditRow('A1-A3')}>
              {item.totalProductEmission && humanize.amount_long(getFactoredEmission(item.totalProductEmission), emissionUnit.id)}

            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant('transport')} className="btn-sm btn-emissions" block onClick={handleEditRow('A4')}>
              {item.totalTransportEmission && humanize.amount_long(getFactoredEmission(item.totalTransportEmission), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant('installation')} className="btn-sm btn-emissions" block onClick={handleEditRow('A5')}>
              {item.totalInstallationEmission && humanize.amount_long(getFactoredEmission(item.totalInstallationEmission), emissionUnit.id)}
            </Button>
          </td>
        </>
      }
      {(emissionType === 'B' || emissionType === 'All') &&
        <>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("installedProducts")} className="btn-sm btn-emissions" block onClick={handleEditRow('B1')}>
              {totalEmissionsB1 && humanize.amount_long(getFactoredEmission(totalEmissionsB1), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("maintenance")} className="btn-sm btn-emissions" block onClick={handleEditRow('B2')}>
              {totalEmissionsB2 && humanize.amount_long(getFactoredEmission(totalEmissionsB2), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("repair")} className="btn-sm btn-emissions" block onClick={handleEditRow('B3')}>
              {totalEmissionsB3 && humanize.amount_long(getFactoredEmission(totalEmissionsB3), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("replacement")} className="btn-sm btn-emissions" block onClick={handleEditRow('B4')}>
              {totalEmissionsB4 && humanize.amount_long(getFactoredEmission(totalEmissionsB4), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("refurbishment")} className="btn-sm btn-emissions" block onClick={handleEditRow('B5')}>
              {totalEmissionsB5 && humanize.amount_long(getFactoredEmission(totalEmissionsB5), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("openergy")} className="btn-sm btn-emissions" block onClick={handleEditRow('B6')}>
              {totalEmissionsB6 && humanize.amount_long(getFactoredEmission(totalEmissionsB6), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("opwater")} className="btn-sm btn-emissions" block onClick={handleEditRow('B7')}>
              {totalEmissionsB7 && humanize.amount_long(getFactoredEmission(totalEmissionsB7), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("otherop")} className="btn-sm btn-emissions" block onClick={handleEditRow('B8')}>
              {totalEmissionsB8 && humanize.amount_long(getFactoredEmission(totalEmissionsB8), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("userutil")} className="btn-sm btn-emissions" block onClick={handleEditRow('B9')}>
              {totalEmissionsB9 && humanize.amount_long(getFactoredEmission(totalEmissionsB9), emissionUnit.id)}
            </Button>
          </td>
        </>
      }
      {(emissionType === 'C' || emissionType === 'All') &&
        <>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("deconstruction")} className="btn-sm btn-emissions" block onClick={handleEditRow('C1')}>
              {totalEmissionsC1 && humanize.amount_long(getFactoredEmission(totalEmissionsC1), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("transportc")} className="btn-sm btn-emissions" block onClick={handleEditRow('C2')}>
              {totalEmissionsC2 && humanize.amount_long(getFactoredEmission(totalEmissionsC2), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("wasteprocessing")} className="btn-sm btn-emissions" block onClick={handleEditRow('C3')}>
              {totalEmissionsC3 && humanize.amount_long(getFactoredEmission(totalEmissionsC3), emissionUnit.id)}
            </Button>
          </td>
          <td colSpan={3} className="center-buttons emissions">
            <Button variant={getVariant("disposal")} className="btn-sm btn-emissions" block onClick={handleEditRow('C4')}>
              {totalEmissionsC4 && humanize.amount_long(getFactoredEmission(totalEmissionsC4), emissionUnit.id)}
            </Button>
          </td>
        </>
      }
      {(emissionType === 'A' || emissionType === 'All') &&
      <>
      <td></td>
        <td className="text-center emissions" style={{ whiteSpace: 'nowrap'}}>
          <strong>
            {totalEmissionsA && humanize.amount_long(getFactoredEmission(totalEmissionsA), emissionUnit.id)}
          </strong>
        </td>
      <td></td>
      </>
      }
      {(emissionType === 'B' || emissionType === 'All') &&
      <>
      <td></td>
        <td className="text-center emissions" style={{ whiteSpace: 'nowrap'}}>
          <strong>
            {totalEmissionsB && humanize.amount_long(getFactoredEmission(totalEmissionsB), emissionUnit.id)}
          </strong>
        </td>
      <td></td>
      </>
      }
      {(emissionType === 'C' || emissionType === 'All') &&
      <>
      <td></td>
        <td className="text-center emissions" style={{ whiteSpace: 'nowrap' }}>
          <strong>
            {totalEmissionsC && humanize.amount_long(getFactoredEmission(totalEmissionsC), emissionUnit.id)}
          </strong>
        </td>
      <td></td>
      </>
      }
      <td></td>
      <td></td>
      <td className="text-center emissions" style={{ whiteSpace: 'nowrap', textAlign:'center'  }}>
        <strong>
          {totalEmissions && humanize.amount_long(getFactoredEmission(totalEmissions), emissionUnit.id)}
        </strong>
      </td>
      <td></td>
      <td></td>
      <td className="text-right emissions" style={{ whiteSpace: 'nowrap', textAlign:'left'  }}>
        <strong>
          {item.totalEmissionPercentage && item.totalEmissionPercentage.toFixed(2)}%
        </strong>
      </td>

    </tr>
  );
});

export default ElementRow;
