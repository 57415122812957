const emptyElement = () => ({
  name: '',
  description: '',
  code: '',
  amount: 0,
  unitId: 0,
  totalEmission: 0,
  products: [],
  installations: [],
  transports: [],
  totalDistance: 0,
  preConstructionStatus: 0,
  productStatus: 0,
  transportStatus: 0,
  installationStatus: 0,
  installedProductsStatus: 0,
  maintenanceStatus: 0,
  repairStatus: 0,
  replacementStatus: 0,
  refurbishmentStatus: 0,
  operationalEnergyStatus: 0,
  operationalWaterStatus: 0,
  otherOperationsStats: 0,
  userUtilizationsStatus: 0,
  deconstructionStatus: 0,
  transportCStatus: 0,
  wasteProcessingStatus: 0,
  disposalStatus: 0,
});
const emptyElementTransport = () => ({
  name: '',
  description: '',
  code: '',
  capacity: 0,
  roadDistance: 0,
  totalDistance: 0,
  totalQuantity: 0,
  transportRoadId: null,
  transportWaterborneId: null,
  totalEmission: 0,
  transportMaterials: [],
});

// Initial data constructors
const emptyMaterial = () => ({
  id: 0,
  name: '',
  description: '',
  emission: 0,
  unitId: 0,
  conversions: [],
});

const emptyProduct = () => ({
  id: 0,
  materialId: 0,
  name: null,
  comment: null,
  description: null,
  conversionId: 0,
  conversionFactor: 1,
  factor: 1,
  totalEmission: 0,
  material: emptyMaterial(),
});

export {
  emptyElement,
  emptyElementTransport,
  emptyMaterial,
  emptyProduct,
};
