import React, {
  useCallback, useEffect, useState, useMemo, useRef
} from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { FaSearch, FaUser, FaDatabase } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FiCheck, FiFile, FiSave } from 'react-icons/fi';

import { withSettingsStore } from './settings-context';
import './components.scss';

const processTemplate = (text, r) => {
  // If there is no template syntax. use text as key
  if (!text.includes('{')) {
    return r[text];
  }
  const mapping = Object.keys(r).map((k) => {
    const key = new RegExp(`{${k}}`, 'gi');
    const value = r[k];
    return {
      key, value,
    };
  });

  // replace tags
  mapping.forEach((m) => text = text.replace(m.key, m.value));
  return text;
};

const processIcon = (fn, r) => {
  if (typeof fn === 'function') {
    return fn(r);
  }
  return undefined;
};

const processBackgroundColor = (fn, r) => {
  if (typeof fn === 'function') {
    const val = fn(r);
    return val === undefined ? 'white' : val;
  }
  return 'white';
};

const TextField = ({
  object, prop, onChange, disabled, isValid, size, placeholder, defaultValue
}) => {
  const [localValue, setLocalValue] = useState(object[prop] === null ? '' : object[prop]);
  const handleChange = (event) => {
    setLocalValue(event.currentTarget.value);
  };
  if (size === undefined) {
    size = 'md';
  }
  if (isValid === undefined) {
    isValid = true;
  }

  useEffect(() => {
    let value = object[prop];
    if (disabled && !isNaN(Number(value))) {
      value = Number(value);
      if (!Number.isInteger(value)) {
        value = value.toFixed(2);
      } else {
        value = value.toFixed(0);
      }
    }
    setLocalValue(object[prop] === null ? '' : value);
  }, [disabled, object, prop]);

  return (<Form.Control placeholder={placeholder} size={size} type="text" disabled={disabled} value={defaultValue ? defaultValue : localValue} onChange={handleChange} name={prop} onBlur={onChange({ ...object, [prop]: localValue })} isInvalid={!isValid} />);
};

const DropDown = ({
  object,
  prop,
  options,
  optionvalue,
  optiontext,
  onChange,
  isValid,
  selectionRequired,
  disabled,
  size,
  isGrouped,
  setDropDownError
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(object[prop] === undefined ? null : object[prop]);

  useEffect(() => {
    let value = object[prop];
    if (disabled && !Number.isNaN((Number(value)))) {
      value = Number(value);
      if (!Number.isInteger(value)) {
        value = value.toFixed(2);
      } else {
        value = value.toFixed(0);
      }
    }
    setLocalValue(object[prop] === undefined ? '' : value);
  }, [disabled, object, prop]);

  const handleChange = useCallback((event) => {
    setLocalValue(event.currentTarget.value);
    onChange({ ...object, [prop]: event.currentTarget.value })(event);
    if(event.currentTarget.value){
      setDropDownError(false);
    }
  }, [object, onChange, prop]);

  return (
    <FormControl size={size} as="select" value={localValue} name={prop} onChange={handleChange} isInvalid={!isValid} disabled={disabled}>
      {!selectionRequired && <option>{t('common.Not selected')}</option>}
      {isGrouped ? (
        options.map((group) => (
          <optgroup key={group.name} label={t(`common.${group.name}`)}>
            {group.list.map((u) => (
              <option key={`${u[optionvalue]}`} value={u[optionvalue]}>
                {processTemplate(optiontext, u)}
              </option>
            ))}
          </optgroup>
        ))
      ) : (
        options.map((u) => (
          <option key={`${u[optionvalue]}`} value={u[optionvalue]}>
            {processTemplate(optiontext, u)}
          </option>
        ))
      )}
    </FormControl>
  );
};
DropDown.propTypes = {
  object: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
  prop: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  optionvalue: PropTypes.string.isRequired,
  optiontext: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  selectionRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  isGrouped: PropTypes.bool,
};
DropDown.defaultProps = {
  isValid: true,
  selectionRequired: false,
  disabled: false,
  size: 'md',
  isGrouped: false,
};

const Typeahead = withSettingsStore(({
  getUnitById, value, name, source, onSelect, resultText, resultIcon, resultBackgroundColor, placeholder, onChange, onBlur,
}) => {
  const timeout = 300; // ms
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [searching, setSearching] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentValue, setCurrentValue] = useState({});

  const handleSearch = async (event) => {
    const { value } = event.currentTarget;

    onChange !== undefined && onChange(event);
    setCurrentValue(value);
    clearTimeout(currentTimeout);
    setResultsVisible(false);

    if (value !== '') {
      setCurrentTimeout(setTimeout(async () => {
        setSearching(true);
        const resultList = await source({ search: value });

        if (resultList?.length > 0) {
          setResults(resultList.map((r) => {
            // create mapping
            const text = processTemplate(resultText, r);
            const icon = processIcon(resultIcon, r);
            const backgroundColor = processBackgroundColor(resultBackgroundColor, r);

            return {
              value: r,
              text,
              icon,
              backgroundColor,
            };
          }));
        } else {
          setResults([]);
        }
        setSearching(false);
        setResultsVisible(true);
      }, timeout));
    }
  };
  const selectFromResults = (result) => {
    setResults([]);
    setResultsVisible(false);
    onSelect(result.value);
    setCurrentValue(result.text);
  };

  const [mouseIsDown, setMouseIsDown] = useState(false);
  const groupRef = useRef(null); 

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    setTimeout(() => {
      if (!mouseIsDown) {
        setResultsVisible(false);
      }
    }, timeout);
  };

  const handleClickOutside = (event) => {
    if (groupRef.current && !groupRef.current.contains(event.target)) {
      setResultsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <InputGroup className="typeahead" ref={groupRef}>
      <InputGroup.Prepend>
        <InputGroup.Text>
          {!searching && <FaSearch />}
          {searching && <span className="spinner-border spinner-border-sm" />}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        name={name}
        value={currentValue}
        placeholder={placeholder || t('common.Search')}
        onChange={handleSearch}
        onBlur={handleBlur}
        className='placeholder-text'
      />
      {results.length > 0 && (
        <div className="dropdown-menu" 
             style={{ display: resultsVisible ? 'inline' : 'none' }}
             onMouseDown={() => setMouseIsDown(true)}
             onMouseUp={() => setMouseIsDown(false)}
             onMouseLeave={() => setMouseIsDown(false)}
        >
          {results.map((r) => (
            <button
              key={r.value.id}
              type="button"
              style={{ backgroundColor: r.backgroundColor }}
              className="dropdown-item"
              onClick={() => selectFromResults(r)}
              onMouseDown={(e) => e.preventDefault()}  
            >
              <span className="d-flex">
                <span className="mr-2">
                  {r.icon === 'user' && <FaUser />}
                  {r.icon === 'database' && <FaDatabase />}
                </span>
                <span>
                  {r.text}
                  <div className="text-muted">{r.value.source && <small>{`${t('database.Source')}: ${r.value.source}`}</small>}</div>
                </span>
              </span>
            </button>
          ))}
        </div>
      )}
    </InputGroup>
  );
});

const StatusButton = ({ onSelectStatus, elementStatus }) => {
  const { t } = useTranslation();
  // Current Status 2 = ready, 1 = draft, 0 = none
  const [currentStatus, setCurrentStatus] = useState(elementStatus);
  const currentStatusText = useMemo(() => {
    if (currentStatus === 0) {
      return 'Save';
    }
    if (currentStatus === 1) {
      return 'Save as draft';
    }
    if (currentStatus === 2) {
      return 'Save as ready';
    }
    return 'Save';
  }, [currentStatus]);
  const currentVariant = useMemo(() => {
    if (currentStatus === 0) {
      return 'primary';
    }
    if (currentStatus === 1) {
      return 'warning';
    }
    if (currentStatus === 2) {
      return 'success';
    }
    return 'primary';
  }, [currentStatus]);
  return (
    <Dropdown as={ButtonGroup}>
      <Button variant={currentVariant} onClick={() => onSelectStatus(currentStatus)}>
        {t(`element.${currentStatusText}`)}
      </Button>
      <Dropdown.Toggle split variant={currentVariant} />
      <Dropdown.Menu alignRight>
        <Dropdown.Item onClick={() => { onSelectStatus(0); setCurrentStatus(0); }}>
          <FiSave className="text-primary mr-1" />
          {t('element.Save')}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => { onSelectStatus(1); setCurrentStatus(1); }}>
          <FiFile className="text-warning mr-1" />
          {t('element.Save as draft')}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => { onSelectStatus(2); setCurrentStatus(2); }}>
          <FiCheck className="text-success mr-1" />
          {t('element.Save as ready')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
StatusButton.propTypes = {
  onSelectStatus: PropTypes.func.isRequired,
  elementStatus: PropTypes.number.isRequired,
};

const PageHeader = ({
  title,
  description,
  children,
}) => (
  <div className="page-header">
    <div className="d-flex flex-column align-items-start">
      <h1 className="mb-0 mr-2">{title}</h1>
      <span>{description}</span>
    </div>
    {children}
  </div>
);
PageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};
PageHeader.defaultProps = {
  title: '',
  description: '',
  children: '',
};

export {
  TextField,
  DropDown,
  Typeahead,
  StatusButton,
  PageHeader,
};
