import React, {
    useState, useEffect, useCallback, Fragment, 
  } from 'react';
  import { useTranslation } from 'react-i18next';
  import { FaTrash, FaPen, FaInfoCircle } from 'react-icons/fa';
  import { TextField, Typeahead } from '../common/components';
  import { renderTooltip } from '../common/helpers';
  import MaterialForm from '../database/MaterialForm';
  import Api from '../../services/api';
  import './Forms.scss';
  import {
    Col,
    Form,
    Container,
    Card,
    Button,
    Row,
    Table,
    ButtonGroup,
    FormControl,
    Alert,
    Popover,
    OverlayTrigger,
  } from 'react-bootstrap';
  import { withSettingsStore } from '../common/settings-context';
import useStore from '../../state/store';

  export default withSettingsStore(({ element, getUnitById, onChange, itemType, organizationId, getUnitByCode}) => {
    const { t } = useTranslation();


    // Initial data constructors
    const emptyMaterial = useCallback(() => ({
      id: 0,
      name: '',
      description: '',
      emission: 0,
      unitId: 0,
      conversions: [],
    }), []);

    const emptyGenericItem = useCallback(() => ({
      id: 0,
      type: itemType,
      name: null,
      comment: null,
      description: null,
      totalEmission: 0,
    }), [emptyMaterial]);

    // Object state
    const hasGenericItems = (e) => e.genericItems.length > 0;

    const [genericItems, setGenericItems] = useState(hasGenericItems(element) ? element.genericItems : []);

    // Event handlers
    const handleInputChange = (orig) => (obj) => (event) => {
      const { name, value } = event.currentTarget;

      const targetObject = genericItems.find((p) => p === orig);
      if (targetObject !== undefined) {
        targetObject[name] = value;

        setGenericItems([...genericItems]);

        element.genericItems = genericItems;
        onChange !== undefined && onChange(element);
      }
    };

    useEffect(() => {
      element.genericItems = genericItems;
      onChange !== undefined && onChange(element);
      console.log(element)
      console.log(genericItems)
    }, [genericItems, onChange, element]);


    // {scenarioId}/element/{elementId}

    // JSX
    return (
      <Container fluid>
        <Card className="mb-2">
          <Card.Body>
            <Container fluid>
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th>{t('generic.Type')}</th>
                      <th>{t('common.Name')}</th>
                      <th>{t('common.Description')}</th>
                      <th>{t('common.Comment')}</th>
                      <th>
                      {t('scenario.Total emissions')}
                      {' '}
                      (
                      {/* <span style={{ textTransform: 'none' }}>{t('common.kgco2eq')}</span> */}
                      <span style={{ textTransform: 'none' }}>{getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}</span>
                      )
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {genericItems.map((genericItem, index) => (
                      <Fragment key={index}>
                        <tr>
                          <td><TextField object={genericItem} prop="type" defaultValue={itemType} disabled={true} onChange={() =>{}} /></td>
                          <td><TextField object={genericItem} prop="name" onChange={handleInputChange(genericItem)} /></td>
                          <td><TextField object={genericItem} prop="description" onChange={handleInputChange(genericItem)} /></td>
                          <td><TextField object={genericItem} prop="comment" onChange={handleInputChange(genericItem)} /></td>
                          <td><TextField object={genericItem} prop="totalEmission" onChange={handleInputChange(genericItem)} /></td>
                          <td>
                            <ButtonGroup>
                              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('generic.Delete generic calculation', 1))}>
                                <Button variant="outline-danger" onClick={() => { setGenericItems([...genericItems.filter((p) => p !== genericItem)]); }}><FaTrash /></Button>
                              </OverlayTrigger>
                            </ButtonGroup>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
                <Button variant="outline-primary" className="mt-1" onClick={() => { setGenericItems([...genericItems, emptyGenericItem()]); }}>{t('generic.Add generic')}</Button>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Container>
    );
  });
