import React, {
  useContext, useEffect, useState, useCallback,
  useRef,
} from 'react';
import {
  Accordion,
  AccordionContext,
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  FormControl,
  ProgressBar,
  Row,
  Spinner,
  Table,
  useAccordionToggle,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { TbPolygon } from 'react-icons/tb';
import { BsTreeFill } from 'react-icons/bs';
import { GiFallingRocks } from 'react-icons/gi';
import { MdDraw, MdOutlineUploadFile } from 'react-icons/md';

import proj4 from 'proj4';
import api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';

import './CarbonStocks.scss';
import CarbonStockMap from '../../common/CarbonStockMap';
import loglevel from '../../../services/loglevel';
import { humanize, clone } from '../../common/helpers';

/**
 * Component for producing custom FileSelect button.
 *
 * @param {{buttonText: string, onSelect: function}} options options object to
 * specify buttonText string in the button and onSelect function for callback after file selection.
 * @returns
 */
function FileSelect({ buttonText, onSelect }) {
  const componentRef = useRef();
  const openFileDialog = () => {
    componentRef.current.click();
  };
  return (
    <>
      <input
        type="file"
        accept=".dxf, .geojson"
        ref={componentRef}
        style={{ display: 'none' }}
        onChange={() => { onSelect(componentRef.current.files); }}
      />
      <Button variant="primary" onClick={openFileDialog}>
        <MdOutlineUploadFile />
        {buttonText}
      </Button>
    </>
  );
}

/**
 * Component for showing all carbon stocks assigned to a single scenario.
 *
 * @component
 */
const CarbonStocks = withSettingsStore(({ projectId }) => {
  proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
  const { t } = useTranslation();

  // Get project
  const [initialized, setInitialized] = useState(false);
  const [project, setProject] = useState({ groups: [] });

  const getProject = useCallback(async () => {
    setProject(await api().projects(projectId).get({}));
  }, [setProject, projectId]);

 /* useEffect(() => {
    if (!initialized) {
      getProject();
      setInitialized(true);
    }
  }, [getProject, initialized]);*/

  const [scenarios, setScenarios] = useState([]);
  const [selectedScenarioId, setSelectedScenarioId] = useState(null);
  const [carbonStocks, setCarbonStocks] = useState([]);
  const [legendMode, setLegendMode] = useState(false);
  // carbonStockValues contains data of classes (used for legend and coloring)
  const [carbonStockValues, setCarbonStockValues] = useState([]);

  console.log(carbonStocks);

  // Debugging related state variables
  // Is Debug Mode on? Other
  const [debugMode, setDebugMode] = useState(false);
  // debugFeatures contains debug features extracted from calculate method
  const [debugFeatures, setDebugFeatures] = useState([]);

  // Set project location
  const [center, setCenter] = useState({
    lng: 24.945831,
    lat: 60.192059,
  });

  // Project groups listings
  const [projectGroups, setProjectGroups] = useState([]);

  const updateProjectGroups = () => {
    if (project.groups === undefined) {
      return;
    }
    // update project groups
    setProjectGroups(project.groups
      .filter((g) => g.parentId === null || g.parentId === g.id)
      .map((g) => ({
        ...g,
        children: project.groups
          .filter((pg) => pg.parentId === g.id)
          .map((pg) => ({ ...pg, parentName: g.name, children: [] })),
      })));
  };

  const getCarbonStockValues = () => api().settings.carbonStock();

  const getScenarios = async () => api().scenarios().get({ projectId });

  const getCarbonStocks = async () => api().carbonStocks(selectedScenarioId).get();

  const updateCarbonStocks = async () => {
    setCarbonStocks((await getCarbonStocks())
      .map((cs) => ({
        ...cs,
        isLoading: false,
        timestamp: Math.random(),
      })));
  };

  // set project center when project is set
  useEffect(() => {
    if (project.locationLongitude) {
      setCenter({
        lng: project.locationLongitude,
        lat: project.locationLatitude,
      });
    }
    updateProjectGroups();
  }, [project]);

  // update carbon stocks if scenario is changed
  useEffect(() => {
    if (selectedScenarioId !== null && selectedScenarioId > 0) {
      updateCarbonStocks();
    }
  }, [selectedScenarioId]);

  // Component initialization. Retrieve all data needed for this component to function.
  useEffect(() => {
    let isMounted = true;

    getCarbonStockValues().then((values) => {
      setCarbonStockValues(values);
     // getProject();
      getScenarios().then((res) => {
        if (res !== undefined && res.length > 0) {
          if (isMounted) {
            setScenarios(res);
            setSelectedScenarioId(res[0].id);
          }
        }
      });
      //updateCarbonStocks();
    });

    return () => { isMounted = false; };
  }, []);

  // Handle Import Area
  const [isImporting, setIsImporting] = useState(false);
  const handleImportArea = async (files) => {
    setIsImporting(true);
    const formData = new FormData();
    formData.append('importFile', files[0]);
    await api().carbonStocks(selectedScenarioId).import(formData);
    updateCarbonStocks();
    setIsImporting(false);
  };

  // Toggle scenarios
  function ToggleScenarios({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        onClick={useAccordionToggle(eventKey, () => callback && callback(eventKey))}
      >
        {children}
        {isCurrentEventKey ? <FiChevronUp /> : <FiChevronDown />}
      </button>
    );
  }

  // mapCoordinatesToGeometry converts plain coordinates into points listing used by map.
  // In addition, this function converts the points from ESPG:4326 (gps) to EPSG:3067 (TM35FIN(E,N))
  const mapCoordinatesToGeometry = (coordinates) => ({
    points: coordinates.map((c) => {
      // Transform coordinates from GPS to EPSG:3067
      const pc = proj4('EPSG:4326', 'EPSG:3067', [c.x, c.y]);
      return {
        x: pc[0],
        y: pc[1],
      };
    }),
  });

  const handleMapChange = useCallback(async (e) => {
    if (selectedScenarioId === null) {
      loglevel.warn('scenarios not initialized yet');
      return;
    }
    let id = 0;
    if (e.action === 'add') {
      const carbonStock = {
        name: t('carbonstocks.Area'),
        timestamp: Math.random(),
        groupId: null,
        area: 0,
        calculationCoverage: 0,
        carbonFluxSoil: 0,
        carbonFluxVegetation: 0,
        geometry: mapCoordinatesToGeometry(e.coordinates),
      };
      setCarbonStocks((css) => ([...css, carbonStock]));
      const response = await api().carbonStocks(selectedScenarioId).post(carbonStock);
      id = response.id;
      setCarbonStocks((css) => ([...css.map((cs) => (cs === carbonStock ? { ...cs, id: response.id } : cs))]));
    } else if (e.action === 'edit') {
      var carbonStock = {
        id: e.id,
      };

      setCarbonStocks((css) => {
        const found = css.find((s) => s.id === Number(e.id));
        if (found !== undefined) {
          carbonStock = { ...carbonStock, ...found };
          carbonStock.geometry = mapCoordinatesToGeometry(e.coordinates);
          carbonStock.timestamp = Math.random();

          found.geometry = mapCoordinatesToGeometry(e.coordinates);
          found.timestamp = Math.random();
        }
        return css;
      });

      Promise.resolve().then(() => {
      api().carbonStocks(selectedScenarioId).update({
        ...carbonStock,
        isLoading: true,
      }).then((cs) => {
        setCarbonStocks((css) => {
          const found = css.find((s) => s.id === Number(carbonStock.id));
          found.area = cs.area;
          found.carbonFluxSoil = cs.carbonFluxSoil;
          found.carbonFluxVegetation = cs.carbonFluxVegetation;
          found.carbonFluxTotal = cs.carbonFluxTotal;
          found.geometry = cs.geometry;
          return [...css];
        });
        updateCarbonStocks();

      });
    })
    } else if (e.action === 'remove') {
      id = 0;
      setCarbonStocks((css) => {
        const found = css.find((s) => s.id === Number(e.id));
        if (found !== undefined) {
          id = found.id;
        }
        if (id > 0) {
          api().carbonStocks(selectedScenarioId).delete(id);
          id = 0;
        }
        return found !== undefined ? [...css.filter((cs) => cs.id !== found.id)] : [...css];
      });
    } else {
      // Nothing to do
    }

    setCarbonStocks((css) => [
      ...css.map((cs) => ({ ...cs, timestamp: Math.random(), geometry: clone(cs.geometry) })),
    ]);

    if (id > 0) {
      // toggle loading on
      setCarbonStocks((css) => ([
        ...css.map((cs) => (cs.id === id ? { ...cs, isLoading: true } : cs)),
      ]));
      setCarbonStocks((css) => ([...css])); // Trigger
      api().carbonStocks(selectedScenarioId).calculate({ id, debug: debugMode })
        .then((debugArray) => {
          // toggle loading off
          setCarbonStocks((css) => ([
            ...css.map((cs) => (cs.id === id ? { ...cs, isLoading: false } : cs)),
          ]));

          // Convert debugArray into features
          const feats = [];
          while (debugArray.length > 0) {
            const [lng, lat, type] = debugArray.splice(0, 3);
            // Transform coordinates from EPSG:3067
            const pc = proj4('EPSG:3067', 'EPSG:4326', [lng - 10, lat - 10]);
            const pc2 = proj4('EPSG:3067', 'EPSG:4326', [lng + 10, lat + 10]);
            feats.push({
              id: `dbg_${feats.length}`,
              timestamp: Math.random(),
              name: `dbg_${feats.length}`,
              coordinates: [{
                x: pc[0],
                y: pc[1],
              }, {
                x: pc2[0],
                y: pc2[1],
              }],
              type: 'rectangle',
              color: `#${carbonStockValues.find((v) => v.id === type).colorHex}`,
            });
          }

          setDebugFeatures(feats);
          updateCarbonStocks();
        });
    }
  }, [carbonStocks, scenarios, selectedScenarioId]);

  // Update Carbon Stock rows
  const onUpdate = (async (name, value, id) => {
    try {
      let carbonStock;
      setCarbonStocks((css) => {
        const found = css.find((s) => s.id === Number(id));
        if (found !== undefined) {
          found[name] = name === 'groupId' ? Number(value) : value;
          found.timestamp = Math.random();
          carbonStock = { ...found };
        }
        api().carbonStocks(selectedScenarioId).update(carbonStock);
        return [...css];
      });
    } catch (e) {
      loglevel.info('Update error: ', e);
    }
  });

  // Calculate totals
  const calculateTotals = (type) => carbonStocks.reduce((a, v) => a + v[type], 0);

  // Show calculated values
  const showCalculatedValues = (isLoading, value, unit) => (
    isLoading ? (
      <div style={{ opacity: '0.3' }}>
        <ProgressBar animated now={100} />
      </div>
    ) : (
      <>
        {value < 1 ? `${humanize.amount_long(value, 2)} ` : `${humanize.amount_long(value, 0)} `}
        <small>
          {' '}
          {unit}
        </small>
      </>
    )
  );

  return (
    <Container fluid>
      <div className="carbon-stocks">
        <div className="page-header">
          <div>
            <div className="d-flex align-items-start">
              <h1 className="mb-0 mr-2">{t('carbonstocks.Carbon stocks')}</h1>
              <Badge pill variant={debugMode ? 'danger' : 'primary'} onClick={() => setDebugMode((mode) => !mode)}>Beta</Badge>
            </div>
            <p>{t('carbonstocks.Calculate the impact on carbon stocks')}</p>
          </div>
        </div>
        {selectedScenarioId === null ? (
          <Alert variant="primary" className="text-center p-5 m-3 mt-0">
            <h2 className="h4 mb-0">{t('scenario.No scenarios have been added to this project')}</h2>
          </Alert>
        ) : (
          <Row>
            <Col>
              <Accordion defaultActiveKey="0">
                {scenarios.map((scenario, i) => (
                  <Card key={scenario.id}>
                    <Card.Header>
                      <ToggleScenarios
                        eventKey={i.toString()}
                        callback={
                        () => setSelectedScenarioId((s) => (s.id !== scenario.id ? scenario.id : s))
                      }
                      >
                        <div className="d-flex align-items-start flex-column">
                          <span>{scenario.name}</span>
                        </div>
                      </ToggleScenarios>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i.toString()}>
                      <Card.Body>
                        <div className="carbon-stocks__map">
                          {selectedScenarioId === scenario.id && (
                          <CarbonStockMap
                            features={carbonStocks.map((cs) => ({
                              id: cs.id,
                              timestamp: cs.timestamp,
                              name: cs.name,
                              group: projectGroups.find((e) => e.id === cs.groupId)?.name,
                              carbonFluxSoil: cs.carbonFluxSoil,
                              carbonFluxVegetation: cs.carbonFluxVegetation,
                              area: cs.area,
                              isLoading: cs.isLoading,
                              coordinates: cs.geometry.points.map((p) => {
                              // Transform coordinates from GPS to EPSG:3067
                                const pc = proj4('EPSG:3067', 'EPSG:4326', [p.x, p.y]);
                                return {
                                  x: pc[0],
                                  y: pc[1],
                                };
                              }),
                              type: 'polygon',
                            }))}
                            position={center}
                            editable
                            onChange={(e) => handleMapChange(e)}
                            debugFeatures={debugFeatures}
                            legendMode={legendMode}
                          />
                          )}
                          <div className={`carbon-stocks__map-legend ${legendMode ? 'is-active' : ''} `}>
                            <button
                              type="button"
                              onClick={() => setLegendMode(!legendMode)}
                              className="carbon-stocks__map-legend-title"
                            >
                              {t('carbonstocks.Legend')}
                              {legendMode ? <FiChevronDown /> : <FiChevronUp />}
                            </button>

                            <div className="carbon-stocks__map-legend-wrapper">
                              {carbonStockValues.map((l) => (
                                <div key={l.id} value={l.id} className="carbon-stocks__map-legend-item">
                                  <span className="color" style={{ backgroundColor: `#${l.colorHex}` }} />
                                  {t(`carbonstocks.${l.label}`)}

                                  {/* {` ${l.label}`} */}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        {carbonStocks.length > 0 && (
                        <>
                          <Row className="carbon-stocks__results">
                            <Col md="auto">
                              <ul>
                                <li>
                                  <TbPolygon className="text-primary" />
                                  <span>{t('carbonstocks.Selected areas in total')}</span>
                                  <span className="h5">
                                    {showCalculatedValues(Number.isNaN(calculateTotals('area')), calculateTotals('area'), 'ha')}
                                  </span>
                                </li>
                                <li>
                                  <GiFallingRocks className="text-secondary" />
                                  <span>{t('carbonstocks.Soil carbon stock')}</span>
                                  <span className="h5">
                                    {showCalculatedValues(Number.isNaN(calculateTotals('area')), calculateTotals('carbonFluxSoil'), 'tCO₂/ha')}
                                  </span>
                                </li>
                                <li>
                                  <BsTreeFill className="text-success" />
                                  <span>{t('carbonstocks.Vegetation carbon stock')}</span>
                                  <span className="h5">
                                    {showCalculatedValues(Number.isNaN(calculateTotals('area')), calculateTotals('carbonFluxVegetation'), 'tCO₂/ha')}
                                  </span>
                                </li>
                              </ul>
                            </Col>
                            <Col className="text-right">
                              <FileSelect onSelect={handleImportArea} buttonText={t('carbonstocks.Import areas')} />
                            </Col>
                          </Row>
                          <Table responsive className="carbon-stocks__table table-sm table-elements">
                            <thead className="thead-light thead-main">
                              <tr className="no-borders text-middle">
                                <th width="300">{t('common.Name')}</th>
                                {projectGroups.length > 0 && <th width="300">{t('common.Group')}</th>}
                                <th width="15%">{t('common.Area')}</th>
                                <th width="15%">{t('carbonstocks.Calculation coverage')}</th>
                                <th width="10%">{t('carbonstocks.Soil carbon stock')}</th>
                                <th width="10%">{t('carbonstocks.Vegetation carbon stock')}</th>
                                <th width="10%">{t('common.Total')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {carbonStocks.sort((a, b) => (b.id < a.id ? -1 : 1)).map((c) => (
                                <tr
                                  key={c.id}
                                  onClick={() => {
                                    const focus = c.geometry.points
                                      .map((p) => ({ x: p.x, y: p.y }))
                                      .reduce((acc, cur) => ({
                                        x: acc.x + cur.x,
                                        y: acc.y + cur.y,
                                      }), { x: 0, y: 0 });
                                    focus.x /= c.geometry.points.length;
                                    focus.y /= c.geometry.points.length;
                                    const focusGps = proj4('EPSG:3067', 'EPSG:4326', [focus.x, focus.y]);
                                    setCenter({ lng: focusGps[0], lat: focusGps[1] });
                                  }}
                                >
                                  <td>
                                    <FormControl
                                      className="long-text"
                                      defaultValue={c.name}
                                      name="name"
                                      onBlur={(e) => onUpdate('name', e.target.value, c.id)}
                                    />
                                  </td>
                                  {projectGroups.length > 0 && (
                                  <td>
                                    <FormControl
                                      as="select"
                                      name="groupId"
                                      defaultValue={c.groupId}
                                      onChange={(e) => onUpdate('groupId', e.target.value, c.id)}
                                    >
                                      <option value disabled selected>{`-- ${t('common.Select group')} --`}</option>
                                      {projectGroups.map((pg) => (
                                        <option key={pg.id} value={pg.id}>{pg.name}</option>
                                      ))}
                                    </FormControl>
                                  </td>
                                  )}
                                  <td>
                                    {showCalculatedValues(c.isLoading, c.area, 'ha')}
                                  </td>
                                  <td>
                                    {showCalculatedValues(c.isLoading, c.calculationCoverage, '%')}
                                  </td>
                                  <td>
                                    {showCalculatedValues(c.isLoading, c.carbonFluxSoil, 'tCO₂/ha')}
                                  </td>
                                  <td>
                                    {showCalculatedValues(c.isLoading, c.carbonFluxVegetation, 'tCO₂/ha')}
                                  </td>
                                  <td>
                                    {showCalculatedValues(c.isLoading, c.carbonFluxTotal, 'tCO₂/ha')}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {isImporting && (
                            <div className="is-importing">
                              <Spinner variant="primary" animation="border" />
                              <div>{t('carbonstocks.Importing areas')}</div>
                            </div>
                          )}
                        </>
                        )}
                        {carbonStocks.length === 0 && (
                        <Alert variant="primary" className="carbon-stocks__alert">
                          <MdDraw className="icon icon--xl" />
                          <h2 className="h4">{t('carbonstocks.There are no areas in the scenario yet')}</h2>
                          <p>{t('carbonstocks.Draw areas on the map or import from a file')}</p>
                          <FileSelect onSelect={handleImportArea} buttonText={t('carbonstocks.Import areas')} />
                        </Alert>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
});

export default CarbonStocks;
