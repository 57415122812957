import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Image,
  Container,
  Row,
  Col,
  Badge,
  Form,
  Button,
} from 'react-bootstrap';

import { FaStar } from 'react-icons/fa';

const AccountDialog = (props) => {
  const { t } = useTranslation();

  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    ...props.user,
    name: '',
    id: -1,
    username: '',
    telephone: '',
    company: '',
    address: '',
    isAdmin: false,
    roles: [],
    email: '',
  });

  useEffect(() => {
    setUser(props.user, [props.user]);
    setRoles(props.roles, [props.roles]);
  }, [props.roles, props.user]);

  const handleChange = (event) => {
    user[event.target.name] = event.target.value;
    setUser(user);

    props.onChange(user);
  };

  return (
    <Form>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs="2" className="text-center">
            <Image className="mb-2" src="/placeholder-user.png" fluid roundedCircle />
            {roles.filter((r) => r.name === 'Admin').length > 0 && (
            <Badge variant="primary" size="xl">
              <FaStar className="mr-1" />
              {t('user.Admin')}
            </Badge>
            )}
          </Col>
          <Col xs="6">
            <Form.Group controlId="formName">
              <Form.Label>{t('common.Name')}</Form.Label>
              <Form.Control name="name" value={user.name} type="text" placeholder={t('common.Enter name')} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('user.Email address')}</Form.Label>
              <Form.Control name="email" value={user.email} type="email" placeholder={t('project.Enter email')} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formCompany">
              <Form.Label>{t('user.Company')}</Form.Label>
              <Form.Control name="company" value={user.company} type="text" placeholder={t('Enter company')} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>{t('user.Address')}</Form.Label>
              <Form.Control name="address" value={user.address} type="text" placeholder={t('Enter address')} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formPhone">
              <Form.Label>{t('user.Phone')}</Form.Label>
              <Form.Control name="phone" value={user.telephone} type="text" placeholder={t('Enter phone number')} onChange={handleChange} />
            </Form.Group>
            <hr />
            {props.children}
          </Col>
        </Row>
      </Container>

    </Form>
  );
};

export default AccountDialog;
