import XLSX from 'xlsx';
import { Tooltip } from 'react-bootstrap';

const defaultHandleChange = (event, setter, target) => {
 let { value } = event.target;
  const { name } = event.target;

  if (value === 'true') {
    value = true;
  }
  if (value === 'false') {
    value = false;
  }
  if (!isNaN(value) && value?.toString()?.trim() !== '') {
    value = Math.abs(Number(value));
  }
  setter((prev) => {
    if (Array.isArray(prev)) {
      prev.find((v) => v === target)[name] = value;
      return [...prev];
    }
    return {
      ...prev,
      [name]: value,
    };
  });
};

const loadSheetData = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws);
    resolve(data);
  };
  reader.readAsBinaryString(file);
});

const renderTooltip = (name, id) => (
  <Tooltip id={id}>
    {name}
  </Tooltip>
);

export { defaultHandleChange, loadSheetData, renderTooltip };
