import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  Button,
  Table,
  ToggleButton,
  FormControl, ButtonGroup, InputGroup,
  OverlayTrigger,
  Popover,
  Container,
} from 'react-bootstrap';
import {
  FaTrash, FaPlus, FaDivide, FaTimes, FaInfoCircle,
} from 'react-icons/fa';
import { withSettingsStore } from '../common/settings-context';
import loglevel from '../../services/loglevel';
import Error from "../common/Error";
import './Database.scss'

const ConversionRow = withSettingsStore(({ units, conversion, onChange, project, organizationId }) => {
  const { t } = useTranslation();
  const handleChange = (element) => (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'factor') {
      // Check if value is a valid number
      if (!isNaN(value)) {
        // Convert value to a number
        const floatValue = parseFloat(value);
        element[name] = floatValue.toString(); // Convert back to string for consistency
        onChange(element);
      } else {
        alert(t("database.Constraint Error"));
      }
    } else {
      // For other fields, directly update the element
      if (name === 'inverse') {
        element[name] = event.currentTarget.checked;
      } else {
        element[name] = value;
      }
      onChange(element);
    }
  };

  const TextField = ({ element, field, checkNumber }) => (<Form.Control type={`${checkNumber ? "number" : "text"}`} defaultValue={element[field]} name={field} onBlur={handleChange(element)} className="database-forms-placeholder"/>);
  const DropDown = ({
    element, field, options, optionvalue, optiontext, organizationId
  }) => {
    //Filter the options based off of organisationId

    const filteredOptions = options.filter(option => option.organizationId === organizationId);

    return(
    <FormControl as="select" name={field} defaultValue={element[field]} onChange={handleChange(element)}>
      {filteredOptions.map((u, i) => (<option key={u[optionvalue]} value={u[optionvalue]}>{u[optiontext]}</option>))}
    </FormControl>
  )};
  return (
    <tr>
      <td><DropDown element={conversion} field="fromUnitId" options={units} optionvalue="id" optiontext="symbol" organizationId={organizationId}/></td>
      <td><DropDown element={conversion} field="toUnitId" options={units} optionvalue="id" optiontext="symbol" organizationId={organizationId}/></td>
      <td>
        <InputGroup>
          <TextField element={conversion} field="factor" checkNumber={true} />
          <ButtonGroup toggle>
            <ToggleButton
              type="checkbox"
              variant="primary"
              checked={conversion.inverse}
              name="inverse"
              defaultValue={false}
              onChange={handleChange(conversion)}
              title={t('Use multiplicative inverse')}
            >
              {conversion.inverse && <FaDivide />}
              {!conversion.inverse && <FaTimes />}
            </ToggleButton>
          </ButtonGroup>
        </InputGroup>
      </td>
      <td><TextField element={conversion} field="comment" /></td>
      <td>
        <ButtonGroup>
          <Button variant="outline-danger" onClick={() => { onChange('remove'); }}><FaTrash /></Button>
        </ButtonGroup>
      </td>
    </tr>
  );
});

//Material Form
export default withSettingsStore(({
  material, onChange, units, fromUnitId, toUnitId, isTransport, project, organizationId, readOnly, getUnitByCode, organizationSelect, errors, isProduct
}) => {
  const { t } = useTranslation();
  const [newMaterial, setNewMaterial] = useState({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
    ...material === undefined ? {} : material,
  });

  useEffect(() => {
    onChange(newMaterial);
  }, [newMaterial, onChange]);

  useEffect(() => {
    if (newMaterial.id !== material.id) {
      setNewMaterial(material);
    }
  }, [material, newMaterial.id]);

  const handleChange = (element) => (event) => {
    const { name, value } = event.currentTarget;
    newMaterial[name] = value;
    setNewMaterial(newMaterial);
  };

  const handleChange1 = (event, element) => {
    let {name, value } = event.currentTarget;
    element[name] = value;
    onChange(element);
  };

 // const TextField = ({ element, field, disabled }) => (<Form.Control type="text" defaultValue={element[field]} name={field} onBlur={handleChange(element)} disabled={disabled}/>);
 const TextField = ({ element, field, disabled, placeholder, checkNumber, formValidation }) => {
  return (
    <Form.Control
      type={checkNumber ? "number" : "text"}
      defaultValue={element[field] === 0 && field === "expirationYear" ? " " : element[field]}
      name={field}
      disabled={disabled}
      placeholder={placeholder}
      className={`database-forms-placeholder ${formValidation}`}
      onChange={(event) => handleChange1(event, element)}
    />
  );
};

const handleEmissionFactorChange = (event, element) => {
  const { name, value } = event.currentTarget;

  if (name === 'emission') {
    // Check if value is a valid number
    if (!isNaN(value)) {
      // Convert value to a number
      const floatValue = parseFloat(value);
      element[name] = floatValue.toString(); // Convert back to string for consistency
      setNewMaterial({ ...newMaterial });
    } else {
      // Display an alert or any other feedback to the user
      alert(t("database.Constraint Error"));
      // You may also consider resetting the value to its previous state here
      element[name] = ''; // Resetting the value to an empty string
      setNewMaterial({ ...newMaterial });
    }
  } else {
    // For other fields, directly update the element
    element[name] = value;
    setNewMaterial({ ...newMaterial });
  }
};

  const DropDown = ({
    element, field, options, optionvalue, optiontext, organizationId, disabled
  }) => {
    const filteredOptions = options.filter(option => option.organizationId === organizationId);

    if(element['unitId'] === 0) {
      element['unitId'] = filteredOptions[0]['id']
    }

    return(
    <FormControl as="select" name={field} defaultValue={element['unitId']} onChange={handleChange(element)} disabled={disabled}>
      {filteredOptions.map((u, i) => {return (<option key={u[optionvalue]} value={u[optionvalue]}>{u[optiontext]}</option>)})}
    </FormControl>
  )};

  const handleRemoveConversion = (idx) => {
    loglevel.info('Removing conversion', idx);
    newMaterial.conversions.splice(idx, 1);
    setNewMaterial({ ...newMaterial });
  };
  const handleAddConversion = () => {
    loglevel.info('Adding conversion');
    const filteredUnits = units.filter(units => units.organizationId === organizationId);
    newMaterial.conversions.push({
      materialId: 0,
      toUnitId: toUnitId ? Number(toUnitId) : newMaterial.unitId,
      fromUnitId: fromUnitId ? Number(fromUnitId) : filteredUnits[0]['id'],
      factor: 0,
      inverse: false,
    });
    setNewMaterial({ ...newMaterial });
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.conversion-row-1')}
          </Row>

          <Row>
            {t('installation.conversion-row-2')}
          </Row>

        </Container>
      </Popover.Content>
    </Popover>
  );


  return (
    <Form>
      <div>
        {isTransport ? (
          <h4>
            {t('transportation.Transport material')}
            {' '}
          </h4>
        ) : (
          <h4>
            {t('product.Material')}
            {' '}
          </h4>
        )}
        <Row className="align-items-end">
          <Col xs="3">
            <Form.Group>
              <Form.Label>
                * {t('common.Name')}
                {errors?.includes('name') && <span className="error-text" > {t('project.is required')}</span>}
              </Form.Label>
              <TextField
                element={newMaterial}
                field="name"
                disabled={readOnly}
                placeholder={isTransport || isProduct ? '' : t('materialForm.eg Name')}
                formValidation={errors?.includes('name') ? 'error-glow' : ''}
              />
            </Form.Group>
          </Col>
          <Col xs="3">
            <Form.Group>
              <Form.Label>
                {t('common.Description')}
              </Form.Label>
              <TextField element={newMaterial} field="description" disabled={readOnly} placeholder={isTransport || isProduct ? '' : t('materialForm.eg Description')}/>
            </Form.Group>
          </Col>
          {/* {!isTransport
            ? (
              <> */}
                <Col xs="1">
                  <Form.Group>
                    <Form.Label>
                      {t('database.Module')}
                    </Form.Label>
                    <TextField element={newMaterial} field="module" placeholder={isTransport || isProduct ? '' : t('materialForm.eg Module')}/>
                  </Form.Group>
                </Col>
                <Col xs="2">
                  <Form.Group>
                    <Form.Label>
                      * {t('database.Source')}
                    {errors?.includes('source') && <span className="error-text"> {t('project.is required')}</span>}
                    </Form.Label>
                    <TextField 
                    element={newMaterial} field="source" 
                    disabled={readOnly} 
                    placeholder={isTransport || isProduct ? '' : t('materialForm.eg Source')}
                    formValidation={errors?.includes('source') ? 'error-glow' : ''}
                    />
                  </Form.Group>
                </Col>
                <Col xs="1">
                <Form.Group>
                    <Form.Label>
                      {t('database.Expiration Year')}
                    </Form.Label>
                    <TextField element={newMaterial} field="expirationYear" placeholder={isTransport || isProduct ? '' : t('materialForm.eg Expiration Year')}/>
                  </Form.Group>
                </Col>
                <Col xs="1">
                  <Form.Group>
                    <Form.Label>
                      {t('common.Emission factor')}{' '}
                      <small style={{ textTransform: 'none' }}>
                        ({getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/<span style={{ textTransform: 'uppercase' }}>{t('common.Unit')}</span>)
                      </small>
                    </Form.Label>
                    <TextField element={newMaterial} field="emission" disabled={readOnly} checkNumber={true} />
                  </Form.Group>
                </Col>
                <Col xs="1">
                  <Form.Group>
                    <Form.Label>
                      {t('common.Unit')}
                    </Form.Label>
                    <DropDown element={newMaterial} field="unitId" options={units} optionvalue="id" optiontext="abbreviation" organizationId={organizationId} disabled={readOnly}/>
                  </Form.Group>
                </Col>
              {/* </>
            // ) : ''} */}
        </Row>

        {/* Conditional Error Display */}
        {/* {errors?.length > 0 && (
          <Row>
            {errors.includes('name') ? (
              <Col xs="3"> <Error text={`${t("common.Name")} ${t("project.is required")}`} /> </Col>) : (<Col xs="3"></Col>)
            }
            <Col xs="3"></Col>
            <Col xs="1"></Col>
            {errors.includes('source') ? ( <Col xs="2"> <Error text={`${t("common.Enter source")}`} /> </Col>) : (<Col></Col>)}
          </Row>
        )} */}

        <h4>{t('common.Conversions')}</h4>
        <Table>

          <thead>
            <tr>
              <th>{t('product.From unit')}</th>
              <th>{t('product.To unit')}</th>
              <th>
                {t('common.Factor')}
                {' '}
                <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                  <FaInfoCircle />
                </OverlayTrigger>
              </th>
              <th>{t('common.Comment')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {newMaterial.conversions.map((c, i) => (
              <ConversionRow
                conversion={c}
                key={c.id}
                project={project}
                organizationId={organizationId}
                onChange={(c) => {
                  if (c === 'remove') {
                    handleRemoveConversion(i);
                  } else {
                    newMaterial.conversions[i] = c;
                    setNewMaterial({ ...newMaterial });
                  }
                }}

              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="5">
                <Button onClick={() => { handleAddConversion(); }}>
                  <FaPlus />
                  {' '}
                  {t('product.Add conversion')}
                </Button>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </Form>
  );
});
