import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import Api from '../../services/api';
import loglevel from '../../services/loglevel';
import EulaModal from './EulaModal';
import TokenManager from '../../services/token-manager';

const SettingsContext = React.createContext();

const tokenManager = new TokenManager(Api());

const SettingsStore = ({ children }) => {
  const { t } = useTranslation();
  const emptyUnit = useCallback(() => ({
    id: 0,
    name: '',
    abbreviation: '',
    symbol: '',
  }), []);
  const [error, setError] = useState({
    title: t('common.Error'),
    text: '',
  });

  const [showError, setShowError] = useState(false);
  const [showEulaModal, setShowEulaModal] = useState(false);

  const displayError = useCallback((text, title) => {
    setError({ title: title === undefined ? t('common.Error') : title, text });
    setShowError(true);
  }, [t]);

  const [settings, setSettings] = useState({
    user: {},
    codes: [],
    units: [],
    projectTemplates: [],
    organizations: [],
    currentOrganization: [],
    vehicles: [],
    vessels: [],
    currentRoles: [],
    findUnit: () => emptyUnit,
    getUnitById: () => emptyUnit,
    isInRole: () => false,
    projectTypes: [],
    projectStatuses: [],
    getProjectTypeById: () => t('common.Unknown'),
    getProjectStatusById: () => t('common.Unknown'),
    getProjectPhaseById: () => t('common.Unknown'),
    carbonStockLabels: [],
    displayError,
    getRoadTransport: () => getRoadTransport(),
    getWaterborneTransports: () => getWaterborneTransports(),
  });

  const createAltUnits = useCallback((abbr) => [abbr.replace('²', '2'), abbr.replace('³', '3')], []);

  const findUnit = useCallback((units) => (str) => units.find((u) => {
    const abbr = u.abbreviation;
    const tests = [abbr, ...createAltUnits(abbr)];

    return tests.includes(str);
  }), [createAltUnits]);

  const getUnitById = useCallback((units) => (unitId) => {
    const found = units.find((u) => u.id === unitId);
    if (found !== undefined) {
      return found;
    }
    return emptyUnit();
  }, [emptyUnit]);

  const getUnitByCode = useCallback((units) => (unitCode, organizationId) => {
    // const found = units.filter((u) => u.code === unitCode).filter((f) => f.organizationId === organizationId);
    const found = units.filter(u => u.code === unitCode && u.organizationId === organizationId);
    if (found !== undefined) {
      return found;
    }
    return emptyUnit();
  }, [emptyUnit]);

  const isInRole = useCallback((roles) => (role, projectId = 0, anyProject = false) => {
    let found = false;
    if (Array.isArray(role)) { // if roles are given as array => any of the roles is success
      found = roles.find((r) => role.includes(r.name) && (r.projectId === Number(projectId) || anyProject));
    } else {
      found = roles.find((r) => r.name === role && (r.projectId === Number(projectId) || anyProject));
    }

    if (found === undefined) {
      return false;
    }
    return true;
  }, []);

  const getObjectKeyByValue = (obj) => (val) => {
    const found = Object.keys(obj).find((k) => obj[k] === val);
    if (found) {
      return found;
    }
    return 'Unknown';
  };

  const validateLocale = (localeString) => {
    // Make sure locale is 5
    if (localeString.length !== 5) {
      return 'fi-FI';
    }
    return localeString;
  };

  const { i18n } = useTranslation();

  const logout = async (shouldReturn = false) => {
    await tokenManager.removeToken();
    if (shouldReturn) {
      window.location = `/login?returnUrl=${window.location.pathname}`;
    } else {
      window.location = '/login';
    }
  };

  const getSettings = useCallback(async () => {
    console.time("Time from settings:")
    const getCodes = Api().settings.codes();
    const getProjectTemplates = Api().settings.templates();
    const getUn = Api().settings.units();
    const getCarbonStockLabels = Api().settings.carbonStock();
    const getCurrentOrganization = Api().settings.organization();
    const getRoadTransports = Api().transports().road().get();
    const getWaterborneTransports = Api().transports().waterborne().get();
    const getMachines = Api().machines().get({});
    const getCurrentRoles = Api().accounts.me.roles();
    const getUser = Api().accounts.me.get();
    const getProjects = Api().settings.project();
    const transportTypes = ['Road transport', 'Waterborne transport'];

    const results = await Promise.allSettled([getCodes, getProjectTemplates, getUn, getCarbonStockLabels, getCurrentOrganization, getRoadTransports, getWaterborneTransports, getMachines, getCurrentRoles, getUser, getProjects])
    const resultAfterFullfilled = [];

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        resultAfterFullfilled.push(result.value);
      } else {
        console.error(`Error in call ${index + 1}: `, result.reason);
      }
    });

    const [codes, projectTemplates, un, carbonStockLabels, currentOrganization, roadTransports, waterborneTransports, machines, currentRoles, user, projects] = [...resultAfterFullfilled]
    let units = []
    if (un != undefined || un != null) {
      units = un?.map((u) => ({ ...u, isCubic: u.abbreviation.includes('m³'), isSquare: u.abbreviation.includes('m²') }));
    }

    currentOrganization.defaultLocale = 'en-GB';

    const language = window.localStorage.getItem('language');
    if (language !== null) {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(currentOrganization.defaultLocale.split('-')[0]);
    }

    const {
      types: projectTypes,
      statuses: projectStatuses,
      phases: projectPhases,
    } = projects;
    console.timeEnd("Time from settings:")
    return {
      user,
      codes,
      units,
      projectTemplates,
      machines,
      roadTransports,
      waterborneTransports,
      transportTypes,
      currentRoles,
      currentOrganization,
      findUnit: findUnit(units),
      getUnitById: getUnitById(units),
      getUnitByCode: getUnitByCode(units),
      isInRole: isInRole(currentRoles),
      projectTypes,
      projectStatuses,
      projectPhases,
      getProjectTypeById: getObjectKeyByValue(projectTypes),
      getProjectStatusById: getObjectKeyByValue(projectStatuses),
      getProjectPhaseById: getObjectKeyByValue(projectPhases),
      carbonStockLabels,
      isLoggedIn: user.id > 0,
      logout,
    };
  }, [findUnit, getUnitById, isInRole]);

  const refreshSettings = useCallback(async () => {
    getSettings().then((s) => setSettings((prev) => ({ ...prev, ...s })));
  }, [getSettings]);

  const getRoadTransport = async () => {
    console.log('running from setting')
    const roadTransports = await Api().transports().road().get();
    setSettings((prev) => ({ ...prev, roadTransports: roadTransports }));
  }

  const getWaterborneTransports = async () => {
    console.log('running from setting')
    const waterborneTransports = await Api().transports().waterborne().get();
    setSettings((prev) => ({ ...prev, waterborneTransports: waterborneTransports }));
  }

  const eulaAccept = async () => {
    await Api().accounts.me.update({ ...settings.user, eulaAcceptedVersion: 1 });
    setShowEulaModal(false);
  };

  useEffect(() => {
    // units/codes/user
    loglevel.info('Settings Context initializing...');
    let isMounted = true;
    tokenManager.refreshApiToken().then(async () => {
      getSettings().then((s) => {
        if (isMounted) {
          setSettings((prev) => ({ ...prev, ...s, refreshSettings }));
          if (s.user.id > 0) {
            tokenManager.start(() => {
              loglevel.error('Failed to start');
              logout(true);
            });
            if (s.user.eulaAcceptedVersion < 1) {
              setShowEulaModal(true);
            }
          }
        }
      });
    }).catch((e) => {
      loglevel.error(e);
      logout(true);
    });
    return () => { isMounted = false; };
  }, [getSettings, refreshSettings]);

  return (
    <>
      <Modal show={showError} onHide={() => { setShowError(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error.text}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowError(false)}>{t('common.Close')}</Button>
        </Modal.Footer>
      </Modal>
      <EulaModal
        show={showEulaModal}
        onAccept={() => eulaAccept()}
        onReject={() => logout()}
      />
      <SettingsContext.Provider value={settings}>
        {children}
      </SettingsContext.Provider>
    </>
  );
};

function withSettingsStore(Component) {
  return function ConnectedComponent(props) {
    return (
      <SettingsContext.Consumer>
        {({
          user,
          codes,
          units,
          machines,
          projectTemplates,
          roadTransports,
          waterborneTransports,
          transportTypes,
          findUnit,
          getUnitById,
          getUnitByCode,
          isInRole,
          currentRoles,
          currentOrganization,
          projectTypes,
          projectStatuses,
          projectPhases,
          getProjectTypeById,
          getProjectStatusById,
          getProjectPhaseById,
          carbonStockLabels,
          displayError,
          refreshSettings,
          isLoggedIn,
          logout,
          getRoadTransport,
          getWaterborneTransports
        }) => (
          <Component
            {...props}
            user={user}
            codes={codes}
            units={units}
            machines={machines}
            projectTemplates={projectTemplates}
            roadTransports={roadTransports}
            waterborneTransports={waterborneTransports}
            transportTypes={transportTypes}
            findUnit={findUnit}
            getUnitById={getUnitById}
            getUnitByCode={getUnitByCode}
            projectStatuses={projectStatuses}
            projectTypes={projectTypes}
            projectPhases={projectPhases}
            getProjectTypeById={getProjectTypeById}
            getProjectStatusById={getProjectStatusById}
            getProjectPhaseById={getProjectPhaseById}
            carbonStockLabels={carbonStockLabels}
            currentRoles={currentRoles}
            currentOrganization={currentOrganization}
            isInRole={isInRole}
            displayError={displayError}
            refreshSettings={refreshSettings}
            isLoggedIn={isLoggedIn}
            logout={logout}
            getRoadTransport={getRoadTransport}
            getWaterborneTransports={getWaterborneTransports}
          />
        )}
      </SettingsContext.Consumer>
    );
  };
}

export { SettingsContext, SettingsStore, withSettingsStore };
