import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import './EulaModal.scss';

import EulaEn from '../../i18n/en.eula';
import EulaFi from '../../i18n/fi.eula';
/**
 * Component for showing EULA to the user
 *
 * @component
 * @example
 * const show = true
 * const onAccept = e => { console.log('Eula accepted with event', e) }
 * const onReject = e => { console.log('Eula rejected with event', e) }
 * return (
 *   <EulaModal show={show} onAccept={onAccept} onReject={onReject} />
 * )
 */
const EulaModal = ({ show, onAccept, onReject }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} size="xl">
      <Modal.Header>
        <Modal.Title>{t('eula.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('eula.disclaimer')}</p>
        <div  readonly style={{ minHeight: '300px' }}>
          <EulaEn></EulaEn>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onReject}>{t('eula.reject')}</Button>
        <Button onClick={onAccept}>{t('eula.accept')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

EulaModal.propTypes = {
  /**
   * Modal's visibility status
   */
  show: PropTypes.bool,
  /**
   * function to be called after clicking on 'I Accept' button
   */
  onAccept: PropTypes.func.isRequired,
  /**
   * function to be called after clicking on 'I Reject' button
   */
  onReject: PropTypes.func.isRequired,
};

// Some props are optional. Set default values for them.
EulaModal.defaultProps = {
  show: false,
};

export default EulaModal;
