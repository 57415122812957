import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FiRefreshCw } from 'react-icons/fi';
import { withSettingsStore } from '../common/settings-context';
import { humanize } from '../common/helpers';
import './Forms.scss';

const CalculationHeader = withSettingsStore(({
  totalEmissionType,
  element,
  getUnitById,
  children,
  isValid,
  saveCalculations,
  organizationId,
  getUnitByCode
}) => {
  const { t } = useTranslation();

  // const tooltipUpdateCalculations = (
  //   <Tooltip id="tooltip-update-calculations">
  //     {t('element.Update calculations')}
  //   </Tooltip>
  // );

  return (
    <div className="calculation-header">
      <div className="calculation-header__name">
        <h2 className="calculation-header__title h5">
          {element.name}
        </h2>
        {element.description && <p className="text-muted">{element.description}</p>}
      </div>
      <div className="calculation-header__values">
        <div>
          <h3 className="h6">{t('common.Quantity')}</h3>
          <span className="value">
            {`${element.quantity} ${getUnitById(element.unitId).symbol}`}
          </span>
          {children}
        </div>
        <div>
          <h3 className="h6">{`${t('common.Total emission')} `}</h3>
          <div className="text-primary value mb-3">
            <strong>
              {`${humanize.amount_long(element[totalEmissionType], 2)} `}
              <small style={{ textTransform: 'none' }}>
                {getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}
              </small>
            </strong>
            {/* <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipUpdateCalculations}>
              <Button
                size="sm"
                className="ml-2"
                disabled={!isValid}
                onClick={() => saveCalculations({ preview: true })}
              >
                <FiRefreshCw />
                <span className="sr-only">{t('element.Update calculations')}</span>
              </Button>
            </OverlayTrigger> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default CalculationHeader;
