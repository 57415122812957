import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  Button,
  Card,
  Modal,
  ButtonGroup,
  Form,
  Spinner,
  Media,
  Nav,
  Dropdown,
  Alert,
} from 'react-bootstrap';
import {
  FaPlus, FaTrash, FaPen, FaWallet, FaFileImport, FaCopy, FaTrailer,
} from 'react-icons/fa';
import { HiOutlineClipboardList } from 'react-icons/hi';
import { FiInfo } from 'react-icons/fi';
import Api from '../../../services/api';
import { Typeahead } from '../../common/components';
import ElementTable from './ElementTable';
import ProductTable from './ProductTable';
import { defaultHandleChange, clone } from '../../common/helpers';
import ImportForm from './ImportForm';
import ProductForm from '../../CalculationsForms/ProductForm';
import TransportationForm from '../../CalculationsForms/TransportationForm';
import InstallationForm from '../../CalculationsForms/InstallationForm';
import { withSettingsStore } from '../../common/settings-context';

import './Scenarios.scss';
import { emptyElementTransport } from '../../common/initializers';
import GenericForm from '../../CalculationsForms/GenericForm';

const CopyScenarioForm = ({ scenarios, onChange }) => {
  const { t } = useTranslation();
  const [copyScenario, setCopyScenario] = useState({
    name: '', description: '', id: null, projectId: null,
  });
  const [useExisting, setUseExisting] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCopyScenario((prev) => ({ ...prev, [name]: value }));
  };
  const searchScenarios = async ({ search }) => scenarios.filter((s) => (s.name.toLowerCase().includes(search.toLowerCase()) || (`${s.description}`).toLowerCase().includes(search.toLowerCase())));

  const handleScenarioSelect = (s) => {
    setCopyScenario((prev) => {
      prev.name = s.name;
      prev.description = s.description;
      prev.id = s.id;
      return { ...prev };
    });
  };
  useEffect(() => {
    onChange(copyScenario);
  }, [copyScenario, onChange]);

  useEffect(() => {
    setCopyScenario((prev) => ({
      ...prev, id: useExisting ? prev.id : null, name: '', description: '',
    }));
  }, [useExisting]);

  return (
    <form>
      <div onChange={(e) => setUseExisting(e.target.value === 'true')}>
        <input
          type="radio"
          name="use-existing"
          id="use-existing-false"
          value="false"
          checked={!useExisting}
        />
        <label htmlFor="use-existing-false" className="ml-1 mr-2">{t('scenario.Create new')}</label>
        <input
          type="radio"
          name="use-existing"
          id="use-existing-true"
          value="true"
          checked={useExisting}
        />
        <label htmlFor="use-existing-true" className="ml-1">{t('scenario.Use existing')}</label>
      </div>
      {useExisting && <Typeahead name="name" value={copyScenario.name} onSelect={(scen) => { handleScenarioSelect(scen); }} source={searchScenarios} resultText="{name}" onBlur={handleInputChange} />}
      {!useExisting && <Form.Control type="text" defaultValue={copyScenario.name} name="name" onBlur={handleInputChange} placeholder={t('common.Name')} />}
    </form>
  );
};

const Scenario = withSettingsStore((props) => {
  const { t } = useTranslation();
  const {
    onAdd, displayError, roadTransports, currentOrganization, project
  } = props;
  const emptyElement = () => ({
    name: '',
    description: '',
    code: '1000',
    quantity: 0,
    unitId: 0,
    totalEmission: 0,
    transports: [],
    products: [],
    installations: [],
    generic: [],
    totalTransportEmission: 0,
    totalInstallationEmission: 0,
    totalProductEmission: 0,
    totalInstalledProductsEmission: 0,
    totalMaintenanceEmission: 0,
    totalRepairEmission: 0,
    totalReplacementEmission: 0,
    totalRefurbishmentEmission: 0,
    totalOPEnergyEmission: 0,
    totalOPWaterEmission: 0,
    totalOtherOPEmission: 0,
    totalUserUtilizationEmissions: 0,
    totalDeconstructionEmission: 0,
    totalTransportCEmission: 0,
    totalWasteProcessingEmission: 0,
    totalDisposalEmission: 0,
    totalEmissionPercentage: 0,
  });

  const getDefaultViewMode = useCallback(() => {
    const viewSetting = currentOrganization.settings.find((s) => s.name === 'default_scenario_view');
    if (viewSetting !== undefined) {
      return viewSetting.value;
    }
    return 'all';
  }, [currentOrganization]);
  // console.log(currentOrganization);

  // scenarioId is used to fetch certain scenario
  // scenario is the whole scenario object
  // elements are elements attached to this scenario
  const scenarioId = Number(props.scenario.id);
  const [scenario, setScenario] = useState({
    name: '',
    description: '',
    elements: [],
    ...props.scenario
  });
  const [editedScenario, setEditedScenario] = useState({
    name: '',
    description: '',
    elements: [],
    ...clone(props.scenario)
  });
  const [loading, setLoading] = useState(false);
  const [copying, setCopying] = useState(false);

  const [viewMode, setViewMode] = useState(getDefaultViewMode() === 'products' ? 1 : 0);
  const [importing, setImporting] = useState(false);
  const [importedElements, setImportedElements] = useState([]);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [selectedElements, setSelectedElements] = useState([]);
  const [showDeleteScenarioDialog, setShowDeleteScenarioDialog] = useState(false);
  const [showProductCalcDialog, setShowProductCalcDialog] = useState(false);
  const [showInstallationCalcDialog, setShowInstallationCalcDialog] = useState(false);
  const [showGenericCalcDialog, setShowGenericCalcDialog] = useState(false);
  const [showTransportationCalcDialog, setShowTransportationCalcDialog] = useState(false);
  const [showCopyElementsDialog, setShowCopyElementsDialog] = useState(false);
  const [showRemoveElementsDialog, setShowRemoveElementsDialog] = useState(false);
  const [copyScenario, setCopyScenario] = useState({
    name: '', description: '', id: null, projectId: null,
  });
  const [editedElement, setEditedElement] = useState(emptyElement());
  const [elements, setElements] = useState([
    ...(props.scenario === undefined ? [] : props.scenario.elements),
  ]);
  const [showScenarioDialog, setShowScenarioDialog] = useState(false);
  const [scenarioError, setScenarioError] = useState(null);

  // check and handle changes
  const onChange = function (action) {
    if (typeof props.onChange === 'function') {
      props.onChange(editedScenario);
    }
  };

  // Handlers for clicks
  const handleRemove = async (s) => {
    await Api().scenarios(s.id).delete({ id: s.id });

    // Check if groups need to be removed.
    const projectScenarios = await Api().scenarios().get({ id: project.id });
    const filteredScenarios = projectScenarios.filter((s) => s.projectId === project.id)
    if (filteredScenarios.length === 0) {
      project.groups = []
      await Api().projects(project.id).update(project);
    }

    typeof props.onDelete === 'function' && props.onDelete(s.id);
    setShowDeleteScenarioDialog(false);
  };

  const handleCopy = async () => {
    setCopying(true);
    try {
      let newScenarioId = copyScenario.id ? copyScenario.id : 0;
      if (newScenarioId === 0) {
        copyScenario.description = scenario.description
        var newScenario = await Api().scenarios().post({
          name: copyScenario.name,
          description: copyScenario.description,
          projectId: props.project.id,
        });
        newScenarioId = newScenario.id;
      }

      // two ways of copying: all or selected
      let copiedElements = [];
      if (selectedElements.length === 0) {
        // all
        const tempElements = await Api().scenarios(scenarioId).elements().get();
        copiedElements = [...tempElements];

      } else {
        // selected
        copiedElements = [...selectedElements];
      }

      await Api().scenarios(newScenarioId).elements().post([...copiedElements.map((element) => {
        element.scenarioId = newScenarioId;
        element.id = undefined;
        // element.groupId = props.project.groups.groupId;
        return element;
      })]);
      typeof onAdd === 'function' && onAdd(newScenario);
      setShowCopyElementsDialog(false);
      getElements();
    } catch {
      displayError(t('scenario.Unable to copy scenario'));
      setCopying(false);
    }
    setCopying(false);
  };

  useEffect(() => {
    return () => {
      setScenarioError(null)
    }
  },[editedScenario.name])

  const handleEdit = async () => {
    if(editedScenario.name.length > 0) {
      const updatedScenario = await Api().scenarios(editedScenario).update(editedScenario);
      setShowScenarioDialog(false);
      setScenario(updatedScenario);
    }else{
      setScenarioError('Please enter new scenario name')
    }
    // getScenarios();
  };

  const [showElementImportDialog, setShowElementImportDialog] = useState(false);
  // Import Form validation
  const [validImport, setValidImport] = useState(false);
  const [showErrorRows, setShowErrorRows] = useState(false);
  const handleElementImport = async () => {
    setImporting(true);

    // Handle groups (create them if needed)
    const projectGroups = props.project.groups;
    // const parentId = importedElements[0].parentGroupId !== null ? Number(importedElements[0].parentGroupId) : null;
    const importGroups = [...new Set(importedElements.map((e) => e.groupName))].map((groupName) => {
      const existing = projectGroups.find((g) => g.name === groupName
        // && g.parentId === parentId
      );

      if (existing) {
        return existing;
      }
      return {
        name: groupName,
        // parentId,
        description: '',
        id: undefined,
        code: '',
      };
    }).filter((g) => g.name !== undefined && g.name !== '' && g.name !== null);
    props.project.groups = [...props.project.groups, ...importGroups.filter((g) => g.id === undefined)];

    props.setProject({ ...props.project });
    const updatedProject = await Api().projects(props.project.id).update(props.project);
    props.setProject(updatedProject);

    // update elements groupId
    setImportedElements(importedElements.map((e) => {
      e.id = undefined;
      // e.groupId = updatedProject.groups.find((g) => g.name === e.groupName && g.parentId === parentId);
      e.groupId = updatedProject.groups.find((g) => g.name === e.groupName);
      if (e.groupId !== undefined) {
        e.groupId = e.groupId.id;
      }
      if (e.code == null) {
        e.code = '';
      }
      e.templateId = null;
      if (e.template !== undefined && e.template !== null && e.template !== false) {

        e.installations = e.template.installations;
        e.generic = e.template.generic;
        e.products = e.template.products;
        e.transports = e.template.transports;
        e.transports.forEach((t) => t.totalDistance = e.transportDistance);
        e.preConstructionStatus = 0; 
        e.productStatus = 1; // draft
        e.installationStatus = 1; // draft
        e.transportStatus = 1; // draft
        e.installedProductsStatus = 0;
        e.maintenanceStatus = 0;
        e.repairStatus = 0;
        e.replacementStatus = 0;
        e.refurbishmentStatus = 0;
        e.operationalEnergyStatus = 0;
        e.operationalWaterStatus = 0;
        e.otherOperationsStats = 0;
        e.userUtilizationsStatus = 0;
        e.deconstructionStatus = 0;
        e.transportCStatus = 0;
        e.wasteProcessingStatus = 0;
        e.disposalStatus = 0;
        e.templateId = e.template.id;
        e.code = e.template.code;
      }

      if (e.template === null || e.template === undefined || e.template === false) {
        if (e.transports === undefined || e.transports.length === 0) {
          e.transports = [emptyElementTransport()];
        }
        const originalDistance = e.transports[0].totalDistance;
        e.transports[0].totalDistance = e.transportDistance;
        if (Number.isNaN(e.transports[0].totalDistance)) {
          e.transports[0].totalDistance = originalDistance;
        }
        if (e.transports[0].transportRoadId === null) {
          const nonLegacyVehicle = roadTransports.find(rt => rt.organizationId === project.organizationId && !rt.legacyVehicle);

          if (nonLegacyVehicle) {
            e.transports[0].transportRoadId = nonLegacyVehicle.id;
          } else {
            e.transports[0].transportRoadId = roadTransports[0].id;
          }
        }
      }
      return e;
    }));

    // await Promise.all(importedElements.map(async e => await Api().scenarios.elementPost(e)))
    try {
      await Api().scenarios(scenario.id).elements().post(importedElements);
      console.log(importedElements);
    } catch {
      displayError(t('Unable to import scenario'));
      setImporting(false);
    }
    setShowElementImportDialog(false);
    setImporting(false);
    setShowErrorRows(false);

    getElements();
    onChange();
  };
  // console.log(elements);

  const handleChange = (event) => {
    defaultHandleChange(event, setEditedScenario);
    onChange(editedScenario);
  };

  const handleAddGroup = async () => {
    props.project.groups = [...props.project.groups, {
      name: t('scenario.New group'),
      description: '',
      code: '',
    }];
    props.setProject({ ...props.project });
    const updatedProject = await Api().projects(props.project.id).update(props.project);
    props.setProject(updatedProject);
  };

  const getElements = useCallback(async () => {
    setLoading(true);
    setElements(await Api().scenarios(scenarioId).elements().get());
    setLoading(false);
  });

  const handleProductCalculations = () => {
    setShowProductCalcDialog(true);
  };

  const handleInstallationCalculations = () => {
    setShowInstallationCalcDialog(true);
  };

  const handleTransportationCalculations = () => {
    setShowTransportationCalcDialog(true);
  };

  const onCheckChange = useCallback((event, element) => {
    const { id } = element;
    const item = element;
    const isChecked = event.currentTarget.checked;
    setCheckedItems((prevState) => ({ ...prevState, [id]: isChecked }));

    if (isChecked) {
      setSelectedElements((prev) => [...prev, item]);
    } else {
      setSelectedElements((prev) => [...prev.filter((e) => e !== item)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElements]);

  const onElementsChange = useCallback((elements) => {
    setElements(elements);
  }, [elements]);

  const saveCalculations = async (closeDialog) => {
    const editedElements = [];
    Array.isArray(selectedElements) && selectedElements.forEach((item) => {
      if (showTransportationCalcDialog === true) {
        item.transports = editedElement.transports;
      }
      if (showProductCalcDialog) {
        item.products = editedElement.products;
      }
      if (showInstallationCalcDialog) {
        item.installations = editedElement.installations;
      }
      if (showGenericCalcDialog) {
        item.generic = editedElement.generic;
      }
      editedElements.push(item);
    });
    await Api().scenarios(scenarioId).elements().post(editedElements);
    setEditedElement(emptyElement());
    if (closeDialog === true) {
      setShowTransportationCalcDialog(false);
      setShowProductCalcDialog(false);
      setShowInstallationCalcDialog(false);
    }
    setCheckedItems([]);
    setSelectedElements([]);
    getElements();
  };

  const handleClearSelections = () => {
    setCheckedItems(new Map());
    setSelectedElements([]);
  };
  const handleRemoveSelectedElements = async () => {
    await Api().scenarios(scenarioId).elements().delete(selectedElements.map((e) => e.id));
    setElements([...elements.filter((e) => !selectedElements.map((se) => se.id).includes(e.id))]);
    handleClearSelections();
    getElements();
  };

  useEffect(() => {
    if (selectedElements.length > 0) {
      setEditedElement({
        ...selectedElements[0],
        quantity: 0,
        products: selectedElements.map((e) => e.products).reduce((acc, cur) => [...acc, ...cur]),
        transports: selectedElements.map((e) => e.transports).reduce((acc, cur) => [...acc, ...cur]),
        installations: selectedElements.map((e) => e.installations).reduce((acc, cur) => [...acc, ...cur]),
        generic: selectedElements.map((e) => e.generic).reduce((acc, cur) => (acc !== undefined && cur !== undefined) ? [...acc, ...cur] : [acc, cur]),
      });
    }


  }, [selectedElements]);

  const [allScenarios, setAllScenarios] = useState([])

  const setScenarios = useCallback(async () => {
    //Retrieve all scenarios in the database
    const retrievedScenarios = await Api().scenarios().get({});

    // Filter scenarios based on the project ID
    const projectScenarios = retrievedScenarios.filter((s) => s.projectId === props.project.id);

    // Set the filtered scenarios to state
    setAllScenarios(projectScenarios);
  }, [props.project.id]);


  console.log(scenario);

  useEffect(() => {
    if (props.selectedScenario.id === scenario.id) {
      getElements();
      setScenarios();
    }
  }, [props, props.selectedScenario]);

  // Set show errors to false when there is no errors
  useEffect(() => {
    if (validImport) {
      setShowErrorRows(false);
    }
  }, [validImport]);

  return (
    <div className="mb-2">
      <Modal
        dialogClassName="modal--lg"
        show={showElementImportDialog}
        onHide={() => { setShowElementImportDialog(false); }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('element.Import elements')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportForm
            projectType={props.project.type}
            groups={props.project.groups}
            onChange={(e) => { setImportedElements(clone(e)); }}
            setValidImport={setValidImport}
            importing={importing}
            showErrorRows={showErrorRows}
            project={props.project}
          />
        </Modal.Body>
        {importedElements.length > 0 && (
          <Modal.Footer>
            {!validImport && (
              <div className="d-flex flex-column">
                <small className="d-flex align-items-center text-danger mr-3">
                  <FiInfo className="mr-1" />
                  {t('import.Check for invalid rows')}
                </small>
                <Form.Check
                  type="switch"
                  id="error-rows-switch"
                  label={t('import.Show only rows with errors')}
                  onChange={() => setShowErrorRows(!showErrorRows)}
                  className="mr-4"
                />
              </div>
            )}
            <Button
              disabled={!validImport || importing}
              onClick={() => { handleElementImport(); }}
            >
              {importing && <Spinner className="mr-1" variant="light" size="sm" animation="border" />}
              {t('element.Import elements')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={showScenarioDialog} onHide={() => { setShowScenarioDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('scenario.Edit scenario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>{t('common.Name')}</Form.Label>
              <Form.Control name="name" defaultValue={editedScenario.name} type="text" placeholder={t('common.Enter name')} onChange={handleChange} />
            </Form.Group>
            {scenarioError && <Alert variant="danger">{scenarioError}</Alert>}
            <Form.Group controlId="formDescription">
              <Form.Label>{t('common.Description')}</Form.Label>
              <Form.Control name="description" defaultValue={editedScenario.description} type="text" placeholder={t('common.Enter description')} onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleEdit()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProductCalcDialog} onHide={() => { setShowProductCalcDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('product.Product calculations')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm element={editedElement} onChange={(e) => setEditedElement(e)} project={project} organizationId={props.project.organizationId} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveCalculations(true)}>{t('common.Save and close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTransportationCalcDialog} onHide={() => { setShowTransportationCalcDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('transportation.Transportation emissions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransportationForm element={editedElement} onChange={(e) => setEditedElement(e)} organizationId={props.project.organizationId} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveCalculations(true)}>{t('common.Save and close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showInstallationCalcDialog} onHide={() => { setShowInstallationCalcDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('installation.Installation emissions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstallationForm element={editedElement} onChange={(e) => setEditedElement(e)} organizationId={props.project.organizationId} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveCalculations(true)}>{t('common.Save and close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showGenericCalcDialog} onHide={() => { setShowGenericCalcDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('Generic calcs')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GenericForm element={editedElement} onChange={(e) => setEditedElement(e)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveCalculations(true)}>{t('common.Save and close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCopyElementsDialog} onHide={() => { setShowCopyElementsDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>{t('scenario.Copy scenario')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedElements.length > 0 && <Alert variant="info">{t('You are copying {{i}} elements', { i: selectedElements.length })}</Alert>}
          <CopyScenarioForm scenarios={allScenarios} onChange={(s) => setCopyScenario(s)} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCopy()}>{t('scenario.Copy and close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteScenarioDialog} onHide={() => { setShowDeleteScenarioDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('scenario.Delete scenario')}
            {' '}
            {scenario.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {scenario.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteScenarioDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button onClick={() => handleRemove(scenario)}>{t('common.Delete')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRemoveElementsDialog} onHide={() => { setShowRemoveElementsDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('common.Remove')}
            {' '}
            {t('scenario.rows')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {selectedElements.length}
            {' '}
            {t('scenario.rows')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowRemoveElementsDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button onClick={() => { handleRemoveSelectedElements(); setShowRemoveElementsDialog(false); }}>{t('common.Delete')}</Button>
        </Modal.Footer>
      </Modal>

      <Accordion activeKey={props.selectedScenario !== undefined ? props.selectedScenario.id : 0}>
        <Card.Header className="scenario-header">
          <Accordion.Toggle as={Media} className="scenario-toggle" variant="link" eventKey={scenario.id} onClick={() => props.setSelectedScenario((s) => (s.id !== scenario.id ? scenario : s))}>
            {loading && (
              <Spinner variant="primary" animation="border" role="status" className="mr-4" size="lg">
                <span className="sr-only">{t('common.Loading')}</span>
              </Spinner>
            )}
            {!loading && <div className="icon"><HiOutlineClipboardList size="40" className="mr-3" /></div>}
            <Media.Body>
              <div className="d-flex align-items-center flex-wrap">
                <h2 className="h5 mb-0 mr-2">{scenario.name}</h2>
                <Button size="sm" variant="link" onClick={() => { setShowScenarioDialog(true); }}>
                  <FaPen />
                  {' '}
                  {t('common.Edit')}
                </Button>
                <Button size="sm" variant="link" onClick={() => { setShowCopyElementsDialog(true); }}>
                  {copying === false ? (<FaCopy />) : (<Spinner variant="primary" size="sm" animation="border" />)}
                  {' '}
                  {t('common.Copy')}
                </Button>
                <Button size="sm" variant="link" className="text-danger" onClick={() => { setShowDeleteScenarioDialog(true); }}>
                  <FaTrash />
                  {' '}
                  {t('common.Delete')}
                </Button>
              </div>
              <p className="mb-0">{scenario.description}</p>
            </Media.Body>
          </Accordion.Toggle>
          <ButtonGroup>
            <Button variant={viewMode === 0 ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => setViewMode(0)}>{t('common.All')}</Button>
            <Button variant={viewMode === 1 ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => setViewMode(1)}>{t('product.Products')}</Button>
          </ButtonGroup>
        </Card.Header>
        {selectedElements.length > 0 && (
          <Nav className="mass-update">
            <ButtonGroup className="mr-2" size="sm">
              <Button variant="danger" onClick={(e) => { setShowRemoveElementsDialog(true); e.stopPropagation(); }}>
                {t('common.Remove')}
                {' '}
                {selectedElements.length}
                {' '}
                {t('scenario.rows')}
                {' '}
              </Button>
            </ButtonGroup>
            <ButtonGroup className="mr-2" size="sm">
              <Dropdown>
                {/* <Dropdown.Toggle variant="primary" id="dropdown-mass-update">
                  {t('scenario.Mass update')}
                  {' '}
                  {selectedElements.length}
                  {' '}
                  {t('scenario.rows')}
                </Dropdown.Toggle> */}
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { handleProductCalculations(); }}>
                    <FaWallet />
                    {' '}
                    {t('scenario.Mass product update')}
                    {' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => { handleTransportationCalculations(); }}>
                    <FaTrailer />
                    {' '}
                    {t('scenario.Mass transportation update')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => { handleInstallationCalculations(); }}>
                    <FaCopy />
                    {' '}
                    {t('scenario.Mass installation update')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
            <Button size="sm" variant="outline-primary" onClick={() => handleClearSelections()}>{t('scenario.Clear selections')}</Button>
          </Nav>
        )}
        <Accordion.Collapse eventKey={scenario.id}>
          <Card>
            <ElementTable
              elements={elements}
              project={props.project}
              scenarioId={scenarioId}
              setProject={props.setProject}
              onCheckChange={onCheckChange}
              onChange={onElementsChange}
              checkedItems={checkedItems}
              organizationId={props.project.organizationId}
            />
            {viewMode === 1 && props.project !== undefined && scenarioId !== undefined && (
              <ProductTable
                organizationId={props.project.organizationId}
                elements={elements}
                project={props.project}
                scenarioId={scenarioId}
                setProject={props.setProject}
                onChange={() => getElements()}
                onSelect={(ids) => setSelectedElements(
                  elements.filter((e) => e.products.map((p) => ids
                    .includes(p.id))
                    .includes(true)),
                )}
              />
            )}
            <Card.Footer>
              <ButtonGroup>
                <Button variant="outline-primary" onClick={() => { handleAddGroup(); }}>
                  <FaPlus />
                  {' '}
                  {t('scenario.Add group')}
                </Button>
                <Button variant="primary" onClick={() => { setShowElementImportDialog(true); }}>
                  {importing === false ? (<FaFileImport />) : (<Spinner variant="primary" size="sm" animation="border" />)}
                  {' '}
                  {t('common.Import')}
                </Button>
              </ButtonGroup>
            </Card.Footer>
          </Card>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
});
export default Scenario;
