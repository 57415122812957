import React, {
  useState, useRef, useMemo, useEffect, Fragment,
} from 'react';
import { Marker, MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';

function DraggableMarker({ center, enabled, onChange }) {
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          onChange !== undefined && onChange(marker.getLatLng());
        }
      },
    }),
    [onChange],
  );

  return (
    <Marker
      draggable={enabled}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    />
  );
}

const Map = ({
  position, editable, onMarkerChange, height, width, noControls = false,
}) => {
  if (height === undefined) {
    height = 400;
  }
  const [map, setMap] = useState(null);
  const [localPosition, setLocalPosition] = useState(position);
  useEffect(() => {
    if (position !== undefined) {
      if ((position.lat === 0 && position.lng === 0) || (position.lat === null && position.lng === null)) {
        setLocalPosition({
          lng: 24.945831,
          lat: 60.192059,
        });
      }
    }
  }, [position]);

  useEffect(() => {
    if (map !== null) {
      try {
        //ToDo: Find out a fix for this.
        map.invalidateSize();
      } catch (e) {
        console.error(e);
      }

    }
  }, [map, position]);

  return (
    <>
      {localPosition !== undefined
        && (
          <MapContainer
            key={localPosition.lat + localPosition.lng}
            zoomControl={!noControls}
            dragging={!noControls}
            center={localPosition}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: `${height}px` }}
            ref={setMap}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker center={localPosition} enabled={!!editable} onChange={onMarkerChange} />
          </MapContainer>
        )}
    </>
  );
};
export default Map;
