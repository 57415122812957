import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { FiMail } from 'react-icons/fi';
import Api from '../../services/api';

import './news.scss';
import {SettingsContext} from '../common/settings-context';

const News = () => {
  const [news, setNews] = useState([]);
  const { user } = useContext(SettingsContext);

  useEffect(() => {
    Api().news.get().then((n) => setNews(n));
  }, []);
  // General = 0,
  // Alert = 1,
  // Update = 2
  const parsedNews = useMemo(() => news
  .filter((n) => n.type === 2 || (n.body && n.body.trim() !== ""))
  .map((n) => ({
    ...n,
  })), [news]);

  if (user.username === 'Public Access User') {
    return null;
  }

  return (
    <div className="news">
      {parsedNews.map((n) => (
        <Card key={n.id}>
          <Card.Body>
            <Card.Title>
              <h6>{n.title}</h6>
            </Card.Title>
            <Card.Text>
              {n.body}
            </Card.Text>
            {n.type === 0
              && (
              <Card.Text className="contact-email">
                <FiMail />
                {' '}
                zeroinfra@ramboll.fi
              </Card.Text>
              )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default News;