import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  Button,
  Table,
  FormControl, ButtonGroup, InputGroup, ToggleButton,
  Alert, Modal
} from 'react-bootstrap';
import {
  FaTrash, FaPlus, FaDivide, FaTimes,
} from 'react-icons/fa';
import { withSettingsStore } from '../common/settings-context';
import loglevel from '../../services/loglevel';
import Error from '../common/Error';
import { clone } from '../common/helpers';
import Api from '../../services/api';
import './Database.scss'

const ConversionRow = withSettingsStore(({ units, conversion, onChange, organizationId, editable, hideTrash}) => {
  const { t } = useTranslation();
  const [messageConversionUsed, setMessageConversionUsed] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleChange = (element) => (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'factor') {
      // Check if value is a valid number
      if (!isNaN(value)) {
        // Convert value to a number
        const floatValue = parseFloat(value);
        element[name] = floatValue.toString(); // Convert back to string for consistency
        onChange(element);
      } else {
        alert(t("database.Constraint Error"));
      }
    } else {
      // For other fields, directly update the element
      if (name === 'inverse') {
        element[name] = event.currentTarget.checked;
      } else {
        element[name] = value;
      }
      onChange(element);
    }
  };


  const TextField = ({ element, field, placeholder, checkNumber }) => (<Form.Control type={`${checkNumber ? "number" : "text"}`} defaultValue={element[field]} name={field} onBlur={handleChange(element)} placeholder={placeholder} className="database-forms-placeholder" disabled={!editable}/>);

  const DropDown = ({
    element, field, options, optionvalue, optiontext, organizationId
  }) => {
    const filteredOptions = options.filter(option => option.organizationId === organizationId);
    let defaultUnit = filteredOptions[0]
    if (element[field] !== 0) {
      defaultUnit = element[field];
    }
    return (
      <FormControl as="select" name={field} defaultValue={defaultUnit} onChange={handleChange(element)} disabled={!editable}>
        {filteredOptions.map((u, i) => (<option key={u[optionvalue]} value={u[optionvalue]}>{u[optiontext]}</option>))}
      </FormControl>
    )
  };
  const checkConversionLink = async (id) => {
    const result = await Api().machines().conversionUsedInIP(id);
    if (result.data === true) {
      setMessageConversionUsed(true);
    } else {
      setDeleteConfirmation(true);
    }
  }
  return (
    <>
      <Modal show={deleteConfirmation} onHide={() => { setDeleteConfirmation(false); }} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('database.Delete Conversion')}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}            
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setDeleteConfirmation(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button variant="outline-danger" onClick={() => { onChange('remove'); setDeleteConfirmation(false); }}>
            <FaTrash />
            {' '}
            {t('common.Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <tr>
        <td><DropDown element={conversion} field="fromUnitId" options={units} optionvalue="id" optiontext="abbreviation" organizationId={organizationId} /></td>
        <td><DropDown element={conversion} field="toUnitId" options={units} optionvalue="id" optiontext="abbreviation" organizationId={organizationId} /></td>
        <td>
          <InputGroup>
          <TextField element={conversion} field="factor" checkNumber={true} />
            <ButtonGroup toggle>
              <ToggleButton
                style={{ zIndex: 0 }}
                type="checkbox"
                variant="primary"
                checked={conversion.inverse}
                name="inverse"
                onChange={handleChange(conversion)}
                title={t('Use multiplicative inverse')}
                disabled={!editable}
              >
                {conversion.inverse && <FaDivide />}
                {!conversion.inverse && <FaTimes />}
              </ToggleButton>
            </ButtonGroup>
          </InputGroup>
        </td>
        <td><TextField element={conversion} field="comment" /></td>
        <td>
          <ButtonGroup>
            <Button variant="outline-danger" disabled={ editable ? false : hideTrash } onClick={() => { checkConversionLink(conversion.id) }}><FaTrash /></Button>
          </ButtonGroup>
        </td>
      </tr>
      <tr>
        {messageConversionUsed ? <Alert variant='danger' dismissible onClose={() => setMessageConversionUsed(false)}>{t('database.Can not delete this conversion')}</Alert> : null}
      </tr>
    </>
  );
});

/*const EmissionRow = withSettingsStore(({ units, conversion: emission, onChange, organizationId, readOnly }) => {
  const handleChange = (element) => (event) => {
    const { name } = event.currentTarget;
    const { value } = event.currentTarget;

    element[name] = value;
    onChange(element);
  };*/


const EmissionRow = withSettingsStore(({ units, conversion: emission, onChange, organizationId, readOnly, getUnitByCode, isInstallation, hideTrash, editEmissionDetails }) => {
  const { t } = useTranslation();
  const handleChange = (event, element) => {
    const { name, value } = event.currentTarget;

    if (name === 'emission') {
      // Check if value is a number
      if (!isNaN(value)) {
        // Convert value to a number
        const floatValue = parseFloat(value);
        element[name] = floatValue.toString(); // Convert back to string for consistency
        onChange(element);
      } else {
        alert(t("database.Constraint Error"));
      }
    } else {
      // For other fields, directly update the element
      element[name] = value;
      onChange(element);
    }
  };

  const TextField = ({ element, field, disabled, placeholder, checkNumber }) => (<Form.Control type={`${checkNumber ? "number" : "text"}`} defaultValue={element[field]} name={field} onChange={(event) => handleChange(event, element)} disabled={disabled} placeholder={placeholder} className="database-forms-placeholder" />);
  const DropDown = ({
    element, field, options, optionvalue, optiontext, organizationId, disabled
  }) => {
    const filteredOptions = options.filter(option => option.organizationId === organizationId);

    let defaultUnit = filteredOptions[0]
    if (element[field] !== 0) {
      defaultUnit = element[field];
    }
    return (
      <FormControl as="select" name={field} defaultValue={defaultUnit} onChange={(event) => handleChange(event, element)} disabled={disabled}>
        {filteredOptions.map((u, i) => {
          return (
            <option key={u[optionvalue]} value={u[optionvalue]}>{u[optiontext]}</option>
          )
        })}
      </FormControl>
    )
  };

  return (
    <tr>
      <td><DropDown disabled={!editEmissionDetails} element={emission} field="fromUnitId" options={units.map((u) => ({ ...u, longtext: `${getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}/${u.symbol}` }))} optionvalue="id" optiontext="longtext" organizationId={organizationId} /></td>
      <td><TextField disabled={!editEmissionDetails} element={emission} field="emission" checkNumber={true}/></td>
      <td><TextField disabled={!editEmissionDetails} element={emission} field="source" placeholder={isInstallation ? '' : t('machineForm.eg Source')} /></td>
      <td><TextField disabled={!editEmissionDetails} element={emission} field="comment" /></td>
      <td>
        <ButtonGroup>
          <Button variant="outline-danger" onClick={() => { onChange('remove'); }} disabled={!hideTrash}><FaTrash /></Button>
        </ButtonGroup>
      </td>
    </tr>
  );
});

export default withSettingsStore(({

  machine, onChange, units, fromUnitId, organizationId, readOnly, editable, hideAddEmission, hideTrash, errors, editMachineDetails, isInstallation, isInRole, editEmissionDetails

}) => {
  const { t } = useTranslation();
  const filteredUnits = units.filter(unit => unit.organizationId === organizationId);
  const defaultOrganizationUnit = filteredUnits[0].id;
  const [newMachine, setNewMachine] = useState({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 1,
    conversions: [],
    emissions: [],
    organizationId: 0,
    ...machine === undefined ? {} : clone(machine),
  });

  useEffect(() => {
    onChange(newMachine);
  }, [newMachine, onChange]);

  useEffect(() => {
    if (newMachine.id !== machine.id) {
      setNewMachine(machine);
    }
  }, [machine, newMachine.id]);

  const handleChange = (element) => (event) => {
    const { name, value } = event.currentTarget;
    newMachine[name] = value;
    setNewMachine(newMachine);
  };

  loglevel.info(readOnly);


  const TextField = ({ element, field, placeholder, editMachineDetails, checkNumber }) => (<Form.Control type={`${checkNumber ? "number" : "text"}`} defaultValue={element[field] === 0 ? " " : element[field]} name={field} onBlur={handleChange(element)} placeholder={placeholder} className="database-forms-placeholder" disabled={!editMachineDetails}/>);


  const handleRemoveConversion = (idx) => {
    loglevel.info('Removing conversion', idx);
    newMachine.conversions.splice(idx, 1);
    setNewMachine({ ...newMachine });
  };
  const handleAddConversion = () => {
    loglevel.info('Adding conversion');
    const firstEmission = newMachine.emissions.length > 0 && newMachine.emissions[0];
    let toUnitId = firstEmission.fromUnitId;
    if (fromUnitId !== undefined && Number(fromUnitId) === 0) {
      fromUnitId = defaultOrganizationUnit;
    }
    if (toUnitId !== undefined && Number(toUnitId) === 0) {
      toUnitId = defaultOrganizationUnit;
    }
    newMachine.conversions.push({
      machineId: 0,
      fromUnitId: fromUnitId !== undefined ? Number(fromUnitId) : defaultOrganizationUnit,
      toUnitId: toUnitId !== undefined ? Number(toUnitId) : defaultOrganizationUnit,
      factor: 0,
      comment: '',
      inverse: false,
    });
    setNewMachine({ ...newMachine });
  };
  const handleRemoveEmission = (idx) => {
    loglevel.info('Removing emission', idx);
    newMachine.emissions.splice(idx, 1);
    setNewMachine({ ...newMachine });
  };
  const handleAddEmission = () => {
    loglevel.info('Adding emission');
    const firstConversion = newMachine.conversions.length > 0 && newMachine.conversions[0];
    let conversionFromUnitId = firstConversion.toUnitId;
    if (conversionFromUnitId !== undefined && Number(conversionFromUnitId) === 0) {
      conversionFromUnitId = defaultOrganizationUnit;
    }
    newMachine.emissions.push({
      machineId: 0,
      fromUnitId: conversionFromUnitId !== undefined ? Number(conversionFromUnitId) : defaultOrganizationUnit,
      emission: 0,
      source: '',
      comment: '',
    });
    setNewMachine({ ...newMachine });
  };
  return (
    <Form>
      <div>
        <h4>
          {t('database.Machine')}
          {' '}
        </h4>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('common.Name')}
              </Form.Label>

              <TextField element={newMachine} field="name" placeholder={isInstallation ? '' : t('machineForm.eg Name')} editMachineDetails={editMachineDetails}/>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('common.Description')}
              </Form.Label>

              <TextField element={newMachine} field="description" placeholder={isInstallation ? '' : t('machineForm.eg Description')} editMachineDetails={editMachineDetails}/>

            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('database.Module')}
              </Form.Label>

              <TextField element={newMachine} field="module" placeholder={isInstallation ? '' : t('machineForm.eg Module')} editMachineDetails={editMachineDetails}/>

            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>
                {t('database.Expiration Year')}
              </Form.Label>

              <TextField element={newMachine} checkNumber={true} field="expirationYear" placeholder={isInstallation ? '' : t('machineForm.eg Expiration Year')} editMachineDetails={editMachineDetails}/>

            </Form.Group>
          </Col>
        </Row>

        {/* Conditional Error Displaying */}
        {errors?.includes("name") && (
          <Row>
            <Col xs="3">
              <Error text={`${t("common.Name")} ${t("project.is required")}`} />
            </Col>
          </Row>
        )}

        <h4>{t('common.Emissions')}</h4>
        <Table>
          {newMachine.emissions.length > 0 && (
            <thead>
              <tr>
                <th>{t('common.Unit')}</th>
                <th>{t('common.Emission factor')}</th>
                <th>{t('database.Source')}</th>
                <th>{t('common.Comment')}</th>
                <th />
              </tr>
            </thead>
          )}
          <tbody>
            {newMachine.emissions.map((e, i) => (
              <EmissionRow
                readOnly={readOnly}
                organizationId={organizationId}
                conversion={e}
                key={i}
                onChange={(c) => {
                  if (c === 'remove') {
                    handleRemoveEmission(i);
                  } else {
                    newMachine.emissions[i] = c;
                    setNewMachine({ ...newMachine });
                  }
                }}

                isInstallation = {isInstallation}

                hideTrash={hideTrash}
                editEmissionDetails={editEmissionDetails}

              />
            ))}
          </tbody>
          {(isInRole('Admin') && editable && !hideAddEmission) ? <tfoot>
            <tr>
              <td colSpan="5">
                <Button onClick={() => { handleAddEmission(); }}>
                  <FaPlus />
                  {' '}
                  {t('database.Add emission')}
                </Button>
              </td>
            </tr>
          </tfoot> : null}
        </Table>
        <h4>{t('common.Conversions')}</h4>
        <Table>
          {newMachine.conversions.length > 0 && (
            <thead>
              <tr>
                <th>{t('product.From unit')}</th>
                <th>{t('product.To unit')}</th>
                <th>{t('common.Factor')}</th>
                <th>{t('common.Comment')}</th>
                <th />
              </tr>
            </thead>
          )}
          <tbody>
            {newMachine.conversions.map((conversion, i) => (
              <ConversionRow
                organizationId={organizationId}
                conversion={conversion}
                key={i}
                onChange={(c) => {
                  if (c === 'remove') {
                    handleRemoveConversion(i);
                  } else {
                    newMachine.conversions[i] = c;
                    setNewMachine({ ...newMachine });
                  }
                }}
                editable={editable}
                hideTrash={hideTrash}
              />
            ))}
          </tbody>
          {
            editable && <tfoot>
              <tr>
                <td colSpan="5">
                  <Button onClick={() => { handleAddConversion(); }}>
                    <FaPlus />
                    {' '}
                    {t('product.Add conversion')}
                  </Button>
                </td>
              </tr>
            </tfoot>
          }
        </Table>
      </div>
    </Form>
  );
});
