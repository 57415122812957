import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Row,
  Col,
  ButtonGroup,
  Button,
  Nav
} from 'react-bootstrap';

// Base services
import Api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';
import loglevel from '../../../services/loglevel';
import { emptyProduct } from '../../common/initializers';
// Common compoinents
import { Typeahead } from '../../common/components';
import TransportationForm from '../../CalculationsForms/TransportationForm';

const ElementFormWithProduct = withSettingsStore((props) => {
  // Translations
  const { t } = useTranslation();

  // Handle props
  const { getUnitById, scenarioId, displayError, onChange, organizationId } = props;
  const [element, setElement] = useState(props.element);
  const [template, setTemplate] = useState({
    id: 0,
    name: '',
  });

  const [currentView, setCurrentView] = useState("product");

  // Search from Api
  const searchTemplates = async ({ search }) => Api().templates().get({ search });
  const searchCodes = async ({ search }) => props.codes
    .filter((c) => (c.name.toLowerCase().includes(search.toLowerCase())
      || c.description.toLowerCase().includes(search.toLowerCase())));
  
  const searchMaterials = async ({ search }) => {
    const results = await Api().materials().get({ search, filterByOrgId: organizationId  });
    return results.items.map((r) => ({ ...r, unit: getUnitById(r.unitId).abbreviation }));
  };
 

  const getMatchingConversionId = useCallback((product) => {
    if (element === undefined
      || element === null
      || product === undefined
      || product === null
      || product.material === undefined) {
      return 0;
    }
    const fromUnitId = element.unitId;
    const toUnitId = product.material.unitId;

    const conversion = product.material.conversions
      .find((c) => Number(c.fromUnitId) === fromUnitId && Number(c.toUnitId) === toUnitId);
    if (conversion !== undefined) {
      return conversion.id;
    }
    return 0;
  }, [element]);

  // Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    element[name] = value;
    setElement({ ...element });
    props.onChange(element);
  };

  // Handle code
  const handleCodeSelect = (code) => {
    element.code = code.name;
    setElement({ ...element });
  };

  // Handle templates
  const handleTemplateSelect = (tpl) => {
    loglevel.info('template selected:', tpl);
    element.code = tpl.code;
    element.name = tpl.name;
    element.unitId = tpl.unitId;
    element.products = tpl.products;
    element.transports = tpl.transports;
    element.installations = tpl.installations;
    setElement({ ...element });
    props.onChange(element);
    setTemplate(tpl);
  };
  const removeTemplate = () => {
    element.products = [];
    element.transports = [];
    element.installations = [];
    setElement({ ...element });
    setTemplate({ id: 0, name: '' });
  };
  /*const searchAndSetTemplate = async () => {
    loglevel.info('Searching...', element);
    const results = await Api().templates().get({
      code: element.code,
      search: element.name,
      unitId: element.unitId,
    });
    if (results.length > 0) {
      const tpl = results[0];
      element.products = tpl.products;
      element.transports = tpl.transports;
      element.installations = tpl.installations;
      setElement({ ...element });
      onChange({...element})
      setTemplate(tpl);
    } else {
      removeTemplate();
    }
  };*/

  // Handle materials
  const handleMaterialSelect = (product, material) => {
    const target = element.products.find((p) => p === product);
    target.material = material;
    target.materialId = material.id;
    target.conversionId = getMatchingConversionId(target);
    target.name = material.name;
    
    element.name = material.name;
    element.unitId = material.unitId;

    setElement({ ...element });
    onChange({...element})
    //setProducts([...products]);
  };

  // Unit listings only use units available for the selected material
  const availableUnits = useMemo(() => {
    const { material } = element.products[0];
    return props.units.filter((u) => material.conversions.map(c => c.fromUnitId).includes(u.id) || u.id === material.unitId);
  }, [element]);

  return (
    <Form>
      <Row className="text-center" style={{ justifyContent: 'center', marginBottom: '1em' }}>
        <Nav variant="pills" defaultActiveKey={currentView} onSelect={(key) => setCurrentView(key)}>
          <Nav.Item>
            <Nav.Link eventKey="product">{t('product.Product')}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="transport">{t('transportation.Transport')}</Nav.Link>
          </Nav.Item>
        </Nav>
      </Row>
      {currentView === 'transport' && (
        <Row>
          <Col>
            <TransportationForm element={element} project={props.project} organizationId={organizationId}/>
          </Col>
        </Row>
      )}
      {currentView === 'product' && (
        <>
          <Row>
            <Col>
              <Form.Label>
                {t('product.Material')}
              </Form.Label>
              <Typeahead value={element.products[0].material.name} onSelect={(mat) => { handleMaterialSelect(element.products[0], mat); }} source={searchMaterials} resultText="{name}, {unit}" resultIcon={(res) => (res.createdBy > 0 ? 'user' : 'database')} resultBackgroundColor={(res) => res.isLinked && '#d4edda'} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Name')}
                </Form.Label>
                <Form.Control name="name" value={element.name} defaultValue={element.name} placeholder={t('common.Enter name')} onChange={(e) => handleInputChange(e)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Description')}
                </Form.Label>
                <Form.Control name="description" defaultValue={element.description} placeholder={t('common.Enter description')} onChange={(e) => handleInputChange(e)} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Quantity')}
                </Form.Label>
                <Form.Control name="quantity" defaultValue={element.quantity} placeholder={t('common.Enter quantity')} onChange={(e) => handleInputChange(e)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  {t('common.Unit')}
                </Form.Label>
                {/* We only list units that are available for the selected material */}
                <Form.Control as="select" name="unitId" placeholder={t('common.Unit')} value={element.unitId} 
                // defaultValue={element.unitId} 
                onChange={(e) => handleInputChange(e)}>
                  {availableUnits.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.name}
                      {' '}
                  (
                      {unit.abbreviation}
                  )
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
});

ElementFormWithProduct.propTypes = {
  displayError: PropTypes.bool,
  scenarioId: PropTypes.number.isRequired,
  element: PropTypes.shape({
    name: PropTypes.string,
  }),
  project: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
ElementFormWithProduct.defaultProps = {
  displayError: false,
  element: {
    id: undefined,
    name: '',
    description: '',
    code: '',
    quantity: 0,
    unitId: 0,
    totalEmission: 0,
    products: [
      emptyProduct(),
    ],
  },
};

export default ElementFormWithProduct;
