import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FiHelpCircle, FiArrowUp } from "react-icons/fi";
import {
  Form,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  OverlayTrigger,
  Tooltip,
  Badge,
  Button,
} from "react-bootstrap";
import Error from "../common/Error";
import Map from "../common/Map";
import { withSettingsStore } from "../common/settings-context";
import Api from "../../services/api";

export default withSettingsStore((props) => {
  const createProject = () => ({
    name: "",
    description: "",
    projectNumber: "",
    type: "",
    status: 1,
    phase: "",
    managerName: "",
    started: "",
    ended: "",
    locationName: "",
    tags: [],
    locationLongitude: 24.945831,
    locationLatitude: 60.192059,
    groups: [],
    organizationId: -1
  });
  const { t } = useTranslation();
  const [errors, setErrors] = useState(
    props.errors === undefined ? [] : props.errors
  );
  const [updateError, setUpdateError] = useState(
    props.updateError === undefined ? [] : props.updateError
  )
  const [tags, setTags] = useState(props.project.tags);
  const [project, setProject] = useState({
    ...createProject(),
    ...props.project,
  });

  const [organizations, setOrganizations] = useState([]);
  const [projectPhases, setProjectPhases] = useState([]);
  const [inputValue, setInputValue] = useState('');


  const getProjectPhases = useCallback(async () => {
    //Get project types
    const projectTypeValueEnum = Object.entries(props.projectTypes).reduce((obj, [key, value]) => {
      obj[value] = key;
      return obj;
    }, {});

    //Get project phases
    const projectPhaseValueEnum = Object.entries(props.projectPhases).reduce((obj, [key, value]) => {
      obj[value] = key;
      return obj;
    }, {});

    //Get selected project string
    var selectedProjectName = projectTypeValueEnum[project.type];
    var selectedProject = project.type;

    //Filtered the stages
    const gripStages = Object.entries(projectPhaseValueEnum).filter(([key, value]) => Number(key) > 14 && Number(key) < 23);
    const otherStages = Object.entries(projectPhaseValueEnum).filter(([key, value]) => Number(key) < 7 || Number(key) === 23);

    //If statement to determine which stages to filter
    if (project.organizationId != 1) {
      if (selectedProject.toString() === "3") {
        setProjectPhases(gripStages);
      } else {
        setProjectPhases(otherStages);
      }
    }
    else {
      setProjectPhases(otherStages);
    }


  }, [projectPhases, project.type]);

  //Project Tags

  const handleTagChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue(''); // Clear input after adding tag
    }
  };

  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  const getOrganiztions = useCallback(async () => {
    const getAllOrganizations = await Api().settings.organizations();
    setOrganizations(getAllOrganizations);
  }, [organizations]);

  useEffect(() => {
    getOrganiztions();
  }, []);

  useEffect(() => {
    getProjectPhases();
  }, [project.type, project.phase]);

  useEffect(() => {
    setProject({ ...createProject(), ...props.project });
    props.project.tags = [...tags];
    project.tags = [...tags];
    setErrors(props.errors === undefined ? [] : props.errors);
    setUpdateError(props.updateError === undefined ? [] : props.updateError);
  }, [props.project, props.errors, tags, props.updateError]);

  console.log(props);

  useEffect(() => {
    getProjectPhases();
  }, []);

  const handleChange = (event) => {
    let { value } = event.currentTarget;
    if (value === "true") {
      value = true;
    }

    if (value === "false") {
      value = false;
    }    
    project[event.currentTarget.name] = value;
    setProject({ ...project });
    props.onChange(project);
  };

  const handleLocationChange = (pos) => {
    project.locationLatitude = pos.lat;
    project.locationLongitude = pos.lng;
    setProject({ ...project });
    props.onChange(project);
  };


  const handleOrganizationChange=(event)=>{

    project[event.currentTarget.name] = event.target.value;
    setProject({...project});
    props.onChange(project)
  }

  const tooltip = (
    <Tooltip id="tooltip-public">
      {t(
        "project.A public URL is created for the public project to view the results of the project"
      )}
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip-public">
      {t(
        "common.End date cannot be before the Start date"
      )}
    </Tooltip>
  );

  const tagsTooltip = (
    <Tooltip id="tooltip-public">
      {t(
        "project.Creating tags for the project generates searchable keywords. This makes finding related projects."
      )}
    </Tooltip>
  );

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="formName">
            <Form.Label>{t("common.Name")} *</Form.Label>
            <Form.Control
              name="name"
              defaultValue={project.name}
              type="text"
              placeholder={t("common.Enter name")}
              onChange={handleChange}
              required
              autoComplete="off"
            />
            {(errors.includes("name") || updateError.includes("projectName")) && (
              <Error text={`${t("common.Name")} ${t("project.is required")}`} />
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formDescription">
            <Form.Label>{t("common.Description")}</Form.Label>
            <Form.Control
              name="description"
              defaultValue={project.description}
              type="text"
              placeholder={t("common.Enter description")}
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formNumber">
            <Form.Label>{t("project.Project number")}</Form.Label>
            <Form.Control
              name="projectNumber"
              defaultValue={project.projectNumber}
              type="text"
              placeholder={t("project.Enter project number")}
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formStatus">
            <Form.Label>{t("project.Status")}</Form.Label>
            <Form.Control
              name="status"
              defaultValue={project.status}
              as="select"
              placeholder={t("Enter status")}
              onChange={handleChange}
              required
              autoComplete="off"
            >
              {Object.keys(props.projectStatuses).map((s) => (
                <option key={s} value={Number(props.projectStatuses[s])}>
                  {t(`project.${s}`)}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formManager">
            <Form.Label>{t("project.Project manager")}</Form.Label>
            <Form.Control
              name="managerName"
              defaultValue={project.managerName}
              placeholder={t("project.Enter project manager")}
              onChange={handleChange}
              autoComplete="off"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formOrg">
            <Form.Label>{`${t("project.Database Source")} *`}</Form.Label>
            <Form.Control
              name="organizationId"
              defaultValue={project.organizationId || t('database.Select database source')}
              value={project.organizationId}
              disabled={props.fromEditForm}
              as="select"
              placeholder={`${t('database.Database')} ${'database.Source'} `}
              onChange={handleOrganizationChange}
              required
              autoComplete="off"
            >
              <option disabled value={-1} >
                {t('database.Select database source')}
              </option>
              {organizations.map((ele) => <option value={ele.id}>{t(`organization.${ele.name}`)} </option>)}
            </Form.Control>
            {errors.includes("projectOrganization") && (
              <Error text={ t('database.Select database source')  } />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="formType">
            <Form.Label>{`${t("project.Project type")} *`}</Form.Label>
            <Form.Control
              name="type"
              defaultValue={project.type ?? t('project.Select project type')}
              as="select"
              placeholder={t('project.Select project type')}
              onChange={handleChange}
              required
              autoComplete="off"
            >
              <option value = '' disabled >
                {t('project.Select project type')}
              </option>
              {Object.keys(props.projectTypes).slice(0, 9).concat(Object.keys(props.projectTypes).slice(10)).map((s) => (
                <option value={props.projectTypes[s]}>
                  {t(`project.${s}`)}
                </option>
              ))}
            </Form.Control>
            {errors.includes("type") && (
              <Error text={`${t("generic.Type")} ${t("project.is required")}`} />
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formPhase">
            <Form.Label>{t("project.Project phase")}</Form.Label>
            <Form.Control
              name="phase"
              value={project.phase || t("Enter project type before selecting phase...")}
              as="select"
              placeholder={t("Enter project type before selecting phase...")}
              onChange={handleChange}
              required
              autoComplete="off"
            >
              {project.type === ""
                ? <option selected disabled>{t('Enter project type before selecting phase...')}</option>
                : null}

              {projectPhases.map(([key, phase]) => (
                <option key={key} value={key}>{t(`project.${phase}`)}</option>
              ))}
              {/* {Object.keys(props.projectPhases).slice(0,-1).map((s) => <option value={props.projectPhases[s]}>{t(`project.${s}`)}</option>)} */}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formLocationName">
            <Form.Label>{t("project.Location name")}</Form.Label>
            <Form.Control
              name="locationName"
              defaultValue={project.locationName}
              placeholder={t("project.Enter location name")}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Label >{t("project.Project Tags")}</Form.Label>
          <OverlayTrigger
            trigger={("hover")}
            placement="right"
            overlay={tagsTooltip}
          >
            <FiHelpCircle/>
          </OverlayTrigger>
          <div className="tags-input-container">
            <div className="tag-container" style={{display: 'flex', marginBottom: '10px'}}>
              {tags.map((tag, index) => (
              <div className="tag-item" key={index} style={{ marginRight: '10px'}}>
                  <Badge pill style={{ backgroundColor: '#e9ecef', color: 'gray' }}
                  > {tag}
                    <span className="close" onClick={() => removeTag(index)}
                    style={{ fontSize: '1.2em', verticalAlign: 'middle', marginLeft: '5px', color: '#344254'}}
                      onMouseEnter={(e) => e.target.style.color = 'red'} // Change color on hover
                      onMouseLeave={(e) => e.target.style.color = '#344254'}>
                      &times;
                    </span>
                  </Badge>
                </div>
              ))}
            </div>
            <div className="tags-input" style={{ display: 'flex' }}>
              <Button variant="outline-primary" onClick={(handleAddTag)}>
                <FiArrowUp width="3px" height="3px"/>
              </Button>
              <Form.Control
                className="tags-input"
                placeholder={t('project.Enter Tags')}
                value={inputValue}
                onChange={handleTagChange}
                style={{width: '40vw'}}
              />


            </div>


          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formLocation">
            <Form.Label>{t("project.Location")}</Form.Label>
            <Map
              position={{
                lat: project.locationLatitude,
                lng: project.locationLongitude,
              }}
              editable
              onMarkerChange={handleLocationChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="formDateStart">
            <Form.Label>{`${t("project.Start date")} *`}</Form.Label>
            <Form.Control
              name="started"
              defaultValue={project.started.slice(0, 10)}
              type="date"
              lang="fr-CA"
              placeholder={t("project.Start date")}
              onChange={handleChange}
              required
              autoComplete="off"
              max={project.ended ? new Date(project.ended).toISOString().split("T")[0]: ""}
            />
            {errors.includes("started") && (
              <Error
                text={`${t("project.Start date")} ${t("project.is required")}`}
              />
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formDateEnd">
            <Form.Label>
              {t("project.End date")}
              <OverlayTrigger
                trigger={("hover", "focus")}
                placement="right"
                overlay={tooltip2}
              >
                <FiHelpCircle />
              </OverlayTrigger>
            </Form.Label>

            <Form.Control
              name="ended"
              defaultValue={
                project.ended !== null &&
                project.ended !== undefined &&
                project.ended.slice(0, 10)
              }
              type="date"
              lang="fr-CA"
              placeholder={t("project.End date")}
              onChange={handleChange}
              min={project.started ? project.started.split("T")[0]: ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
        {(errors.includes("startDate") || updateError.includes("startDate")) && (
          <Error
            text={`End date can not be before start date`}
          />
        )}
        </Col>        
      </Row>

      {props.fromEditForm ? <Row>
        <Col>
          <Form.Group controlId="formAvailability">
            <div>
              <Form.Label>
                {t("project.Public availability")}{" "}
                <OverlayTrigger
                  trigger={("hover", "focus")}
                  placement="right"
                  overlay={tooltip}
                >
                  <FiHelpCircle />
                </OverlayTrigger>
              </Form.Label>
            </div>

            <ButtonGroup toggle className="mb-2">
              <ToggleButton
                type="checkbox"
                variant="secondary"
                checked={project.isPublic}
                value={!project.isPublic}
                name="isPublic"
                onChange={handleChange}
              >
                {project.isPublic && t("project.Project is public")}
                {!project.isPublic && t("project.Project is private")}
              </ToggleButton>
            </ButtonGroup>
          </Form.Group>
        </Col>
      </Row> : null}

    </Form>
  );
});
