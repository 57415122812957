import React, { useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  ButtonGroup,
  FormControl,
  OverlayTrigger,
  Form,
  Alert,
  Container,
  Row,
  Popover,
  Dropdown,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withSettingsStore } from '../../common/settings-context';
import { humanize } from '../../common/helpers';

const ExplainModal = withSettingsStore(({
  explain, show, onHide, getUnitById,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
  }, [explain]);

  const stages = {
    0: 'None',
    1: 'Product',
    2: 'Construction',
    3: 'ConstructionTransport',
    4: 'ConstructionInstallation',
  };

  const action = {
    0: 'None',
    1: 'Addition',
    2: 'Substraction',
    3: 'Multiplication',
    4: 'Division',
    5: 'Empty'
  };

  const actionText = {
    0: '=',
    1: '+',
    2: '-',
    3: '×',
    4: '÷',
    5: '',
  };

  const getValue = () => {

  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{t('common.Explain')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th>{t('explain.Stage')}</th>
              <th>{t('common.Name')}</th>
              <th>{t('explain.Description')}</th>
              <th>{t('explain.Op')}</th>
              <th>{t('explain.Value')}</th>
              <th>{t('explain.Result')}</th>
            </tr>
          </thead>
          {Array.isArray(explain) && explain.map((e) => (
            <tr>
              <td>{t(stages[e.stage])}</td>
              <td><strong>{t(`explain.${e.name}`, {defaultValue: e.name})}</strong></td>
              <td>{t(`explain.${e.description}`, {defaultValue: e.description})}</td>
              <td>{actionText[e.type]}</td>
              {/* <td>{e.type !== 0 && `${humanize.amount_long(e.value, 3)} ${e.unitIds.map((u) => getUnitById(u).symbol).join(' / ')}`}</td> */}
              <td>
                <strong>
                  {
                    e.type !== 0 && 
                    `${
                      (0 < e.value && e.value < 0.001) ?
                        e.value.toExponential(3) :
                        humanize.amount_long(e.value, 3)
                    } ${e.unitIds.map((u) => getUnitById(u).symbol).join('/')}`
                  }
                </strong>
              </td>
              <td>
                <strong>
                  {
                    (0 < e.result && e.result < 0.001) ?
                      e.result.toExponential(3) :
                      humanize.amount_long(e.result, 3)
                  }
                {' '}
                {e.resultUnitIds.map((u) => getUnitById(u).symbol).join('/')}
                </strong>
              </td>
              {/* <td>
                <strong>{humanize.amount_long(e.result, 3)}</strong>
                {' '}
                {e.resultUnitIds.map((u) => getUnitById(u).symbol).join(' / ')}
              </td> */}
            </tr>
          ))}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()}>{t('common.Close')}</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ExplainModal;
