import React from 'react';

const EulaEn = () => (
  <div id="eula">
    <p><strong>End User Terms and Conditions for Ramboll’s SaaS Service</strong></p>
    <h1 id="use-of-the-service">Use of the Service</h1>
    <h2
      id="this-application-and-the-services-related-to-same-jointly-the-service-is-owned-and-made-available-to-you-by-ramboll-finland-oy-0101197-5-itsehallintokuja-3-p.o.-box-25-02601-espoo-ramboll.">
      This application and the services related to same (jointly, the “Service”) is owned and made available to you by
      Ramboll Finland Oy, 0101197-5, Itsehallintokuja 3, P.O. Box 25, 02601 Espoo (“Ramboll”).</h2>
    <h2
      id="by-registering-as-a-user-of-the-service-andor-by-utilising-the-service-you-accept-these-terms-and-conditions-as-outlined-below.-if-you-do-not-accept-the-terms-and-conditions-you-will-not-be-entitled-to-use-the-service.">
      By registering as a user of the Service and/or by utilising the Service, you accept these terms and conditions
      as
      outlined below. If you do not accept the terms and conditions, you will not be entitled to use the Service.</h2>
    <h1 id="grant-of-license-and-intellectual-property-rights">Grant of License and Intellectual Property Rights</h1>
    <h2 id="ramboll-grants-to-you-a-non-exclusive-revocable-and-non-transferable-license-to-use-the-service.">Ramboll
      grants
      to you a non-exclusive, revocable and non-transferable license to use the Service.</h2>
    <h2
      id="ramboll-or-its-licensors-own-all-intellectual-property-rights-including-patent-copyright-trade-secret-trademark-and-other-proprietary-rights-in-and-to-the-application-pertaining-to-the-service-the-application-the-content-made-available-through-the-application-and-any-corrections-bug-fixes-enhancements-updates-or-other-modifications-to-the-application.">
      Ramboll or its licensors own all intellectual property rights, including patent, copyright, trade secret,
      trademark
      and other proprietary rights, in and to the application pertaining to the Service (the “Application”), the
      content
      made available through the Application and any corrections, bug fixes, enhancements, updates or other
      modifications
      to the Application.</h2>
    <h2
      id="nothing-in-these-terms-and-conditions-shall-confer-upon-you-the-right-to-use-any-of-rambolls-trade-names-trademarks-service-marks-logos-domain-names-or-other-distinctive-brand-features.">
      Nothing in these terms and conditions shall confer upon you the right to use any of Ramboll’s trade names,
      trademarks, service marks, logos, domain names, or other distinctive brand features.</h2>
    <h2
      id="you-are-not-permitted-to-reverse-engineer-decompile-andor-disassemble-the-application-or-otherwise-seek-to-obtain-the-source-code-to-the-application-or-any-source-material-displayed-via-the-application.">
      You are not permitted to reverse engineer, decompile and/or disassemble the Application or otherwise seek to
      obtain
      the source code to the Application or any source material displayed via the Application.</h2>
    <h1 id="liability">Liability</h1>
    <h2 id="the-service-is-provided-as-is-and-ramboll-does-not-warrant-or-guarantee-that-the-service-will-be">The
      Service is
      provided as is and Ramboll does not warrant or guarantee that the Service will be:</h2>
    <ol type="1">
      <li>
        <p>compatible with all or any hardware and software that you may use;</p>
      </li>
    </ol>
    <ol start="4" type="1">
      <li>
        <p>available all the times or at any specific time, uninterrupted, secure or error free; or</p>
      </li>
      <li>
        <p>suitable for your requirements or meet any specific level of performance or functionality.</p>
      </li>
    </ol>
    <h2
      id="you-agree-that-ramboll-is-not-liable-for-any-direct-or-indirect-damages-arising-out-of-your-use-of-the-service-unless-otherwise-stipulated-by-mandatory-legislation.-for-the-avoidance-of-doubt-this-exclusion-of-liability-shall-encompass-any-loss-of-profits-business-revenue-or-goodwill-and-any-other-indirect-and-consequential-losses.">
      You agree that Ramboll is not liable for any direct or indirect damages arising out of your use of the Service,
      unless otherwise stipulated by mandatory legislation. For the avoidance of doubt, this exclusion of liability
      shall
      encompass any loss of profits, business, revenue or goodwill and any other indirect and consequential losses.
    </h2>
    <h2
      id="if-the-application-is-defective-or-the-performance-of-the-application-is-reduced-ramboll-may-in-its-sole-discretion-provide-an-update-to-the-application-in-order-to-fix-the-defect-or-performance-issue.-this-shall-constitute-your-sole-and-exclusive-remedies-in-respect-of-any-defects-or-performance-issues-related-to-the-application.">
      If the Application is defective or the performance of the Application is reduced, Ramboll may in its sole
      discretion
      provide an update to the Application in order to fix the defect or performance issue. This shall constitute your
      sole and exclusive remedies in respect of any defects or performance issues related to the Application.</h2>
    <h1 id="privacy-and-data-protection">Privacy and Data Protection</h1>
    <h2
      id="the-user-shall-be-liable-for-all-actions-occurring-via-their-user-account-as-well-as-for-maintaining-all-passwords-and-other-user-identification-confidential.">
      The user shall be liable for all actions occurring via their user account, as well as for maintaining all
      passwords
      and other user identification confidential.</h2>
    <h2
      id="ramboll-collects-and-processes-the-users-personal-data-in-order-to-provide-the-functionalities-of-the-application-and-for-other-purposes-mentioned-in-these-terms-of-use-and-the-service-description-of-the-service-the-purpose.-all-personal-data-will-be-treated-as-confidential-information-and-only-be-used-and-accessed-by-ramboll-for-the-purpose-and-for-the-term-of-validity-of-the-purpose.">
      Ramboll collects and processes the user’s personal data in order to provide the functionalities of the
      Application
      and for other purposes mentioned in these Terms of Use and the service description of the Service (the
      “Purpose”).
      All personal data will be treated as confidential information and only be used and accessed by Ramboll for the
      Purpose and for the term of validity of the Purpose.</h2>
    <h2
      id="ramboll-may-engage-third-party-service-providers-to-work-with-us-to-administer-and-provide-the-functionality-in-the-application.-such-third-party-service-providers-have-access-to-personal-data-only-for-fulfilling-the-purpose-and-are-expressly-obligated-to-secure-the-information-and-not-to-disclose-or-use-such-information-for-any-other-purpose.-this-may-include-recipients-outside-of-the-eueea.-further-information-regarding-the-physical-location-of-personal-data-and-data-transfers-is-available-from-the-service-description-of-the-service.">
      Ramboll may engage third party service providers to work with us to administer and provide the functionality in
      the
      Application. Such third party service providers have access to personal data only for fulfilling the Purpose and
      are
      expressly obligated to secure the information and not to disclose or use such information for any other purpose.
      This may include recipients outside of the EU/EEA. Further information regarding the physical location of
      personal
      data and data transfers is available from the service description of the Service. </h2>
    <h2
      id="in-your-capacity-as-a-user-you-can-obtain-further-information-on-the-personal-data-which-ramboll-stores-and-processes-about-you-by-contacting-inforamboll.com.-as-a-data-subject-you-may-object-to-your-personal-data-being-processed-request-that-your-personal-data-be-rectified-or-deleted-or-withdraw-your-consent-to-such-processing-at-any-time.-if-you-wish-to-withdraw-your-consent-or-have-your-personal-data-rectified-or-deleted-please-contact-the-e-mail-address-mentioned-above.-however-corporate-clients-should-primarily-contact-the-person-responsible-for-the-data-protection-matters-of-their-own-organisation-when-they-wish-to-exercise-their-above-mentioned-rights.-further-if-you-have-any-complaints-about-rambolls-processing-of-your-personal-data-you-may-contact-the-data-protection-ombudsmans-office.">
      In your capacity as a user, you can obtain further information on the personal data which Ramboll stores and
      processes about you by contacting info@ramboll.com. As a data subject, you may object to your personal data
      being
      processed, request that your personal data be rectified or deleted or withdraw your consent to such processing
      at
      any time. If you wish to withdraw your consent or have your personal data rectified or deleted, please contact
      the
      e-mail address mentioned above. However, corporate clients should primarily contact the person responsible for
      the
      data protection matters of their own organisation when they wish to exercise their above-mentioned rights.
      Further,
      if you have any complaints about Ramboll’s processing of your personal data, you may contact the Data Protection
      Ombudsman’s office.</h2>
    <h1 id="cookies">Cookies</h1>
    <p>We utilise cookies on the website of the Service. You may obtain further information regarding our cookie policy
      from
      <a
        href="https://fi.ramboll.com/ramboll_finland_oy/tietosuoja">https://fi.ramboll.com/ramboll_finland_oy/tietosuoja</a>.
    </p>
    <h1 id="amendments-and-termination">Amendments and termination</h1>
    <h2
      id="ramboll-reserves-the-right-in-its-sole-discretion-to-modify-improve-and-correct-the-service.-ramboll-may-decide-to-discontinue-the-service-or-any-part-thereof-in-its-sole-discretion.">
      Ramboll reserves the right, in its sole discretion, to modify, improve and correct the Service. Ramboll may
      decide
      to discontinue the Service or any part thereof in its sole discretion.</h2>
    <h2
      id="ramboll-may-deactivate-a-user-account-in-case-the-user-breaches-the-terms-of-use-of-the-service-or-if-there-is-reason-to-suspect-that-the-user-is-utilising-the-service-for-unlawful-or-other-inappropriate-purposes.">
      Ramboll may deactivate a user account, in case the user breaches the Terms of Use of the Service or if there is
      reason to suspect that the user is utilising the Service for unlawful or other inappropriate purposes.</h2>
    <h2 id="ramboll-reserves-the-right-to-amend-the-terms-and-conditions-from-time-to-time-without-prior-notice.">
      Ramboll
      reserves the right to amend the terms and conditions from time to time without prior notice.</h2>
    <h1 id="governing-law">Governing Law </h1>
    <p>Your use of the Service and these Terms of Use shall be governed by the laws of Finland, without regard to its
      conflict of laws provisions.</p>
  </div>
);

export default EulaEn;
