export default {
  size(size) {
    size = Number(size);
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
  },
  date(str, type = 'datetime') {
    // Check for date
    const iso8601regex = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
    if (iso8601regex.test(str)) {
      const date = new Date(str);
      if (type === 'datetime') {
        return date.toLocaleString('fi-FI', {
          timeZone: 'Europe/Helsinki',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      }
      return date.toLocaleString('fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' });
    }
    return str;
  },
  amount_short(amount) {
    amount = Number(amount);

    if (amount === 0 || amount === Infinity) {
      return Number(0).toFixed(2);
    }
    const i = Math.floor(Math.log(amount) / Math.log(1000));
    if (i < 0) {
      return amount;
    }
    return `${(amount / Math.pow(1000, i)).toFixed(2) * 1} ${['', 'k', 'M', 'Q'][i]}`;
  },
  amount_long(amount, decimalAmount = 2, thousandSeparator = ' ') {
    amount = Number(amount);
    const integerPart = Math.floor(amount).toFixed(0);
    const decimalPart = amount.toFixed(decimalAmount).split('.')[1];
    let formattedDecimalPart = '';
    if (decimalPart) {
      formattedDecimalPart = decimalPart.replace(/0+$/, '');
    }

    // Use below code if trailing zeros for the decimal part is not handled by the input field.
    // if (decimalPart) {
    //   if (formattedDecimalPart === '') {
    //     formattedDecimalPart = '';
    //   } else {
    //     formattedDecimalPart = `.${formattedDecimalPart}`;
    //   }
    // }
    // Required output:
    // '10000' -> '000' '10'
    // How this approach works
    // '12345' -> '54321' -> '543' '21' -> '21', '543' -> '12', '345'
    const pieces = integerPart.split('').reverse().join('').match(/.{1,3}/g)
      .reverse()
      .map((p) => p.split('').reverse().join(''));

    let str = pieces.join(thousandSeparator);
    (formattedDecimalPart > 0) ? str = `${str}.${formattedDecimalPart}` : str = `${str}`;
    return str;
  },
};