import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  Button,
  ButtonGroup,
  Modal,
  Form,
} from 'react-bootstrap';

import {
  FaTrash,
  FaPlus,
  FaPen,
  FaPaste,
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ElementFormWithProduct from './ElementFormWithProduct';
import { humanize } from '../../common/helpers';
import Api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';
import { emptyElement, emptyElementTransport, emptyProduct } from '../../common/initializers';

const ProductRow = withSettingsStore(({
  product,
  getUnitById,
  onEdit,
  onUpdate,
  onRemove,
  isSelected,
  onSelect,
  organizationId,
}) => {
  const [currentProduct, setCurrentProduct] = useState(product);
  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const componentRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentProduct({ ...currentProduct, [name]: value });
    setIsChanged(true);
  };

  const handleUpdate = () => {
    if (isChanged) {
      onUpdate(currentProduct);
    }
    setIsChanged(false);
  };

  const handleTouch = (e) => {
    const isDescendant = (haystack, needle) => {
      if (haystack === needle) {
        return true;
      }

      if (haystack !== undefined && haystack !== null && haystack.parentElement !== undefined) {
        return isDescendant(haystack.parentElement, needle);
      }

      return false;
    };

    if (isDescendant(e.target, componentRef.current)) {
      setIsEditing(true);
    } else {
      // focus changed. save and continue.
      handleUpdate();
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const event = (e) => {
      handleTouch(e);
    };

    document.body.addEventListener('click', event, true);
    // On removal the eventlistener needs to be removed
    return () => {
      document.body.removeEventListener('click', event, true);
    };
  }, []);

  return (
    <tr
      ref={componentRef}
      onMouseEnter={() => setIsEditing(true)}
      onMouseLeave={() => { handleUpdate(); setIsEditing(false); }}
      onTouchStart={handleTouch}
    >
      <td className="text-center">
        <Form.Check
          type="checkbox"
          defaultChecked={isSelected}
          onClick={(e) => onSelect(!!e.target.checked)}
        />
      </td>
      <td>
        {isEditing
          ? <Form.Control size="sm" defaultValue={currentProduct.name} name="name" onChange={handleInputChange} onBlur={handleUpdate} />
          : currentProduct.name}
      </td>
      <td className="text-right">
        {isEditing
          ? <Form.Control size="sm" defaultValue={currentProduct.transportDistance} name="transportDistance" onChange={handleInputChange} onBlur={handleUpdate} />
          : currentProduct.transportDistance}
      </td>
      <td>
        km
      </td>
      <td className="text-right">
        {isEditing
          ? <Form.Control size="sm" defaultValue={currentProduct.elementQuantity} name="elementQuantity" onChange={handleInputChange} onBlur={handleUpdate} />
          : humanize.amount_long(currentProduct.elementQuantity, 0)}
      </td>
      <td>{getUnitById(currentProduct.elementUnitId).abbreviation}</td>
      <td className="text-right emissions">{humanize.amount_long(currentProduct.elementTotalEmission, 0)}</td>
      <td className="text-right emissions">
        {humanize.amount_long(currentProduct.elementTotalEmissionPercentage)}
        &nbsp;
        %
      </td>
      <td>{currentProduct.description}</td>
      <td className="text-right">
        <ButtonGroup size="sm">
          <Button variant="outline-danger" onClick={() => onRemove(currentProduct.elementId)}><FaTrash /></Button>
          <Button variant="outline-primary" onClick={() => onEdit(currentProduct.elementId)}><FaPen /></Button>
        </ButtonGroup>
      </td>
    </tr>
  );
});

const GroupRow = ({
  group, onAddElement, onUpdate, onRemoveGroup, onMoveElement, moving, organizationId
}) => {
  const { t } = useTranslation();
  const [currentGroup, setCurrentGroup] = useState(group);

  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentGroup({ ...currentGroup, [name]: value });
    setIsChanged(true);
  };

  const handleUpdate = () => {
    if (isChanged) {
      onUpdate(currentGroup);
    }
    setIsChanged(false);
  };
  return (
    <tbody className="thead-light" style={{ background: '#eee' }}>
      <tr>
        <td>
          <Form.Check
            type="checkbox"
          />
        </td>
        <td colSpan="6" onMouseEnter={() => setIsEditing(true)} onMouseLeave={() => { handleUpdate(); setIsEditing(false); }}>
          {isEditing
            ? <Form.Control size="sm" defaultValue={currentGroup.name} name="name" onChange={handleInputChange} onBlur={handleUpdate} disabled={group.id <= 0} />
            : currentGroup.name}

        </td>
        <td colSpan="3" className="text-right">
          <ButtonGroup size="sm">
            {moving ? (
              <Button size="sm" variant="primary" onClick={() => onMoveElement()}>
                <FaPaste />
                &nbsp;
                {' '}
                {t('Move product')}
              </Button>
            ) : (
              <Button onClick={() => onAddElement()}>
                <FaPlus />
                &nbsp;
                {t('product.Add product')}
              </Button>
            )}
            {group.id > 0 && <Button onClick={() => onRemoveGroup()} variant="danger"><FaTrash /></Button>}
          </ButtonGroup>
        </td>
      </tr>
    </tbody>
  );
};

GroupRow.propTypes = {
  onAddElement: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

const ProductTable = withSettingsStore((props) => {
  const {
    displayError,
    scenarioId,
    elements,
    project,
    roadTransports,
    onChange,
    organizationId,
  } = props;

  // Translation
  const { t } = useTranslation();

  // Selected rows
  const [selectedProducts, setSelectedProducts] = useState([]);

  // Modal visibility
  const [showElementModal, setShowElementModal] = useState(false);

  // Modal parameters
  const [newElement, setNewElement] = useState(null);

  // groups
  const groups = useMemo(() => {
    // update project groups
    if (Array.isArray(props.project.groups)) {
      // extend groups
      return [...project.groups.filter((g) => g.parentId === null).map((g) => ({
        ...g,
        children: project.groups
          .filter((pg) => pg.parentId === g.id)
          .map((pg) => ({ ...pg, parentName: g.name })),
      })), {
        disableEdit: true, code: null, name: t('common.No group'), id: null,
      }];
    }
    return [];
  }, [project]);
  // Each row in this view is a product that is extended
  const extendedProducts = useMemo(() => elements.reduce((acc, cur) => {
    acc.push(
      ...cur.products.map((p) => ({
        ...p,
        elementId: cur.id,
        elementName: cur.name,
        elementQuantity: cur.quantity,
        elementUnitId: cur.unitId,
        elementTotalEmission: cur.totalEmission,
        elementTotalEmissionPercentage: cur.totalEmissionPercentage,
        elementGroupId: cur.groupId,
        transportDistance: cur.transports.length > 0 ? cur.transports[0].totalDistance : 0,
      })),
    );
    return acc;
  }, []), [elements]);

  // Api
  // Handle saving and retrieving
  const saveElement = async () => {
    try {
      if (newElement.id > 0) {
        await Api().scenarios(scenarioId).elements(newElement.id).update(newElement);
      } else {
        await Api().scenarios(scenarioId).elements().post(newElement);
      }
    } catch {
      displayError(t('Unable to save element'));
    }
  };

  // Handlers
  const handleSaveElement = async () => {
    await saveElement();
    props.onChange();
    setShowElementModal(false);
  };

  const handleRemoveElementProduct = async (elementId, productId) => {
    const target = elements.find((e) => Number(e.id) === Number(elementId));
    if (target !== undefined) {
      target.products = target.products.filter((p) => p.id !== productId);
      if (target.products.length === 0) {
        // Remove
        await Api().scenarios(scenarioId).elements(target.id).delete();
      } else {
        await Api().scenarios(scenarioId).elements(target.id).update(target);
      }
      props.onChange();
    }
  };

  const handleUpdateSelectedProductsGroup = async (group) => {
    console.log('moving to ', group.id, selectedProducts, elements);
    const targets = elements.filter((e) => e.products.map((p) => Number(p.id)).some((pId) => selectedProducts.includes(pId)));

    for (const target of targets) {
      target.groupId = group.id;
      await Api().scenarios(scenarioId).elements(target.id).update(target);
    }
    setSelectedProducts([]);
    props.onChange();
  };

  const handleEditElementProduct = (elementId) => {
    // TODO Focus the correct product
    const target = elements.find((e) => Number(e.id) === Number(elementId));
    if (target !== undefined) {
      setNewElement(target);
      setShowElementModal(true);
    }
  };

  const handleSelectElementProduct = (productId, selected) => {
    if (selected) {
      if (!selectedProducts.includes(productId)) {
        setSelectedProducts([...selectedProducts, productId]);
      }
    } else if (selectedProducts.includes(productId)) {
      setSelectedProducts([...selectedProducts.filter((id) => id !== productId)]);
    }
  };

  // Update selections to the parent component
  useEffect(() => {
    if (typeof props.onSelect === 'function') {
      props.onSelect(selectedProducts);
    }
  }, [selectedProducts]);

  const filterByGroupId = (groupId) => (row) => row.elementGroupId === groupId;

  return (
    <>
      <Modal size="xl" show={showElementModal} onHide={() => setShowElementModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{newElement !== null && newElement !== undefined && newElement.id !== 0 ? t('product.Edit product') : t('common.New product')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ElementFormWithProduct element={newElement} project={project} onChange={(e) => { setNewElement(e); }} organizationId={organizationId} />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup>
            <Button onClick={() => handleSaveElement()}>{t('common.Save')}</Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>

      <Table responsive className="table-sm" style={{ marginBottom: '0', tableLayout: 'fixed', width: '100%' }}>
        <thead className="thead-light thead-light-border">
          <tr className="no-borders light-blue">
            <th style={{ width: '32px' }} className="text-center">#</th>
            <th style={{ width: '40%' }}>{t('product-table.name')}</th>
            <th style={{ width: '150px' }} className="text-center" colSpan="2">{t('product-table.distance')}</th>
            <th style={{ width: '150px' }} className="text-center" colSpan="2">{t('product-table.quantity')}</th>
            <th colSpan="4" className="emissions text-center">{t('product-table.total-emissions')}</th>
          </tr>
        </thead>

        {groups.map((g) => (
          <>
            <GroupRow
              organizationId={organizationId}
              key={g.id}
              group={g}
              moving={selectedProducts.length > 0}
              onUpdate={async (group) => {
                const existing = props.project.groups.find((pg) => pg.id === group.id);
                if (existing !== undefined) {
                  existing.name = group.name;
                  existing.description = group.description;
                  const updatedProject = await Api()
                    .projects(props.project.id)
                    .update(props.project);
                  props.setProject(updatedProject);
                }
              }}
              onRemoveGroup={async () => {
                const existing = props.project.groups.find((pg) => pg.id === g.id);
                if (existing !== undefined) {
                  props.project.groups = props.project.groups.filter((pg) => pg.id !== g.id);
                  const updatedProject = await Api()
                    .projects(props.project.id)
                    .update(props.project);
                  props.setProject(updatedProject);
                }
              }}
              onAddElement={() => {
                setNewElement({
                  ...emptyElement(),
                  products: [emptyProduct()],
                  groupId: g.id,
                });
                setShowElementModal(true);
              }}
              onMoveElement={() => {
                handleUpdateSelectedProductsGroup(g);
              }}
            />
            {/* Group related rows */}
            <tbody>
              {extendedProducts.filter(filterByGroupId(g.id)).length === 0 && (
                <tr>
                  <td />
                  <td colSpan="7">{t('product-table.no-rows')}</td>
                </tr>
              )}
              {extendedProducts.filter(filterByGroupId(g.id)).map((p) => (
                <ProductRow
                  organizationId={organizationId}
                  key={[p.totalEmission, p.elementTotalEmission].join(':')}
                  product={p}
                  onEdit={(id) => handleEditElementProduct(id)}
                  onRemove={(id) => handleRemoveElementProduct(id, p.id)}
                  onSelect={(checked) => handleSelectElementProduct(p.id, checked)}
                  onUpdate={async (product) => {
                    let element = { ...elements.find((e) => e.id === product.elementId) };
                    let { products, transports } = element;

                    if (products.length === 0) {
                      products = [emptyProduct()];
                    }
                    if (transports.length === 0) {
                      transports = [emptyElementTransport()];
                    }
                    products[0] = product;
                    transports[0].totalDistance = product.transportDistance;
                    if ((transports[0].transportRoadId === null
                      && transports[0].transportWaterborneId === null)
                      || (transports[0].transportRoadId === 0
                        && transports[0].transportWaterborneId === 0)
                      || (transports[0].transportRoadId === undefined
                        && transports[0].transportWaterborneId === undefined)
                    ) {
                      transports[0].transportRoadId = roadTransports[0].id;
                    }
                    element.quantity = product.elementQuantity;

                    element = { ...element, products, transports };
                    await Api().scenarios(scenarioId).elements(element.id).update(element);
                    onChange();
                  }}
                />
              ))}
            </tbody>
          </>
        ))}

      </Table>
    </>
  );
});

ProductTable.propTypes = {
  displayError: PropTypes.func,
  scenarioId: PropTypes.number.isRequired,
  elements: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  project: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
ProductTable.defaultProps = {
  displayError: () => { /**/ },
};

export default ProductTable;
