/**
 * saveAs attempts to save blobs as files
 * it follows the same implementation as FileSaver library
 */
export default (blob, name) => {
  const url = URL.createObjectURL(blob);

  // Test for download link support
  if ('download' in document.createElement('a')) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', name);

    // Create Click event
    const clickEvent = document.createEvent('MouseEvent');
    clickEvent.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    // dispatch click event to simulate download
    a.dispatchEvent(clickEvent);
  } else {
    // fallover, open resource in new tab.
    window.open(url, '_blank', '');
  }
};
