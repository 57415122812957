import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Spinner,
  OverlayTrigger
} from 'react-bootstrap';
import { FaTrash, FaPen } from 'react-icons/fa';
import Api from '../../services/api';

import ElementForm from '../project/scenarios/ElementForm';
import TransportationForm from '../CalculationsForms/TransportationForm';
import ProductForm from '../CalculationsForms/ProductForm';
import InstallationForm from '../CalculationsForms/InstallationForm';

import { withSettingsStore } from '../common/settings-context';
import { renderTooltip, humanize } from '../common/helpers';
import loglevel from '../../services/loglevel';
import notFound from '../../assets/no-data.png';

export default withSettingsStore(({ displayError, getUnitById, projectTypes, organizationSelect, isInRole, user }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');

  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showTemplateProducts, setShowTemplateProducts] = useState(false);
  const [showTemplateTransports, setShowTemplateTransports] = useState(false);
  const [showTemplateInstallations, setShowTemplateInstallations] = useState(false);
  const [showDeleteTemplateDialog, setShowDeleteTemplateDialog] = useState(false);
  const [productError, setProductError] = useState(false);
  const [Dropdown, setDropDownError] = useState(null);
  const [error, setError] = useState(null);

  const emptyTemplate = () => ({
    id: 0,
    name: '',
    description: '',
    code: '',
    organizationId: 0,
    projectType: 0,
  });
  const [templateToDelete, setTemplateToDelete] = useState(emptyTemplate());
  const [loading, setLoading] = useState(false);

  const retrieveTemplates = useCallback(async () => {
    const v = await Api().templates().get({});
    setTemplates(v.map((tpl) => ({ ...tpl, quantity: 1 })));
  }, [setTemplates]);

  console.log(templates);

  const retrieveTemplatesByOrgId = useCallback(async (orgId) => {
    setLoading(true);
    const res = await Api().templates().getTemplateByOrgId({ orgId });
    setTemplates(res.map((tpl) => ({ ...tpl, quantity: 1 })));
    setLoading(false);
  }, [setTemplates, organizationSelect]);

  const removeTemplate = async (template) => {
    console.log(template);
    setTemplateToDelete(template);
    setShowDeleteTemplateDialog(true);
  };

  const handleRemoveTemplate = async (id) => {
    loglevel.info(`remove ${id}`);
    try {
      if (id > 0) {
        await Api().templates(id).delete();
        // retrieveTemplates();
        retrieveTemplatesByOrgId(organizationSelect);
      }
    }
    catch (e) {
      displayError(t('database.failed-to-remove-template'));
    }

  };
  const handleSaveTemplate = async () => {
    try{
      await Api().templates(template.id).update(template);
      setShowTemplate(false);
      setShowTemplateProducts(false);
      setShowTemplateInstallations(false);
      setShowTemplateTransports(false);
      retrieveTemplatesByOrgId(organizationSelect);
    }catch(error){
      console.log(error.response);
    }

  };
  const handleEditTemplate = async (tpl) => {
    setTemplate(tpl);
    setShowTemplate(true);
  };
  const handleEditTransports = async (tpl) => {
    setTemplate(tpl);
    setShowTemplateTransports(true);
  };
  const handleEditProducts = async (tpl) => {
    setTemplate(tpl);
    setShowTemplateProducts(true);
  };
  const handleEditInstallations = async (tpl) => {
    setTemplate(tpl);
    setShowTemplateInstallations(true);
  };
  const filterBySearch = useCallback((m) => m.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0, [searchText]);

  // useEffect(() => {
  //   retrieveTemplates();
  // }, [retrieveTemplates]);
  console.log(organizationSelect);
  useEffect(() => {
    retrieveTemplatesByOrgId(organizationSelect);
  }, [retrieveTemplatesByOrgId, organizationSelect])

    function toggleError() {
    if (error) {
      setError(!error);
    }
  }

  return (
    <div>
      <Modal show={showDeleteTemplateDialog} onHide={() => setShowDeleteTemplateDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('database.Delete template')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('common.Are you sure you want to delete')} {templateToDelete.name}?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteTemplateDialog(false)}>{t('common.Cancel')}</Button>
          <Button variant="outline-danger" onClick={() => { handleRemoveTemplate(templateToDelete.id); setShowDeleteTemplateDialog(false); }}>
            <FaTrash /> {t('common.Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showTemplate} onHide={() => setShowTemplate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('element.Edit template')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ElementForm elements={template} onChange={(e) => setTemplate(e)} organizationId={organizationSelect} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowTemplate(false)}>{t('common.Cancel')}</Button>
          <Button onClick={handleSaveTemplate}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTemplateProducts} onHide={() => setShowTemplateProducts(false)} dialogClassName="form-large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('product.Product calculations')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProductForm element={template} onChange={(e) => setTemplate(e)} organizationId={organizationSelect} setProductError={setProductError} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowTemplateProducts(false)}>{t('common.Cancel')}</Button>
          <Button onClick={handleSaveTemplate}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTemplateTransports} onHide={() => setShowTemplateTransports(false)} dialogClassName="form-large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('transportation.Transportation emissions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransportationForm element={template} onChange={(e) => setTemplate(e)} organizationId={organizationSelect} setDropDownError={setDropDownError}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowTemplateTransports(false)}>{t('common.Cancel')}</Button>
          <Button onClick={handleSaveTemplate}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTemplateInstallations} onHide={() => setShowTemplateInstallations(false)} dialogClassName="form-large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t('installation.Installation emissions')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InstallationForm element={template} onChange={(e) => { setTemplate(e); console.log('LOL', e); }} organizationId={organizationSelect} toggleError={toggleError} setDropDownError={setDropDownError} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowTemplateInstallations(false)}>{t('common.Cancel')}</Button>
          <Button onClick={handleSaveTemplate}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>

      <div className="button-toolbar">
        <InputGroup>
          <InputGroup.Text>{t('common.Search')}</InputGroup.Text>
          <FormControl onChange={(e) => setSearchText(e.target.value)} />
        </InputGroup>
      </div>

      {loading ? (
        <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div id='template' className='table-container'>
          <Table>
            <thead className="sticky1-header">
              <tr>
                <th style={{ width: '1vw' }}></th>
                <th style={{ width: '5vw' }}>{t('common.Code')}</th>
                <th style={{ width: '20vw' }}>{t('common.Name')}</th>
                <th style={{ width: '5vw' }}>{t('common.Unit')}</th>
                <th style={{ width: '20vw' }}>{t('common.Description')}</th>
                <th style={{ width: '10vw', textAlign: 'center' }}>{t('product.Products')}</th>
                <th style={{ width: '10vw', textAlign: 'center' }}>{t('transportation.Transports')}</th>
                <th style={{ width: '10vw', textAlign: 'center' }}>{t('installation.Installations')}</th>
                <th style={{ width: '5vw' }}>{t('project.Project type')}</th>
                <th style={{ width: '5vw' }}>{t('database.Created')}</th>
                {isInRole('Admin') && (<th style={{ width: '5vw' }}>{t('database.Created By')}</th>)}
                <th style={{ width: '5vw' }}>{t('database.Updated')}</th>
                {isInRole('Admin') && (<th style={{ width: '5vw' }}>{t('database.Updated By')}</th>)}
                <th style={{ width: '2vw' }}></th>
              </tr>
            </thead>
            <tbody>
              {templates.length > 0 ? (
                templates.filter(filterBySearch).sort((a, b) => (a.code < b.code ? -1 : 1)).map((v) => (
                  <tr key={v.id}>
                    <td></td>
                    <td>{v.code?.trim() ? v.code : '-'}</td>
                    <td>{v.name?.trim() ? v.name : '-'}</td>
                    <td>{getUnitById(v.unitId).abbreviation}</td>
                    <td>{v.description?.trim() ? v.description : '-'}</td>
                    <td style={{ textAlign: 'center' }}>
                      <Button onClick={() => handleEditProducts(v)} variant="outline-primary">{v.products.length}</Button>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Button onClick={() => handleEditTransports(v)} variant="outline-primary">{v.transports.length}</Button>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Button onClick={() => handleEditInstallations(v)} variant="outline-primary">{v.installations.length}</Button>
                    </td>
                    <td>{t(`project.${Object.keys(projectTypes).find((k) => projectTypes[k] === v.projectType)}`)}</td>
                    <td>{v.createdAt ? humanize.date(v.createdAt, "date") : '-'}</td>
                    {isInRole('Admin') && (<td>{v.createdByName === null ? '-' : (
                          v.createdBy === user.id ? (
                            <strong>{v.createdByName}</strong>
                          ) : 
                          v.createdByName
                        )}
                        </td>)}
                    <td>{v.updatedAt ? humanize.date(v.updatedAt, "date") : '-'}</td>
                    {isInRole('Admin') && (<td>{v.updatedByName === null ? '-' : (
                          v.updatedBy === user.id ? (
                            <strong>{v.updatedByName}</strong>
                          ) : 
                          v.updatedByName
                        )}
                        </td>)}
                    <td className="text-right">
                      <ButtonGroup>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('database.Edit', 1))}>
                          <Button variant="outline-primary" onClick={() => handleEditTemplate(v)}><FaPen /></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('database.Delete', 2))}>
                          <Button variant="outline-danger" onClick={() => removeTemplate(v)}><FaTrash /></Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ height: '60vh' }}>
                  <td colSpan="14" className="text-center">
                    <div className="mt-4 d-flex-col">
                      <div className="inner-div">
                        <img src={notFound} className="img-responsive" style={{ height: '70px' }} alt="Not found" />
                      </div>
                      <div className="inner-div">
                        <h1 style={{ fontSize: "20px" }}>{t('database.Data Not Available in the Database')}</h1>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
});